/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { insertReviewsCompany } from "../../../redux/actions/reviews/ActionReviews";
import { tokenCompanyUsername } from "../../../redux/types/Types";
import { useEffect, useState } from "react";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
const CompanyPollLogic = () => {
  const myDataPollStudent = [
    {
      title: "سمعت كثيراً عن برنامج التدريب التعاوني قبل بدايته",
      answer: false,
      key: 0,
    },
    {
      title: "حملة التعريف ببرنامج التدريب التعاوني كانت مميزة وواضحة الهدف",
      answer: false,
      key: 1,
    },
    {
      title:
        "يمكن ان يكون برنامج التدريب التعاوني فرصة مواتية للمتدرب للانخراط في سوق العمل",
      answer: false,
      key: 2,
    },
    {
      title:
        "الفرصة التدريبية التي يحصل عليها المتدرب بنفسه تساعده كثيراُ على التعرف على سوق العمل",
      answer: false,
      key: 3,
    },
    {
      title:
        "من المهم ان تعرف جهة التدريب عن وقت زيارة المشرف من الكلية /المعهد له",
      answer: false,
      key: 4,
    },
    {
      title: "برنامج التدريب التعاوني مفيد ومشوق",
      answer: false,
      key: 5,
    },
    {
      title: "أدلة برنامج التدريب التعاوني الموزعة علينا كانت كافية للتعريف به",
      answer: false,
      key: 6,
    },
    {
      title:
        "خطة التدريب التعاوني كانت واضحة جداً فلدينا معرفة بما يجب عمله في المنشأة التدريبية كل أسبوع , بمايحقق مصلحة المتدرب والعمل",
      answer: false,
      key: 7,
    },
    {
      title: "هناك توافق كبير بين ماتدرب عليه المتدرب وبين تخصصه",
      answer: false,
      key: 8,
    },
    {
      title: "توقيت التدريب التعاوني مناسب لطبيعة عمل المنشأة",
      answer: false,
      key: 9,
    },
    {
      title:
        "زيارات المشرف الميداني كانت كافية للوقوف على سير برنامج التدريب التعاوني",
      answer: false,
      key: 10,
    },
    {
      title:
        "من الضروري التواصل عن طريق الجوال / الهاتف بين المشرف من الكلية / المعهد وجهة التدريب",
      answer: false,
      key: 11,
    },
    {
      title: "يمكن الربط بين برنامج التدريب التعاوني والحصول على فرصة عمل",
      answer: false,
      key: 12,
    },
    {
      title:
        "من المهم وجود مشرف جهة التدريب أثناء الزيارة الإشرافية من قبل المشرف الميداني من الكلية أو المعهد",
      answer: false,
      key: 13,
    },
    {
      title:
        "بعد حهة التدريب عن منزل المتدرب مؤثر على انضباط المتدرب في التدريب التعاوني",
      answer: false,
      key: 14,
    },
    {
      title:
        " الأعمال المتكررة والروتينية تضعف من محصلة التدريب وتقلل فائدته للمتدرب",
      answer: false,
      key: 15,
    },
    {
      title:
        "فترة التدريب كانت كافية لتطبيق المهارات التي حصل عليها المتدرب في الكلية / المعهد",
      answer: false,
      key: 16,
    },
    {
      title: "الحضور والإنصراف هما اهم عنصرين في عملية تقييم المتدرب",
      answer: false,
      key: 17,
    },
    {
      title: "درجات التقييم في مصلحة المتدرب وتعطيه حقه",
      answer: false,
      key: 18,
    },
    {
      title: "التقارير الدورية تساعد كثيراً في اتمام التقرير الفني",
      answer: false,
      key: 19,
    },
    {
      title:
        "يستطيع المتدرب تنمية مهارات إضافية بعد برنامج التدريب التعاوني غير التي تدرب عليها في الكلية / المعهد",
      answer: false,
      key: 20,
    },
    {
      title:
        "اطلعنا على جميع نماذج التقييم ذات العلاقة ببرنامج التدريب التعاوني",
      answer: false,
      key: 21,
    },
  ];

  const myDispatch = useDispatch();

  // Start Student
  const responseReviews = useSelector((state) => state.rootReviews.reviews);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // limit Gerade the value
  const limitGrade = 5 * myDataPollStudent.length;

  let arrStorage = [];
  let avg;

  // the Array all => [false,false,false,false,false,false,]
  for (let index = 0; index < myDataPollStudent.length; index++) {
    arrStorage.push(false);
  }
  // onSubmit the student poll
  const submitPoll = async (e) => {
    e.preventDefault();
    // console.log(arrStorage);

    // here check if one the value === false => out the function
    for (let index = 0; index < arrStorage.length; index++) {
      if (arrStorage[index] === false) {
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    // here Summition the all values
    let summArrStorage = arrStorage.reduce((a, b) => a + b);

    avg = (summArrStorage * 100) / limitGrade;

    setIsLoading(true);
    setIsLoad(true);
    await myDispatch(
      insertReviewsCompany({
        username: tokenCompanyUsername,
        total: avg.toFixed(1),
        "review-name": "company_poll",
      })
    );
    setIsLoad(false);

    // console.log(avg.toFixed(1));
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseReviews) {
        // console.log(responseReviews);
        setIsLoading(false);
      }
      if (responseReviews.status === 200) {
        if (responseReviews.data === "done") {
          NotificationsToast("done", null, "تم التقييم بنجاح");
          setIsLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        } else if (responseReviews.data === "already") {
          NotificationsToast("warn", null, "تم التقييم مسبقاً ");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseReviews.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في التقييم");
          setIsLoading(false);
          return;
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  return [myDataPollStudent, arrStorage, isLoading, submitPoll];
};

export default CompanyPollLogic;
