/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "../../../duplicateComponents/Button";
import FormInput from "../../../duplicateComponents/FormInput";
import Taps from "../../../duplicateComponents/Taps";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import SopervisorAdditionTasksLogic from "../../../../logic/sopervisor/tasks/SopervisorAdditionTasksLogic";
import { Select } from "antd";
import { Spin, Table, Typography } from "antd";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
import { getAllTasksPage } from "../../../../redux/actions/tasks/ActionTasks";
import { useDispatch } from "react-redux";
import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
const { Column } = Table;
const { Paragraph } = Typography;
const SopervisorAdditionTasksContainer = () => {
  const [
    handelTaskName,
    tasksName,
    myDataInsideSopervisor,
    setArrChecked,
    arrChecked,
    isLoadingAttach,
    submitAddTasks,
    myDataTasks,
    loadingTasks,
    isShowMsgError,
  ] = SopervisorAdditionTasksLogic();
  const myDispatch = useDispatch();

  let filteredOptions = [];

  if (myDataInsideSopervisor) {
    filteredOptions = myDataInsideSopervisor.filter(
      (o) => !arrChecked.includes(o)
    );
  }


  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "المهمة",
      dataIndex: "task_details",
      key: "task_details",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.task_details)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "نسخ المهمة",
      dataIndex: "tesk_id",
      key: "tesk_id",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return <Paragraph copyable={{ text: record.task_details }}></Paragraph>;
      },
    },
  ];

  return (
    <Taps
      title={["إضافة المهام", "المهام السابقة"]}
      charIdAndTarget={["a", "b"]}
      titleItem={[
        <>
          <div
            className="col-12 bg-white p-3 style-shadow-small"
            style={{ borderRadius: "var(--radius)" }}
          >
            <div className="col-12 col-lg-6 m-auto">
              <h5
                className="text-center fw-bold my-2"
                style={{ color: "var(--primary-color)" }}
              >
                إضافة مهام للمتدربين
              </h5>
              <div className="row gx-5 gy-3 ">
                <div className="col-12">
                  <FormInputTextArea
                    type={"text"}
                    onChange={handelTaskName}
                    value={tasksName}
                    label={"عنوان المهمة"}
                    placeholder={"عنوان المهمة..."}
                    msgError={isShowMsgError ? "الحقل مطلوب" : ""}
                  />
                </div>
              </div>
              <div
                className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
                style={{
                  borderRadius: "var(--radius)",
                  // width: "190px",
                }}
              >
                <span className="">
                  <input
                    className="form-check-input border shadow-none"
                    type="checkbox"
                    // value={arrChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      let newArrCheckedMap = [];

                      if (myDataInsideSopervisor) {
                        newArrCheckedMap = Object.values(
                          myDataInsideSopervisor
                        ).map((item) => item.student_id);
                      }

                      if (checked) {
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "done",
                          null,
                          "تم تحديد جميع المتدربين"
                        );
                      } else {
                        newArrCheckedMap = [];
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "warn",
                          null,
                          "تم الغاء جميع المتدربين"
                        );
                      }
                    }}
                  />
                </span>{" "}
                <span className="bg-dange fw-bold mx-3">
                  تحديد جميع المتدربين
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column flex-md-row my-3">
            <Select
              mode="multiple"
              placeholder="البحث بالرقم التدريبي"
              value={arrChecked}
              onChange={setArrChecked}
              style={{
                width: "100%",
              }}
              options={filteredOptions.map((item, i) => ({
                value: item.student_id,
                label: item.name + " - " + item.student_id,
              }))}
              // options={selectedItems.map((item, i) => ({
              //   value: item,
              //   label: saveName[i] + " - " + item,
              // }))}
            />

            <div className=" me-0 my-3 my-md-0 me-md-3 d-flex justify-content-center align-items-center ">
              {isLoadingAttach ? (
                <ButtonLoading
                  title={"جاري الاضافة"}
                  className={"fw-bold"}
                  width={"150px"}
                />
              ) : (
                <Button
                  width={"150px"}
                  onclick={submitAddTasks}
                  title={"إرسال"}
                />
              )}
            </div>
          </div>
        </>,
        <>
          <Spin spinning={false} delay={500} size="large">
            <div className="placeholder-glow">
              <div className="row">
                <div className="col-12 col-lg-6 my-3">
                  <FormInput
                    type={"search"}
                    placeholder={"البحث ..."}
                    shadow={"style-shadow-large-extra"}
                    isShowIcon={true}
                    onSearch={(val) => {
                      setSearchText(val);
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    bgColor="#fff"
                  />
                </div>
                <div>
                  {loadingTasks ? (
                    <TableLodaing itemsColumns={dataTableColumns} />
                  ) : (
                    <Table
                      pagination={{
                        position: ["bottomCenter"],
                        size: "small",
                        style: {
                          display: "flex",
                          flexDirection: "row-reverse",
                        },
                        // 1,2,3,4
                        current: page,
                        // items show
                        total: myDataTasks && myDataTasks.total,
                        // pageSize: 100,
                        onChange: (page, pageSize) => {
                          setPage(page);
                          myDispatch(getAllTasksPage(page));
                        },
                      }}
                      size="middle"
                      dataSource={myDataTasks && myDataTasks.data}
                      rowKey={"task_id"}
                      className="style-scroll-radius style-shadow-large bg-white rounded-3"
                    >
                      {dataTableColumns.map((item, i) => (
                        <Column
                          title={item.title}
                          dataIndex={item.dataIndex}
                          key={item.key}
                          filteredValue={item.filteredValue}
                          onFilter={item.onFilter}
                          render={item.render}
                        />
                      ))}
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </Spin>
        </>,
      ]}
    />
  );
};

export default SopervisorAdditionTasksContainer;

// <div className="">
//   <Taps
//     title={["إضافة المهمة", "إرسال المهام للمتدربين", "جميع المهام"]}
//     charIdAndTarget={["a", "b", "c"]}
//     titleItem={[
//       <>
//         <div className="col-12 col-lg-6">
//           <div
//             className="bg-white p-3 style-shadow-small"
//             style={{ borderRadius: "var(--radius)" }}
//           >
//             <h5
//               className="text-center fw-bold my-2"
//               style={{ color: "var(--primary-color)" }}
//             >
//               إضافة مهام للمتدربين
//             </h5>
//             <div className="row gx-5 gy-3 ">
//               <div className="col-12">
//                 <FormInput
//                   type={"text"}
//                   onChange={handelTaskName}
//                   value={tasksName}
//                   label={"عنوان المهمة"}
//                   placeholder={"عنوان المهمة..."}
//                   idAndfor={"validationCustomFullName"}
//                   isValid={"valid"}
//                   msgValid={"Gooooooooood !!"}
//                 />
//               </div>
//             </div>
//             <div
//               className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
//               style={{
//                 borderRadius: "var(--radius)",
//                 // width: "190px",
//               }}
//             >
//               <span className="">
//                 <input
//                   className="form-check-input border shadow-none"
//                   type="checkbox"
//                   // value={arrChecked}
//                   onChange={(e) => {
//                     let checked = e.target.checked;
//                     let newArrCheckedMap = [];

//                     if (role === "emp") {
//                       if (myDataInsideSopervisor) {
//                         newArrCheckedMap = Object.values(
//                           myDataInsideSopervisor.data
//                         ).map((item) => item.student_id);
//                       }
//                     } else if (role === "company") {
//                       if (myDataStudentInsideCompany) {
//                         newArrCheckedMap = Object.values(
//                           myDataStudentInsideCompany.data
//                         ).map((item) => item.student_id);
//                       }
//                     }

//                     if (checked) {
//                       setArrChecked(newArrCheckedMap);
//                       NotificationsToast(
//                         "done",
//                         null,
//                         "تم تحديد جميع المتدربين"
//                       );
//                       setArrCheckedBool(true);
//                     } else {
//                       newArrCheckedMap = [];
//                       setArrChecked(newArrCheckedMap);
//                       NotificationsToast(
//                         "warn",
//                         null,
//                         "تم الغاء جميع المتدربين"
//                       );
//                       setArrCheckedBool(false);
//                     }
//                   }}
//                 />
//               </span>{" "}
//               <span className="bg-dange fw-bold mx-3">
//                 تحديد جميع المتدربين
//               </span>
//             </div>
//           </div>
//         </div>
//       </>,
//       <>
//         <div>
//           <div className="mb-3 col-12 col-xl-6">
//             <FormInput
//               type={"search"}
//               onChange={() => null}
//               // value={""}
//               placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//               idAndfor={"validationCustomFullName"}
//               isValid={"valid"}
//               msgValid={"Gooooooooood !!"}
//               shadow={"style-shadow-large-extra"}
//               isShowIcon={true}
//               icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//             />
//           </div>

//           {role === "emp" ? (
//             loadingInsideSopervisor ? (
//               <TableContainer
//                 thead={[
//                   "#",
//                   "أسم المتدرب",
//                   "الرقم التدريبي",
//                   "تحديد المتدرب",
//                 ]}
//                 item={Array(4)
//                   .fill(0)
//                   .map((_, i) => (
//                     <SopervisorAdditionTasksTableItem
//                       key={i}
//                       index={i + 1}
//                       name={
//                         <LoadingPlaceholderItem
//                           col={"col-12 col-lg-6"}
//                           isSize={"lg"}
//                         />
//                       }
//                       studentId={
//                         <LoadingPlaceholderItem
//                           col={"col-12 col-lg-6"}
//                           isSize={"lg"}
//                         />
//                       }
//                       isShowCheckBox={null}
//                     />
//                   ))}
//               />
//             ) : myDataInsideSopervisor.data ? (
//               Object.values(myDataInsideSopervisor.data).length > 0 ? (
//                 <>
//                   <TableContainer
//                     thead={[
//                       "#",
//                       "أسم المتدرب",
//                       "الرقم التدريبي",
//                       "تحديد المتدرب",
//                     ]}
//                     item={
//                       <>
//                         {Object.values(myDataInsideSopervisor.data).map(
//                           (item, i) => (
//                             <SopervisorAdditionTasksTableItem
//                               key={i}
//                               index={i + 1}
//                               name={item.name}
//                               studentId={item.student_id}
//                               onchange={(e) => {
//                                 // e.default();
//                                 let value = e.target.value;
//                                 let checked = e.target.checked;

//                                 if (arrCheckedBool) {
//                                   NotificationsToast(
//                                     "warn",
//                                     null,
//                                     "جميع المتدربين محددين سابقاً"
//                                   );
//                                   return;
//                                 }

//                                 if (checked) {
//                                   setArrChecked([
//                                     ...arrChecked,
//                                     parseInt(value),
//                                   ]);
//                                 } else {
//                                   let newArrFilter = arrChecked.filter(
//                                     (item) => item !== parseInt(value)
//                                   );
//                                   setArrChecked(newArrFilter);
//                                 }
//                               }}
//                             />
//                           )
//                         )}
//                       </>
//                     }
//                   />
//                   <div className="  d-flex justify-content-center align-items-center flex-column">
//                     <div className=" my-3 ">
//                       {isLoadingAttach ? (
//                         <ButtonLoading
//                           title={"جاري الاضافة"}
//                           className={"fw-bold"}
//                           width={"292px"}
//                         />
//                       ) : (
//                         <Button
//                           width={"292px"}
//                           onclick={submitAddTasks}
//                           title={"إرسال"}
//                         />
//                       )}
//                     </div>
//                     {/*Start pagination */}
//                     <div className="">
//                       <Pagination
//                         propsCountPage={
//                           myDataInsideSopervisor &&
//                           myDataInsideSopervisor.last_page
//                         }
//                         onPress={getNowPageEmp}
//                       />
//                     </div>
//                     {/*End pagination */}
//                   </div>
//                 </>
//               ) : (
//                 <AlertMsg msg={"لايوجد متدربين"} />
//               )
//             ) : (
//               <AlertMsg msg={"لايوجد متدربين"} />
//             )
//           ) : role === "company" ? (
//             loadingStudentInsideCompany ? (
//               <TableContainer
//                 thead={[
//                   "#",
//                   "أسم المتدرب",
//                   "الرقم التدريبي",
//                   "تحديد المتدرب",
//                 ]}
//                 item={Array(4)
//                   .fill(0)
//                   .map((_, i) => (
//                     <SopervisorAdditionTasksTableItem
//                       key={i}
//                       index={i + 1}
//                       name={
//                         <LoadingPlaceholderItem
//                           col={"col-12 col-lg-6"}
//                           isSize={"lg"}
//                         />
//                       }
//                       studentId={
//                         <LoadingPlaceholderItem
//                           col={"col-12 col-lg-6"}
//                           isSize={"lg"}
//                         />
//                       }
//                       isShowCheckBox={null}
//                     />
//                   ))}
//               />
//             ) : myDataStudentInsideCompany.data ? (
//               Object.values(myDataStudentInsideCompany.data).length > 0 ? (
//                 <>
//                   <TableContainer
//                     thead={[
//                       "#",
//                       "أسم المتدرب",
//                       "الرقم التدريبي",
//                       "تحديد المتدرب",
//                     ]}
//                     item={
//                       <>
//                         {Object.values(myDataStudentInsideCompany.data).map(
//                           (item, i) => (
//                             <SopervisorAdditionTasksTableItem
//                               key={i}
//                               index={i + 1}
//                               name={item.name}
//                               studentId={item.student_id}
//                               onchange={(e) => {
//                                 let value = e.target.value;
//                                 let checked = e.target.checked;

//                                 if (arrCheckedBool) {
//                                   NotificationsToast(
//                                     "warn",
//                                     null,
//                                     "جميع المتدربين محددين سابقاً"
//                                   );
//                                   return;
//                                 }

//                                 if (checked) {
//                                   setArrChecked([
//                                     ...arrChecked,
//                                     parseInt(value),
//                                   ]);
//                                 } else {
//                                   let newArrFilter = arrChecked.filter(
//                                     (item) => item !== parseInt(value)
//                                   );
//                                   setArrChecked(newArrFilter);
//                                 }
//                               }}
//                             />
//                           )
//                         )}
//                       </>
//                     }
//                   />
//                   <div className="  d-flex justify-content-center align-items-center flex-column">
//                     <div className=" my-3 ">
//                       {isLoadingAttach ? (
//                         <ButtonLoading
//                           title={"جاري الاضافة"}
//                           className={"fw-bold"}
//                           width={"292px"}
//                         />
//                       ) : (
//                         <Button
//                           width={"292px"}
//                           onclick={submitAddTasks}
//                           title={"إرسال"}
//                         />
//                       )}
//                     </div>
//                     {/*Start pagination */}
//                     <div className="">
//                       <Pagination
//                         propsCountPage={
//                           myDataStudentInsideCompany &&
//                           myDataStudentInsideCompany.last_page
//                         }
//                         onPress={getNowPageCompany}
//                       />
//                     </div>
//                     {/*End pagination */}
//                   </div>
//                 </>
//               ) : (
//                 <AlertMsg msg={"لايوجد متدربين"} />
//               )
//             ) : (
//               <AlertMsg msg={"لايوجد متدربين"} />
//             )
//           ) : null}
//         </div>
//       </>,
//       <>
//         {loadingTasks ? (
//           <TableContainer
//             thead={["#", "عنوان المهمة", "حذف المهمة"]}
//             item={Array(4)
//               .fill(0)
//               .map((_, i) => (
//                 <SopervisorAllTasksItem
//                   key={i}
//                   index={i + 1}
//                   name={
//                     <LoadingPlaceholderItem
//                       col={"col-12 col-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   studentId={
//                     <LoadingPlaceholderItem
//                       col={"col-12 col-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   isShowCheckBox={null}
//                 />
//               ))}
//           />
//         ) : myDataTasks.data ? (
//           Object.values(myDataTasks.data).length > 0 ? (
//             <>
//               <TableContainer
//                 thead={["#", "عنوان المهمة", "حذف المهمة"]}
//                 item={
//                   <>
//                     {Object.values(myDataTasks.data).map((item, i) => (
//                       <SopervisorAllTasksItem
//                         key={i}
//                         index={i + 1}
//                         name={item.task_details}
//                       />
//                     ))}
//                   </>
//                 }
//               />
//               {/*Start pagination */}
//               <div className="my-3 d-flex justify-content-center align-items-center">
//                 <Pagination
//                   propsCountPage={myDataTasks && myDataTasks.last_page}
//                   onPress={getNowPageCompany}
//                 />
//               </div>
//               {/*End pagination */}
//             </>
//           ) : (
//             <AlertMsg msg={"لاتوجد مهام"} />
//           )
//         ) : (
//           <AlertMsg msg={"لاتوجد مهام"} />
//         )}
//       </>,
//     ]}
//   />
// </div>
