/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import TemplateCircle from "../TemplateCircle";
import FollowUpDetailsCardBgWhite from "../FollowUpDetailsCardBgWhite";

const FollowUpDetailsGradesContainer = ({ propsGrades }) => {
  return (
    <FollowUpDetailsCardBgWhite
      title={"تفاصيل الدرجات"}
      subTitle={"التقييم على 3 عناصر"}
      item={
        <div className=" my-3 row">
          {/* col total */}
          {/* <button onClick={submitGradeTotal}>get grade</button> */}
          <div className="col-12">
            <div className="text-center col">
              <TemplateCircle
                value={
                  !isNaN(propsGrades.total)
                    ? propsGrades.total === null
                      ? "-"
                      : parseInt(propsGrades.total)
                    : "-"
                }
                text={"100/"}
                w={"140px"}
                h={"140px"}
                p={"7px"}
                circleWidth={"9"}
                fs={"22px"}
                valuePercentDev={"100"}
              />
              <div
                className="fw-bold mt-2"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "14px",
                }}
              >
                الدرجة النهائية
              </div>
            </div>
          </div>
          {/* another grades */}
          <div className="col-12 mt-4">
            <div className="row">
              <div className="text-center col">
                <TemplateCircle
                  value={
                    !isNaN(propsGrades.Training_supervisor_evaltion)
                      ? propsGrades.Training_supervisor_evaltion === null
                        ? "-"
                        : parseInt(propsGrades.Training_supervisor_evaltion)
                      : "-"
                  }
                  text={"30/"}
                  w={"90px"}
                  h={"90px"}
                  p={"7px"}
                  circleWidth={"11"}
                  fs={"23px"}
                  valuePercentDev={"30"}
                />
                <div
                  className="fw-bold mt-2"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "14px",
                  }}
                >
                  تقييم مشرف التدريب
                </div>
              </div>
              <div className="text-center col ">
                <TemplateCircle
                  value={
                    !isNaN(propsGrades.training_provider)
                      ? propsGrades.training_provider === null
                        ? "-"
                        : parseInt(propsGrades.training_provider)
                      : "-"
                  }
                  text={"40/"}
                  w={"90px"}
                  h={"90px"}
                  p={"7px"}
                  circleWidth={"11"}
                  fs={"23px"}
                  valuePercentDev={"40"}
                />
                <div
                  className="fw-bold mt-2"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "14px",
                  }}
                >
                  تقييم جهة التدريب
                </div>
              </div>
              <div className="text-center col">
                <TemplateCircle
                  value={
                    !isNaN(propsGrades.final_report_evaltion)
                      ? propsGrades.final_report_evaltion === null
                        ? "-"
                        : parseInt(propsGrades.final_report_evaltion)
                      : "-"
                  }
                  text={"30/"}
                  w={"90px"}
                  h={"90px"}
                  p={"7px"}
                  circleWidth={"11"}
                  fs={"23px"}
                  valuePercentDev={"30"}
                />
                <div
                  className="fw-bold mt-2"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "14px",
                  }}
                >
                  التقرير النهائي والمناقشة
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      textFooter={`المجموع : ${
        !isNaN(propsGrades.total)
          ? propsGrades.total === null
            ? "-"
            : parseInt(propsGrades.total)
          : "-"
      }`}
    />
  );
};

export default FollowUpDetailsGradesContainer;
