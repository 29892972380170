import {
  EDIT_ALL_STUDENT,
  GET_ALL_STUDENT,
  GET_ALL_STUDENT_PAGE,
  GET_ALL_STUDENT_WITHOUT_PAGINATION,
  GET_ALL_STUDENT_WITHOUT_SOPERVISOR,
  GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE,
  GET_ERROR,
  GET_STUDENT_BY_USERNAME,
  INSERT_ALL_STUDENT,
} from "../../types/Types";

const inital = {
  student: [],
  editStudent: [],
  studentWithoutSopervisor: [],
  studentWithoutPagination: [],
  loading: true,
  loadingEditStudent: true,
};

const reducerStudent = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT:
      return {
        ...state,
        student: action.payload,
        loading: false,
      };
    case GET_ALL_STUDENT_WITHOUT_SOPERVISOR:
      return {
        ...state,
        studentWithoutSopervisor: action.payload,
        loading: false,
      };
    case GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE:
      return {
        ...state,
        student: action.payload,
        loading: false,
      };
    case GET_ALL_STUDENT_WITHOUT_PAGINATION:
      return {
        ...state,
        studentWithoutPagination: action.payload,
        loading: false,
      };
    case GET_STUDENT_BY_USERNAME:
      return {
        ...state,
        student: action.payload,
        loading: false,
      };
    case GET_ALL_STUDENT_PAGE:
      return {
        ...state,
        student: action.payload,
        loading: false,
      };
    case INSERT_ALL_STUDENT:
      return {
        student: action.payload,
        loading: false,
      };
    case EDIT_ALL_STUDENT:
      return {
        editStudent: action.payload,
        loadingEditStudent: false,
      };
    case GET_ERROR:
      return {
        student: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
export default reducerStudent;
