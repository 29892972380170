/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  getSopervisorByUsername,
  getStudentInsideSopervisorWithoutPagination,
} from "../../../redux/actions/sopervisor/ActionSopervisor";
import { role, tokenSopervisorUsername } from "../../../redux/types/Types";
import { useEffect, useState } from "react";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";

const SopervisorStatusLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getSopervisorByUsername(tokenSopervisorUsername));
    // myDispatch(getCompanyByUsername(tokenCompanyUsername));
    myDispatch(getAllStudentWithoutPagination());
    myDispatch(
      getStudentInsideSopervisorWithoutPagination(tokenSopervisorUsername)
    );
    // myDispatch(getStudentInsideCompany(tokenCompanyUsername));
  }, []);

  // api sopervsior
  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor.data
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );
  // api sopervsior Inside
  const myDataInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisorInsideWithoutPagination
  );
  const loadingInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.loadingInsideWithoutPagination
  );

  let countDoneStudent;
  let countNotDoneStudent;

  // console.log(myDataInsideSopervisor && myDataInsideSopervisor);

  if (role === "emp") {
    if (myDataInsideSopervisor) {
      if (myDataInsideSopervisor === "nothing") {
        console.log("");
      } else {
        // اعداد المسجلين
        countDoneStudent =
          myDataInsideSopervisor &&
          myDataInsideSopervisor.filter((item) => item.username !== null);
        // اعداد الغير المسجلين
        countNotDoneStudent =
          myDataInsideSopervisor &&
          myDataInsideSopervisor.filter((item) => item.username === null);
      }
    }
  }

  const dataStatistics = [
    {
      title: "أعداد المتدربين",
      icon: "../../images/icon-all-student.png",
      count:
        loadingInsideSopervisor === false
          ? myDataInsideSopervisor !== "nothing"
            ? myDataInsideSopervisor.length
            : 0
          : "...",

      //  role === "company"
      // ? loadingInsideCompany === false
      //   ? myDataInsideCompany !== "nothing"
      //     ? myDataInsideCompany.total
      //     : 0
      //   : "..."
      color: "#009EF7",
      bgColor: "#F1FAFF",
    },
    {
      title: "أعداد المسجلين",
      icon: "../../images/icon-done-student.png",
      count:
        loadingInsideSopervisor === false
          ? myDataInsideSopervisor !== "nothing"
            ? countDoneStudent.length
            : 0
          : "...",
      color: "#50CD89",
      bgColor: "#E8FFF3",
    },
    {
      title: "أعداد الغير المسجلين",
      icon: "../../images/icon-not-student.png",
      count:
        loadingInsideSopervisor === false
          ? myDataInsideSopervisor !== "nothing"
            ? countNotDoneStudent.length
            : 0
          : "...",
      color: "#F1416C",
      bgColor: "#FFF5F8",
    },
  ];

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [collageName, setCollageName] = useState("");
  const [department, setDepartment] = useState("");

  useEffect(() => {
    if (localStorage.getItem("storageDataObj") && role) {
      setName(JSON.parse(localStorage.getItem("storageDataObj")).name);
      setUsername(
        JSON.parse(localStorage.getItem("storageDataObj")).username_emp
      );
      setCollageName(
        JSON.parse(localStorage.getItem("storageDataObj")).collage_name
      );
      setDepartment(
        JSON.parse(localStorage.getItem("storageDataObj")).job_type
      );
    }
  }, []);

  return [
    myDataSopervisor,
    loadingSopervisor,
    dataStatistics,
    name,
    username,
    collageName,
    department,
  ];
};

export default SopervisorStatusLogic;
