import React from "react";

const SopervisorAdditionGradesDetailsTotalItem = ({
  index,
  grade,
  totalGrade,
}) => {
  return (
    <tr className="style-tr-without-border-bottom">
      {/* index */}
      <th>
        <div className="mt-2">{index}</div>
      </th>
      {/* title */}
      <td>
        <div style={{color:"var(--primary-color)"}} className="fw-bold  mt-2 text-nowrap">
          المجموع
        </div>
      </td>
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
          {grade}
        </div>
      </td>
      <td>
        <div
          style={{ width: "154px" }}
          className="me-2 text-center  d-flex align-items-center justify-content-center fw-bold"
        >
          <div style={{ width: "50px",height:"50px" ,backgroundColor:"var(--primary-color)"}} className="text-white fs-5 d-flex align-items-center justify-content-center rounded-circle">
            {totalGrade === null ? "-" : totalGrade}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SopervisorAdditionGradesDetailsTotalItem;
