/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { role, tokenCompanyUsername } from "../../../redux/types/Types";
import {
  getCompanyByUsername,
  getStudentInsideCompany,
} from "../../../redux/actions/company/ActionCompany";

const CompanyStatusLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getCompanyByUsername(tokenCompanyUsername));
    myDispatch(getStudentInsideCompany(tokenCompanyUsername));
  }, []);

  // api company
  const myDataCompany = useSelector((state) => state.rootCompany.company);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);

  // api company inside
  const myDataInsideCompany = useSelector(
    (state) => state.rootCompany.companyInside
  );
  const loadingInsideCompany = useSelector(
    (state) => state.rootCompany.loadingInside
  );

  const dataStatistics = [
    {
      title: "أعداد المتدربين",
      icon: "../../images/icon-all-student.png",
      count:
        loadingInsideCompany === false
          ? myDataInsideCompany !== "nothing"
            ? myDataInsideCompany.total
            : 0
          : "...",
      color: "#009EF7",
      bgColor: "#F1FAFF",
    },
  ];
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [collageName, setCollageName] = useState("");
  const [department, setDepartment] = useState("");

  useEffect(() => {
    if (localStorage.getItem("storageDataObj") && role) {
      setName(JSON.parse(localStorage.getItem("storageDataObj")).company_name);
      setUsername(JSON.parse(localStorage.getItem("storageDataObj")).username);
      setCollageName(JSON.parse(localStorage.getItem("storageDataObj")).phone);
      setDepartment(
        JSON.parse(localStorage.getItem("storageDataObj")).department_name
      );
    }
  }, []);

  return [
    myDataCompany,
    loadingCompany,
    dataStatistics,
    name,
    username,
    collageName,
    department,
  ];
};

export default CompanyStatusLogic;
