/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TitleWithNumber from "../../duplicateComponents/TitleWithNumber";
import FormInput from "../../duplicateComponents/FormInput";

import { Spin, Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import TableLodaing from "../../duplicateComponents/TableLodaing";

import {
  addSubmissitionLastApp,
  getSubmissitionAll,
  getSubmissitionAllPage,
} from "../../../redux/actions/submissition/ActionSubLastApp";
import { role } from "../../../redux/types/Types";
import {
  getSubmissitionByApproved,
  getSubmissitionByApprovedPage,
  getSubmissitionCompanyByCompanyName,
} from "../../../redux/actions/submissition/ActionSubmissitions";
import { NotificationsToast } from "../../duplicateComponents/NotificationsToast";
import ButtonRoundedFill from "../../duplicateComponents/ButtonRoundedFill";
const { Column } = Table;
const AdminOrderApprovedOrDeclined = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    //   // get approved => 2 قبول مبدئي من الجهة
    myDispatch(getSubmissitionByApproved("2"));
  }, []);
  // api submisstion
  const myDataSubmissitionApp = useSelector(
    (state) => state.rootSubmissitions.submissitionApproved
  );

  const loadingSubmissitionApp = useSelector(
    (state) => state.rootSubmissitions.loadingSubmissitionApproved
  );

  const responseSubmissitionAppCompany = useSelector(
    (state) => state.rootSubLastApp.addSubmissitionLastApp
  );

  const [isLoadA, setIsLoadA] = useState(true);
  const [isLoadingA, setIsLoadingA] = useState(false);

  const submitSubLastAppro = async (e, id) => {
    e.preventDefault();

    setIsLoadA(true);
    setIsLoadingA(true);
    await myDispatch(
      addSubmissitionLastApp({
        "sub-id": id,
        type: role,
      })
    );
    setIsLoadA(false);
  };

  useEffect(() => {
    if (isLoadA === false) {
      if (responseSubmissitionAppCompany) {
        myDispatch(getSubmissitionAll());
        myDispatch(getSubmissitionAllPage());
        myDispatch(
          getSubmissitionCompanyByCompanyName(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        myDispatch(getSubmissitionByApproved("2"));

        setIsLoadingA(false);
      }

      if (responseSubmissitionAppCompany.status === 200) {
        if (responseSubmissitionAppCompany.data === "somthing wrong") {
          NotificationsToast("warn", null, "تم القبول مسبقاً");
          setIsLoadingA(false);

          return;
        } else if (responseSubmissitionAppCompany.data === "done") {
          NotificationsToast("done", null, "تم قبول النهائي للمتدرب");
          setIsLoadingA(false);
          return;
        } else {
          if (responseSubmissitionAppCompany.status !== 200) {
            // console.log("app");
            NotificationsToast("error", "في القبول او الرفض ", " هناك مشكلة");
            setIsLoadingA(false);
            return;
          }
        }
      }
      setIsLoadA(true);
    }
  }, [isLoadA]);

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record, index) => {
        return record.name === null ? "-" : record.name;
      },
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.student_id === null ? "-" : record.student_id;
      },
    },
    {
      title: "الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.collage_name === null ? "-" : record.collage_name;
      },
    },
    {
      title: "القسم",
      dataIndex: "Department",
      key: "Department",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.Department === null ? "-" : record.Department;
      },
    },
    {
      title: "التخصص",
      dataIndex: "major",
      key: "major",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.major === null ? "-" : record.major;
      },
    },
    {
      title: "أسم الجهة",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.company_name === null ? "-" : record.company_name;
      },
    },
    // {
    //   title: "CV",
    //   dataIndex: "cv",
    //   key: "cv",
    //   filteredValue: null,
    //   onFilter: null,
    //   render: (text, record, index) => {
    //     return (
    //       <a className="fw-bold" style={{ color: "var(--primary-color)" }}>
    //         تنزيل
    //       </a>
    //     );
    //     // return record.cv === null ? "-" : record.cv;
    //   },
    // },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              {/* <span className="mx-1">
                <ButtonRoundedFill
                  title={<CloseOutlined />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  // isloading={isLoading}
                  onclick={(e) => {
                    console.log(record.student_id);
                    // submitDeleteStudent(e, record.student_id);
                  }}
                />
              </span> */}

              <span className="mx-1">
                <ButtonRoundedFill
                  title={<CheckOutlined />}
                  colorHover="#fff"
                  width={40}
                  onclick={(e) => submitSubLastAppro(e, record.submissions_id)}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];
  return (
    <Spin
      spinning={isLoadingA}
      // spinning={false}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع الطلبات المتدربين في الجهات"}
                subTitle={"عدد الطلبات"}
                num={
                  loadingSubmissitionApp
                    ? 0
                    : myDataSubmissitionApp === "nothing here"
                    ? 0
                    : myDataSubmissitionApp && myDataSubmissitionApp.total
                }
              />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingSubmissitionApp ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // // items show
                  total: loadingSubmissitionApp
                    ? 0
                    : myDataSubmissitionApp === "nothing here"
                    ? 0
                    : myDataSubmissitionApp && myDataSubmissitionApp.total,
                  // pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getSubmissitionByApprovedPage(2, page));
                  },
                }}
                size="middle"
                dataSource={myDataSubmissitionApp && myDataSubmissitionApp.data}
                rowKey={"student_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminOrderApprovedOrDeclined;

// <div>
//   <div className="col-12 col-lg-6 mb-3">
//     <TitleWithNumber
//       title={"جميع الطلبات المتدربين في الجهات"}
//       subTitle={"عدد الطلبات"}
//       num={
//         myDataSubmissitionApp && myDataSubmissitionApp === "nothing here"
//           ? 0
//           : myDataSubmissitionAppTotal
//       }
//     />
//     <FormInput
//       type={"search"}
//       onChange={handelQuery}
//       placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//       idAndfor={"validationCustomFullName"}
//       isValid={"valid"}
//       msgValid={"Gooooooooood !!"}
//       shadow={"style-shadow-large-extra"}
//       isShowIcon={true}
//       icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//     />
//   </div>
//   {loadingSubmissitionApp ? (
//     <TableContainer
//       thead={[
//         "#",
//         "اسم المتدرب",
//         "الرقم التدريبي",
//         "الكلية",
//         "القسم",
//         "التخصص",
//         "أسم الجهة",
//         "القبول أو الرفض",
//       ]}
//       item={Array(4)
//         .fill(0)
//         .map((_, i) => (
//           <OrderSopervisorItem
//             key={i}
//             index={i + 1}
//             name={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             stuedntId={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             collageName={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             major={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             specialty={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             companyName={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             isShowBtn={null}
//           />
//         ))}
//     />
//   ) : (
//     <>
//       {myDataSubmissitionApp.data ? (
//         Object.values(myDataSubmissitionApp.data).length > 0 ? (
//           <>
//             <TableContainer
//               thead={[
//                 "#",
//                 "اسم المتدرب",
//                 "الرقم التدريبي",
//                 "الكلية",
//                 "القسم",
//                 "التخصص",
//                 "أسم الجهة",
//                 "القبول أو الرفض",
//               ]}
//               item={
//                 <>
//                   {Object.values(myDataSubmissitionApp.data)
//                     .filter((item, i) => {
//                       if (query === "") {
//                         return item;
//                       } else if (
//                         item.name
//                           .toLocaleLowerCase()
//                           .includes(query.toLocaleLowerCase()) ||
//                         item.student_id.toString().includes(query)
//                       ) {
//                         return item;
//                       }
//                     })
//                     .map((item, i) => (
//                       <OrderSopervisorItem
//                         key={i}
//                         index={i + 1}
//                         name={item.name === null ? "-" : item.name}
//                         stuedntId={
//                           item.student_id === null ? "-" : item.student_id
//                         }
//                         collageName={
//                           item.collage_name === null
//                             ? "-"
//                             : item.collage_name
//                         }
//                         major={
//                           item.Department === null ? "-" : item.Department
//                         }
//                         specialty={item.major === null ? "-" : item.major}
//                         companyName={
//                           item.company_name === null
//                             ? "-"
//                             : item.company_name
//                         }
//                         subId={item.submissions_id}
//                       />
//                     ))}
//                 </>
//               }
//             />
//             {/*Start pagination */}
//             <div className="my-3 d-flex justify-content-center align-items-center col">
//               <Pagination
//                 propsCountPage={
//                   myDataSubmissitionApp && myDataSubmissitionApp.last_page
//                 }
//                 onPress={getNowPage}
//               />{" "}
//             </div>
//             {/*End pagination */}
//           </>
//         ) : (
//           <AlertMsg msg={"لاتوجد طلبات"} />
//         )
//       ) : (
//         <AlertMsg msg={"لاتوجد طلبات"} />
//       )}
//     </>
//   )}
// </div>
