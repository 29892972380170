/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { insertReviewsStudent } from "../../../redux/actions/reviews/ActionReviews";
import { tokenStudentUsername } from "../../../redux/types/Types";
import { useEffect, useState } from "react";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";

const FollowUpPollCardStudentLogic = () => {
  const myDataPollStudent = [
    {
      title: "سمعت كثيراً عن برنامج التدريب التعاوني قبل بدايته",
      answer: false,
      key: 0,
    },

    {
      title: "دورة التهيئة لبرنامج التدريب التعاوني كانت واضحة الأهداف",
      answer: false,
      key: 1,
    },

    {
      title:
        "تم تعريفنا ببرامج تطويرية للتدريب التعاوني تساهم في توظيفنا قبل بداية التدريب التعاوني",
      answer: false,
      key: 2,
    },
    {
      title:
        "دورة تهيئة المتدربة للتدريب التعاوني كانت مميزة وتجيب على مايدور لدينا من أسئلة",
      answer: false,
      key: 3,
    },
    {
      title:
        "أعتقد أن الفرصة التي يحصل عليها المتدربة بنفسه تساعده كثيراً على الإنتاجية والتوظيف",
      answer: false,
      key: 4,
    },
    {
      title:
        "كنت متشوقاً لبرنامج التدريب التعاوني لأنه ينقلنا لمرحلة التطبيق الميداني",
      answer: false,
      key: 5,
    },
    {
      title: "ربطت بين التدريب التعاوني وبين حصولي على الوظيفة",
      answer: false,
      key: 6,
    },

    {
      title: "برنامج التدريب التعاوني شيق ومفيد",
      answer: false,
      key: 7,
    },

    {
      title: "برنامج التدريب التعاوني شجعني على حب التفوق",
      answer: false,
      key: 8,
    },

    {
      title:
        "لم يتم  توزيع علينا نبذة عن حول برنامج التدريب التعاوني من أدلة كتيبات تعريف كان كافياً",
      answer: false,
      key: 9,
    },
    {
      title:
        "خطة التدريب التعاوني كانت واضحة جداً فلدينا معرفة بما يجب عمله في المنشأة التدريبية كل أسبوع",
      answer: false,
      key: 10,
    },
    {
      title:
        "هناك توافق كبير بين مجال التخصص الذي تخرجت منه وطبيعة فرصة التدريب التعاوني التي حصلت عليها",
      answer: false,
      key: 11,
    },
    {
      title: "استقبال منشأة التدريب التعاوني كان مميزاً",
      answer: false,
      key: 12,
    },

    {
      title:
        "توقعت على أن أحصل على مكافأة مجزية نهاية التدريب التعاوني من المنشأة",
      answer: false,
      key: 13,
    },
    {
      title: "حاولت أن أبحث عن فرصة لي في التدريب التعاوني",
      answer: false,
      key: 14,
    },

    {
      title:
        "زيارات مشرف التدريب التعاوني كانت كافية للوقوف على مسيرتي في برنامج التدريب التعاوني",
      answer: false,
      key: 15,
    },
    {
      title:
        "من الضروري التواصل عن طريق الجوال بين المشرف من الكلية / المعهد والمتدربة",
      answer: false,
      key: 16,
    },
    {
      title:
        "من المهم أن يعرف المتدربة عن وقت زيارة المشرف من الكلية / المعهد له",
      answer: false,
      key: 17,
    },
    {
      title:
        "أهتمام مشرف جهة التدريب (المنشأة) بي وإعطائي الحافز للتدريب كان مميزاً",
      answer: false,
      key: 18,
    },
    {
      title:
        "بعد جهة التدريب عن منزلي كان عائق لعملية إنتظامي في التدريب التعاوني",
      answer: false,
      key: 19,
    },
    {
      title: "لاحظت أن الأعمال التي تسند لي متكررة ورتينية وتخالف خطة التدريب",
      answer: false,
      key: 20,
    },

    {
      title:
        "فترة التدريب كانت كافية لتطبيق المهارات التي حصلنا عليها في الكلية / المعهد",
      answer: false,
      key: 21,
    },
    {
      title:
        "من وجهة نظري ان الحضور والإنصراف هما أهم عنصرين في عملية تقييم المتدربة",
      answer: false,
      key: 22,
    },
    {
      title: "درجات التقييم في مصلحة المتدربة وتعطيها حقها",
      answer: false,
      key: 23,
    },

    {
      title: "التقارير الدورية ساعدت كثيراً في إتمام التقرير الفني",
      answer: false,
      key: 24,
    },

    {
      title:
        "أتمنى أن يتابع مشرف التدريب التعاوني من الكلية / المعهد عملية توظيفي لدى المنشأة لأن هذا هو الهدف الذي أسعى لتحقيقه",
      answer: false,
      key: 25,
    },
    {
      title: "تقييم مشرف المنشأة لي كان عادلاً وأعطاني حقي",
      answer: false,
      key: 26,
    },

    {
      title:
        "أرى أن إثبات الوجود في بداية فترة التوظيف أهم من الراتب الذي قد يكون منخفضاً في البداية ",
      answer: false,
      key: 27,
    },
    {
      title:
        "نميت مهارات إضافية بعد برنامج التدريب التعاوني غير التي تدربت عليها في الكلية / المعهد",
      answer: false,
      key: 28,
    },
    {
      title:
        "اطلعت على جميع نماذج التقييم التي تخصني أثناء برنامج التدريب التعاوني",
      answer: false,
      key: 29,
    },
    {
      title:
        "تقييم مشرف التدريب التعاوني في الكلية / المعهد كان عادلاً وأعطاني ما أستحق",
      answer: false,
      key: 30,
    },
  ];

  const myDispatch = useDispatch();

  // Start Student
  const responseReviews = useSelector((state) => state.rootReviews.reviews);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // limit Gerade the value
  const limitGrade = 5 * myDataPollStudent.length;

  let arrStorage = [];
  let avg;

  // the Array all => [false,false,false,false,false,false,]
  for (let index = 0; index < myDataPollStudent.length; index++) {
    arrStorage.push(false);
  }
  // onSubmit the student poll
  const submitPoll = async (e) => {
    e.preventDefault();
    console.log(arrStorage);

    // here check if one the value === false => out the function
    for (let index = 0; index < arrStorage.length; index++) {
      if (arrStorage[index] === false) {
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    // here Summition the all values
    let summArrStorage = arrStorage.reduce((a, b) => a + b);

    avg = (summArrStorage * 100) / limitGrade;

    // console.log(arrStorage);

    setIsLoading(true);
    setIsLoad(true);
    await myDispatch(
      insertReviewsStudent({
        "student-id": tokenStudentUsername,
        total: avg.toFixed(1),
        "review-name": "student_poll",
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      // if (responseReviews) {
      //   console.log(responseReviews);
      //   setIsLoading(false);
      // }
      if (responseReviews.status === 200) {
        if (responseReviews.data === "done") {
          NotificationsToast("done", null, "تم التقييم بنجاح");
          setIsLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        } else if (responseReviews.data === "already") {
          NotificationsToast("warn", null, "تم التقييم مسبقاً ");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseReviews.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في التقييم");
          setIsLoading(false);
          return;
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  return [myDataPollStudent, arrStorage, isLoading, submitPoll];
};

export default FollowUpPollCardStudentLogic;
