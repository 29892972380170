import { UseCreateData } from "../../../hooks/UseCreateData";
import { DELETE_STUDENT, GET_ERROR } from "../../types/Types";

const deleteStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_student", dataParams);
    // console.log(response);
    dispatch({
      type: DELETE_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { deleteStudent };
