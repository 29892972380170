import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_SKILLS,
  GET_ALL_SKILLS_PAGE,
  GET_ERROR,
  GET_SKILLS_BY_USERNAME,
  INSERT_ATTACH_SKILLS,
  INSERT_NEW_SKILLS,
} from "../../types/Types";

const getAllSkills = () => async (dispatch) => {
  try {
    const response = await UseGetData(`get_skill`);
    dispatch({
      type: GET_ALL_SKILLS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllSkillsPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_skill?page=${dataParams}`);
    dispatch({
      type: GET_ALL_SKILLS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getSkillsByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_skill_student/${dataParams}`);
    dispatch({
      type: GET_SKILLS_BY_USERNAME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertNewSkill = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`inskills`, dataParams);
    dispatch({
      type: INSERT_NEW_SKILLS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertAttachSkills = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("attach_skill", dataParams);
    dispatch({
      type: INSERT_ATTACH_SKILLS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export {
  getAllSkills,
  getAllSkillsPage,
  getSkillsByUsername,
  insertNewSkill,
  insertAttachSkills,
};
