import React from "react";
import { Spin, Table } from "antd";
import AdminDepartmentFollowUpLogic from "../../../../logic/admin/followUp/AdminDepartmentFollowUpLogic";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import ButtonWithIcon from "../../../duplicateComponents/ButtonWithIcon";
import FormInput from "../../../duplicateComponents/FormInput";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
import { getAllDepartmentPage } from "../../../../redux/actions/department/ActionDepartment";
const { Column } = Table;
const AdminDepartmentIndexContainer = () => {
  const [
    myDataDepartment,
    loadingDepartment,
    myDataDepartmentTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteDepartment,
    dataTableColumns,
    myDispatch,
  ] = AdminDepartmentFollowUpLogic();
  return (
    <Spin
      // spinning={false}
      spinning={isLoadingDeleteDepartment}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع الاقسام"}
                subTitle={"عدد الاقسام"}
                num={myDataDepartment && myDataDepartmentTotal}
              />
            </span>
            <span className="mt-2 mt-sm-0">
              <ButtonWithIcon path={"/admin/users-department/create"} />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingDepartment ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total: myDataDepartment && myDataDepartment.total,
                  // pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getAllDepartmentPage(page));
                  },
                }}
                size="middle"
                dataSource={myDataDepartment && myDataDepartment.data}
                rowKey={"department_name"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminDepartmentIndexContainer;
