/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import LoginContainer from "../../components/auth/LoginContainer";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("role") === "student") {
        nav("/student/status");
      } else if (localStorage.getItem("role") === "emp") {
        nav("/sopervisor/status");
      } else if (localStorage.getItem("role") === "") {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/login"
        ) {
          // console.log("1dddddddddddd");
        }
      }
      // console.log("1");
    } else {
      if (window.location.pathname === "/") {
        // nav("/");
      } else if (window.location.pathname === "/login") {
        nav("/login");
      } else {
        // nav("/");
        console.log("first");
      }
      // console.log("2");
    }
  }, [window.location.pathname]);

  return (
    <div>
      <LoginContainer />
    </div>
  );
};

export default LoginPage;
