import {
  GET_ERROR,
  INSERT_EXCEL_COLLAGE,
  INSERT_EXCEL_COMPANY,
  INSERT_EXCEL_COOP,
  INSERT_EXCEL_DEPARTMENT,
  INSERT_EXCEL_MAJOR,
  INSERT_EXCEL_SOPERVISOR,
  INSERT_EXCEL_STUDENT,
} from "../../types/Types";

const inital = {
  excel: [],
  loading: true,
};

const reducerExecl = (state = inital, action) => {
  switch (action.type) {
    case INSERT_EXCEL_STUDENT:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_SOPERVISOR:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_COOP:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_COMPANY:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_COLLAGE:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_MAJOR:
      return {
        excel: action.payload,
        loading: false,
      };
    case INSERT_EXCEL_DEPARTMENT:
      return {
        excel: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        excel: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerExecl;
