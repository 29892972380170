/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";
import { getAllCollage } from "../../../redux/actions/collage/ActionCollage";
import { getAllSopervisor } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { getAllDepartment } from "../../../redux/actions/department/ActionDepartment";
import { getAllMajor } from "../../../redux/actions/major/ActionMajor";
import { getAllCompany } from "../../../redux/actions/company/ActionCompany";

const AdminStatusLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudentWithoutPagination());
    myDispatch(getAllCollage());
    myDispatch(getAllSopervisor());
    myDispatch(getAllCompany());
    myDispatch(getAllDepartment());
    myDispatch(getAllMajor());
  }, []);

  // api sopervsior
  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor.total
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );

  // api collage
  const myDataCollage = useSelector((state) => state.rootCollage.collage.total);
  const laodingCollage = useSelector((state) => state.rootCollage.loading);

  // api company
  const myDataCompany = useSelector(
    (state) => state.rootCompany.allCompany.total
  );
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );

  // api department
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.total
  );
  const loadingDepartment = useSelector(
    (state) => state.rootDepartment.loading
  );

  // api department
  const myDataMajor = useSelector((state) => state.rootMajor.major.total);
  const loadingMajor = useSelector((state) => state.rootMajor.loading);

  // api student
  const myDataStudent = useSelector(
    (state) => state.rootStudent.studentWithoutPagination
  );
  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  let countDoneStudent;
  let countNotDoneStudent;

  if (myDataStudent) {
    // اعداد المسجلين
    countDoneStudent = myDataStudent.filter((item) => item.username !== null);

    // اعداد الغير المسجلين
    countNotDoneStudent = myDataStudent.filter(
      (item) => item.username === null
    );
  }

  const dataStatistics = [
    {
      title: "أعداد المتدربين",
      icon: "../../images/icon-all-student.png",
      count:
        loadingStudent === false
          ? myDataStudent && myDataStudent.length
          : "...",
      color: "#009EF7",
      bgColor: "#F1FAFF",
    },
    {
      title: "أعداد المسجلين",
      icon: "../../images/icon-done-student.png",
      count:
        loadingStudent === false
          ? myDataStudent && countDoneStudent.length
          : "...",
      color: "#50CD89",
      bgColor: "#E8FFF3",
    },
    {
      title: "أعداد الغير المسجلين",
      icon: "../../images/icon-not-student.png",
      count:
        loadingStudent === false
          ? myDataStudent && countNotDoneStudent.length
          : "...",
      color: "#F1416C",
      bgColor: "#FFF5F8",
    },
    {
      title: "أعداد الكليات",
      icon: "../../images/icon-collages.png",
      count: laodingCollage === false ? myDataCollage && myDataCollage : "...",
      color: "#7239ea",
      bgColor: "#7239ea33",
    },
    {
      title: "أعداد المشرفين",
      icon: "../../images/icon-sopervisor.png",
      count:
        loadingSopervisor === false
          ? myDataSopervisor && myDataSopervisor
          : "...",
      color: "#009688",
      bgColor: "#0096885e",
    },
    {
      title: "أعداد الجهات",
      icon: "../../images/icon-companys.png",
      count: loadingCompany === false ? myDataCompany && myDataCompany : "...",
      color: "var(--yellow-color)",
      bgColor: "var(--yellow-opacity-color)",
    },
    {
      title: "أعداد الأقسام",
      icon: "../../images/icon-department.png",
      count:
        loadingDepartment === false
          ? myDataDepartment && myDataDepartment
          : "...",
      color: "#F2681F",
      bgColor: "rgba(242, 104, 31, 0.3)",
    },
    {
      title: "أعداد التخصصات",
      icon: "../../images/icon-major.png",
      count: loadingMajor === false ? myDataMajor && myDataMajor : "...",
      color: "#6c757d",
      bgColor: "#6c757d6e",
    },

    // {
    //   title: "أعداد المنسقين",
    //   icon: "../../images/icon-coop.png",
    //   count: myDataSopervisor
    //     ? myDataSopervisor.length > 0
    //       ? myDataSopervisor.length
    //       : 0
    //     :"...",
    //   color: "#6c757d",
    //   bgColor: "#6c757d6e",
    // },
  ];

  return [dataStatistics];
};

export default AdminStatusLogic;
