import React from "react";

const FollowUpDetailsCardBgWhite = ({ title, subTitle, item, textFooter }) => {
  return (
    <div
      className="card border-0 style-shadow-small p-3 p-md-4"
      style={{ minHeight: "", borderRadius: "var(--radius)" }}
    >
      {/* title header */}
      <div>
        {/* title */}
        <div
          className="fw-bold style-title-head"
          style={{ color: "var(--primary-color)" }}
        >
          {title}
        </div>
        {/* subTitle */}
        <div
          className="text-dark text-opacity-50 fw-bold"
          style={{ fontSize: "12px" }}
        >
          {subTitle}
        </div>
      </div>
      {/* content item*/}
      <div>{item}</div>
      {/* text footer */}
      <div className="text-start fw-bold mt-4">
        <div className="text-dark text-opacity-75">{textFooter}</div>
      </div>
    </div>
  );
};

export default FollowUpDetailsCardBgWhite;
