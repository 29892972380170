/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SopervisorAdditionGradesDetailsFinalReportItem from "./SopervisorAdditionGradesDetailsFinalReportItem";
import SopervisorAdditionGradesDetailsTotalItem from "./SopervisorAdditionGradesDetailsTotalItem";
import TableContainer from "../../../duplicateComponents/TableContainer";
// import SopervisorTitleWithNumber from "../../SopervisorTitleWithNumber";
import Button from "../../../duplicateComponents/Button";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import { useDispatch, useSelector } from "react-redux";
import { insert30Grades } from "../../../../redux/actions/grades/ActionInsert30Grades";
import { role } from "../../../../redux/types/Types";
import { insertGradesTotal } from "../../../../redux/actions/grades/ActionGradeTotal";
// import Taps from "../../../duplicateComponents/Taps";

const SopervisorAdditionGradesDetailsFinalReport = ({ propsDataStudent }) => {
  const [dataFinalRepor, setDataFinalRepor] = useState([
    {
      title: "السيرة الذاتية والتعريف بجهة التدريب",
      grade: 5,
    },
    {
      title: "الوصف الوظيفي للمهام التي قام بها مع النماذج",
      grade: 3,
    },
    {
      title: "التعريف بالتجهيزات التي تدرب عليها",
      grade: 3,
    },
    {
      title: "إيجابيات وسلبيات التدريب , التوصيات",
      grade: 3,
    },
    {
      title: "أسلوب الكتابة واللغة",
      grade: 3,
    },
    {
      title:
        "أسلوب عرض الأراء وتقديم التقرير (الطريقة - الوسائل - الترابط - الألفاظ)",
      grade: 6,
    },
    {
      title: "الجدية والأستعداد للمناقشة",
      grade: 2,
    },
    {
      title: "المظهر الشخصي",
      grade: 2,
    },
    {
      title: "الاهتمام بالسلامة المهنية",
      grade: 3,
    },
  ]);

  // const idRoute = useParams();
  const myDispatch = useDispatch();
  // useEffect(() => {
  //   myDispatch(getAllStudent());
  // }, []);

  // // api student
  // const myDataStudent = useSelector((state) => state.rootStudent.student.data);

  // api 30Grade
  const response30grade = useSelector(
    (state) => state.rootInsert30Grades.insert30grades
  );

  // api grade total
  const responseGradeTotal = useSelector(
    (state) => state.rootInsertGradesTotal.gradesTotal
  );

  const [vala, seta] = useState("");
  const [valb, setb] = useState("");
  const [valc, setc] = useState("");
  const [vald, setd] = useState("");
  const [vale, sete] = useState("");
  const [valf, setf] = useState("");
  const [valg, setg] = useState("");
  const [valh, seth] = useState("");
  const [vali, seti] = useState("");

  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);

  // console.log(dataFinalReportMap)

  const handelA = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[0];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        seta(val);
      }
    }
  };

  const handelB = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[1];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setb(val);
      }
    }
  };
  const handelC = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[2];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setc(val);
      }
    }
  };

  const handelD = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[3];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setd(val);
      }
    }
  };
  const handelE = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[4];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        sete(val);
      }
    }
  };
  const handelF = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[5];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setf(val);
      }
    }
  };
  const handelG = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[6];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setg(val);
      }
    }
  };
  const handelH = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[7];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        seth(val);
      }
    }
  };
  const handelI = (e) => {
    let val = e.target.value;
    let valLimit = dataFinalReportMap[8];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        seti(val);
      }
    }
  };

  let arrHandel = [
    handelA,
    handelB,
    handelC,
    handelD,
    handelE,
    handelF,
    handelG,
    handelH,
    handelI,
  ];
  let arrVal = [vala, valb, valc, vald, vale, valf, valg, valh, vali];
  let arrValNum = [
    parseInt(vala),
    parseInt(valb),
    parseInt(valc),
    parseInt(vald),
    parseInt(vale),
    parseInt(valf),
    parseInt(valg),
    parseInt(valh),
    parseInt(vali),
  ];
  // summistion all data
  let sumArrVal = arrValNum.reduce((one, two) => one + two);
  const submitAddFinalRepor = async (e) => {
    e.preventDefault();

    // check the val if empty => give me msg
    if (
      vala === "" ||
      valb === "" ||
      valc === "" ||
      vald === "" ||
      vale === "" ||
      valf === "" ||
      valg === "" ||
      valh === "" ||
      vali === ""
    ) {
      NotificationsToast("warn", null, "جميع الحقول مطلوبة");
      return;
    }

    // the total if not empty => give me msg
    // if (total !== null) {
    //   NotificationsToast("warn", null, `الدرجة مسجلة سابقاً`);
    //   return;
    // }
    if (propsDataStudent.username_emp === null) {
      NotificationsToast("warn", `المتدرب ليس لدية مشرف`);
      return;
    }
    if (propsDataStudent.username === null) {
      NotificationsToast("warn", `المتدرب ليس مسجل في الجهة`);
      return;
    }

    // if summ > 40 => give me msg
    if (sumArrVal > dataFinalReportReduce) {
      NotificationsToast(
        "warn",
        null,
        `الدرجات اعلى من ${dataFinalReportReduce} يجب ان تكون اقل`
      );
      return;
    }

    setIsLoading(true);
    setIsLoad(true);
    await myDispatch(
      insert30Grades({
        "student-id": propsDataStudent && propsDataStudent.student_id,
        "final-report-evaltion": sumArrVal,
        type: role,
      })
    );
    await myDispatch(
      insertGradesTotal(propsDataStudent && propsDataStudent.student_id)
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      // if (response30grade) {
      //   console.log(response30grade);
      //   setIsLoading(false);
      // }
      // if (responseGradeTotal) {
      //   console.log("11111");
      //   console.log(responseGradeTotal);
      //   console.log("22222");
      //   setIsLoading(false);
      // }
      if (response30grade.status === 200) {
        // the check it is Done sem sem
        if (
          response30grade.data === "done" ||
          response30grade.data ===
            "student is not inserted , i created student know"
        ) {
          NotificationsToast("done", null, "تم إدخال الدرجة للطالب");
          setTotal(sumArrVal);
          setIsLoading(false);
        } else if (response30grade.data === "already") {
          NotificationsToast("warn", null, `الدرجة مسجلة سابقاً`);
          setIsLoading(false);
        } else {
          if (response30grade.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في إدخال الدرجة`);
            setIsLoading(false);
          }
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  // maping => [7, 5, 5, 5, 10, 4, 4]
  let dataFinalReportMap = dataFinalRepor.map((item) => item.grade);
  //###################################################
  // summiation => 30
  let dataFinalReportReduce = dataFinalReportMap.reduce((a, b) => a + b);

  return (
    <div>
      <div className="mb-3">
        {/* <SopervisorTitleWithNumber
          title={"باسل محمد يحيى ال ظفره"}
          subTitle={"41242342"}
          num={null}
        /> */}
      </div>

      <div className="">
        <TableContainer
          thead={[
            "#",
            "عناصر تقييم التقرير",
            "درجة التقييم",
            "الدرجة المستحقة",
          ]}
          item={
            <>
              {dataFinalRepor.map((item, i) => (
                <SopervisorAdditionGradesDetailsFinalReportItem
                  key={i}
                  index={i + 1}
                  title={item.title}
                  grade={item.grade}
                  dueGrade={arrHandel[i]}
                  value={arrVal[i]}
                  indexId={i}
                />
              ))}
              <SopervisorAdditionGradesDetailsTotalItem
                index={10}
                grade={dataFinalReportReduce}
                totalGrade={total}
              />
            </>
          }
        />
        <div className=" mt-3 d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إدخال الدرجة"} width={"180px"} />
          ) : (
            <Button
              title={"حساب الدرجات"}
              onclick={submitAddFinalRepor}
              width={"180px"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SopervisorAdditionGradesDetailsFinalReport;
