import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  ADD_SUBMISSITIONS,
  ADD_SUBMISSITIONS_APPRO_FIRST,
  ADD_SUBMISSITIONS_DEC,
  GET_ERROR,
  GET_SUBMISSITIONS_BY_APPROVED,
  GET_SUBMISSITIONS_BY_APPROVED_PAGE,
  GET_SUBMISSITIONS_BY_USERNAME,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY012,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE,
  GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED,
  GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE,
  INSERT_SUBMISSITIONS_FIRST_DROP,
  INSERT_SUBMISSITIONS_LAST_DROP,
} from "../../types/Types";

const getSubmissitionByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_sub/${dataParams}`);
    dispatch({
      type: GET_SUBMISSITIONS_BY_USERNAME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getSubmissitionCompanyByCompanyName =
  (dataParams) => async (dispatch) => {
    try {
      const response = await UseGetData(`get_sub_company/${dataParams}`);
      dispatch({
        type: GET_SUBMISSITIONS_BY_USERNAME_COMPANY,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const getSubmissitionCompanyByCompanyNamePage =
  (dataParams, dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_sub_company/${dataParams}?page=${dataParamsPage}&limit=10`
      );
      dispatch({
        type: GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const getSubmissitionCompanyByCompanyName012 =
  (dataParams) => async (dispatch) => {
    try {
      const response = await UseGetData(`get_sub_company012/${dataParams}`);
      dispatch({
        type: GET_SUBMISSITIONS_BY_USERNAME_COMPANY012,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const getSubmissitionCompanyByCompanyName012Page =
  (dataParams, dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_sub_company012/${dataParams}?page=${dataParamsPage}&limit=10`
      );
      dispatch({
        type: GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const addSubmissition = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("inssubmissions", dataParams);
    dispatch({
      type: ADD_SUBMISSITIONS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getSubmissitionByApproved = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_subs/${dataParams}`);
    dispatch({
      type: GET_SUBMISSITIONS_BY_APPROVED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getSubmissitionByApprovedPage =
  (dataParams, dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_subs/${dataParams}?page=${dataParamsPage}&limit=10`
      );
      dispatch({
        type: GET_SUBMISSITIONS_BY_APPROVED_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };
const getSubmissitionByWaitAndFirstDeclinedAndFirstApproved =
  () => async (dispatch) => {
    try {
      const response = await UseGetData(`get_012`);
      dispatch({
        type: GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };
const getSubmissitionByWaitAndFirstDeclinedAndFirstApprovedPage =
  (dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_012?page=${dataParamsPage}&limit=10`
      );
      dispatch({
        type: GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };
const insertSubmissitionFristDrop = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("fdrop", dataParams);
    dispatch({
      type: INSERT_SUBMISSITIONS_FIRST_DROP,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertSubmissitionLastDrop = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("ldrop", dataParams);
    dispatch({
      type: INSERT_SUBMISSITIONS_LAST_DROP,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const addSubmissitionDec = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("decsubmission", dataParams);
    dispatch({
      type: ADD_SUBMISSITIONS_DEC,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const addSubmissitionFirstApp = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("fapproved", dataParams);
    dispatch({
      type: ADD_SUBMISSITIONS_APPRO_FIRST,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export {
  // getSubmissitionByApproved,
  getSubmissitionByUsername,
  getSubmissitionCompanyByCompanyName,
  addSubmissition,
  getSubmissitionCompanyByCompanyNamePage,
  getSubmissitionByApproved,
  getSubmissitionByApprovedPage,
  insertSubmissitionFristDrop,
  insertSubmissitionLastDrop,
  getSubmissitionByWaitAndFirstDeclinedAndFirstApproved,
  getSubmissitionByWaitAndFirstDeclinedAndFirstApprovedPage,
  getSubmissitionCompanyByCompanyName012,
  getSubmissitionCompanyByCompanyName012Page,
  addSubmissitionDec,
  addSubmissitionFirstApp,
};
