import React from "react";
import Breadcrumbs from "../../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import AdminStudentCreateContainer from "../../../../components/admin/users/student/AdminStudentCreateContainer";

const AdminStudentCreatePage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/admin/users-student"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              رجوع
            </Link>,
            "إضافة متدرب",
          ]}
        />
        <div className="container p-3 p-md-5">
          <AdminStudentCreateContainer />
        </div>
      </div>
    </div>
  );
};

export default AdminStudentCreatePage;
