import React from "react";
import ButtonLink from "./ButtonLink";

const Page404 = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: "url('../../images/bg-3.jpg')",
      }}
    >
      <div className="container">
        <div
          style={{
            minHeight: "80vh",
          }}
          className=" d-flex justify-content-center align-items-center flex-column"
        >
          <div className="">
            <div className="">
              <lottie-player
                src="https://lottie.host/7e845b87-faf6-4645-ab25-01af988c0a0e/jv697rBw8x.json"
                background="transparent"
                speed="1"
                // style={{ width: "400px", height: "400px" }}
                loop
                // controls
                autoplay
                fill="#f00"
              ></lottie-player>
            </div>
            <div className="text-center">
              <h1 className="fw-bold text-dark text-opacity-75">
               الصفحة غير موجودة
              </h1>
              <p>
                <span
                  className="fw-bold"
                  style={{ color: "var(--primary-color)" }}
                >
                  عذرًا،
                </span>{" "}
                الصفحة التي تبحث عنها غير موجودة. أو غير صحيحة أو أن الصفحة قد
                تمت إزالتها.
              </p>
              <div className="col-8 col-sm-6 m-auto">
                <ButtonLink title={"العودة الى الصفحة الرئيسة"} path={"/"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
