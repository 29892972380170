import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import CompanyAdditionGradesDetailsContainer from "../../../components/company/addition/grades/CompanyAdditionGradesDetailsContainer";

const CompanyAdditionGradesDetailsPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/company/addition-grades"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              إضافات للمتدربين
            </Link>,
            "إدخال الدرجات",
          ]}
        />
        <div className="container p-3 p-md-5">
          <CompanyAdditionGradesDetailsContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyAdditionGradesDetailsPage;
