/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "../duplicateComponents/Button";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormsContainer from "./FormsContainer";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import TableContainer from "../duplicateComponents/TableContainer";
import FormsTriningAvalibleInCompanyTableItem from "./FormsTriningAvalibleInCompanyTableItem";
import FormInputCheckBox from "../duplicateComponents/FormInputCheckBox";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import { useDispatch } from "react-redux";
import { insertFormsPdfTriningAvalible } from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";

const FormsTriningAvalibleInCompanyContainer = ({
  propsTitle,
  propsSubTitle,
}) => {
  const whatGiveMecheckBox = ["مكافئة", "مواصلات", "دورات", "علاج", "إعاشة"];
  const myDispatch = useDispatch();

  const [dataOpportunities, setDataOpportunities] = useState([
    {
      department: "",
      count: "",
      job: "",
      trining: "",
    },
    {
      department: "",
      count: "",
      job: "",
      trining: "",
    },
    {
      department: "",
      count: "",
      job: "",
      trining: "",
    },
  ]);
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  // const [arrDept, setArrDept] = useState([
  //   dataOpportunities,
  //   dataOpportunities,
  //   dataOpportunities,
  // ]);
  const handelDataOpportunities = (e) => {
    setDataOpportunities({
      ...dataOpportunities[e],
      [e.target.name]: e.target.value,
    });
    // setArrDept([dataOpportunities]);
    // setArrDept([...arrDept, dataOpportunities]);
  };
  const addDept = () => {
    // setArrDept([...arrDept, dataOpportunities]);
  };

  // console.log(dataOpportunities);

  const [dataInputs, setDataInputs] = useState({
    company_name: "",
    NatureOfTheActivity: "",
    phone: "",
    fax: "",
    from: "",
    to: "",
    opportunities: dataOpportunities,
    HeadquartersIsLocatedAt: "",
    OneOfTheBranchesIsLocatedIn: "",
    choose1: false,
    choose2: false,
    choose3: false,
    choose4: false,
    choose5: false,
    name: "",
    job: "",
    phone2: "",
    email: "",
    companyManager: "",
    sign: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const submitPDF = async (e) => {
    e.preventDefault();
    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfTriningAvalible(formData));
    setIsLoad(false);
  };
  // console.log(dataOpportunities);
  // console.log(arrDept);

  const arrDataInputs = [
    {
      name: "company_name",
      value: dataInputs.company_name || "",
      label: "أسم المنشأة",
      placeholder: "أسم المنشأة",
    },
    {
      name: "NatureOfTheActivity",
      value: dataInputs.NatureOfTheActivity || "",
      label: "طبيعة النشاط",
      placeholder: "طبيعة النشاط",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "التليفون",
      placeholder: "التليفون",
    },
    {
      name: "fax",
      value: dataInputs.fax || "",
      label: "فاكس",
      placeholder: "فاكس",
    },
    {
      name: "from",
      value: dataInputs.from || "",
      label: "تاريخ بداية التدريب",
      placeholder: "تاريخ بداية التدريب",
    },
    {
      name: "to",
      value: dataInputs.to || "",
      label: "تاريخ نهاية التدريب",
      placeholder: "تاريخ نهاية التدريب",
    },
    {
      title: "موقع التدريب",
    },
    {
      name: "HeadquartersIsLocatedAt",
      value: dataInputs.HeadquartersIsLocatedAt || "",
      label: "المقر الرئيسي ويقع في",
      placeholder: "المقر الرئيسي ويقع في",
    },
    {
      name: "OneOfTheBranchesIsLocatedIn",
      value: dataInputs.OneOfTheBranchesIsLocatedIn || "",
      label: "أحد الفروع ويقع في",
      placeholder: "أحد الفروع ويقع في",
    },
    {
      title: "المسؤول عن التدريب في المنشأة",
    },
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم",
      placeholder: "أسم",
    },
    {
      name: "job",
      value: dataInputs.job || "",
      label: "الوظيفة",
      placeholder: "الوظيفة",
    },
    {
      name: "phone2",
      value: dataInputs.phone2 || "",
      label: "الهاتف",
      placeholder: "الهاتف",
    },

    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
    },
    {
      name: "companyManager",
      value: dataInputs.companyManager || "",
      label: "مدير المنشأة",
      placeholder: "مدير المنشأة",
    },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        fs={"22px"}
        titleHeader={`ماهو ${propsTitle} ؟`}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />

      {/* forms */}
      <div className="">
        <FormsContainer
          titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
          item={
            <form className="row gx-5 gy-3 needs-validation" noValidate>
              {arrDataInputs.map((item, i) =>
                i === 6 || i === 9 ? (
                  <FormInputTitleHead key={i} title={item.title} fs={"21px"} />
                ) : i === 7 || i === 8 ? (
                  <div className="col-12" key={i}>
                    <FormInputTextArea
                      type={"text"}
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      idAndfor={"validationCustomIdNo"}
                      isValid={"valid"}
                      msgValid={"Gooooooooood !!"}
                    />
                  </div>
                ) : (
                  <div className="col-lg-6" key={i}>
                    <FormInput
                      type={"text"}
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      idAndfor={"validationCustomFullName"}
                      isValid={"valid"}
                      msgValid={"Gooooooooood !!"}
                    />
                  </div>
                )
              )}

              <div>
                {/* <div>
                  <Button
                    type={"button"}
                    onclick={() => addDept()}
                    title={
                      <div className="d-flex align-items-center">
                        <div className="text-white">فرصة تدريبية</div>
                        <div className="text-white d-flex fw-bold mx-2 align-items-center justify-content-center">
                          <ion-icon color="#" name="add-circle"></ion-icon>
                        </div>
                      </div>
                    }
                    width={"140px"}
                  />
                </div> */}
                <TableContainer
                  className={"my-4"}
                  thead={[
                    "#",
                    "القسم",
                    "عدد الفرص التدريبية المتاحة",
                    "الفرص الوظيفية ",
                  ]}
                  item={
                    <>
                      {dataOpportunities.map((item, i) => (
                        <FormsTriningAvalibleInCompanyTableItem
                          index={i + 1}
                          key={i}
                          title={item}
                          propsOnchange={handelDataOpportunities}
                          propsVal={dataOpportunities[i]}
                        />
                      ))}
                    </>
                  }
                />
              </div>
              <div className="col-12 d-flex align-items-center ">
                <span className="">
                  <input
                    className="form-check-input border shadow-none"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        dataInputs.sign = true;
                      } else {
                        dataInputs.sign = false;
                      }
                    }}
                    id="sign"
                    name={"sign"}
                    value={dataInputs.sign}
                  />
                </span>{" "}
                <label htmlFor="sign" className="bg-dange fw-bold mx-3">
                  للتوقيع اضغط هنا
                </label>
              </div>
            </form>
          }
        />

        <div className="col-12">
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
            ) : (
              <Button
                title={"أرسال"}
                width={"250px"}
                dataBsToggle="modal"
                dataBsTarget="#exampleModalForms-request-exception"
                type="submit"
              />
            )}
            <PopupAreYouSure
              id={"exampleModalForms-request-exception"}
              // onCLick={countTimeLineNow}
              onCLick={submitPDF}
              idPopupStar={"exampleModalForms-request-exception"}
              textAlign={"center"}
              title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsTriningAvalibleInCompanyContainer;
