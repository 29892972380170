import { UseCreateData } from "../../../hooks/UseCreateData";
import { DELETE_COLLAGE, GET_ERROR } from "../../types/Types";

const deleteCollage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_collage", dataParams);

    dispatch({
      type: DELETE_COLLAGE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { deleteCollage };
