/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ProfileOutlined, LogoutOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";
import { role } from "../../../redux/types/Types";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
const CompanyHeaderLogic = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log(window.innerWidth);
    // console.log(collapseWidth);
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
      // setCollapseWidth(window.innerWidth)
      // setCollapse(true);
    } else {
      // setCollapse(false);
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);

  // const nav = useNavigate();
  // // const auth = useAuth();

  // const handelLogout = () => {
  //   auth.logout();
  //   nav("/", { replace: true });
  // };

  const nav = useNavigate();
  const auth = useAuth();

  const handelLogout = () => {
    auth.logout();
    nav("/", { replace: true });
  };
  const items = [
    {
      key: "1",
      label: (
        <Link style={{ textDecoration: "none" }} to={"/company/profile"}>
          المعلومات الشخصية
        </Link>
      ),
      icon: <ProfileOutlined className="mt-1" />,
    },

    {
      key: "2",
      danger: true,
      // label: (
      //   <div
      //     onClick={() => {
      //       if (
      //         localStorage.getItem("token") &&
      //         localStorage.getItem("storageDataObj") &&
      //         localStorage.getItem("role")
      //       ) {
      //         localStorage.clear();
      //         window.location.href = "/";
      //         // console.log("logout");
      //       }
      //     }}
      //   >
      //     تسجيل خروج
      //   </div>
      // ),
      label: <div onClick={handelLogout}>تسجيل خروج</div>,
      icon: <LogoutOutlined className="mt-1" />,
    },
  ];
  const [companyName, setCompanyName] = useState("");
  useEffect(() => {
    if (localStorage.getItem("storageDataObj") && role) {
      setCompanyName(
        JSON.parse(localStorage.getItem("storageDataObj")).company_name
      );
    }
  }, []);
  return [companyName, open, setOpen, items, onClose];
};

export default CompanyHeaderLogic;
