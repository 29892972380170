import {
  GET_ALL_COLLAGE,
  GET_ALL_COLLAGE_PAGE,
  GET_ERROR,
  INSERT_COLLAGE,
} from "../../types/Types";

const inital = {
  collage: [],
  loading: true,
};

const reducerCollage = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_COLLAGE:
      return {
        ...state,
        collage: action.payload,
        loading: false,
      };
    case INSERT_COLLAGE:
      return {
        collage: action.payload,
        loading: false,
      };
    case GET_ALL_COLLAGE_PAGE:
      return {
        collage: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        collage: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerCollage;
