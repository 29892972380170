/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGradesByUsername } from "../../../redux/actions/grades/ActionGrades";
import { tokenStudentUsername } from "../../../redux/types/Types";
const FollowUpGradeContainerLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getGradesByUsername(tokenStudentUsername));
  }, []);

  // api grades
  const myDataGrades = useSelector((state) => state.rootGrades.grades);
  const loading = useSelector((state) => state.rootGrades.loading);

  return [myDataGrades, loading];
};

export default FollowUpGradeContainerLogic;
