/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { role } from "../../../redux/types/Types";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";
import { deleteMajor } from "../../../redux/actions/delete/ActionDeleteMajor";
import { getAllMajor } from "../../../redux/actions/major/ActionMajor";

const AdminDeleteMajorLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllMajor());
  }, []);

  // api clear
  const responseDelMajor = useSelector(
    (state) => state.rootDelMajor.deleteMajor
  );
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const submitDeleteMajor = async (e, majorName) => {
    e.preventDefault();

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      deleteMajor({
        major_name: majorName,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseDelMajor) {
        myDispatch(getAllStudentWithoutPagination());
        myDispatch(getAllMajor());
      }
      if (responseDelMajor.status === 200) {
        if (responseDelMajor.data === "done") {
          NotificationsToast("done", null, `تم حذف التخصص`);
          setIsLoading(false);
          return;
        } else if (responseDelMajor.data === "nothing") {
          NotificationsToast("warn", null, `تم حذف التخصص سابقاً`);
          setIsLoading(false);
          return;
        } else {
          if (responseDelMajor.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في حذف التخصص`);
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  return [submitDeleteMajor, isLoading];
};

export default AdminDeleteMajorLogic;
