/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllMajor,
  insertMajor,
} from "../../../../redux/actions/major/ActionMajor";
import { role } from "../../../../redux/types/Types";
import { getAllStudentWithoutPagination } from "../../../../redux/actions/student/ActionStudentWithoutPagination";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import Button from "../../../duplicateComponents/Button";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";

const AdminMajorCreateContainer = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [departmentName, setDepartmentName] = useState("");
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isShowMsgErrorSelect, setIsShowMsgErrorSelect] = useState(false);

  const responseNewMajor = useSelector((state) => state.rootMajor.major);
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );

  const handelDataInputs = (e) => {
    if (e.target.value.length > 0) {
      setIsShowMsgError(false);
    }
    setDepartmentName(e.target.value);
  };

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.department_name === "") {
      setIsShowMsgErrorSelect(true);
    } else {
      setIsShowMsgErrorSelect(false);
    }
    setSelectedDepartmentName(selectedItem.department_name);
  };
  const onRemove = (selectedList, selectedItem) => {
    // let newArr = selectedList.filter((val) => val !== val.username_emp);
    // let newArr2 = newArr.map((val) => val.username_emp);
    // setArrSelectedMuiltySopervisor(newArr2);
    setSelectedDepartmentName("");
  };

  //   console.log(selectedDepartmentName);

  const fun = () => {
    setOptions(myDataDepartment);
  };

  useEffect(() => {
    fun();
  }, [options]);

  const submitInsertNewDepartment = async (e) => {
    e.preventDefault();

    if (departmentName === "" && selectedDepartmentName === "") {
      setIsShowMsgError(true);
      setIsShowMsgErrorSelect(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }

    if (departmentName === "") {
      setIsShowMsgError(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }
    if (selectedDepartmentName === "") {
      setIsShowMsgErrorSelect(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      insertMajor({
        department: selectedDepartmentName,
        major: departmentName,
        type: role,
      })
    );
    setIsLoad(false);
    setDepartmentName("");
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewMajor) {
        // console.log(responseNewMajor);
        myDispatch(getAllMajor());
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseNewMajor.status === 200) {
        if (responseNewMajor.data === "done") {
          NotificationsToast("done", null, "تم إضافة التخصص بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewMajor.data === "already") {
          NotificationsToast("warn", null, "التخصص مسجلة سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewMajor.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة التخصص");
          setIsLoading(false);
          return;
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);
  return (
    <div>
      <FormsContainer
        titleHead={
          <FormInputTitleHead title={"إضافة تخصص جديدة"} fs={"30px"} />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            <div className="col-12 col-lg-6">
              <div className="">
                <FormInputTitleHead
                  textAlign={"center"}
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <div>تحديد القسم</div>
                      <div className="mx-2">
                        <FontAwesomeIcon icon={faArrowDown} />{" "}
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="style-scroll-radiu"
                // here if click container MultipleSelected get all data skills
                onClick={async () => {
                  await myDispatch(getAllDepartment());
                  fun();
                }}
              >
                <Multiselect
                  options={options} // Options to display in the dropdown
                  // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                  // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue={"department_name"} // Property name to display in the dropdown options
                  placeholder="اختار عن القسم"
                  className="text-center rounded-3 "
                  showArrow={true}
                  singleSelect={true}
                />
              </div>
              <div
                className={` position-absolut text-danger fw-bold my-2`}
                style={{ fontSize: "12px" }}
              >
                {isShowMsgErrorSelect ? "الحقل مطلوب" : ""}
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-5">
              <FormInput
                type={"text"}
                onChange={handelDataInputs}
                value={departmentName}
                label={"أسم التخصص"}
                placeholder={"أسم التخصص"}
                msgError={isShowMsgError ? "الحقل مطلوب" : ""}
              />
            </div>
          </form>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة التخصص"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewDepartment"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewDepartment"}
            onCLick={submitInsertNewDepartment}
            idPopupStar={"exampleModal_InsertNewDepartment"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة التخصص`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminMajorCreateContainer;
