import { UseCreateData } from "../../../hooks/UseCreateData";
import { GET_ERROR, ISNERT_40_GRADES } from "../../types/Types";

const insert40Grades = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("i_grades", dataParams);
    dispatch({
      type: ISNERT_40_GRADES,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export { insert40Grades };
