import React from "react";

const LoadingPlaceholderItem = ({ bgColor, styleBgColor, isSize }) => {
  return (
    <>
      {isSize === "lg" ? (
        <span
          className={`placeholder  ${"col-12 col-lg-6"} bg-${bgColor}  rounded-5`}
          style={{ backgroundColor: styleBgColor }}
        ></span>
      ) : isSize === "sm" ? (
        <span
          className={`placeholder  placeholder-sm ${"col-12 col-lg-6"} bg-${bgColor}  rounded-5`}
          style={{ backgroundColor: styleBgColor }}
        ></span>
      ) : null}
    </>
  );
};

export default LoadingPlaceholderItem;
