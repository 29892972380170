import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import SopervisorStatusContainer from "../../../components/sopervisor/status/SopervisorStatusContainer";

const SopervisorStatusPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["حالة المشرف"]} />
        <div className="container p-3 p-md-5">
          <SopervisorStatusContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorStatusPage;
