import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_COLLAGE,
  GET_ALL_COLLAGE_PAGE,
  GET_ERROR,
  INSERT_COLLAGE,
} from "../../types/Types";

const getAllCollage = () => async (dispatch) => {
  try {
    const response = await UseGetData("getcollage");

    dispatch({
      type: GET_ALL_COLLAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertCollage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("incollage", dataParams);
    dispatch({
      type: INSERT_COLLAGE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllCollagePage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`getcollage?page=${dataParams}`);

    dispatch({
      type: GET_ALL_COLLAGE_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getAllCollage, insertCollage,getAllCollagePage };
