import {
  GET_ALL_SKILLS,
  GET_ALL_SKILLS_PAGE,
  GET_ERROR,
  GET_SKILLS_BY_USERNAME,
  INSERT_ATTACH_SKILLS,
  INSERT_NEW_SKILLS,
} from "../../types/Types";

const inital = {
  skills: [],
  insertSkills: [],
  insertAttachSkills: [],
  loading: true,
  loadingInsertSkills: true,
  loadingInsertAttachSkills: true,
};

const reducerSkills = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SKILLS:
      return {
        ...state,
        skills: action.payload,
        loading: false,
      };
    case GET_ALL_SKILLS_PAGE:
      return {
        ...state,
        skills: action.payload,
        loading: false,
      };
    case GET_SKILLS_BY_USERNAME:
      return {
        ...state,
        skills: action.payload,
        loading: false,
      };
    case INSERT_NEW_SKILLS:
      return {
        insertSkills: action.payload,
        loadingInsertSkills: false,
      };
    case INSERT_ATTACH_SKILLS:
      return {
        insertAttachSkills: action.payload,
        loadingInsertAttachSkills: false,
      };
    case GET_ERROR:
      return {
        skills: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};
export default reducerSkills;
