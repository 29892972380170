import {
  FORMS_PDF_CERF,
  FORMS_PDF_DIRECT_TRINING,
  FORMS_PDF_ENROLL,
  FORMS_PDF_FORMSGUIDANCE,
  FORMS_PDF_REQUEST_EXCEPTION,
  FORMS_PDF_REQUEST_TRINING,
  FORMS_PDF_TRINING_AVALIBAL,
  FORMS_PDF_TRINING_PROCESS,
  GET_ERROR,
} from "../../types/Types";

const inital = {
  formsPdf: [],
  loading: true,
};

const reducerFormsPdf = (state = inital, action) => {
  switch (action.type) {
    case FORMS_PDF_DIRECT_TRINING:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_FORMSGUIDANCE:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_ENROLL:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_REQUEST_EXCEPTION:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_REQUEST_TRINING:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_CERF:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_TRINING_PROCESS:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case FORMS_PDF_TRINING_AVALIBAL:
      return {
        formsPdf: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        formsPdf: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerFormsPdf;
