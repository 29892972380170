/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCompanyPage } from "../../../../redux/actions/company/ActionCompany";
import TimeLineBoxCardDarkGreen from "../../../duplicateComponents/TimeLineBoxCardDarkGreen";
import LoadingTimeLineLightGreen from "../../../duplicateComponents/LoadingTimeLineLightGreen";
import TimeLineWithTitleAndSubTitle from "../../../duplicateComponents/TimeLineWithTitleAndSubTitle";

const AdminCompanyViewContainer = () => {
  const columnEng = [
    "company_name",
    "username",
    "about",
    "department_name",
    "phone",
    "email",
    "Fax",
    "Zip_code",
    "address",
    "Working_hours",
  ];

  const columnAr = [
    "أسم الجهة",
    "اسم الستخدم",
    "نبذة عنها",
    "أسم القسم",
    "رقم الهاتف",
    "البريد الالكتروني",
    "فاكس",
    "الرمز البريدي",
    "العنوان",
    "ساعات العمل",
  ];

  const idRoute = useParams();

  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllCompanyPage(idRoute.page));
  }, []);

  const myDataCompany = useSelector(
    (state) => state.rootCompany.allCompany.data
  );
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );

  return (
    <TimeLineBoxCardDarkGreen
      titleHeader={"معلومات الجهة"}
      item={
        <>
          {loadingCompany ? (
            <LoadingTimeLineLightGreen countPlaceholder={4} />
          ) : myDataCompany ? (
            columnAr.map((item, i) => (
              <TimeLineWithTitleAndSubTitle
                key={i}
                title={item}
                subTitle={
                  myDataCompany[idRoute.id][columnEng[i]] === null
                    ? "-"
                    : myDataCompany[idRoute.id][columnEng[i]]
                }
                whatColor={"lightGreen"}
              />
            ))
          ) : (
            <TimeLineWithTitleAndSubTitle
              title={"لاتوجد بيانات"}
              whatColor={"lightGreen"}
            />
          )}
        </>
      }
    />
  );
};

export default AdminCompanyViewContainer;
