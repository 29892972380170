import React from "react";
import AdminStatusLogic from "../../../logic/admin/status/AdminStatusLogic";
import ReactLoading from "react-loading";

const AdminStatusContainer = () => {
  const [dataStatistics] = AdminStatusLogic();

  return (
    <div className="container p-3 p-md-5 placeholder-glow ">
      <div
        className="row m-0 bg-white px-0 px-lg-3 my-5 gy-3 style-shadow-smal"
        style={{ borderRadius: "var(--radius)" }}
      >
        <div
          className="text-center fw-bold fs-4"
          style={{
            transform: "translateY(30px)",
            color: "var(--primary-color)",
          }}
        >
          إحصائيات المستخدمين
        </div>
        {dataStatistics.map((item, i) => (
          <div className="col-12 col-md-6 col-xl-4" key={i}>
            <div
              className=" p-4 rounded-3"
              style={{
                transform: "translateY(60px)",
                backgroundColor: item.bgColor,
              }}
            >
              <div>
                <img
                  src={item.icon}
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <div className="fw-bold me-" style={{ color: item.color }}>
                  {item.title}{" "}
                </div>
                <div
                  className="fw-bold fs-5 mx-2"
                  style={{ color: item.color }}
                >
                  {isNaN(item.count) ? (
                    <ReactLoading
                      type={"bubbles"}
                      color={item.color}
                      width={40}
                      height={40}
                    />
                  ) : (
                    item.count
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminStatusContainer;
