import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { role } from "../../redux/types/Types";
const Breadcrumbs = ({ main, element, mainPath }) => {
  return (
    <div className="container px-3 px-md-5 mt-3">
      <div className="fw-bold style-Breadcrumbs-fs">
        <span>
          <Link
            to={
              role === "student"
                ? "/student/status"
                : role === "company"
                ? "/company/status"
                : role === "emp"
                ? "/sopervisor/status"
                : role === "admin"
                ? "/admin/status"
                : null
            }
            style={{ color: "var(--primary-color)", textDecoration: "none" }}
          >
            {role === "student"
              ? "المتدرب"
              : role === "company"
              ? "الجهة"
              : role === "emp"
              ? "المشرف"
              : role === "admin"
              ? "مدير النظام"
              : null}
          </Link>
        </span>

        {element.map((item, i) => (
          <span key={i}>
            <FontAwesomeIcon
              className="mx-1"
              style={{ fontSize: "14px", transform: "translateY(1px)" }}
              icon={faAngleLeft}
            />
            <span>{item}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
