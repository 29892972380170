/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { role } from "../../../redux/types/Types";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";
import { getAllDepartment } from "../../../redux/actions/department/ActionDepartment";
import { deleteDepartment } from "../../../redux/actions/delete/ActionDeleteDepaertment";

const AdminDeleteDepartmentLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  // api department
  const responseDelDepartment = useSelector(
    (state) => state.rootDelDepartment.deleteDepartment
  );
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const submitDeleteDepartment = async (e, departmentName) => {
    e.preventDefault();

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      deleteDepartment({
        department_name: departmentName,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseDelDepartment) {
        myDispatch(getAllStudentWithoutPagination());
        myDispatch(getAllDepartment());
      }
      if (responseDelDepartment.status === 200) {
        if (responseDelDepartment.data === "done") {
          NotificationsToast("done", null, `تم حذف القسم `);
          setIsLoading(false);
          return;
        } else if (responseDelDepartment.data === "nothing") {
          NotificationsToast("warn", null, `تم حذف القسم سابقاً`);
          setIsLoading(false);
          return;
        } else {
          if (responseDelDepartment.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في حذف القسم`);
            setIsLoading(false);
            return;
          }
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  return [submitDeleteDepartment, isLoading];
};

export default AdminDeleteDepartmentLogic;
