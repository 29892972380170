/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import axios from "axios";

const SubscriptionsForms = ({ inputSubscriptions }) => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState({
    app_name: "",
    from: "",
    phone: "",
    email: "",
    sub: "",
    Msg: "",
  });
  const [dataInputs, setDataInputs] = useState({
    app_name: "مارس",
    from: "",
    phone: "",
    email: "",
    sub: inputSubscriptions,
    Msg: "",
  });
  const handelDataInputs = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!isNaN(value)) {
        setDataInputs({ ...dataInputs, [name]: value });
      }
    } else {
      setDataInputs({ ...dataInputs, [name]: value });
    }
  };

  const arrDataInputs = [
    {
      name: "from",
      value: dataInputs.from || "",
      label: "الأسم",
      placeholder: "الأسم",
      msgError: isError
        ? dataInputs.from === ""
          ? "الحقل مطلوب"
          : errors.from
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الهاتف",
      placeholder: "رقم الهاتف",
      msgError: isError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : errors.phone
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "الإيميل",
      placeholder: "الإيميل",
      msgError: isError
        ? dataInputs.email === ""
          ? "الحقل مطلوب"
          : errors.email
        : "",
    },
    {
      name: "sub",
      value: dataInputs.sub || "",
      label: "الباقة",
      placeholder: "الباقة",
      disabled: true,
      msgError: isError
        ? dataInputs.sub === ""
          ? "الحقل مطلوب"
          : errors.sub
        : "",
    },
    {
      name: "Msg",
      value: dataInputs.Msg || "",
      label: "رسالتك",
      placeholder: "رسالتك",
      msgError: isError
        ? dataInputs.Msg === ""
          ? "الحقل مطلوب"
          : errors.Msg
        : "",
    },
  ];

  const sendEmail = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsError(true);
        // NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    console.log(dataInputs);
    setLoading(true);

    await axios
      .post("https://form.osoolsys.sa/public/api/FormRecive", dataInputs)
      .then((val) => {
        setLoading(false);
        setLoad(true);
        console.log("done", val);
        setResponse(val);
        setLoad(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoad(true);
        console.log("not Done", error);
        setLoad(false);
      });
  };
  useEffect(() => {
    if (load === false) {
      if (response.status !== 200) {
        NotificationsToast("error", "هناك مشكلة في إرسال الطلب ");
        return;
      }
      if (response.data === "done") {
        NotificationsToast("done", "تم إرسال طلب ");
        return;
      }
    }
  }, [load]);
  return (
    <div className="style-shadow-small rounded-3 bg-white p-3 mt-3">
      <div className="text-center text-dark mb-5" style={{ zIndex: "100" }}>
        <div
          className="fs-3 fw-bold "
          style={{ color: "var(--primary-color)" }}
        >
          <span>تواصـل معنـا</span>
        </div>
        <p className="mt-3 text-title-icons-blobs">
          إذا كنت تريد الاشتراك في هذي الباقة تواصل معنا عبر النموذج التالي
        </p>
      </div>
      <form className="  d-flex justify-content-center align-items-center flex-column  ">
        {/* inputs */}
        {arrDataInputs.map((item, i) => (
          <div className="col-12" key={i}>
            {i === arrDataInputs.length - 1 ? (
              <FormInputTextArea
                onChange={handelDataInputs}
                name={item.name}
                value={item.value}
                label={item.label}
                placeholder={item.placeholder}
                inputType={item.inputType}
                icon={item.icon}
                msgError={item.msgError}
              />
            ) : (
              <FormInput
                onChange={handelDataInputs}
                name={item.name}
                value={item.value}
                label={item.label}
                placeholder={item.placeholder}
                inputType={item.inputType}
                icon={item.icon}
                msgError={item.msgError}
                disabled={item.disabled}
                // bgColor="#fff"
              />
            )}
          </div>
        ))}
        <div className="d-flex justify-content-center mt-4">
          {loading ? (
            <button
              className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
              id="style-btn-start-service"
              disabled
              style={{
                width: "200px",
              }}
            >
              ... جاري الارسال
            </button>
          ) : (
            <button
              className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
              id="style-btn-start-service"
              style={{
                width: "200px",
              }}
              onClick={sendEmail}
            >
              إرسال
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SubscriptionsForms;
