import {
  GET_ERROR,
  INSERT_CLEAR_COLLAGE,
  INSERT_CLEAR_COMPANY,
  INSERT_CLEAR_DEPARTMENT,
  INSERT_CLEAR_MAJOR,
  INSERT_CLEAR_SOPERVISOR,
  INSERT_CLEAR_STUDENT,
} from "../../types/Types";

const inital = {
  clear: [],
  loading: true,
};

const reducerClear = (state = inital, action) => {
  switch (action.type) {
    case INSERT_CLEAR_STUDENT:
      return {
        clear: action.payload,
        loading: false,
      };
    case INSERT_CLEAR_COMPANY:
      return {
        clear: action.payload,
        loading: false,
      };
    case INSERT_CLEAR_SOPERVISOR:
      return {
        clear: action.payload,
        loading: false,
      };
    case INSERT_CLEAR_COLLAGE:
      return {
        clear: action.payload,
        loading: false,
      };
    case INSERT_CLEAR_DEPARTMENT:
      return {
        clear: action.payload,
        loading: false,
      };
    case INSERT_CLEAR_MAJOR:
      return {
        clear: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        clear: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerClear;
