import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
// import SidebarSopervisorPage from "../../sideBar/SidebarSopervisorPage";
// import HeaderSopervisor from "../../../components/sopervisor/HeaderSopervisor";
import SopervisorAdditionSkillsContainer from "../../../components/sopervisor/addition/skills/SopervisorAdditionSkillsContainer";
const SopervisorAdditionSkillsPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["إضافات للمتدربين", "إضافة مهارات"]} />
        <div className="container p-3 p-md-5">
          <SopervisorAdditionSkillsContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorAdditionSkillsPage;
