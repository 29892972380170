import React from "react";
import { Spin, Table } from "antd";
import AdminCollageFollowUpLogic from "../../../../logic/admin/followUp/AdminCollageFollowUpLogic";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import ButtonWithIcon from "../../../duplicateComponents/ButtonWithIcon";
import FormInput from "../../../duplicateComponents/FormInput";
import { getAllCollagePage } from "../../../../redux/actions/collage/ActionCollage";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
const { Column } = Table;

const AdminCollageIndexContainer = () => {
  const [
    myDataCollage,
    loadingCollage,
    myDataCollageTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeletecollage,
    dataTableColumns,
    myDispatch,
  ] = AdminCollageFollowUpLogic();

  return (
    <Spin
      spinning={isLoadingDeletecollage}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع الكليات"}
                subTitle={"عدد الكليات"}
                num={myDataCollage && myDataCollageTotal}
              />
            </span>
            <span className="mt-2 mt-sm-0">
              <ButtonWithIcon path={"/admin/users-collage/create"} />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingCollage ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total: myDataCollage && myDataCollage.total,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getAllCollagePage(page));
                  },
                }}
                size="middle"
                dataSource={myDataCollage && myDataCollage.data}
                rowKey={"collage_name"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminCollageIndexContainer;
