/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FollowUpDetailsCardBgWhite from "../FollowUpDetailsCardBgWhite";

const FollowUpDetailsVisitsContainer = ({ propsVisitCount }) => {
  return (
    <div className="row gy-3">
      <div className="col-12 col-lg-6">
        <FollowUpDetailsCardBgWhite
          title={"عدد الزيارات"}
          subTitle={"عدد الزيارات الميدانية للمشرف الميداني"}
          item={
            <div className="my-3">
              {/* <TimeLine /> */}
              <div
                className="fw-bold"
                style={{ fontSize: "66px", color: "var(--primary-color)" }}
              >
                {!isNaN(propsVisitCount) ? parseInt(propsVisitCount) : "-"}
              </div>
            </div>
          }
          textFooter={`مجموع الزيارات : ${
            !isNaN(propsVisitCount) ? parseInt(propsVisitCount) : "-"
          }`}
        />
      </div>
    </div>
  );
};

export default FollowUpDetailsVisitsContainer;
