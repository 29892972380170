import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";

const SubscriptionsCard = ({ price, month, AdvandagesItems = [], onclick }) => {
  return (
    <div className="style-shadow-small rounded-4 bg-white ">
      {/* price */}
      <div
        className="position-relative"
        style={{
          background: "var(--primary-color)",
          borderRadius: "1rem 1rem 0 0 ",
        }}
      >
        <div className="p-3 text-white  d-flex flex-column jusitfy-content-center align-items-center ">
          <div className="fw-bold">
            <span className="fs-1 ">{price}</span>
            <span className="mx-1">ريال</span>
          </div>
          <div>
            <div>{month}</div>
          </div>
        </div>
        <div className="Triangles"></div>
      </div>
      {/* Advandages */}
      <div className="mt-4 p-3 fw-bold">
        {AdvandagesItems.map((item, i) => (
          <div key={i}>
            <span className="">
              <CheckCircleFilled style={{ color: "var(--primary-color)" }} />
            </span>
            <span className="mx-2" style={{ fontSize: 12 }}>
              {item}
            </span>
          </div>
        ))}
      </div>
      <hr className="" />
      <div className="d-flex justify-content-center w-100">
        <button
          onClick={onclick}
          className="btn fw-bold rounded-5 style-shadow-large bg-whit text-center  mb-2  w-50"
          id="style-btn-start-service"
          style={{
            width: "200px",
          }}
        >
          اشتراك
        </button>
      </div>
    </div>
  );
};

export default SubscriptionsCard;
