import React from "react";

const FormInputTextArea = ({
  label,
  placeholder,
  idAndfor,
  isValid,
  msgValid,
  onChange,
  value,
  isLabel,
  name,
  msgError,
}) => {
  return (
    <div className="">
      {isLabel === null ? null : (
        <label
          htmlFor={idAndfor}
          className="form-label fw-bold "
          style={{ fontSize: "14px" }}
        >
          {label}
        </label>
      )}
      <textarea
        className="form-control shadow-none p-2 style-scroll-radius"
        style={{ backgroundColor: "#f9f9f9" }}
        id={idAndfor}
        name={name}
        onChange={onChange}
        value={value}
        // value="Mark"
        placeholder={placeholder}
        required
      ></textarea>
      <div
        className={`${isValid}-feedbackek position-absolut text-danger fw-bold my-2`}
        style={{ fontSize: "12px" }}
      >
        {msgError}
      </div>
    </div>
  );
};

export default FormInputTextArea;
