import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import SopervisorProfileContainer from "../../../components/sopervisor/profile/SopervisorProfileContainer";

const SopervisorProfilePage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["المعلومات الشخصية"]} />
        <div className="container p-3 p-md-5">
          <SopervisorProfileContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorProfilePage;
