import {
  GET_ERROR,
  INSERT_RELATION_STUDENT_WITH_SOPERVIOSR,
} from "../../types/Types";

const inital = {
  relation: [],
  loading: true,
};
const reducerRelation = (state = inital, action) => {
  switch (action.type) {
    case INSERT_RELATION_STUDENT_WITH_SOPERVIOSR:
      return {
        relation: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        relation: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerRelation;
