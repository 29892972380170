import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  GET_ERROR,
  INSERT_CLEAR_COLLAGE,
  INSERT_CLEAR_COMPANY,
  INSERT_CLEAR_DEPARTMENT,
  INSERT_CLEAR_MAJOR,
  INSERT_CLEAR_SOPERVISOR,
  INSERT_CLEAR_STUDENT,
} from "../../types/Types";

const insertClearStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_all", dataParams);
    dispatch({
      type: INSERT_CLEAR_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertClearSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_emps", dataParams);
    dispatch({
      type: INSERT_CLEAR_SOPERVISOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertClearCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_companys", dataParams);
    dispatch({
      type: INSERT_CLEAR_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertClearCollage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_collages", dataParams);
    dispatch({
      type: INSERT_CLEAR_COLLAGE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertClearDepartment = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_departments", dataParams);
    dispatch({
      type: INSERT_CLEAR_DEPARTMENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertClearMajor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_majors", dataParams);
    dispatch({
      type: INSERT_CLEAR_MAJOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export {
  insertClearCollage,
  insertClearCompany,
  insertClearDepartment,
  insertClearMajor,
  insertClearSopervisor,
  insertClearStudent,
};
