import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  GET_ERROR,
  INSERT_REVIEWS_COMPANY,
  INSERT_REVIEWS_STUDENT,
} from "../../types/Types";

const insertReviewsStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`student_reviews`, dataParams);
    dispatch({
      type: INSERT_REVIEWS_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertReviewsCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`companys_reviews`, dataParams);
    dispatch({
      type: INSERT_REVIEWS_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { insertReviewsCompany, insertReviewsStudent };
