import React from "react";
import { Link } from "react-router-dom";

const ButtonLink = ({ title, path, width,fs }) => {
  return (
    <div className="d-flex justify-content-center">
      <Link
        to={path}
        style={{ width: width, fontSize: fs }}
        className="btn fw-bold text-white  text-center border-0 p-2"
        id="primary-hover"
      >
        {title}
      </Link>
    </div>
  );
};

export default ButtonLink;
