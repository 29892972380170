import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminExcelCompanyContainer from "../../../components/admin/excel/AdminExcelCompanyContainer";

const AdminExcelCompanyPage = () => {
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs
          // mainPath={"/admin/status"}
          // main={"مدير النظام"}
          element={["إضافة البيانات", "الجهات"]}
        />
        <div className="container p-3 p-md-5">
          <AdminExcelCompanyContainer />
        </div>
      </div>
    </div>
  );
};

export default AdminExcelCompanyPage;
