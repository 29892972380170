/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubmissitionByApproved } from "../../../redux/actions/submissition/ActionSubmissitions";

const SopervisorSidebarLogic = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getSubmissitionByApproved("4"));
  }, []);

  const myDataSubmissitionFirstDrop = useSelector(
    (state) => state.rootSubmissitions.submissitionApproved
  );

  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);

  return [myDataSubmissitionFirstDrop];
};

export default SopervisorSidebarLogic;
