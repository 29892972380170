/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Sidebar, Menu } from "react-pro-sidebar";
import { role } from "../../../redux/types/Types";
import {
  PieChartOutlined,
  PieChartFilled,
  QuestionCircleOutlined,
  QuestionCircleFilled,
  DotChartOutlined,
  CaretLeftOutlined,
  LeftCircleFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import DateAndDay from "../../duplicateComponents/DateAndDay";
import SideBarItem from "../../sideBar/SideBarItem";
import SideBarItemWithSubItem from "../../sideBar/SideBarItemWithSubItem";
import SideBarSubItem from "../../sideBar/SideBarSubItem";
import SidebarLogo from "../../sideBar/SidebarLogo";
import SopervisorSidebarLogic from "../../../logic/sopervisor/layout/SopervisorSidebarLogic";
const SopervisorSidebar = ({ isPositionFixed = true }) => {
  const [myDataSubmissitionFirstDrop] = SopervisorSidebarLogic();

  return (
    <Sidebar
      rtl={true}
      className="border-0"
      rootStyles={{
        position: isPositionFixed ? "fixed" : "relative",
        height: "100%",
        overflowY: "auto !important",
        Zndex: 3,
        top: 0,
        userSelect: "none !important",
      }}
    >
      <div className=" h-100 d-flex justify-content-between flex-column ">
        <Menu>
          <SidebarLogo />
          <SideBarItem
            title={`حالة ${role === "emp" ? "المشرف" : "الجهة"}`}
            icon={<PieChartOutlined />}
            iconActive={<PieChartFilled />}
            path={"/sopervisor/status"}
          />
          <SideBarItem
            title={"متابعة المتدربين"}
            icon={<DotChartOutlined />}
            iconActive={<DotChartOutlined />}
            path={"/sopervisor/users"}
          />
          <SideBarItemWithSubItem
            title={"إضافات للمتدربين"}
            icon={<PlusCircleOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"إضافة مهام"}
                  path={"/sopervisor/addition-tasks"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"إضافة مهارات"}
                  path={"/sopervisor/addition-skills"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />{" "}
                <SideBarSubItem
                  title={"إضافة الدرجات"}
                  path={"/sopervisor/addition-grades"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />
          <SideBarItemWithSubItem
            title={"النماذج"}
            icon={<FileTextOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"توجية المتدرب"}
                  path={"/sopervisor/forms-Guidance"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"طلب فرص تدريب"}
                  path={"/sopervisor/forms-request-trining"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />

          <SideBarItem
            title={"طلبات الغاء المتدربين"}
            icon={<QuestionCircleOutlined />}
            iconActive={<QuestionCircleFilled />}
            path={"/sopervisor/order-drop"}
            isHideNotification={true}
            valueNotification={
              myDataSubmissitionFirstDrop
                ? myDataSubmissitionFirstDrop.total
                : 0
            }
          />
        </Menu>
        <div className="mt-5">
          <div className="my-3">
            <DateAndDay />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SopervisorSidebar;
