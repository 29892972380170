import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminExcelCollageContainer from "../../../components/admin/excel/AdminExcelCollageContainer";

const AdminExcelCollagePage = () => {
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs
          // mainPath={"/admin/status"}
          // main={"مدير النظام"}
          element={["إضافة البيانات", "الكليات"]}
        />
        <div className="container p-3 p-md-5">
          <AdminExcelCollageContainer />
        </div>{" "}
      </div>
    </div>
  );
};

export default AdminExcelCollagePage;
