import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminPollReviewContainer from "../../../components/admin/pollReview/AdminPollReviewContainer";

const AdminPollReviewPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["تقييم التدريب التعاوني"]} />
        <div className="container p-3 p-md-5">
          <AdminPollReviewContainer />
        </div>
      </div>
    </div>
  );
};

export default AdminPollReviewPage;
