import React from "react";
import CompanyPollContainer from "../../../components/company/poll/CompanyPollContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";

const CompanyPollPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["استطلاع رأي"]} />
        <div className="container p-3 p-md-5">
          <CompanyPollContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyPollPage;
