import { Spin, Table } from "antd";
import React from "react";
import AdminSopervisorFollowUpLogic from "../../../../logic/admin/followUp/AdminSopervisorFollowUpLogic";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import ButtonWithIcon from "../../../duplicateComponents/ButtonWithIcon";
import FormInput from "../../../duplicateComponents/FormInput";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
import { getAllSopervisorPage } from "../../../../redux/actions/sopervisor/ActionSopervisor";
const { Column } = Table;

const AdminSopervisorIndexContainer = () => {
  const [
    myDataSopervisor,
    loadingSopervisor,
    myDataSopervisorTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteSopervisor,
    dataTableColumns,
    myDispatch,
  ] = AdminSopervisorFollowUpLogic();

  return (
    <Spin
      // spinning={false}
      spinning={isLoadingDeleteSopervisor}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع المشرفين"}
                subTitle={"عدد المشرفين"}
                num={myDataSopervisor && myDataSopervisorTotal}
              />
            </span>
            <span className="mt-2 mt-sm-0">
              <ButtonWithIcon path={"/admin/users-sopervisor/create"} />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingSopervisor ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total: myDataSopervisor && myDataSopervisor.total,
                  // pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getAllSopervisorPage(page));
                  },
                }}
                size="middle"
                dataSource={myDataSopervisor && myDataSopervisor.data}
                rowKey={"username_emp"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminSopervisorIndexContainer;
