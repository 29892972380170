import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import StudentPollContainer from "../../../components/student/poll/StudentPollContainer";

const StudentPollPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["استطلاع رأي"]} />
        <div className="container p-3 p-md-5">
          <StudentPollContainer />
        </div>
      </div>
    </div>
  );
};

export default StudentPollPage;
