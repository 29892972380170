/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubmissitionByApproved } from "../../../redux/actions/submissition/ActionSubmissitions";

const AdminSidebarLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getSubmissitionByApproved("2"));
  }, []);
  // api submisstion
  const myDataSubmissitionApp = useSelector(
    (state) => state.rootSubmissitions.submissitionApproved
  );
  const loadingSubmissitionApp = useSelector(
    (state) => state.rootSubmissitions.loadingSubmissitionApproved
  );

  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
    } else {
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);
  return [loadingSubmissitionApp, myDataSubmissitionApp];
};

export default AdminSidebarLogic;
