import React from "react";
import Breadcrumbs from "../../components/duplicateComponents/Breadcrumbs";
import FormsDirectTriningContainer from "../../components/forms/FormsDirectTriningContainer";
import { useSelector } from "react-redux";
import CheckRouteWhereYotGo from "../../components/duplicateComponents/CheckRouteWhereYotGo";
// import HeaderSopervisor from "../../components/sopervisor/HeaderSopervisor";
// import SidebarSopervisorPage from "../sideBar/SidebarSopervisorPage";

const FormsDirectTriningPage = () => {
  const formsAddress = useSelector((state) => state.rootForms.dataForms);

  // Not History Back()
  CheckRouteWhereYotGo();

  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs
          main={formsAddress.mainSopervisor}
          element={["النماذج", formsAddress.DirectTrining.title]}
        />
        <FormsDirectTriningContainer
          propsTitle={formsAddress.DirectTrining.title}
          propsSubTitle={formsAddress.DirectTrining.subTitle}
        />
      </div>
    </div>
  );
};

export default FormsDirectTriningPage;
