import { GET_ERROR, ISNERT_30_GRADES } from "../../types/Types";

const inital = {
  insert30grades: [],
  loading: true,
};
const reducerInsert30Grades = (state = inital, action) => {
  switch (action.type) {
    case ISNERT_30_GRADES:
      return {
        insert30grades: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        insert30grades: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerInsert30Grades;
