import {
  ADD_SUBMISSITIONS_APPRO_LAST,
  GET_ERROR,
  GET_SUBMISSITIONS_ALL,
  GET_SUBMISSITIONS_ALL_PAGE,
} from "../../types/Types";

const inital = {
  submissitionLastApp: [],
  addSubmissitionLastApp: [],
  loading: true,
};
const reducerSubmissionsLastApp = (state = inital, action) => {
  switch (action.type) {
    case ADD_SUBMISSITIONS_APPRO_LAST:
      return {
        addSubmissitionLastApp: action.payload,
        loading: false,
      };
    case GET_SUBMISSITIONS_ALL:
      return {
        ...state,
        submissitionLastApp: action.payload,
        loading: false,
      };
    case GET_SUBMISSITIONS_ALL_PAGE:
      return {
        ...state,
        submissitionLastApp: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        submissitionLastApp: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerSubmissionsLastApp;
