/* eslint-disable no-unused-vars */
import { read, utils, writeFile } from "xlsx";
import { useEffect } from "react";
import { useState } from "react";
const AdminExcelCoopLogic = () => {
    const [dataExcel, setDataExcel] = useState([]);
    const [fileName, setFileName] = useState(null);
    const [sendFileName, setSendFileName] = useState(null);
    const [isLoadExcel, setIsLoadExcel] = useState(true);
  
    const handleImport = async ($event) => {
      const files = $event.target.files;
      let file = "";
  
      if (files.length) {
        file = files[0];
  
        const reader = new FileReader();
        reader.onload = (event) => {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
  
          if (sheets.length) {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            // if (myDataStudent) {
            //   // setDataExcel(myDataStudent);
            // }
            // setDataExcel(rows);
            // NotificationsToast("done", null, "لحفظ البيانات اضغط على حفظ");
          }
        };
        reader.readAsArrayBuffer(file);
      }
      // if (files && $event.target.files[0]) {
      //   setFileName(files[0].name);
      // }
  
      // if (myDataStudent.length > 0) {
      //   NotificationsToast("warn", null, "المتدربين مسجلين سابقاً");
      //   return;
      // }
  
      // console.log(file);
  
      // const formDate = new FormData();
      //
      // formDate.append("file", file);
  
      // setIsLoadExcel(true);
      // await myDispatch(insertExcel(formDate));
      // setIsLoadExcel(false);
  
      // console.log(file);
      // setSendFileName(file);
    };
  
    //   useEffect(() => {
    //     if (isLoadExcel === false) {
    //       if (responseExcel) {
    //         console.log(responseExcel);
    //       }
    //       if (responseExcel.status === 200) {
    //         if (responseExcel.data === "success") {
    //           myDispatch(getAllStudent());
    //           NotificationsToast("done", null, "تم إضافة بيانات المتدربين");
    //         }
    //       } else {
    //         if (responseExcel.status !== 200) {
    //           myDispatch(getAllStudent());
    //           NotificationsToast("error", null, "هناك مشكلة في إضافة المتدربين");
    //         }
    //       }
    //       setIsLoadExcel(true);
    //     }
    //   }, [isLoadExcel]);
  
    const handleExport = () => {
      const headings = [
        [
          "student_id",
          "name",
          "phone",
          "password",
          "email",
          "major",
          "gpa",
          "Department",
          "address",
          "collage_name",
          "national_id",
        ],
      ];
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      // utils.sheet_add_json(ws, myDataStudent && myDataStudent, {
      //   origin: "A2",
      //   skipHeader: true,
      // });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, "بيانات المتدربين.xlsx");
    };
  
    // console.log(dataExcel);
    // if (myDataStudent) {
    //   console.log(myDataStudent);
    // }
  
    const handleExportStyleEmpty = () => {
      const headings = [
        [
          "student_id",
          "name",
          "phone",
          "password",
          "email",
          "major",
          "gpa",
          "Department",
          "address",
          "collage_name",
          "national_id",
        ],
      ];
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, dataExcel, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, "تنسيق الأعمدة.xlsx");
    };
    return [handleExport, handleExportStyleEmpty, handleImport, fileName];
}

export default AdminExcelCoopLogic