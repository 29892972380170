/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import FormsContainer from "./FormsContainer";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormInput from "../duplicateComponents/FormInput";
import Button from "../duplicateComponents/Button";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import { useDispatch, useSelector } from "react-redux";
import { getStudentByUsername } from "../../redux/actions/student/ActionStudent";
import { tokenStudentUsername } from "../../redux/types/Types";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import { insertFormsPdfRequestException } from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";

const FormsRequestExceptionContainer = ({ propsTitle, propsSubTitle }) => {
  const myDispatch = useDispatch();
  // api get all studnet
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loadingStudent = useSelector((state) => state.rootStudent.loading);
  useEffect(() => {
    myDispatch(getStudentByUsername(tokenStudentUsername));
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // api forms
  const responseFormsPdf = useSelector((state) => state.rootFormsPdf.formsPdf);

  const [dataInputs, setDataInputs] = useState({});
  // show msg is required if onclick the btn
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  useEffect(() => {
    if (myDataStudent) {
      setDataInputs({
        student_name: `${loadingStudent ? "" : myDataStudent.name}`,
        student_id: `${loadingStudent ? "" : myDataStudent.student_id}`,
        department: `${loadingStudent ? "" : myDataStudent.Department}`,
        kit: "",
        hours: "",
        text1: "",
        term: "",
        gpa: `${loadingStudent ? "" : myDataStudent.GPA}`,
        review: "",
        review2: "",
      });
    }
  }, [myDataStudent]);

  const handelDataInputs = (e) => {
    // let { val, name } = e.target;

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const submitPDF = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfRequestException(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseFormsPdf) {
        // console.log(responseFormsPdf);

        if (responseFormsPdf.status === 200) {
          // download(
          //   responseFormsPdf.data.replace("demo.", ""),
          //   `${propsTitle}.pdf`
          // );
          const link = document.createElement("a");
          link.target = "_blanck";
          link.href = responseFormsPdf.data.replace("demo.", "");
          link.download = `${propsTitle}.pdf`;
          link.click();

          NotificationsToast("done", null, "تم تنزيل النموذج بنجاح");
          setIsLoading(false);
          return;
        } else {
          if (responseFormsPdf.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في تنزيل النموذج");
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "student_name",
      value: dataInputs.student_name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      msgError: isShowMsgError
        ? dataInputs.student_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "student_id",
      value: dataInputs.student_id || "",
      label: "الرقم التدريبي",
      placeholder: "الرقم التدريبي",
      msgError: isShowMsgError
        ? dataInputs.student_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "kit",
      value: dataInputs.kit || "",
      label: "المادة المتبيقة",
      placeholder: "المادة المتبيقة",
      msgError: isShowMsgError
        ? dataInputs.kit === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "hours",
      value: dataInputs.hours || "",
      label: "عدد ساعاتها",
      placeholder: "عدد ساعاتها",
      msgError: isShowMsgError
        ? dataInputs.hours === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "text1",
      value: dataInputs.text1 || "",
      label: "دواعي الاستثناء",
      placeholder: "دواعي الاستثناء",
      msgError: isShowMsgError
        ? dataInputs.text1 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "يعبأ من القبول والتسجيل",
    },
    {
      name: "term",
      value: dataInputs.term || "",
      label: "الفصل الحالي للمتدربة",
      placeholder: "الفصل الحالي للمتدربة",
      msgError: isShowMsgError
        ? dataInputs.term === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "gpa",
      value: dataInputs.gpa || "",
      label: "المعدل التراكمي",
      placeholder: "المعدل التراكمي",
      msgError: isShowMsgError
        ? dataInputs.gpa === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "review",
      value: dataInputs.review || "",
      label: "رأي رئيس القسم",
      placeholder: "رأي رئيس القسم",
      msgError: isShowMsgError
        ? dataInputs.review === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "review2",
      value: dataInputs.review2 || "",
      label: "رأي وكيل شؤون المتدربين",
      placeholder: "رأي وكيل شؤون المتدربين",
      msgError: isShowMsgError
        ? dataInputs.review2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        titleHeader={`ماهو ${propsTitle} ؟`}
        fs={"22px"}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />

      {/* forms */}
      <div className="">
        <FormsContainer
          // reef={componentRef}
          titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
          item={
            <form className="row gx-5 gy-3">
              {arrDataInputs.map((item, i) =>
                i === 6 ? (
                  <FormInputTitleHead key={i} title={item.title} fs={"21px"} />
                ) : i === 5 || i === 9 || i === 10 ? (
                  <div className="col-12" key={i}>
                    <FormInputTextArea
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                    />
                  </div>
                ) : (
                  <div className="col-lg-6" key={i}>
                    <FormInput
                      type={"text"}
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                    />
                  </div>
                )
              )}
            </form>
          }
        />
        <div className="col-12">
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
            ) : (
              <Button
                title={"أرسال"}
                width={"250px"}
                dataBsToggle="modal"
                dataBsTarget="#exampleModalForms-request-exception"
                type="submit"
              />
            )}
            <PopupAreYouSure
              id={"exampleModalForms-request-exception"}
              // onCLick={countTimeLineNow}
              onCLick={submitPDF}
              idPopupStar={"exampleModalForms-request-exception"}
              textAlign={"center"}
              title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsRequestExceptionContainer;
