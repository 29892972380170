import React from "react";
import { Link } from "react-router-dom";
import Button from "../duplicateComponents/Button";
import ButtonLoading from "../duplicateComponents/ButtonLoading";
import FormInput from "../duplicateComponents/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ResetPasswordLogic from "../../logic/auth/ResetPasswordLogic";

const ResetPasswordContainer = () => {
  const [
    username,
    password,
    handelUsername,
    handelPassword,
    submitResetPassword,
    loading,
    isShowMsgErrorUsrename,
    isShowMsgErrorPassword,
    isShowMsgErrorPasswordLength,
  ] = ResetPasswordLogic();
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{
        minHeight: "100vh",
        backgroundImage: "url('../../images/bg-3.jpg')",
      }}
    >
      <Link to={"/"}>
        <img
          src="../../images/logo.png"
          style={{ width: "60px", height: "70px" }}
          alt="شعار مارس"
        />
      </Link>
      <div className="login-width my-5">
        <form
          // onSubmit={submitResetPassword}
          className=" bg-white p-2 p-sm-4 style-shadow-large mx-3 position-relative"
          style={{
            borderRadius: "var(--radius)",
          }}
        >
          <div className="position-relative py-4 ">
            <h2 className="position-absolute fw-bold w-100 text-center">
              تغيير كلمة المرور
            </h2>
            <img
              src="../../images/bg-login-blob.png"
              className="position-absolute"
              style={{
                left: "50%",
                top: "100%",
                transform: "translate(-50%,-10%)",
                width: "190px",
                height: "250px",
              }}
              alt="blob"
            />
          </div>
          <div className="my-3">
            <FormInput
              type={"text"}
              onChange={handelUsername}
              value={username}
              label={"أسم المستخدم"}
              placeholder={"أسم المستخدم"}
              msgError={isShowMsgErrorUsrename ? "الحقل مطلوب" : ""}
              isShowIcon={true}
              icon={
                <FontAwesomeIcon icon={faUser} color="var(--primary-color)" />
              }
            />
          </div>
          <div className="my-3">
            <FormInput
              type={"password"}
              onChange={handelPassword}
              value={password}
              name={"password"}
              label={"كلمة المرور الجديدة"}
              placeholder={"كلمة المرور الجديدة"}
              msgError={
                isShowMsgErrorPassword
                  ? "الحقل مطلوب"
                  : isShowMsgErrorPasswordLength
                  ? "يجب ان يكون الطول اكبر او يساوي 8"
                  : ""
              }
              isShowIcon={true}
            />
          </div>

          <div className="mt-4 py-2">
            {loading ? (
              <ButtonLoading width={"100%"} title={"جاري ارسال"} />
            ) : (
              <Button
                onclick={submitResetPassword}
                type={"button"}
                title={"ارسال"}
              />
            )}
          </div>
          <Link
            to={"/login"}
            className="mt-2"
            style={{ fontSize: 13, color: "var(--primary-color)" }}
          >
            الرجوع لصفحة تسجيل دخول
          </Link>
          {/* <div
          className="text-center text-danger w-100 fw-bold position-absolute bg-dange "
          style={{ fontSize: "15px", left: "0", bottom: "20px" }}
        >
          {msgAllError}
        </div> */}
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
