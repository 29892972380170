import React from "react";
import Breadcrumbs from "../../components/duplicateComponents/Breadcrumbs";
import { useSelector } from "react-redux";
import CheckRouteWhereYotGo from "../../components/duplicateComponents/CheckRouteWhereYotGo";
import FormsCerfContainer from "../../components/forms/FormsCerfContainer";

const FormsCerfPage = () => {
  const formsAddress = useSelector((state) => state.rootForms.dataForms);

  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs
          main={formsAddress.mainSopervisor}
          element={["النماذج", formsAddress.cerf.title]}
        />
        <FormsCerfContainer
          propsTitle={formsAddress.cerf.title}
          propsSubTitle={formsAddress.cerf.subTitle}
        />
      </div>
    </div>
  );
};

export default FormsCerfPage;
