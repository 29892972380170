import {
  EDIT_SOPERVISOR,
  GET_ALL_SOPERVISOR,
  GET_ALL_SOPERVISOR_PAGE,
  GET_ERROR,
  GET_SOPERVISOR_BY_USERNAME,
  GET_STUDENT_INSIDE_SOPERVISOR,
  GET_STUDENT_INSIDE_SOPERVISOR_PAGE,
  GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION,
  INSERT_SOPERVISOR,
} from "../../types/Types";

const inital = {
  sopervisor: [],
  editSopervisor: [],
  sopervisorInside: [],
  sopervisorInsideWithoutPagination: [],
  loading: true,
  loadingEditSopervisor: true,
  loadingInside: true,
  loadingInsideWithoutPagination: true,
};

const reducerSopervisor = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SOPERVISOR:
      return {
        ...state,
        sopervisor: action.payload,
        loading: false,
      };
    case GET_ALL_SOPERVISOR_PAGE:
      return {
        ...state,
        sopervisor: action.payload,
        loading: false,
      };
    case GET_SOPERVISOR_BY_USERNAME:
      return {
        ...state,
        sopervisor: action.payload,
        loading: false,
      };
    case INSERT_SOPERVISOR:
      return {
        ...state,
        sopervisor: action.payload,
        loading: false,
      };
    case GET_STUDENT_INSIDE_SOPERVISOR:
      return {
        ...state,
        sopervisorInside: action.payload,
        loadingInside: false,
      };

    case GET_STUDENT_INSIDE_SOPERVISOR_PAGE:
      return {
        ...state,
        sopervisorInside: action.payload,
        loadingInside: false,
      };
    case GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION:
      return {
        ...state,
        sopervisorInsideWithoutPagination: action.payload,
        loadingInsideWithoutPagination: false,
      };
    case EDIT_SOPERVISOR:
      return {
        ...state,
        editSopervisor: action.payload,
        loadingEditSopervisor: false,
      };
    case GET_ERROR:
      return {
        sopervisor: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerSopervisor;
