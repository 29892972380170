/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import HeaderStudent from "../../../components/student/HeaderStudent";
import TimeLine from "../../../components/duplicateComponents/TimeLine";
// import SidebarPage from "../../sideBar/SidebarPage";
import JoinRequestDetails from "../../../components/student/joinRequest/JoinRequestDetails";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const StudentJoinRequestDetailsPage = () => {
  const countTimeLine = useSelector((state) => state.rootForms.countTimeLine);
  const idRoute = useParams();
  const [idParams, setIdParams] = useState(0);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
  }, []);

  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* <HeaderStudent /> */}
        {/* BreadCumb Router */}
        <Breadcrumbs
          // main={"المتدرب"}
          // mainPath={"/student/status"}
          element={["طلب انضمام للتدريب"]}
        />

        <div className="container p-3 p-md-5 ">
          {/* TimeLine */}
          <TimeLine
            count={countTimeLine + 1}
            activeBool={[true, true]}
            idRoute={idParams}
          />
          <JoinRequestDetails propsIdRoute={idParams} />
        </div>
      </div>
      {/* here side bar */}
      {/* <div className="d-none d-md-block">
        <SidebarPage />
      </div> */}
    </div>
  );
};

export default StudentJoinRequestDetailsPage;
