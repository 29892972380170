import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminClearUsersContainer from "../../../components/admin/clear/AdminClearUsersContainer";

const AdminClearUsersPage = () => {
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs element={["حذف المستخدمين"]} />
        <div className="container p-3 p-md-5">
          <AdminClearUsersContainer />
        </div>
      </div>
    </div>
  );
};

export default AdminClearUsersPage;
