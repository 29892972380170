/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { role } from "../../../redux/types/Types";
import { Link, useNavigate } from "react-router-dom";
import { ProfileOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../auth/Auth";
const SopervisorHeaderLogic = () => {
  // const accountItem = [
  //   {
  //     title: "المعلومات الشخصية",
  //     path: "/sopervisor/profile",
  //     icon: null,
  //     color: "",
  //   },
  //   {
  //     title: "تسجيل خروج",
  //     // path: "/",
  //     onclick: () => {
  //       if (
  //         localStorage.getItem("token") &&
  //         localStorage.getItem("storageDataObj") &&
  //         localStorage.getItem("role")
  //       ) {
  //         localStorage.clear();
  //         window.location.href = "/";
  //         // console.log("logout");
  //       }
  //     },
  //     icon: "../../../images/logout.png",
  //     color: "text-danger",
  //   },
  // ];
  // const dataNotification = [
  //   {
  //     title: "المشرف",
  //     subTitle: "يجب انهاء المهام قبل نهاية التدريب",
  //     time: "منذ 3 ايام",
  //     path: "/student/follow-up-tasks",
  //   },
  //   {
  //     title: "مدير النظام",
  //     subTitle: "يجب انهاء المهام قبل نهاية التدريب",
  //     time: "منذ 5 ايام",
  //     path: "/student/follow-up-tasks",
  //   },
  //   {
  //     title: "مدير النظام",
  //     subTitle: "يجب انهاء المهام قبل نهاية التدريب",
  //     time: "منذ 9 ايام",
  //     path: "/student/follow-up-tasks",
  //   },

  //   {
  //     title: "مدير النظام",
  //     subTitle: "يجب انهاء المهام قبل نهاية التدريب",
  //     time: "منذ 5 ايام",
  //     path: "/student/follow-up-tasks",
  //   },
  // ];
  // active the burrger menu to (x)
  // const activesMenu = () => {
  //   let bars = document.querySelector(".bars");
  //   bars.classList.toggle("active");
  // };

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log(window.innerWidth);
    // console.log(collapseWidth);
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
      // setCollapseWidth(window.innerWidth)
      // setCollapse(true);
    } else {
      // setCollapse(false);
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);

  // const nav = useNavigate();
  // // const auth = useAuth();

  // const handelLogout = () => {
  //   auth.logout();
  //   nav("/", { replace: true });
  // };

  const nav = useNavigate();
  const auth = useAuth();

  const handelLogout = () => {
    auth.logout();
    nav("/", { replace: true });
  };
  const items = [
    {
      key: "1",
      label: (
        <Link style={{ textDecoration: "none" }} to={"/sopervisor/profile"}>
          المعلومات الشخصية
        </Link>
      ),
      icon: <ProfileOutlined className="mt-1" />,
    },

    {
      key: "2",
      danger: true,
      // label: (
      //   <div
      //     onClick={() => {
      //       if (
      //         localStorage.getItem("token") &&
      //         localStorage.getItem("storageDataObj") &&
      //         localStorage.getItem("role")
      //       ) {
      //         localStorage.clear();
      //         window.location.href = "/";
      //         // console.log("logout");
      //       }
      //     }}
      //   >
      //     تسجيل خروج
      //   </div>
      // ),
      label: <div onClick={handelLogout}>تسجيل خروج</div>,
      icon: <LogoutOutlined className="mt-1" />,
    },
  ];

  const [sopervisorName, setSopervisorName] = useState("");
  useEffect(() => {
    if (localStorage.getItem("storageDataObj") && role) {
      setSopervisorName(
        JSON.parse(localStorage.getItem("storageDataObj")).name
      );
    }
  }, []);

  return [sopervisorName, open, setOpen, items, onClose];
};

export default SopervisorHeaderLogic;
