/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TimeLineBoxCardDarkGreen from "../../duplicateComponents/TimeLineBoxCardDarkGreen";
import TimeLineWithTitleAndSubTitle from "../../duplicateComponents/TimeLineWithTitleAndSubTitle";
import FormsContainer from "../../forms/FormsContainer";
import Button from "../../duplicateComponents/Button";
import FormInput from "../../duplicateComponents/FormInput";
import FormInputTitleHead from "../../duplicateComponents/FormInputTitleHead";
import LoadingTimeLineLightGreen from "../../duplicateComponents/LoadingTimeLineLightGreen";
import StudentProfileContainerLogic from "../../../logic/student/profile/StudentProfileContainerLogic";
import ButtonLoading from "../../duplicateComponents/ButtonLoading";
import { UploadOutlined } from "@ant-design/icons";

const StudentProfileContainer = () => {
  const [
    myDataStudent,
    loadingStudent,
    columnAr,
    columnEng,
    password,
    handelPassword,
    submitResetPassword,
    handelNewPassword,
    newPassword,
    loading,
    isShowMsgErrorPassword,
    isShowMsgErrorNewPassword,
    isShowMsgErrorPasswordLength,
    isShowMsgErrorNewPasswordLength,
  ] = StudentProfileContainerLogic();
  const [fileUpload, setFileUpload] = useState("");

  const handelFileUpload = (e) => {
    const file = e.target.files[0];
    setFileUpload(file);
    if (file) {
      const allowedExtensions = ["image/jpeg", "image/png"];

      if (allowedExtensions.includes(file.type)) {
        setFileUpload(file);
      } else {
        alert("Please select a PNG or JPG file.");
        e.target.value = ""; // Clear the input field
        setFileUpload(null);
      }
    }
  };

  return (
    <div className="row">
      {/* Display info student */}
      <div className="col-12 col-lg-6">
        <TimeLineBoxCardDarkGreen
          titleHeader={"معلوماتك الشخصية"}
          item={
            <div>
              {loadingStudent ? (
                <LoadingTimeLineLightGreen countPlaceholder={4} />
              ) : myDataStudent && Object.values(myDataStudent).length > 0 ? (
                myDataStudent &&
                columnAr.map((item, i) => (
                  <TimeLineWithTitleAndSubTitle
                    key={i}
                    title={item}
                    subTitle={
                      myDataStudent[columnEng[i]] === null
                        ? "-"
                        : myDataStudent[columnEng[i]]
                    }
                    whatColor={"lightGreen"}
                  />
                ))
              ) : (
                <TimeLineWithTitleAndSubTitle
                  title={"لاتوجد بيانات"}
                  whatColor={"lightGreen"}
                />
              )}
            </div>
          }
        />
      </div>

      <div className="col-12 col-lg-6">
        <div className="col-12">
          <FormsContainer
            titleHead={
              <FormInputTitleHead title={"تغيير كلمة المرور"} fs={"21px"} />
            }
            item={
              <div className="row gx-5 gy-3 ">
                <div className="col-12">
                  <FormInput
                    type={"password"}
                    onChange={handelPassword}
                    value={password}
                    label={"كلمة المرور القديمة"}
                    placeholder={"كلمة المرور القديمة"}
                    msgError={
                      isShowMsgErrorPassword
                        ? "الحقل مطلوب"
                        : isShowMsgErrorPasswordLength
                        ? "يجب ان يكون الطول اكبر او يساوي 8"
                        : ""
                    }
                  />
                </div>
                <div className="col-12">
                  <FormInput
                    type={"password"}
                    label={"كلمة المرور الجديدة"}
                    placeholder={"كلمة المرور الجديدة"}
                    onChange={handelNewPassword}
                    value={newPassword}
                    msgError={
                      isShowMsgErrorNewPassword
                        ? "الحقل مطلوب"
                        : isShowMsgErrorNewPasswordLength
                        ? "يجب ان يكون الطول اكبر او يساوي 8"
                        : ""
                    }
                  />
                </div>
                <div className="col-12">
                  <div className="col-12 col-sm-6 p-2 m-auto">
                    {loading ? (
                      <ButtonLoading width={"100%"} title={"جاري ارسال"} />
                    ) : (
                      <Button
                        onclick={submitResetPassword}
                        type={"button"}
                        title={"ارسال"}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-12">
          <FormsContainer
            titleHead={<FormInputTitleHead title={"إرفاق CV"} fs={"21px"} />}
            item={
              <div className="   position-relative">
                <Button
                  title={
                    <div className="custom-input-file ">
                      <input
                        type="file"
                        accept=".png, .jpg"
                        onChange={handelFileUpload}
                      />
                      <div>
                        <span>
                          <UploadOutlined />
                        </span>
                        <span className="mx-1">رفع CV</span>
                      </div>
                    </div>
                  }
                />
                <div className="mt-2 text-center">{fileUpload.name}</div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StudentProfileContainer;
