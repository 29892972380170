import React from "react";
import Breadcrumbs from "../../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import AdminSopervisorViewContainer from "../../../../components/admin/users/sopervisor/AdminSopervisorViewContainer";

const AdminSopervisorViewPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/admin/users-sopervisor"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              المشرفين
            </Link>,
            "تفاصيل المشرف",
          ]}
        />
        <div className="container p-3 p-md-5">
          <AdminSopervisorViewContainer />
        </div>{" "}
      </div>
    </div>
  );
};

export default AdminSopervisorViewPage;
