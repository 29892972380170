/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insertClearStudent } from "../../../redux/actions/clearUsers/ActionClear";
import { role } from "../../../redux/types/Types";
import {
  getAllStudent,
  getAllStudentWithoutSopervisor,
} from "../../../redux/actions/student/ActionStudent";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { deleteStudent } from "../../../redux/actions/delete/ActionDeleteStudent";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";

const AdminDeleteStudentLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudent());
  }, []);

  // api clear
  const responseDelStudent = useSelector(
    (state) => state.rootDelStudent.deleteStudent
  );
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const submitDeleteStudent = async (e, id) => {
    e.preventDefault();

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      deleteStudent({
        student_id: id,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseDelStudent) {
        // console.log(responseDelStudent);
        myDispatch(getAllStudentWithoutPagination());
        myDispatch(getAllStudent());
      }
      if (responseDelStudent.status === 200) {
        if (responseDelStudent.data === "done") {
          NotificationsToast("done", null, `تم حذف المتدرب`);
          setIsLoading(false);
          return;
        } else if (responseDelStudent.data === "nothing") {
          NotificationsToast("warn", null, `تم حذف المتدرب سابقاً`);
          setIsLoading(false);
          return;
        } else {
          if (responseDelStudent.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في حذف المتدرب`);
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  return [submitDeleteStudent, isLoading];
};

export default AdminDeleteStudentLogic;
