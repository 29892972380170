import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
// import SidebarSopervisorPage from "../../sideBar/SidebarSopervisorPage";
// import HeaderSopervisor from "../../../components/sopervisor/HeaderSopervisor";
import SopervisorAdditionTasksContainer from "../../../components/sopervisor/addition/tasks/SopervisorAdditionTasksContainer";
const SopervisorAdditionTasksPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["إضافات للمتدربين", "إضافة مهام"]} />
        <div className="container p-3 p-md-5">
          <SopervisorAdditionTasksContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorAdditionTasksPage;
