/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSopervisor } from "../../../redux/actions/sopervisor/ActionSopervisor";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
import { DeleteFilled, EditFilled, EyeFilled } from "@ant-design/icons";
import AdminDeleteSopervisorLogic from "../deleteUsers/AdminDeleteSopervisorLogic";
const AdminSopervisorFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllSopervisor());
  }, []);

  // api sopervisor
  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor
  );
  const myDataSopervisorTotal = useSelector(
    (state) => state.rootSopervisor.sopervisor.total
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );

  const [page, setPage] = useState(1);
  // const [submitDeleteStudent, isLoading] = AdminDeleteStudentLogic();

  const [searchText, setSearchText] = useState("");
  const [submitDeleteSopervisor, isLoadingDeleteSopervisor] =
    AdminDeleteSopervisorLogic();

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم المشرف",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "رقم المشرف",
      dataIndex: "username_emp",
      key: "username_emp",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "قسم المشرف",
      dataIndex: "department",
      key: "department",
      filteredValue: null,
      onFilter: null,
      render: null,
    },

    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitDeleteSopervisor(e, record.username_emp);
                  }}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  to={`/admin/users-sopervisor/edit/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EyeFilled />}
                  colorHover="#fff"
                  width={40}
                  to={`/admin/users-sopervisor/view/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];

  return [
    myDataSopervisor,
    loadingSopervisor,
    myDataSopervisorTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteSopervisor,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminSopervisorFollowUpLogic;
