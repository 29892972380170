/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentByUsername } from "../../../redux/actions/student/ActionStudent";
import { tokenStudentUsername } from "../../../redux/types/Types";
import {
  addSubmissition,
  getSubmissitionByUsername,
} from "../../../redux/actions/submissition/ActionSubmissitions";
import { useParams } from "react-router-dom";
// import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { getAllCompany } from "../../../redux/actions/company/ActionCompany";
// import { addSubmissition } from "../../../redux/actions/submissition/ActionSubmissitions";
const StudentJoinRequestPageLogic = () => {
  const idRoute = useParams();

  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllCompany());
    myDispatch(getStudentByUsername(tokenStudentUsername));
    myDispatch(getSubmissitionByUsername(tokenStudentUsername));
  }, []);

  // useEffect(() => {
  //   test(true);
  // }, []);

  // console.log(idRoute.id);
  const [load, setLoad] = useState(true);
  const [isloading, setIsLoading] = useState(false);

  // api get all compnay
  const myDataCompany = useSelector((state) => state.rootCompany.allCompany);
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );
  // api get all studnet
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  const responseSubmissitionByUsername = useSelector(
    (state) => state.rootSubmissitions.submissition
  );

  const [dataInputs, setDataInputs] = useState({});
  // show msg is required if onclick the btn
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  useEffect(() => {
    if (myDataStudent) {
      setDataInputs({
        student_name: `${
          loadingStudent
            ? ""
            : myDataStudent.name === null
            ? ""
            : myDataStudent.name
        }`,
        student_id: `${
          loadingStudent
            ? ""
            : myDataStudent.student_id === null
            ? ""
            : myDataStudent.student_id
        }`,
        natioal_id: `${
          loadingStudent
            ? ""
            : myDataStudent.natioal_id === null
            ? ""
            : myDataStudent.natioal_id
        }`,
        major: `${
          loadingStudent
            ? ""
            : myDataStudent.major === null
            ? ""
            : myDataStudent.major
        }`,
        GPA: `${
          loadingStudent
            ? ""
            : myDataStudent.GPA === null
            ? ""
            : myDataStudent.GPA
        }`,
        phone: `${
          loadingStudent
            ? ""
            : myDataStudent.phone === null
            ? ""
            : myDataStudent.phone
        }`,
      });
    }
  }, [myDataStudent]);

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };

  // console.log(myDataCompany && myDataCompany);

  const arrDataInputs = [
    {
      name: "student_name",
      value: dataInputs.student_name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      msgError: isShowMsgError
        ? dataInputs.student_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "student_id",
      value: dataInputs.student_id || "",
      label: "الرقم التدريبي",
      placeholder: "الرقم التدريبي",
      msgError: isShowMsgError
        ? dataInputs.student_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "natioal_id",
      value: dataInputs.natioal_id || "",
      label: "الهوية الشخصية للمتدرب",
      placeholder: "الهوية الشخصية",
      msgError: isShowMsgError
        ? dataInputs.natioal_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "GPA",
      value: dataInputs.GPA || "",
      label: "المعدل التراكمي",
      placeholder: "المعدل التراكمي",
      msgError: isShowMsgError
        ? dataInputs.GPA === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  const imagesCompany = [
    "../../images/logo-ministry-commerce.png",
    "../../images/logo-tvtc.png",
    "../../images/logo-aramco.png",
    "../../images/logo-learn.png",
    "../../images/logo-bank.png",
    "../../images/logo-ministry-commerce.png",
    "../../images/logo-tvtc.png",
    "../../images/logo-aramco.png",
    "../../images/logo-learn.png",
    "../../images/logo-bank.png",
  ];

  const columnCompanyEn = [
    "company_name",
    "about",
    "email",
    "phone",
    "address",
    "Working_hours",
    "Fax",
    "Zip_code",
  ];

  const columnCompanyAr = [
    "أسم الجهة",
    "نبذة عن الجهة",
    "البريد الإلكتروني",
    "رقم الهاتف",
    "العنوان",
    "ساعات العمل",
    "فاكس",
    "الرمز البريدي",
  ];

  // add submisstion
  const submitAddSubmisstion = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    setLoad(true);
    setIsLoading(true);
    await myDispatch(
      addSubmissition({
        "student-id": myDataStudent.student_id,
        name: loadingCompany
          ? null
          : myDataCompany.data && myDataCompany.data[idRoute.id].company_name,
      })
    );
    setLoad(false);
  };

  return [
    myDataCompany,
    loadingCompany,
    imagesCompany,
    columnCompanyAr,
    columnCompanyEn,
    myDataStudent,
    loadingStudent,
    responseSubmissitionByUsername,
    idRoute,
    submitAddSubmisstion,
    isloading,
    load,
    setLoad,
    setIsLoading,
    arrDataInputs,
    handelDataInputs,
    handelNumberInteger,
  ];
};

export default StudentJoinRequestPageLogic;
