import React from "react";
import Breadcrumbs from "../../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import AdminSopervisorEditContainer from "../../../../components/admin/users/sopervisor/AdminSopervisorEditContainer";

const AdminSopervisorEditPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/admin/users-sopervisor"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              متابعة المشرفين
            </Link>,
            "تعديل بيانات المشرف",
          ]}
        />
        <div className="container p-3 p-md-5">
          <AdminSopervisorEditContainer />
        </div>{" "}
      </div>
    </div>
  );
};

export default AdminSopervisorEditPage;
