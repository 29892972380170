import { UseCreateData } from "../../../hooks/UseCreateData";
import { DELETE_COMPANY, GET_ERROR } from "../../types/Types";

const deleteCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_company", dataParams);

    dispatch({
      type: DELETE_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { deleteCompany };
