import React from "react";
import FormInput from "../../../duplicateComponents/FormInput";

const SopervisorAdditionGradesDetailsTriningSopervisorItem = ({
  index,
  title,
  grade,
  dueGradeOne,
  dueGradeTwo,
  dueGradeThree,
  valueOne,
  valueTwo,
  valueThree,
  // inputCount = [],
}) => {
  return (
    <tr className="style-tr-without-border-bottom">
      {/* index */}
      <th>
        <div className="mt-2">{index}</div>
      </th>
      {/* title */}
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowra">
          {title}
        </div>
      </td>
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
          {grade}
        </div>
      </td>
      <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={dueGradeOne}
            value={valueOne}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td>
      {/* <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={dueGradeTwo}
            value={valueTwo}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td> */}
      {/* <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={dueGradeThree}
            value={valueThree}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td> */}
      {/* {inputCount.length === 0
        ? null
        : inputCount.map((_, i) => (
            <td key={i}>
              <div style={{ width: "170px" }}>
                <FormInput
                  type={"text"}
                  onChange={dueGrade}
                  value={value}
                  isLabel={null}
                  placeholder={"الدرجة المستحقة..."}
                  idAndfor={"validationCustomIdNo"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
            </td>
          ))} */}
      {/* <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td> */}
      {/* <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td>
      <td>
        <div style={{ width: "170px" }}>
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            isLabel={null}
            placeholder={"الدرجة المستحقة..."}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
      </td> */}
      {/* <td>
        <div
          style={{ width: "160px", backgroundColor: "var(--primary-color)" }}
          className="fw-bold text-white mt-2 rounded-3 fs-5 bg-dange text-center  d-inline-block text-nowrap"
        >
          21
        </div>
      </td> */}
    </tr>
  );
};

export default SopervisorAdditionGradesDetailsTriningSopervisorItem;
