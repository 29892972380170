import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  GET_ERROR,
  INSERT_EXCEL_COLLAGE,
  INSERT_EXCEL_COMPANY,
  INSERT_EXCEL_COOP,
  INSERT_EXCEL_DEPARTMENT,
  INSERT_EXCEL_MAJOR,
  INSERT_EXCEL_SOPERVISOR,
  INSERT_EXCEL_STUDENT,
} from "../../types/Types";

const insertExcelStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("insert_excel", dataParams);
    dispatch({
      type: INSERT_EXCEL_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertExcelSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("excel_emps", dataParams);
    dispatch({
      type: INSERT_EXCEL_SOPERVISOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertExcelCoop = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("excel_coop", dataParams);
    dispatch({
      type: INSERT_EXCEL_COOP,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertExcelCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("excel_company", dataParams);
    dispatch({
      type: INSERT_EXCEL_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertExcelCollage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("collage_excel", dataParams);
    dispatch({
      type: INSERT_EXCEL_COLLAGE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertExcelMajor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("major_excel", dataParams);
    dispatch({
      type: INSERT_EXCEL_MAJOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertExcelDepartment = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("department_excel", dataParams);
    dispatch({
      type: INSERT_EXCEL_DEPARTMENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export {
  insertExcelStudent,
  insertExcelCollage,
  insertExcelCompany,
  insertExcelCoop,
  insertExcelSopervisor,
  insertExcelMajor,
  insertExcelDepartment,
};
