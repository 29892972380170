import {
  ADD_SUBMISSITIONS,
  ADD_SUBMISSITIONS_APPRO_FIRST,
  ADD_SUBMISSITIONS_DEC,
  GET_ERROR,
  GET_SUBMISSITIONS_BY_APPROVED,
  GET_SUBMISSITIONS_BY_APPROVED_PAGE,
  GET_SUBMISSITIONS_BY_USERNAME,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY012,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE,
  GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE,
  GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED,
  GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE,
  INSERT_SUBMISSITIONS_FIRST_DROP,
  INSERT_SUBMISSITIONS_LAST_DROP,
} from "../../types/Types";

const inital = {
  submissition: [],
  submissitionCompany: [],
  submissitionCompany012: [],
  submissitionApproved: [],
  submissitionFirstDrop: [],
  submissitionLastDrop: [],
  submissitionByWaitAndFirstDeclinedAndFirstApproved: [],
  submissitionDec: [],
  submissitionApp: [],
  loading: true,
  loadingSubmissitionCompany: true,
  loadingSubmissitionCompany012: true,
  loadingSubmissitionApproved: true,
  loadingSubmissitionFirstDrop: true,
  loadingSubmissitionLastDrop: true,
  loadingSubmissitionByWaitAndFirstDeclinedAndFirstApproved: true,
  loadingSubmissitionDec: true,
  loadingSubmissitionApp: true,
};
const reducerSubmissions = (state = inital, action) => {
  switch (action.type) {
    case GET_SUBMISSITIONS_BY_USERNAME:
      return {
        ...state,
        submissition: action.payload,
        loading: false,
      };

    case GET_SUBMISSITIONS_BY_USERNAME_COMPANY:
      return {
        ...state,
        submissitionCompany: action.payload,
        loadingSubmissitionCompany: false,
      };
    case GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE:
      return {
        ...state,
        submissitionCompany: action.payload,
        loadingSubmissitionCompany: false,
      };
    case GET_SUBMISSITIONS_BY_USERNAME_COMPANY012:
      return {
        ...state,
        submissitionCompany012: action.payload,
        loadingSubmissitionCompany012: false,
      };
    case GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE:
      return {
        ...state,
        submissitionCompany012: action.payload,
        loadingSubmissitionCompany012: false,
      };
    case ADD_SUBMISSITIONS:
      return {
        submissition: action.payload,
        loading: false,
      };
    case GET_SUBMISSITIONS_BY_APPROVED:
      return {
        ...state,
        submissitionApproved: action.payload,
        loadingSubmissitionApproved: false,
      };
    case GET_SUBMISSITIONS_BY_APPROVED_PAGE:
      return {
        ...state,
        submissitionApproved: action.payload,
        loadingSubmissitionApproved: false,
      };
    case INSERT_SUBMISSITIONS_FIRST_DROP:
      return {
        submissitionFirstDrop: action.payload,
        loadingSubmissitionFirstDrop: false,
      };

    case INSERT_SUBMISSITIONS_LAST_DROP:
      return {
        submissitionLastDrop: action.payload,
        loadingSubmissitionLastDrop: false,
      };
    case GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED:
      return {
        submissitionByWaitAndFirstDeclinedAndFirstApproved: action.payload,
        loadingSubmissitionByWaitAndFirstDeclinedAndFirstApproved: false,
      };
    case GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE:
      return {
        submissitionByWaitAndFirstDeclinedAndFirstApproved: action.payload,
        loadingSubmissitionByWaitAndFirstDeclinedAndFirstApproved: false,
      };
    case ADD_SUBMISSITIONS_DEC:
      return {
        submissitionDec: action.payload,
        loadingSubmissitionDec: false,
      };
    case ADD_SUBMISSITIONS_APPRO_FIRST:
      return {
        submissitionApp: action.payload,
        loadingSubmissitionApp: false,
      };
    case GET_ERROR:
      return {
        submissition: action.payload,
        loadingSubmissitionDec: true,
      };
    default:
      return state;
  }
};

export default reducerSubmissions;
