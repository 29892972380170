/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Drawer, Dropdown, Layout, Space } from "antd";
import Hamburger from "hamburger-react";
import { DownOutlined } from "@ant-design/icons";
import SopervisorSidebar from "./SopervisorSidebar";
import SopervisorHeaderLogic from "../../../logic/sopervisor/layout/SopervisorHeaderLogic";
const { Header } = Layout;
const SopervisorHeader = () => {
  const [sopervisorName, open, setOpen, items, onClose] =
    SopervisorHeaderLogic();

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        background: "var(--primary-color)",
      }}
      className=" d-flex justify-content-between p-3"
    >
      {window.innerWidth > 768 ? (
        <div></div>
      ) : (
        <div className="text-white border rounded" style={{}}>
          <Hamburger toggled={open} toggle={setOpen} />
        </div>
      )}
      {/* <div className="text-white">Account</div> */}
      <Dropdown
        trigger={"click"}
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()} className="text-white ">
          <Space>
            {sopervisorName}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>

      <Drawer
        title=""
        placement="right"
        onClose={onClose}
        open={open}
        width={230}
        style={{
          background: "#fff",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
          className="d-flex justify-content-between flex-column style-sidebar-scroll-radius"
        >
          <SopervisorSidebar isPositionFixed={false} />
        </div>
      </Drawer>
    </Header>
  );
};

export default SopervisorHeader;
