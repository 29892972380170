/* eslint-disable no-self-compare */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { tokenSopervisorUsername } from "../../../redux/types/Types";
import { getStudentInsideSopervisorPage } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { getCompanyByUsername } from "../../../redux/actions/company/ActionCompany";
import { getGradesByUsername } from "../../../redux/actions/grades/ActionGrades";
import { getVisitNumberByStudentId } from "../../../redux/actions/visit/ActionVisit";
import TitleWithNumber from "../../duplicateComponents/TitleWithNumber";
import Taps from "../../duplicateComponents/Taps";
import FollowUpDetailsInfoStudentContainer from "../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoStudentContainer";
import FollowUpDetailsInfoCompanyContainer from "../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoCompanyContainer";
import FollowUpDetailsGradesContainer from "../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsGradesContainer";
import FollowUpDetailsVisitsContainer from "../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsVisitsContainer";
import FollowUpDetailsFinalReportContainer from "../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsFinalReportContainer";

const SopervisorUserViewContainer = () => {
  const idRoute = useParams();

  const myDispatch = useDispatch();
  const [idParams, setIdParams] = useState(0);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(
      getStudentInsideSopervisorPage(tokenSopervisorUsername, idRoute.page)
    );
  }, []);

  // api sopervsior Inside
  const myDataInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisorInside
  );

  const loadingInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.loadingInside
  );

  // api compnay username
  const myDataCompany = useSelector((state) => state.rootCompany.company);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);

  // api grades
  const myDataGrades = useSelector((state) => state.rootGrades.grades);

  useEffect(() => {
    myDispatch(
      getCompanyByUsername(
        loadingInsideSopervisor
          ? null
          : myDataInsideSopervisor &&
              myDataInsideSopervisor.data[idParams].username
      )
    );
    myDispatch(
      getGradesByUsername(
        loadingInsideSopervisor
          ? null
          : myDataInsideSopervisor &&
              myDataInsideSopervisor.data[idParams].student_id
      )
    );
    myDispatch(
      getVisitNumberByStudentId(
        loadingInsideSopervisor
          ? null
          : myDataInsideSopervisor &&
              myDataInsideSopervisor.data[idParams].student_id
      )
    );
  }, [myDataInsideSopervisor]);

  // api visit
  const myDataVisit = useSelector((state) => state.rootVisit.visit.data);

  return (
    <div className="">
      <div className="mb-2">
        {loadingInsideSopervisor ? (
          <ReactLoading
            type={"bubbles"}
            color={"var(--primary-color)"}
            width={40}
            height={40}
          />
        ) : (
          <TitleWithNumber
            title={
              myDataInsideSopervisor ? (
                myDataInsideSopervisor.data[idParams].name
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            subTitle={
              myDataInsideSopervisor ? (
                myDataInsideSopervisor.data[idParams].student_id
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            num={null}
          />
        )}
      </div>

      <Taps
        title={[
          "معلومات المتدرب",
          "معلومات الجهة",
          "تفاصيل الدرجات",
          "عدد الزيارات",
          "التقرير النهائي",
        ]}
        charIdAndTarget={["a", "b", "c", "d", "e"]}
        titleItem={[
          <FollowUpDetailsInfoStudentContainer
            propsLoadingStudent={loadingInsideSopervisor}
            propsStudent={
              loadingInsideSopervisor
                ? null
                : myDataInsideSopervisor &&
                  myDataInsideSopervisor.data[idParams]
            }
          />,
          <FollowUpDetailsInfoCompanyContainer
            propsLaodingCompany={loadingCompany}
            propsCompany={myDataCompany.data && myDataCompany.data}
          />,
          <FollowUpDetailsGradesContainer
            propsGrades={myDataGrades && myDataGrades}
          />,
          <FollowUpDetailsVisitsContainer
            //props student_id => 441202295
            propsVisitCount={myDataVisit && myDataVisit}
          />,
          <FollowUpDetailsFinalReportContainer />,
        ]}
      />
    </div>
  );
};

export default SopervisorUserViewContainer;
