import {
  GET_ALL_DEPARTMENT,
  GET_ALL_DEPARTMENT_PAGE,
  GET_ERROR,
  INSERT_DEPARTMENT,
} from "../../types/Types";

const inital = {
  department: [],
  loading: true,
};

const reducerDepartment = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_DEPARTMENT:
      return {
        ...state,
        department: action.payload,
        loading: false,
      };
    case GET_ALL_DEPARTMENT_PAGE:
      return {
        ...state,
        department: action.payload,
        loading: false,
      };
    case INSERT_DEPARTMENT:
      return {
        department: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        department: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerDepartment;
