import { UseCreateData } from "../../../hooks/UseCreateData";
import { GET_ERROR, INSERT_VISIT_TOTAL } from "../../types/Types";

const insertVisitTotal = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`total_visit`, dataParams);
    dispatch({
      type: INSERT_VISIT_TOTAL,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { insertVisitTotal };
