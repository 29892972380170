import baseUrl from "../api/BaseUrl";

export const UseCreateData = async (apiUrl, param) => {
  const config = {
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const response = await baseUrl.post(apiUrl, param, config);
  return response;
};
