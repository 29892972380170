/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { emailPattern } from "../../../../redux/types/Types";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllSopervisor,
  insertSopervisor,
} from "../../../../redux/actions/sopervisor/ActionSopervisor";
import { getAllStudentWithoutPagination } from "../../../../redux/actions/student/ActionStudentWithoutPagination";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
const AdminSopervisorCreateContainer = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllCollage());
    myDispatch(getAllDepartment());
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsCollage, setOptionsCollage] = useState([]);
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [selectedCollageName, setSelectedCollageName] = useState("");
  const myDataCollage = useSelector((state) => state.rootCollage.collage.data);
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );
  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const responseNewSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor
  );

  // start selcet dropdown
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({ ...dataInputs, department: selectedItem.department_name });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, department: "" });
  };
  // onSelect Collage
  const onSelectCollage = (selectedList, selectedItem) => {
    setSelectedCollageName(selectedItem.collage_name);
    setDataInputs({ ...dataInputs, collage_name: selectedItem.collage_name });
  };
  // onRemove Collage
  const onRemoveCollage = (selectedList, selectedItem) => {
    setSelectedCollageName("");
    setDataInputs({ ...dataInputs, collage_name: "" });
  };

  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };
  // fun Collage
  const funCollage = () => {
    setOptionsCollage(myDataCollage);
  };
  useEffect(() => {
    myDispatch(getAllCollage());
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funDepartment();
    funCollage();
  }, [optionsDepartment, optionsCollage]);
  // end selcet dropdown

  const [dataInputs, setDataInputs] = useState({
    username_emp: "",
    name: "",
    phone: "",
    password: "",
    email: "",
    job_type: "",
    collage_name: selectedCollageName,
    department: selectedDepartmentName,
  });

  const submitInsertNewSopervisor = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }
    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertSopervisor(formData));
    setIsLoad(false);
    // insertSopervisor({
    //   username_emp: dataInputs.username_emp,
    //   name: dataInputs.name,
    //   phone: dataInputs.phone,
    //   password: dataInputs.password,
    //   email: dataInputs.email,
    //   job_type: dataInputs.job_type,
    //   collage_name: selectedCollageName,
    //   department: selectedDepartmentName,
    // })
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewSopervisor) {
        // console.log(responseNewSopervisor);
        myDispatch(getAllSopervisor());
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseNewSopervisor.status === 200) {
        if (responseNewSopervisor.data === "done") {
          NotificationsToast("done", null, "تم إضافة المشرف بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewSopervisor.data === "already") {
          NotificationsToast("warn", null, "المشرف مسجل سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewSopervisor.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة المشرف");
          setIsLoading(false);
          return;
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المشرف",
      placeholder: "أسم المشرف",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "job_type",
      value: dataInputs.job_type || "",
      label: "الرقم الوظيفي",
      placeholder: "الرقم الوظيفي",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.job_type === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "collage",
      value: dataInputs.collage_name || "",
      label: "الكلية",
      placeholder: "الكلية",
      note: "",
      formTypes: "dropdown",
      msgError: isShowMsgError
        ? dataInputs.collage_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
    {
      title: "إضافة حساب للمشرف",
    },
    {
      name: "username_emp",
      value: dataInputs.username_emp || "",
      label: "أسم المستخدم",
      placeholder: "أسم المستخدم",
      note: "الحقل يقبل أرقام فقط",
      formTypes: "input",
      action: handelNumberIntegerUsername,
      msgError: isShowMsgError
        ? dataInputs.username_emp === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "password",
      value: dataInputs.password || "",
      label: "كلمة المرور",
      placeholder: "كلمة المرور",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.password === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];
  // console.log(myDataDepartment && myDataDepartment);
  // console.log(arrDataInputs.length - 1);
  return (
    <div>
      <FormsContainer
        titleHead={<FormInputTitleHead title={"إضافة مشرف جديد"} fs={"30px"} />}
        item={
          <>
            <form className="row gx-5 gy-3">
              {arrDataInputs.map((item, i) =>
                i === arrDataInputs.length - 3 ? (
                  <div className="col-12" key={i}>
                    <FormInputTitleHead fs={"21px"} title={item.title} />
                  </div>
                ) : (
                  <div className="col-lg-6" key={i}>
                    {item.formTypes === "dropdown" ? (
                      <>
                        <label
                          className="form-label fw-bold "
                          style={{ fontSize: "14px" }}
                        >
                          {item.label}
                        </label>
                        <div
                          className="style-scroll-radiu"
                          // here if click container MultipleSelected get all data skills
                          onClick={async () => {
                            if (item.name === "collage") {
                              await myDispatch(getAllCollage());
                              funCollage();
                            } else if (item.name === "department") {
                              await myDispatch(getAllDepartment());
                              funDepartment();
                            }
                          }}
                        >
                          <Multiselect
                            options={
                              item.name === "collage"
                                ? optionsCollage
                                : item.name === "department"
                                ? optionsDepartment
                                : []
                            } // Options to display in the dropdown
                            // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                            // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                            onSelect={
                              item.name === "collage"
                                ? onSelectCollage
                                : item.name === "department"
                                ? onSelectDepartment
                                : null
                            } // Function will trigger on select event
                            onRemove={
                              item.name === "collage"
                                ? onRemoveCollage
                                : item.name === "department"
                                ? onRemoveDepartment
                                : null
                            } // Function will trigger on remove event
                            displayValue={
                              item.name === "collage"
                                ? "collage_name"
                                : item.name === "department"
                                ? "department_name"
                                : null
                            } // Property name to display in the dropdown options
                            placeholder={`اختار ${item.label}`}
                            className="text-center rounded-3"
                            showArrow={true}
                            singleSelect={true}
                            emptyRecordMsg="لاتوجد بيانات"
                          />
                        </div>
                        <div
                          className={` position-absolut text-danger fw-bold my-2`}
                          style={{ fontSize: "12px" }}
                        >
                          {item.msgError}
                        </div>
                      </>
                    ) : (
                      <FormInput
                        type={
                          i === arrDataInputs.length - 1 ? "password" : "text"
                        }
                        onChange={item.action}
                        name={item.name}
                        value={item.value}
                        label={item.label}
                        placeholder={item.placeholder}
                        msgError={item.msgError}
                        note={item.note}
                      />
                    )}
                  </div>
                )
              )}
            </form>
          </>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة المشرف"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewSopervisor}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة المشرف`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSopervisorCreateContainer;
