import {
  ADD_SUBMISSITIONS_APPRO_FIRST,
  GET_ERROR,
  GET_SUBMISSITIONS_APPRO_FIRST,
} from "../../types/Types";

const inital = {
  submissitionApp: [],
  loading: true,
};
const reducerSubmissionsApp = (state = inital, action) => {
  switch (action.type) {
    case GET_SUBMISSITIONS_APPRO_FIRST:
      return {
        ...state,
        submissitionApp: action.payload,
        loading: false,
      };
    case ADD_SUBMISSITIONS_APPRO_FIRST:
      return {
        submissitionApp: action.payload,
        loading: false,
      };

    case GET_ERROR:
      return {
        submissitionApp: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerSubmissionsApp;
