import React from "react";
import FollowUpTaskCardContainer from "../../../components/student/followUp//tasks/FollowUpTaskCardContainer";
// import FollowUpTaskCardImplementContainer from "../../../components/student/followUp//tasks/FollowUpTaskCardImplementContainer";
// import FollowUpTaskImplementYesOrNoContainer from "../../../components/student/followUp//tasks/FollowUpTaskImplementYesOrNoContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import Taps from "../../../components/duplicateComponents/Taps";
import StudentFollowUpSkillsContainer from "../../../components/student/followUp/skills/StudentFollowUpSkillsContainer";
import CheckRouteWhereYotGo from "../../../components/duplicateComponents/CheckRouteWhereYotGo";

const StudentFollowUpTasksPage = () => {
  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs element={["متابعة تنفيذ الخطة", "المهام والمهارات"]} />
        <div className=" container mt-4 p-3 p-md-5 placeholder-glow">
          <Taps
            title={[
              "جميع المهام",
              // "تنفيذ المهام",
              // "حالة المهام",
              "جميع المهارات",
            ]}
            charIdAndTarget={[
              "a",
              // "b",
              //  "c",
              "d",
            ]}
            titleItem={[
              <FollowUpTaskCardContainer />,
              // <FollowUpTaskCardImplementContainer />,
              // <FollowUpTaskImplementYesOrNoContainer />,
              <StudentFollowUpSkillsContainer />,
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentFollowUpTasksPage;
