import React from "react";
import LoadingPlaceholderItem from "../../duplicateComponents/LoadingPlaceholderItem";
import StudentStatusPageLogic from "../../../logic/student/status/StudentStatusPageLogic";
import StatusCard from "../../../components/duplicateComponents/StatusCard";

const StudentStatusCardContainer = () => {
  const [myDataStudent, loading] = StudentStatusPageLogic();

  return (
    <div>
      {loading ? (
        <div className="placeholder-glow">
          <StatusCard
            name={
              <LoadingPlaceholderItem
                isSize={"lg"}
                col={"col-7"}
                bgColor={"white"}
              />
            }
            nameId={
              <LoadingPlaceholderItem
                isSize={"sm"}
                col={"col-3"}
                bgColor={"white"}
              />
            }
            collageName={
              <div>
                <LoadingPlaceholderItem
                  isSize={"sm"}
                  col={"col-12"}
                  bgColor={"white"}
                />
                <LoadingPlaceholderItem
                  isSize={"sm"}
                  col={"col-7"}
                  bgColor={"white"}
                />
              </div>
            }
            majorName={<span className="px-5 mx-3"></span>}
            status={null}
          />
        </div>
      ) : myDataStudent ? (
        Object.values(myDataStudent).length > 0 ? (
          <StatusCard
            name={myDataStudent.name === null ? "-" : myDataStudent.name}
            nameId={
              myDataStudent.student_id === null ? "-" : myDataStudent.student_id
            }
            collageName={
              myDataStudent.collage_name === null
                ? "-"
                : myDataStudent.collage_name
            }
            majorName={myDataStudent.major === null ? "-" : myDataStudent.major}
            status={null}
          />
        ) : null
      ) : (
        <div className="placeholder-glow">
          <StatusCard
            name={
              <LoadingPlaceholderItem
                isSize={"lg"}
                col={"col-7"}
                bgColor={"white"}
              />
            }
            nameId={
              <LoadingPlaceholderItem
                isSize={"sm"}
                col={"col-3"}
                bgColor={"white"}
              />
            }
            collageName={
              <div>
                <LoadingPlaceholderItem
                  isSize={"sm"}
                  col={"col-12"}
                  bgColor={"white"}
                />
                <LoadingPlaceholderItem
                  isSize={"sm"}
                  col={"col-7"}
                  bgColor={"white"}
                />
              </div>
            }
            majorName={<span className="px-5 mx-3"></span>}
            status={null}
          />
        </div>
      )}
    </div>
  );
};

export default StudentStatusCardContainer;
