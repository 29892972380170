/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  insertClearCollage,
  insertClearCompany,
  insertClearDepartment,
  insertClearMajor,
  insertClearSopervisor,
  insertClearStudent,
} from "../../../redux/actions/clearUsers/ActionClear";
import { role } from "../../../redux/types/Types";
import { getAllStudentWithoutSopervisor } from "../../../redux/actions/student/ActionStudent";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";

const AdminClearUsersLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    // myDispatch(getAllCollage());
  }, []);

  // api clear
  const responseClear = useSelector((state) => state.rootClear.clear);
  const [chooseUserName, setChooseUserName] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const submitClearStudent = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[0].name);
    setIsLoading(true);
    await myDispatch(
      insertClearStudent({
        type: role,
      })
    );
    setIsLoad(false);
  };

  const submitClearSopervisor = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[1].name);
    setIsLoading(true);
    await myDispatch(
      insertClearSopervisor({
        type: role,
      })
    );
    setIsLoad(false);
  };

  const submitClearCompany = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[2].name);
    setIsLoading(true);
    await myDispatch(
      insertClearCompany({
        type: role,
      })
    );
    setIsLoad(false);
  };

  const submitClearCollage = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[3].name);
    setIsLoading(true);
    await myDispatch(
      insertClearCollage({
        type: role,
      })
    );
    setIsLoad(false);
  };

  const submitClearDepartment = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[4].name);
    setIsLoading(true);
    await myDispatch(
      insertClearDepartment({
        type: role,
      })
    );
    setIsLoad(false);
  };
  const submitClearMajor = async (e) => {
    e.preventDefault();

    setIsLoad(true);
    setChooseUserName(arrMyDataTitleClears[5].name);
    setIsLoading(true);
    await myDispatch(
      insertClearMajor({
        type: role,
      })
    );
    setIsLoad(false);
  };
  useEffect(() => {
    if (isLoad === false) {
      if (responseClear) {
        // console.log(responseClear);
        myDispatch(getAllStudentWithoutSopervisor());
      }
      if (responseClear.status === 200) {
        if (responseClear.data === "done") {
          NotificationsToast("done", null, `تم حذف جميع ${chooseUserName}`);
          setIsLoading(false);
          return;
        } else if (
          responseClear.data === 0 ||
          responseClear.data === "somthing wrong"
        ) {
          NotificationsToast("warn", null, `تم حذف  ${chooseUserName} سابقاً`);
          setIsLoading(false);
          return;
        } else {
          if (responseClear.status !== 200) {
            NotificationsToast(
              "error",
              null,
              `هناك مشكلة في حذف ${chooseUserName}`
            );
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  let arrMyDataTitleClears = [
    {
      name: "المتدربين",
      actionClear: submitClearStudent,
    },
    {
      name: "المشرفين",
      actionClear: submitClearSopervisor,
    },
    {
      name: "الجهات",
      actionClear: submitClearCompany,
    },
    {
      name: "الكليات",
      actionClear: submitClearCollage,
    },
    {
      name: "الاقسام",
      actionClear: submitClearDepartment,
    },
    {
      name: "التخصصات",
      actionClear: submitClearMajor,
    },
  ];

  return [arrMyDataTitleClears, isLoading];
};

export default AdminClearUsersLogic;
