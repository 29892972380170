/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollage } from "../../../redux/actions/collage/ActionCollage";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import AdminDeleteCollageLogic from "../deleteUsers/AdminDeleteCollageLogic";
const AdminCollageFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllCollage());
  }, []);

  // api collage
  const myDataCollage = useSelector((state) => state.rootCollage.collage);
  const myDataCollageTotal = useSelector(
    (state) => state.rootCollage.collage.total
  );
  const loadingCollage = useSelector((state) => state.rootCollage.loading);

  const [page, setPage] = useState(1);
  // const [submitDeleteStudent, isLoading] = AdminDeleteStudentLogic();

  const [searchText, setSearchText] = useState("");
  const [submitDeletecollage, isLoadingDeletecollage] =
    AdminDeleteCollageLogic();

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم الجهة",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.collage_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitDeletecollage(e, record.collage_name);
                  }}
                />
              </span>
              {/* <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  // to={`/admin/projects/edit/${0}`}
                  // onclick={() => console.log("first")}
                />
              </span> */}
            </>
          </div>
        );
      },
    },
  ];
  return [
    myDataCollage,
    loadingCollage,
    myDataCollageTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeletecollage,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminCollageFollowUpLogic;
