/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../duplicateComponents/Button";
import FormInputTitleHead from "../../duplicateComponents/FormInputTitleHead";
import FormInput from "../../duplicateComponents/FormInput";
import PopupAreYouSure from "../../duplicateComponents/PopupAreYouSure";
// import PopupDone from "../../duplicateComponents/PopupDone";
// import { useParams } from "react-router-dom";
import StudentJoinRequestPageLogic from "../../../logic/student/joinRequest/StudentJoinRequestPageLogic";
// import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ButtonLoading from "../../duplicateComponents/ButtonLoading";
import PopupDone from "../../duplicateComponents/PopupDone";
import { NotificationsToast } from "../../duplicateComponents/NotificationsToast";
import { useDispatch } from "react-redux";
import { getSubmissitionByUsername } from "../../../redux/actions/submissition/ActionSubmissitions";
import { tokenStudentUsername } from "../../../redux/types/Types";
import { getAllCompany } from "../../../redux/actions/company/ActionCompany";
import { useNavigate } from "react-router-dom";

const JoinRequestFillForm = ({ test }) => {
  // const idRoute = useParams();

  const [
    myDataCompany,
    loadingCompany,
    imagesCompany,
    columnCompanyAr,
    columnCompanyEn,
    myDataStudent,
    loadingStudent,
    responseSubmissitionByUsername,
    idRoute,
    submitAddSubmisstion,
    isloading,
    load,
    setLoad,
    setIsLoading,
    arrDataInputs,
    handelDataInputs,
    handelNumberInteger,
  ] = StudentJoinRequestPageLogic();
  // test(3);
  const myDispatch = useDispatch();
  const nav = useNavigate();

  // useEffect(() => {
  //   test(true);
  // }, []);
  const [idParams, setIdParams] = useState(0);

  ///////////////////////////
  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);

    if (load === false) {
      // if (responseSubmissitionByUsername) {
      //   console.log(responseSubmissitionByUsername);
      // }
      if (responseSubmissitionByUsername.status === 200) {
        if (responseSubmissitionByUsername.data === "done") {
          test(true);
          NotificationsToast("done", null, "تم إضافة الرغبة");
          setIsLoading(false);
          nav("/student/status");
        } else if (
          responseSubmissitionByUsername.data === "already in this company"
        ) {
          NotificationsToast("warn", null, "الجهة مضافة سابقاً");
          setIsLoading(false);
        } else if (
          responseSubmissitionByUsername.data === "already in company"
        ) {
          NotificationsToast("warn", null, "أنت مسجل ف جهة");
          setIsLoading(false);
        } else {
          if (responseSubmissitionByUsername.status !== 200) {
            NotificationsToast("error", null, "  هناك مشكلة في إضافة الجهة");
            setIsLoading(false);
          }
        }
      }
      myDispatch(getAllCompany());
      myDispatch(getSubmissitionByUsername(tokenStudentUsername));

      setLoad(true);
    }
  }, [load]);

  return (
    <div>
      <div className="card  border-0 style-shadow-small px-2 py-4">
        <div className=" d-flex justify-content-center">
          {/* <img
            src={imagesCompany[idRoute.id]}
            className="p-3"
            id="img-join-request-details-width"
            alt="logo"
          /> */}
          <img
            src={"../../../images/img-join-request.png"}
            className="card-img-top"
            id="img-join-request-width"
            alt="logo"
          />
        </div>
        <div className="card-body p-2">
          <div className=" m-0 m-lg-5">
            <div className="row gx-5 gy-3">
              <FormInputTitleHead title={"معلوماتك الشخصية"} fs={"19px"} />
              {arrDataInputs.map((item, i) => (
                <div className="col-lg-6" key={i}>
                  <FormInput
                    type={"text"}
                    onChange={
                      item.name === "natioal_id"
                        ? handelNumberInteger
                        : handelDataInputs
                    }
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                  />
                </div>
              ))}

              {/* <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.name) || ""}
                  readOnly={true}
                  label={"الأسم الكامل"}
                  placeholder={"الأسم الكامل"}
                  idAndfor={"validationCustomFullName"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.student_id) || ""}
                  readOnly={true}
                  label={"رقمك التدريبي"}
                  placeholder={"رقمك التدريبي"}
                  idAndfor={"validationCustomIdNo"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.natioal_id) || ""}
                  readOnly={true}
                  label={"الهوية الشخصية للمتدرب"}
                  placeholder={"الهوية الشخصية"}
                  idAndfor={"validationCustomIdPrivate"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>

              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.phone) || ""}
                  readOnly={true}
                  label={"رقم الهاتف"}
                  placeholder={"رقم الهاتف"}
                  idAndfor={"validationCustomPhoneNumber"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.major) || ""}
                  readOnly={true}
                  label={"التخصص"}
                  placeholder={"التخصص"}
                  idAndfor={"validationCustoSpecial"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={(myDataStudent && myDataStudent.GPA) || ""}
                  readOnly={true}
                  label={"المعدل التراكمي"}
                  placeholder={"المعدل التراكمي"}
                  idAndfor={"validationCustomGPA"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div> */}
              <FormInputTitleHead title={"معلومات الجهة"} fs={"19px"} />
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  readOnly={true}
                  value={
                    (myDataCompany.data &&
                      myDataCompany.data[idParams].company_name) ||
                    ""
                  }
                  label={"أسم الجهة"}
                  placeholder={"أسم الجهة"}
                  idAndfor={"validationCustomCompanyName"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  type={"text"}
                  value={
                    (myDataCompany.data &&
                      myDataCompany.data[idParams].email) ||
                    ""
                  }
                  readOnly={true}
                  label={"البريد الإلكتروني"}
                  placeholder={"البريد الإلكتروني"}
                  idAndfor={"validationCustomCompanyEmail"}
                  isValid={"valid"}
                  msgValid={"Gooooooooood !!"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="mt-4 d-flex justify-content-center">
          {isloading ? (
            <ButtonLoading width={"200px"} title={"جاري الاضافة"} />
          ) : (
            <Button
              width={"250px"}
              title={"أرسال"}
              // onclick={submitAddSubmisstion}
              type={"button"}
              // onclick={name}
              dataBsToggle="modal"
              dataBsTarget="#exampleModalJoinRequest"
              // onclick={countTimeLineNow}
              // type="button"
            />
          )}
        </div>
      </div>
      {/* popoup Ate YOu Sure for Send data to sopervoiser */}
      <PopupAreYouSure
        id={"exampleModalJoinRequest"}
        // onCLick={countTimeLineNow}
        onCLick={submitAddSubmisstion}
        idPopupStar={"exampleModalJoinRequest"}
        textAlign={"center"}
        title={"هل أنت متأكد من أرسال البيانات للجهة؟"}
      />
    </div>
  );
};

export default JoinRequestFillForm;
