/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
import Multiselect from "multiselect-react-dropdown";
import { getAllMajor } from "../../../../redux/actions/major/ActionMajor";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import FormInput from "../../../duplicateComponents/FormInput";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import FormsContainer from "../../../forms/FormsContainer";
import {
  getAllStudent,
  getAllStudentWithoutPagination,
  getAllStudentWithoutSopervisor,
  insertStudent,
} from "../../../../redux/actions/student/ActionStudent";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import { emailPattern, role } from "../../../../redux/types/Types";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";

const AdminStudentCreateContainer = () => {
  const myDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [studentId, setstudentId] = useState("");

  const responseNewStudent = useSelector((state) => state.rootStudent.student);
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );
  const myDataMajor = useSelector((state) => state.rootMajor.major.data);

  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [selectedMajorName, setSelectedMajorName] = useState("");
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsMajor, setOptionsMajor] = useState([]);
  const [optionsCollage, setOptionsCollage] = useState([]);
  const [selectedCollageName, setSelectedCollageName] = useState("");
  const myDataCollage = useSelector((state) => state.rootCollage.collage.data);

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  // start selcet dropdown
  // onSelect Collage
  const onSelectCollage = (selectedList, selectedItem) => {
    setSelectedCollageName(selectedItem.collage_name);
    setDataInputs({ ...dataInputs, collage_name: selectedItem.collage_name });
  };
  // onRemove Collage
  const onRemoveCollage = (selectedList, selectedItem) => {
    setSelectedCollageName("");
    setDataInputs({ ...dataInputs, collage_name: "" });
  };
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({ ...dataInputs, Department: selectedItem.department_name });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, Department: "" });
  };
  // onSelect Major
  const onSelectMajor = (selectedList, selectedItem) => {
    setSelectedMajorName(selectedItem.major_name);
    setDataInputs({ ...dataInputs, major: selectedItem.major_name });
  };
  // onRemove Major
  const onRemoveMajor = (selectedList, selectedItem) => {
    setSelectedMajorName("");
    setDataInputs({ ...dataInputs, major: "" });
  };
  // fun Collage
  const funCollage = () => {
    setOptionsCollage(myDataCollage);
  };
  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };
  // fun Major
  const funMajor = () => {
    setOptionsMajor(myDataMajor);
  };
  useEffect(() => {
    myDispatch(getAllCollage());
    myDispatch(getAllMajor());
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funCollage();
    funDepartment();
    funMajor();
  }, [optionsDepartment, optionsMajor, optionsCollage]);
  // end selcet dropdown
  const [dataInputs, setDataInputs] = useState({
    name: "",
    student_id: "",
    phone: "",
    password: "",
    email: "",
    collage_name: selectedCollageName,
    major: selectedMajorName,
    Department: selectedDepartmentName,
    GPA: "",
    address: "",
    natioal_id: "",
    type: role,
  });
  const submitInsertNewStudent = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertStudent(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewStudent) {
        myDispatch(getAllStudent());
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseNewStudent.status === 200) {
        if (responseNewStudent.data === "done") {
          NotificationsToast("done", null, "تم إضافة المتدرب بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewStudent.data === "already") {
          NotificationsToast("warn", null, "المتدرب مسجل سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewStudent.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة المتدرب");
          setIsLoading(false);
          return;
        }
      }
      myDispatch(getAllStudentWithoutSopervisor());

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "الجوال",
      placeholder: "الجوال",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
    {
      name: "collage_name",
      value: dataInputs.collage_name || "",
      label: "الكلية",
      placeholder: "الكلية",
      note: "",
      formTypes: "dropdown",

      msgError: isShowMsgError
        ? dataInputs.collage_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Department",
      value: dataInputs.Department || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",

      msgError: isShowMsgError
        ? dataInputs.Department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      note: "",
      formTypes: "dropdown",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "GPA",
      value: dataInputs.GPA || "",
      label: "المعدل التراكمي",
      placeholder: "المعدل التراكمي",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.GPA === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: "العنوان",
      placeholder: "العنوان",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "natioal_id",
      value: dataInputs.natioal_id || "",
      label: "الهوية الشخصية",
      placeholder: "الهوية الشخصية",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.natioal_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "إضافة حساب للمتدرب",
    },
    {
      name: "student_id",
      value: dataInputs.student_id || "",
      label: "الرقم التدريبي / اسم المستخدم",
      placeholder: "الرقم التدريبي / اسم المستخدم",
      note: "الحقل يقبل أرقام فقط",
      formTypes: "input",
      action: handelNumberIntegerUsername,
      msgError: isShowMsgError
        ? dataInputs.student_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "password",
      value: dataInputs.password || "",
      label: "كلمة المرور",
      placeholder: "كلمة المرور",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.password === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div>
      <FormsContainer
        titleHead={
          <FormInputTitleHead title={"إضافة متدرب جديد"} fs={"30px"} />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) =>
              i === arrDataInputs.length - 3 ? (
                <div className="col-12" key={i}>
                  <FormInputTitleHead fs={"21px"} title={item.title} />
                </div>
              ) : (
                <div className="col-12 col-lg-6" key={i}>
                  {item.formTypes === "dropdown" ? (
                    <>
                      <label
                        className="form-label fw-bold "
                        style={{ fontSize: "14px" }}
                      >
                        {item.label}
                      </label>
                      <div
                        className="style-scroll-radiu"
                        // here if click container MultipleSelected get all data skills
                        onClick={async () => {
                          if (item.name === "major") {
                            await myDispatch(getAllMajor());
                            funMajor();
                          } else if (item.name === "Department") {
                            await myDispatch(getAllDepartment());
                            funDepartment();
                          } else if (item.name === "collage_name") {
                            await myDispatch(getAllCollage());
                            funCollage();
                          }
                        }}
                      >
                        <Multiselect
                          options={
                            item.name === "major"
                              ? optionsMajor
                              : item.name === "Department"
                              ? optionsDepartment
                              : item.name === "collage_name"
                              ? optionsCollage
                              : []
                          } // Options to display in the dropdown
                          // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                          // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                          onSelect={
                            item.name === "major"
                              ? onSelectMajor
                              : item.name === "Department"
                              ? onSelectDepartment
                              : item.name === "collage_name"
                              ? onSelectCollage
                              : null
                          } // Function will trigger on select event
                          onRemove={
                            item.name === "major"
                              ? onRemoveMajor
                              : item.name === "Department"
                              ? onRemoveDepartment
                              : item.name === "collage_name"
                              ? onRemoveCollage
                              : null
                          } // Function will trigger on remove event
                          displayValue={
                            item.name === "major"
                              ? "major_name"
                              : item.name === "Department"
                              ? "department_name"
                              : item.name === "collage_name"
                              ? "collage_name"
                              : null
                          } // Property name to display in the dropdown options
                          placeholder={`اختار ${item.label}`}
                          className="text-center rounded-3"
                          showArrow={true}
                          singleSelect={true}
                          emptyRecordMsg="لاتوجد بيانات"
                        />
                      </div>
                      <div
                        className={` position-absolut text-danger fw-bold my-2`}
                        style={{ fontSize: "12px" }}
                      >
                        {item.msgError}
                      </div>
                    </>
                  ) : (
                    <FormInput
                      type={
                        i === arrDataInputs.length - 1 ? "password" : "text"
                      }
                      onChange={item.action}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                      note={item.note}
                    />
                  )}
                </div>
              )
            )}
          </form>
        }
      />

      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة المتدرب"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewStudent}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة المتدرب`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminStudentCreateContainer;
