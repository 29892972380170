import React from "react";
import Taps from "../../duplicateComponents/Taps";
import AdminPollReviewStudentContainer from "./AdminPollReviewStudentContainer";
import AdminPollReviewCompanyContainer from "./AdminPollReviewCompanyContainer";

const AdminPollReviewContainer = () => {
  return (
    <Taps
      title={["تقييم المتدرب", "تقييم الجهة"]}
      charIdAndTarget={["a", "b"]}
      titleItem={[
        <AdminPollReviewStudentContainer />,
        <AdminPollReviewCompanyContainer />,
      ]}
    />
  );
};

export default AdminPollReviewContainer;
