/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { role } from "../../redux/types/Types";

const CheckRouteWhereYotGo = () => {
  const nav = useNavigate();

  //   useEffect(() => {
  //     // if (localStorage.getItem("token")) {
  //     //   return;
  //     // } else {
  //     //   // window.location.href = "/";
  //     //   // if (window.location.pathname === "/") {
  //     //   // } else if (window.location.pathname === "/login") {
  //     //   //   nav("/login");
  //     //   // } else {
  //     //   nav("/");
  //     //   // }
  //     // }
  //     if (localStorage.getItem("role")) {
  //       if (role === "student") {
  //         return nav("/student/status");
  //       } else if (role === "sopervisor") {
  //         return nav("/sopervisor/status");
  //       } else if (role === "admin") {
  //         return nav("/admin/status");
  //       }
  //     } else {
  //       return nav("/");
  //     }
  //   },[window.location.hostname]);
};

export default CheckRouteWhereYotGo;
