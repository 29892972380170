import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminStatusContainer from "../../../components/admin/status/AdminStatusContainer";

const AdminStatusPage = () => {
  return (
    <div className="position-relative ">
      {/* here all contant */}
      <div className="main">
        {/* <HeaderAdmin /> */}
        <Breadcrumbs
          // mainPath={"/admin/status"}
          // main={"uuu النظام"}
          element={["الحالة"]}
        />

        <AdminStatusContainer />
      </div>
    </div>
  );
};

export default AdminStatusPage;
