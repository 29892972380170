/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { role } from "../../../redux/types/Types";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { getAllSopervisor } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { deleteSopervisor } from "../../../redux/actions/delete/ActionDeleteSopervisor";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";

const AdminDeleteSopervisorLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllSopervisor());
  }, []);

  // api clear
  const responseDelSopervisor = useSelector(
    (state) => state.rootDelSopervisor.deleteSopervisor
  );
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const submitDeleteSopervisor = async (e, id) => {
    e.preventDefault();

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      deleteSopervisor({
        username_emp: id,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseDelSopervisor) {
        myDispatch(getAllStudentWithoutPagination());
        myDispatch(getAllSopervisor());
      }
      if (responseDelSopervisor.status === 200) {
        if (responseDelSopervisor.data === "done") {
          NotificationsToast("done", null, `تم حذف المشرف`);
          setIsLoading(false);
          return;
        } else if (responseDelSopervisor.data === "nothing") {
          NotificationsToast("warn", null, `تم حذف المشرف سابقاً`);
          setIsLoading(false);
          return;
        } else {
          if (responseDelSopervisor.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في حذف المشرف`);
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  return [submitDeleteSopervisor, isLoading];
};

export default AdminDeleteSopervisorLogic;
