import { GET_ERROR, ISNERT_GRADE_TOTAL } from "../../types/Types";

const inital = {
  gradesTotal: [],
  loading: true,
};
const reducerGradesTotal = (state = inital, action) => {
  switch (action.type) {
    case ISNERT_GRADE_TOTAL:
      return {
        gradesTotal: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        gradesTotal: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default reducerGradesTotal;
