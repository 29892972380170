import React from "react";
import LoadingPlaceholderItem from "../../../components/duplicateComponents/LoadingPlaceholderItem";
import SopervisorStatusLogic from "../../../logic/sopervisor/status/SopervisorStatusLogic";
import ReactLoading from "react-loading";
import StatusCard from "../../../components/duplicateComponents/StatusCard";

const SopervisorStatusContainer = () => {
  const [
    myDataSopervisor,
    loadingSopervisor,
    dataStatistics,
    name,
    username,
    collageName,
    department,
  ] = SopervisorStatusLogic();

  return (
    <>
      <div>
        {loadingSopervisor ? (
          <div className="placeholder-glow">
            <StatusCard
              name={
                <LoadingPlaceholderItem
                  isSize={"lg"}
                  col={"col-7"}
                  bgColor={"white"}
                />
              }
              nameId={
                <LoadingPlaceholderItem
                  isSize={"sm"}
                  col={"col-3"}
                  bgColor={"white"}
                />
              }
              collageName={
                <div>
                  <LoadingPlaceholderItem
                    isSize={"sm"}
                    col={"col-12"}
                    bgColor={"white"}
                  />
                  <LoadingPlaceholderItem
                    isSize={"sm"}
                    col={"col-7"}
                    bgColor={"white"}
                  />
                </div>
              }
              majorName={<span className="px-5 mx-3"></span>}
              status={null}
            />
          </div>
        ) : myDataSopervisor ? (
          Object.values(myDataSopervisor).length > 0 ? (
            <StatusCard
              name={name === null ? "-" : name}
              nameId={username === null ? "-" : username}
              collageName={collageName === null ? "-" : collageName}
              majorName={department === null ? "-" : department}
              status={null}
            />
          ) : (
            <StatusCard
              name={"----------"}
              nameId={"------"}
              collageName={
                <div>
                  <div>-------</div>
                  <div>----</div>
                </div>
              }
              majorName={<span className="px-5 mx-3"></span>}
              status={null}
            />
          )
        ) : (
          <StatusCard
            name={"----------"}
            nameId={"------"}
            collageName={
              <div>
                <div>-------</div>
                <div>----</div>
              </div>
            }
            majorName={<span className="px-5 mx-3"></span>}
            status={null}
          />
        )}
      </div>
      <div
        className="row  bg-white style-shadow-small  my-5 m-0 gy-3 pb-5"
        style={{ borderRadius: "var(--radius)" }}
      >
        <div
          className="text-center fw-bold fs-4"
          style={{
            transform: "translateY(30px)",
            color: "var(--primary-color)",
          }}
        >
          إحصائيات المتدربين
        </div>
        {dataStatistics.map((item, i) => (
          <div className="col-12 col-lg-4 " key={i}>
            <div
              className=" p-4 rounded-3"
              style={{
                transform: "translateY(90px)",
                backgroundColor: item.bgColor,
              }}
            >
              <div>
                <img
                  src={item.icon}
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <div className="fw-bold me-" style={{ color: item.color }}>
                  {item.title}{" "}
                </div>
                <div
                  className="fw-bold fs-5 mx-2"
                  style={{ color: item.color }}
                >
                  {isNaN(item.count) ? (
                    <ReactLoading
                      type={"bubbles"}
                      color={item.color}
                      width={40}
                      height={40}
                    />
                  ) : (
                    item.count
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SopervisorStatusContainer;
