/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSopervisorByUsername } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { getCompanyByUsername } from "../../../redux/actions/company/ActionCompany";
import { getSubmissitionByUsername } from "../../../redux/actions/submissition/ActionSubmissitions";
import {
  tokenCompanyUsername,
  tokenSopervisorUsername,
  tokenStudentUsername,
} from "../../../redux/types/Types";
import { getStudentByUsername } from "../../../redux/actions/student/ActionStudent";
const StudentStatusContainerLogic = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    // username Student
    myDispatch(getSubmissitionByUsername(tokenStudentUsername));
    // username company
    myDispatch(getCompanyByUsername(tokenCompanyUsername));
    // username sopervisor
    myDispatch(getSopervisorByUsername(tokenSopervisorUsername));
    myDispatch(getStudentByUsername(tokenStudentUsername));
  }, []);

  // Start Student
  const myDataStudent = useSelector((state) => state.rootStudent.student);

  const loading = useSelector((state) => state.rootStudent.loading);

  ///////////////////////////////////////////
  //Start Company
  const myDataCompany = useSelector((state) => state.rootCompany.company.data);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);
  //End Company
  ///////////////////////////////////////////
  //Start Company by username
  const myDataCompanyByUsername = useSelector(
    (state) => state.rootCompany.company.data
  );
  const loadingCompanyByUsername = useSelector(
    (state) => state.rootCompany.loading
  );
  //End Company by username
  ///////////////////////////////////////////
  //Start Submissition by username
  const myDataSubmissitionByUsername = useSelector(
    (state) => state.rootSubmissitions.submissition.data
  );
  const loadingSubmissitionByUsername = useSelector(
    (state) => state.rootSubmissitions.loading
  );
  //End Submissition by username
  ///////////////////////////////////////////
  //Start sopervisor by username
  const myDataSopervisorByUsername = useSelector(
    (state) => state.rootSopervisor.sopervisor.data
  );
  const loadingSopervisorByUsername = useSelector(
    (state) => state.rootSopervisor.loading
  );
  //End sopervisor by username
  ///////////////////////////////////////////

  const columnCompanyEn = [
    "company_name",
    "about",
    "email",
    "phone",
    "address",
    "Working_hours",
    "Fax",
    "Zip_code",
  ];

  const columnCompanyAr = [
    "أسم الجهة",
    "نبذة عن الجهة",
    "البريد الإلكتروني",
    "رقم الهاتف",
    "العنوان",
    "ساعات العمل",
    "فاكس",
    "الرمز البريدي",
  ];

  const columnSopervisorAr = [
    "أسم المشرف",
    "أسم الكلية",
    "قسم المشرف",
    "الوظيفة",
    "البريد الإلكتروني",
    "رقم الهاتف",
  ];
  const columnSopervisorEn = [
    "name",
    "collage_name",
    "department",
    "job_type",
    "email",
    "phone",
  ];

  return [
    myDataSopervisorByUsername,
    loadingSopervisorByUsername,
    myDataCompanyByUsername,
    loadingCompanyByUsername,
    myDataSubmissitionByUsername,
    loadingSubmissitionByUsername,
    myDataCompany,
    loadingCompany,
    columnCompanyAr,
    columnCompanyEn,
    columnSopervisorAr,
    columnSopervisorEn,
    loading,
  ];
};

export default StudentStatusContainerLogic;
