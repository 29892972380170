import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  ADD_SUBMISSITIONS_APPRO_LAST,
  GET_ERROR,
  GET_SUBMISSITIONS_ALL,
  GET_SUBMISSITIONS_ALL_PAGE,
} from "../../types/Types";

const getSubmissitionAll = () => async (dispatch) => {
  try {
    const response = await UseGetData(`get_sub_all`);
    dispatch({
      type: GET_SUBMISSITIONS_ALL,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getSubmissitionAllPage = (dataParamsPage) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_sub_all?page=${dataParamsPage}`);
    dispatch({
      type: GET_SUBMISSITIONS_ALL_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const addSubmissitionLastApp = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("lapproved", dataParams);
    dispatch({
      type: ADD_SUBMISSITIONS_APPRO_LAST,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { addSubmissitionLastApp, getSubmissitionAll ,getSubmissitionAllPage};
