/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const TimeLine = ({ count, activeColor, activeBool = [], idRoute }) => {
  const varTimeLine = [
    {
      title: "طلب انضمام",
      isActive: activeBool[0],
      path: "/student/join-request",
    },
    {
      title: "عرض معلومات الجهة",
      isActive: activeBool[1],
      path: `/student/join-request-details/${idRoute}`,
    },
    {
      title: "التقديم",
      isActive: activeBool[2],
      path: `/student/join-request-fill-form/${idRoute}`,
    },
    {
      title: "تأكيد التقديم",
      isActive: activeBool[3],
      path: `/student/status`,
    },
  ];

  const [sizeWidthTimeLine, setSizeWidthTimeLine] = useState(576);
  useState(25);

  const getWidth = () => {
    setSizeWidthTimeLine(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getWidth);

    return () => {
      window.removeEventListener("resize", getWidth);
    };
    // console.log(sizeWidthTimeLine);
  }, [sizeWidthTimeLine]);

  return (
    <div className=" my-4 mb-5">
      <div className=" step-container">
        {varTimeLine.map((item, i) => (
          <span className="col" key={i}>
            <Link
              to={item.isActive ? item.path : null}
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <li className="step-item">
                {" "}
                <div className={`${item.isActive ? "active" : null}`}>
                  {item.isActive ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="var(--primary-color-opacity)"
                    />
                  )}
                </div>
              </li>
              <div className="fw-bold mt-2 text-center step-item-title">
                {item.title}
              </div>
            </Link>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TimeLine;
