import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";

// const SideBarItem = ({
//   title,
//   icon,
//   path,
//   onclick,
//   active,
//   bgColor,
//   color,
//   isHideNotification,
//   valueNotification,
// }) => {
//   return (
//     <MenuItem
//       onClick={onclick}
//       component={<Link to={path} />}
//       // style={{
//       //   backgroundColor: active ? bgColor : null,
//       //   color: active ? color : null,
//       // }}
//       icon={
//         <div
//           // style={{ backgroundColor: active ? color : null }}
//           className="mx-1 p-2 d-flex justify-content-center ms-0 ms-sm-4 align-items-center css-6a03ny22 sidebar-menu-item sidebar-icon"
//         >
//           <img
//             src={icon}
//             style={{ width: "30px", height: "30px" }}
//             alt="icon"
//           />{" "}
//         </div>
//       }
//       className={`rounded-2 my-1`}
//     >
//       <div
//         className="fw-bold mx-1 css-12w9als22"
//         title={title}
//         style={{ fontSize: "15px" }}
//       >
//         {title}
//       </div>
//       {isHideNotification && valueNotification > 0 ? (
//         <span
//           className="position-absolute  badge rounded-pill bg-danger"
//           style={{
//             right: "40px",
//             top: "-5px",
//           }}
//         >
//           {valueNotification}
//         </span>
//       ) : null}
//     </MenuItem>
//   );
// };

// export default SideBarItem;

const SideBarItem = ({
  title,
  icon,
  iconActive,
  path,
  isHideNotification,
  valueNotification,
  onClick,
}) => {
  const location = useLocation();

  const url = "/" + location.pathname.split("/").splice(1, 2).join("/");

  return (
    <MenuItem
      onClick={onClick}
      component={<Link to={path} />}
      style={{ width: 230 }}
      active={url === path ? true : false}
      icon={url === path ? iconActive : icon}
    >
      <div className="fw-bold" title={title} style={{ fontSize: "14px" }}>
        {title}
      </div>
      {isHideNotification && valueNotification > 0 ? (
        <span
          className="position-absolute  badge rounded-pill bg-danger"
          style={{
            left: "7px",
            top: "14px",
          }}
        >
          {valueNotification}
        </span>
      ) : null}
    </MenuItem>
  );
};

export default SideBarItem;
