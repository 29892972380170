/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { emailPattern } from "../../../../redux/types/Types";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllCompany,
  insertNewCompany,
} from "../../../../redux/actions/company/ActionCompany";
import { getAllStudentWithoutPagination } from "../../../../redux/actions/student/ActionStudent";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";

const AdminCompanyCreateContainer = () => {
  const myDispatch = useDispatch();
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");

  const responseNewCompany = useSelector((state) => state.rootCompany.company);

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  // start selcet dropdown
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({
      ...dataInputs,
      department_name: selectedItem.department_name,
    });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, department_name: "" });
  };

  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };

  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funDepartment();
  }, [optionsDepartment]);
  // end selcet dropdown

  const [dataInputs, setDataInputs] = useState({
    username: "",
    company_name: "",
    Fax: "",
    phone: "",
    password: "",
    address: "",
    department_name: selectedDepartmentName,
    about: "",
    Working_hours: "",
    email: "",
    Zip_code: "",
  });

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const submitInsertNewCompany = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertNewCompany(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewCompany) {
        // console.log(responseNewCompany);
        // setIsLoading(false);
        myDispatch(getAllCompany());
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseNewCompany.status === 200) {
        if (responseNewCompany.data === "done") {
          NotificationsToast("done", null, "تم إضافة الجهة بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewCompany.data === "already") {
          NotificationsToast("warn", null, "الجهة مسجلة سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewCompany.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة الجهة");
          setIsLoading(false);
          return;
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "company_name",
      value: dataInputs.company_name || "",
      label: "أسم الجهة",
      placeholder: "أسم الجهة",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.company_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "about",
      value: dataInputs.about || "",
      label: "نبذة عن الجهة",
      placeholder: "نبذة عن الجهة",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.about === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "الهاتف",
      placeholder: "الهاتف",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
    {
      name: "department_name",
      value: dataInputs.department_name || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.department_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Working_hours",
      value: dataInputs.Working_hours || "",
      label: "عدد ساعات الدوام",
      placeholder: "عدد ساعات الدوام",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.Working_hours === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: "العنوان",
      placeholder: "العنوان",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Fax",
      value: dataInputs.Fax || "",
      label: "الفاكس",
      placeholder: "الفاكس",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.Fax === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Zip_code",
      value: dataInputs.Zip_code || "",
      label: "ص.ب",
      placeholder: "ص.ب",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.Zip_code === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "إضافة حساب للجهة",
    },
    {
      name: "username",
      value: dataInputs.username || "",
      label: "أسم المستخدم",
      placeholder: "أسم المستخدم",
      note: "الحقل يقبل أرقام فقط",
      formTypes: "input",
      action: handelNumberIntegerUsername,
      msgError: isShowMsgError
        ? dataInputs.username === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "password",
      value: dataInputs.password || "",
      label: "الرمز السري",
      placeholder: "الرمز السري",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.password === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];
  return (
    <div>
      <FormsContainer
        titleHead={<FormInputTitleHead title={"إضافة جهة جديدة"} fs={"30px"} />}
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) =>
              i === arrDataInputs.length - 3 ? (
                <div className="col-12" key={i}>
                  <FormInputTitleHead fs={"21px"} title={item.title} />
                </div>
              ) : (
                <div className="col-12 col-lg-6" key={i}>
                  {item.formTypes === "dropdown" ? (
                    <>
                      <label
                        className="form-label fw-bold "
                        style={{ fontSize: "14px" }}
                      >
                        {item.label}
                      </label>
                      <div
                        className="style-scroll-radiu"
                        // here if click container MultipleSelected get all data skills
                        onClick={async () => {
                          if (item.name === "department_name") {
                            await myDispatch(getAllDepartment());
                            funDepartment();
                          }
                        }}
                      >
                        <Multiselect
                          options={
                            item.name === "department_name"
                              ? optionsDepartment
                              : []
                          } // Options to display in the dropdown
                          // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                          // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                          onSelect={
                            item.name === "department_name"
                              ? onSelectDepartment
                              : null
                          } // Function will trigger on select event
                          onRemove={
                            item.name === "department_name"
                              ? onRemoveDepartment
                              : null
                          } // Function will trigger on remove event
                          displayValue={
                            item.name === "department_name"
                              ? "department_name"
                              : null
                          } // Property name to display in the dropdown options
                          placeholder={`اختار ${item.label}`}
                          className="text-center rounded-3"
                          showArrow={true}
                          singleSelect={true}
                          emptyRecordMsg="لاتوجد بيانات"
                        />
                      </div>
                      <div
                        className={` position-absolut text-danger fw-bold my-2`}
                        style={{ fontSize: "12px" }}
                      >
                        {item.msgError}
                      </div>
                    </>
                  ) : item.name === "about" ? (
                    <FormInputTextArea
                      type={
                        i === arrDataInputs.length - 1 ? "password" : "text"
                      }
                      onChange={item.action}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                      note={item.note}
                    />
                  ) : (
                    <FormInput
                      type={
                        i === arrDataInputs.length - 1 ? "password" : "text"
                      }
                      onChange={item.action}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                      note={item.note}
                    />
                  )}
                </div>
              )
            )}
          </form>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة الجهة"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewCompany}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة الجهة`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCompanyCreateContainer;
