/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import AdminClearUsersLogic from "../../../logic/admin/clear/AdminClearUsersLogic";
import { Spin, Table } from "antd";
import ButtonRoundedFill from "../../duplicateComponents/ButtonRoundedFill";
import { DeleteFilled } from "@ant-design/icons";

const { Column } = Table;

const AdminClearUsersContainer = () => {
  const [arrMyDataTitleClears, isLoading] = AdminClearUsersLogic();

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },

    {
      title: "المستخدمين",
      dataIndex: "name",
      key: "name",
      filteredValue: null,
      onFilter: null,
      render: null,
    },

    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  // isloading={isLoading}
                  onclick={(e) => {
                    record.actionClear(e);
                    // submitDeleteStudent(e, record.student_id);
                  }}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];
  const [modal2Open, setModal2Open] = useState(false);

  return (
    <Spin
      spinning={isLoading}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div>
            <Table
              pagination={false}
              size="middle"
              dataSource={arrMyDataTitleClears}
              rowKey={"name"}
              className="style-scroll-radius style-shadow-large bg-white rounded-3"
            >
              {dataTableColumns.map((item, i) => (
                <Column
                  title={item.title}
                  dataIndex={item.dataIndex}
                  key={item.key}
                  filteredValue={item.filteredValue}
                  onFilter={item.onFilter}
                  render={item.render}
                />
              ))}
            </Table>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminClearUsersContainer;

// <div className="placeholder-glow">
//   <div className="row">
//     <div className="col-12 col-lg-6 mb-3">
//       <SopervisorTitleWithNumber
//         title={"حذف المستخدمين"}
//         // subTitle={"عدد المتدربين"}
//         num={null}
//       />
//     </div>
//   </div>
//   {/* {loadingStudent ? ( */}
//   <div>
//     <TableContainer
//       thead={["#", "المستخدم", "حذف جميع المستخدمين"]}
//       item={
//         <>
//           {arrMyDataTitleClears.map((item, i) => (
//             <AdminClearUsersItem key={i} index={i + 1} name={item} />
//           ))}
//         </>
//       }
//     />
//   </div>
// </div>
