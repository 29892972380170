import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  EDIT_SOPERVISOR,
  GET_ALL_SOPERVISOR,
  GET_ALL_SOPERVISOR_PAGE,
  GET_ERROR,
  GET_SOPERVISOR_BY_USERNAME,
  GET_STUDENT_INSIDE_SOPERVISOR,
  GET_STUDENT_INSIDE_SOPERVISOR_PAGE,
  GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION,
  INSERT_SOPERVISOR,
} from "../../types/Types";

const getAllSopervisor = () => async (dispatch) => {
  try {
    const response = await UseGetData("get_emps");
    dispatch({
      type: GET_ALL_SOPERVISOR,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllSopervisorPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_emps?page=${dataParams}&limit=10`);
    dispatch({
      type: GET_ALL_SOPERVISOR_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getSopervisorByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_emp/${dataParams}`);
    dispatch({
      type: GET_SOPERVISOR_BY_USERNAME,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`inemp`, dataParams);
    console.log(response)
    dispatch({
      type: INSERT_SOPERVISOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const editSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("update_emps", dataParams);
    dispatch({
      type: EDIT_SOPERVISOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getStudentInsideSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_student_emp/${dataParams}`);
    dispatch({
      type: GET_STUDENT_INSIDE_SOPERVISOR,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getStudentInsideSopervisorPage =
  (dataParams, dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_student_emp/${dataParams}?page=${dataParamsPage}`
      );
      dispatch({
        type: GET_STUDENT_INSIDE_SOPERVISOR_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const getStudentInsideSopervisorWithoutPagination =
  (dataParams) => async (dispatch) => {
    try {
      const response = await UseGetData(`get_student_emp2/${dataParams}`);
      dispatch({
        type: GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export {
  getAllSopervisor,
  getAllSopervisorPage,
  getSopervisorByUsername,
  insertSopervisor,
  getStudentInsideSopervisor,
  getStudentInsideSopervisorPage,
  getStudentInsideSopervisorWithoutPagination,
  editSopervisor,
};
