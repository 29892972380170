// import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import { GET_ERROR, ISNERT_GRADE_TOTAL } from "../../types/Types";

const insertGradesTotal = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`total/${dataParams}`);
    dispatch({
      type: ISNERT_GRADE_TOTAL,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export { insertGradesTotal };
