/* eslint-disable array-callback-return */
import React from "react";
import FormInputTitleHead from "../../duplicateComponents/FormInputTitleHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  // faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
// import TableContainer from "../../duplicateComponents/TableContainer";
// import SopervisorAdditionSkillsTableItem from "../../sopervisor/addition/skills/SopervisorAdditionSkillsTableItem";
// import LoadingPlaceholderItem from "../../duplicateComponents/LoadingPlaceholderItem";
import Button from "../../duplicateComponents/Button";
// import Pagination from "../../duplicateComponents/Pagination";
// import AlertMsg from "../../duplicateComponents/AlertMsg";
import { getAllSopervisor } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { NotificationsToast } from "../../duplicateComponents/NotificationsToast";
import AdminRelationStudentWithSoperLogic from "../../../logic/admin/relation/AdminRelationStudentWithSoperLogic";
import ButtonLoading from "../../duplicateComponents/ButtonLoading";
// import FormInput from "../../duplicateComponents/FormInput";
// import { Spin, Table } from "antd";
// import SopervisorTitleWithNumber from "../../sopervisor/SopervisorTitleWithNumber";
// import ButtonWithIcon from "../../duplicateComponents/ButtonWithIcon";
// import TableLodaing from "../../duplicateComponents/TableLodaing";
// import LinkRoundedFill from "../../duplicateComponents/LinkRoundedFill";
// import ButtonRoundedFill from "../../duplicateComponents/ButtonRoundedFill";
// import { DeleteFilled, EditFilled, EyeFilled } from "@ant-design/icons";
// import Badge from "../../duplicateComponents/Badge";
// import { getAllStudentPage } from "../../../redux/actions/student/ActionStudent";
// import FormInputCheckBox from "../../duplicateComponents/FormInputCheckBox";
import { Select } from "antd";

// const { Column } = Table;

const AdminRelationStudentWithSoperContainer = () => {
  const [
    isLoading,
    onSelect,
    onRemove,
    myDataStudentAll,
    setArrCheckedBool,
    myDispatch,
    fun,
    addSubmitRelation,
    options,
    arrChecked,
    filteredOptions,
    setArrChecked,
  ] = AdminRelationStudentWithSoperLogic();

  return (
    // <Spin
    //   // spinning={isLoadingDeleteStudent}
    //   spinning={false}
    //   delay={500}
    //   size="large"
    //   className="text-danger "
    // >
    <div className="placeholder-glow">
      <div className="row">
        <div className="col-12 col-l ">
          <div
            className=" mb-4  bg-white p-3 style-shadow-large"
            style={{ borderRadius: "var(--radius)" }}
          >
            <div className="col-12 col-xl-6 m-auto">
              <div className="">
                <FormInputTitleHead
                  textAlign={"center"}
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <div>تحديد المشرفين</div>
                      <div className="mx-2">
                        <FontAwesomeIcon icon={faArrowDown} />{" "}
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="style-scroll-radiu"
                // here if click container MultipleSelected get all data skills
                onClick={async () => {
                  await myDispatch(getAllSopervisor());
                  fun();
                }}
              >
                <Multiselect
                  options={options} // Options to display in the dropdown
                  // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                  // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  placeholder="ابحث عن المشرف"
                  className="text-center rounded-3 "
                  showArrow={true}
                  singleSelect={true}
                />
              </div>
              {/* <div
            className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
            style={{
              borderRadius: "var(--radius)",
              // width: "190px",
            }}
          >
            <span className="">
              <input
                className="form-check-input border shadow-none"
                type="checkbox"
                // value={arrChecked}
                onChange={(e) => {
                  let checked = e.target.checked;
                  let newArrCheckedMap;
                  if (myDataSopervsior) {
                    newArrCheckedMap = Object.values(myDataSopervsior).map(
                      (item) => item.username_emp
                    );
                  }
                  // setArrSelectedMuiltySopervisor([]);
                  if (checked) {
                    setArrSelectedMuiltySopervisor(newArrCheckedMap);
                    NotificationsToast(
                      "done",
                      null,
                      "تم تحديد جميع المشرفين"
                    );
                  } else {
                    newArrCheckedMap = [];
                    setArrSelectedMuiltySopervisor(newArrCheckedMap);
                    NotificationsToast(
                      "warn",
                      null,
                      "تم الغاء جميع المشرفين"
                    );
                  }
                  // if seletedted all
                  setArrCheckedBool(checked);
                }}
              />
            </span>{" "}
            <span className="bg-dange fw-bold mx-3">
              تحديد جميع المشرفين
            </span>
          </div> */}
              <div
                className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
                style={{
                  borderRadius: "var(--radius)",
                  // width: "190px",
                }}
              >
                <span className="">
                  <input
                    className="form-check-input border shadow-none"
                    type="checkbox"
                    // value={arrChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      let newArrCheckedMap;
                      if (myDataStudentAll) {
                        newArrCheckedMap = Object.values(myDataStudentAll).map(
                          (item) => item.student_id
                        );
                      }

                      if (checked) {
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "done",
                          null,
                          "تم تحديد جميع المتدربين"
                        );
                      } else {
                        newArrCheckedMap = [];
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "warn",
                          null,
                          "تم الغاء جميع المتدربين"
                        );
                      }
                      // if seletedted all
                      setArrCheckedBool(checked);
                    }}
                  />
                </span>{" "}
                <span className="bg-dange fw-bold mx-3">
                  تحديد جميع المتدربين
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
        <Select
          mode="multiple"
          placeholder="البحث بالرقم التدريبي"
          value={arrChecked}
          onChange={setArrChecked}
          style={{
            width: "100%",
          }}
          options={filteredOptions.map((item, i) => ({
            value: item.student_id,
            label: item.name + " - " + item.student_id,
          }))}
          // options={selectedItems.map((item, i) => ({
          //   value: item,
          //   label: saveName[i] + " - " + item,
          // }))}
        />

        <div className=" me-0 my-3 my-md-0 me-md-3 d-flex justify-content-center align-items-center ">
          {isLoading ? (
            <ButtonLoading
              title={"جاري الاضافة"}
              className={"fw-bold"}
              width={"150px"}
            />
          ) : (
            <Button
              width={"150px"}
              onclick={addSubmitRelation}
              title={"إرسال"}
            />
          )}
        </div>
      </div>

      {/* <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
          <span>
            <SopervisorTitleWithNumber
              title={"جميع المتدربين"}
              subTitle={"عدد المتدربين"}
              num={myDataStudent && myDataStudent.total}
            />
          </span>
          <span className="mt-2 mt-sm-0">
            <ButtonWithIcon path={"/admin/follow-up-student/create"} />
          </span>
        </div>
        <div className="col-12 col-lg-6 my-3">
          <FormInput
            type={"search"}
            placeholder={"البحث بالأسم - الرقم التدريبي ..."}
            shadow={"style-shadow-large-extra"}
            isShowIcon={true}
            onSearch={(val) => {
              setSearchText(val);
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            bgColor="#fff"
          />
        </div>
        <div>
          {loadingStudent ? (
            <TableLodaing itemsColumns={dataTableColumns} />
          ) : (
            <Table
              pagination={{
                position: ["bottomCenter"],
                size: "small",
                style: { display: "flex", flexDirection: "row-reverse" },
                // 1,2,3,4
                current: page,
                // items show
                total: myDataStudent && myDataStudent.total,
                // pageSize: 10,
                onChange: (page, pageSize) => {
                  setPage(page);
                  myDispatch(getAllStudentPage(page));
                },
              }}
              size="middle"
              dataSource={myDataStudent && myDataStudent.data}
              rowKey={"student_id"}
              className="style-scroll-radius style-shadow-large bg-white rounded-3"
            >
              {dataTableColumns.map((item, i) => (
                <Column
                  title={item.title}
                  dataIndex={item.dataIndex}
                  key={item.key}
                  filteredValue={item.filteredValue}
                  onFilter={item.onFilter}
                  render={item.render}
                />
              ))}
            </Table>
          )}
        </div> */}
    </div>
    // </Spin>
  );
};

export default AdminRelationStudentWithSoperContainer;

// <div className=" placeholder-glow">
//   <div className="row">

//   <div className="col-12 col-lg-6 mb-3">
//     <FormInput
//       type={"search"}
//       onChange={handelQuery}
//       // value={""}
//       placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//       idAndfor={"validationCustomFullName"}
//       isValid={"valid"}
//       msgValid={"Gooooooooood !!"}
//       shadow={"style-shadow-large-extra"}
//       isShowIcon={true}
//       icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//     />
//   </div>

//   {loadingStudent ? (
//     <TableContainer
//       thead={["#", "أسم المتدرب", "الرقم التدريبي"]}
//       item={Array(4)
//         .fill(0)
//         .map((_, i) => (
//           <SopervisorAdditionSkillsTableItem
//             key={i}
//             index={i + 1}
//             name={
//               <LoadingPlaceholderItem
//                 col={"col-12 col-lg-6"}
//                 isSize={"lg"}
//               />
//             }
//             studentId={
//               <LoadingPlaceholderItem
//                 col={"col-12 col-lg-6"}
//                 isSize={"lg"}
//               />
//             }
//             isShowCheckBox={null}
//           />
//         ))}
//     />
//   ) : myDataStudent ? (
//     Object.values(myDataStudent).length > 0 ? (
//       <>
//         <TableContainer
//           thead={["#", "أسم المتدرب", "الرقم التدريبي", "تحديد المتدرب"]}
//           item={
//             <>
//               {Object.values(myDataStudent)
//                 .filter((item, i) => {
//                   if (query === "") {
//                     return item;
//                   } else if (
//                     item.name
//                       .toLocaleLowerCase()
//                       .includes(query.toLocaleLowerCase()) ||
//                     item.student_id.toString().includes(query)
//                   ) {
//                     return item;
//                   }
//                 })
//                 .map((item, i) => (
//                   <SopervisorAdditionSkillsTableItem
//                     key={i}
//                     index={i + 1}
//                     name={item.name}
//                     studentId={item.student_id}
//                     onchange={(e) => {
//                       let value = e.target.value;
//                       let checked = e.target.checked;
//                       // if selceted all out the onChange
//                       if (arrCheckedBool) {
//                         if (checked) {
//                           NotificationsToast(
//                             "warn",
//                             null,
//                             "جميع المتدربين محددين سابقاً"
//                           );
//                         }
//                         return;
//                       }
//                       // if checked  === true  ? add from array
//                       if (checked) {
//                         setArrChecked([...arrChecked, parseInt(value)]);
//                       } else {
//                         // if checked  === false  ? remove from array
//                         let newArrChecked = arrChecked.filter(
//                           (val) => val !== parseInt(value)
//                         );
//                         setArrChecked(newArrChecked);
//                       }
//                     }}
//                   />
//                 ))}
//             </>
//           }
//         />

//         <div className="  d-flex justify-content-center align-items-center flex-column">
//           <div className=" my-3 ">
//             {isLoading ? (
//               <ButtonLoading
//                 title={"جاري الاضافة"}
//                 className={"fw-bold"}
//                 width={"292px"}
//               />
//             ) : (
//               <Button
//                 width={"292px"}
//                 onclick={addSubmitRelation}
//                 title={"إرسال"}
//               />
//             )}
//           </div>

//           {/*Start pagination */}
//           {/* <div className="">
//             <Pagination
//               propsCountPage={myDataStudent && myDataStudent.last_page}
//               onPress={getNowPage}
//             />
//           </div> */}
//           {/*End pagination */}
//         </div>
//       </>
//     ) : (
//       <AlertMsg msg={"لايوجد متدربين"} />
//     )
//   ) : (
//     <AlertMsg msg={"لايوجد متدربين"} />
//   )}
// </div>
