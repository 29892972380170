/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import {
  getSubmissitionByApproved,
  insertSubmissitionFristDrop,
} from "../../../redux/actions/submissition/ActionSubmissitions";

const FollowUpDetailsDropStudentFromCompanyLogic = ({
  propsStudent,
  propsCompany,
}) => {
  const myDispatch = useDispatch();
  //   console.log(propsCompany.company_name);
  //   console.log(propsCompany);

  // Start Student
  const responsefDrop = useSelector(
    (state) => state.rootSubmissitions.submissitionFirstDrop
  );
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [noteToStudent, setNoteToStudent] = useState("");

  const handelNoteToStudent = (e) => {
    setNoteToStudent(e.target.value);
  };

  // onSubmit the student poll
  const submitfDrop = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsLoad(true);
    await myDispatch(
      insertSubmissitionFristDrop({
        student_id: propsStudent.student_id,
        company_name: propsCompany.company_name,
        note: noteToStudent,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responsefDrop) {
        // console.log(responsefDrop);
        myDispatch(getSubmissitionByApproved("4"));
        setIsLoading(false);
      }
      if (responsefDrop.status === 200) {
        if (responsefDrop.data === "done") {
          NotificationsToast("done", "الغاء المتدرب بنجاح", "رفع طلب");
          setIsLoading(false);
          return;
        } else if (responsefDrop.data === "no company") {
          NotificationsToast("warn", null, "ليس مسجل في جهة");
          setIsLoading(false);
          return;
        } else if (responsefDrop.data === "already") {
          NotificationsToast("warn", null, "تم الغاء المتدرب سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responsefDrop.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في الغاء المتدرب");
          setIsLoading(false);
          return;
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);
  return [handelNoteToStudent, noteToStudent, isLoading, submitfDrop];
};

export default FollowUpDetailsDropStudentFromCompanyLogic;
