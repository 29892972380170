import React from "react";
// import HeaderStudent from "../../../components/student/HeaderStudent";
// import SidebarPage from "../../sideBar/SidebarPage";
import FollowUpGradeContainer from "../../../components/student/followUp/grades/FollowUpGradeContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import CheckRouteWhereYotGo from "../../../components/duplicateComponents/CheckRouteWhereYotGo";

const StudentFollowUpGradePage = () => {
  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* <HeaderStudent /> */}
        <Breadcrumbs element={["متابعة تنفيذ الخطة", "الدرجات"]} />
        <div className="container p-3 p-md-5">
          <FollowUpGradeContainer />
        </div>
      </div>
    </div>
  );
};

export default StudentFollowUpGradePage;
