/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailPattern, role } from "../../../../redux/types/Types";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllStudentWithoutPagination,
  getAllStudentWithoutSopervisor,
} from "../../../../redux/actions/student/ActionStudent";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  editSopervisor,
  getAllSopervisorPage,
} from "../../../../redux/actions/sopervisor/ActionSopervisor";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";

const AdminSopervisorEditContainer = () => {
  const myDispatch = useDispatch();
  const idRoute = useParams();
  const [idParams, setIdParams] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [studentId, setstudentId] = useState("");

  // api get all sopervisor
  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(getAllSopervisorPage(idRoute.page));
  }, []);

  const responseEditSopervisor = useSelector(
    (state) => state.rootSopervisor.editSopervisor
  );
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );
  const myDataCollage = useSelector((state) => state.rootCollage.collage.data);

  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsCollage, setOptionsCollage] = useState([]);
  const [selectedCollageName, setSelectedCollageName] = useState("");

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  // start selcet dropdown
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({ ...dataInputs, department: selectedItem.department_name });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, department: "" });
  };
  // onSelect Collage
  const onSelectCollage = (selectedList, selectedItem) => {
    setSelectedCollageName(selectedItem.collage_name);
    setDataInputs({ ...dataInputs, collage_name: selectedItem.collage_name });
  };
  // onRemove Collage
  const onRemoveCollage = (selectedList, selectedItem) => {
    setSelectedCollageName("");
    setDataInputs({ ...dataInputs, collage_name: "" });
  };

  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };
  // fun Collage
  const funCollage = () => {
    setOptionsCollage(myDataCollage);
  };
  useEffect(() => {
    myDispatch(getAllCollage());
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funDepartment();
    funCollage();
  }, [optionsDepartment, optionsCollage]);
  // end selcet dropdown

  const [dataInputs, setDataInputs] = useState({});

  // console.log(dataInputs);
  useEffect(() => {
    if (myDataSopervisor) {
      setDataInputs({
        username_emp: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor &&
              myDataSopervisor.data[idParams].username_emp === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].username_emp
        }`,
        name: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].name === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].name
        }`,
        job_type: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor &&
              myDataSopervisor.data[idParams].job_type === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].job_type
        }`,
        department: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor &&
              myDataSopervisor.data[idParams].department === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].department
        }`,
        collage_name: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor &&
              myDataSopervisor.data[idParams].collage_name === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].collage_name
        }`,
        phone: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].phone === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].phone
        }`,
        email: `${
          loadingSopervisor
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].email === null
            ? ""
            : myDataSopervisor && myDataSopervisor.data[idParams].email
        }`,
      });
    }
  }, [myDataSopervisor]);

  const submitInsertNewStudent = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(editSopervisor(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseEditSopervisor) {
        myDispatch(getAllSopervisorPage(idRoute.page));
        myDispatch(getAllStudentWithoutPagination());
      }

      if (!responseEditSopervisor) {
        NotificationsToast("error", null, "هناك مشكلة في تعديل بيانات المشرف");
        setIsLoading(false);
        return;
      }
      if (responseEditSopervisor.status === 200) {
        if (responseEditSopervisor.data === "done") {
          NotificationsToast("done", null, "تم تعديل بيانات المشرف بنجاح");
          setIsLoading(false);
          return;
        }
      } else {
        NotificationsToast("error", null, "هناك مشكلة في تعديل بيانات المشرف");
        setIsLoading(false);
        return;
      }
      myDispatch(getAllStudentWithoutSopervisor());
      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المشرف",
      placeholder: "أسم المشرف",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "job_type",
      value: dataInputs.job_type || "",
      label: "الرقم الوظيفي",
      placeholder: "الرقم الوظيفي",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.job_type === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "collage_name",
      value: dataInputs.collage_name || "",
      label: "الكلية",
      placeholder: "الكلية",
      note: "",
      formTypes: "dropdown",
      msgError: isShowMsgError
        ? dataInputs.collage_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
  ];

  return (
    <div>
      <FormsContainer
        titleHead={
          <FormInputTitleHead
            title={
              <div className="d-flex align-items-center">
                <span>تعديل بيانات المشرف - </span>
                <span className="mx-1">
                  {loadingSopervisor ? (
                    <ReactLoading
                      type={"bubbles"}
                      color={"var(--primary-color)"}
                      width={40}
                      height={40}
                    />
                  ) : myDataSopervisor &&
                    myDataSopervisor.data[idParams].username_emp === null ? (
                    ""
                  ) : (
                    myDataSopervisor &&
                    myDataSopervisor.data[idParams].username_emp
                  )}
                </span>
              </div>
            }
            fs={"30px"}
          />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) => (
              <div className="col-12 col-lg-6" key={i}>
                {item.formTypes === "dropdown" ? (
                  <>
                    <label
                      className="form-label fw-bold "
                      style={{ fontSize: "14px" }}
                    >
                      {item.label}
                    </label>
                    <div
                      className="style-scroll-radiu"
                      // here if click container MultipleSelected get all data skills
                      onClick={async () => {
                        if (item.name === "collage_name") {
                          await myDispatch(getAllCollage());
                          funCollage();
                        } else if (item.name === "department") {
                          await myDispatch(getAllDepartment());
                          funDepartment();
                        }
                      }}
                    >
                      <Multiselect
                        options={
                          item.name === "collage_name"
                            ? optionsCollage
                            : item.name === "department"
                            ? optionsDepartment
                            : []
                        } // Options to display in the dropdown
                        // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                        // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                        onSelect={
                          item.name === "collage_name"
                            ? onSelectCollage
                            : item.name === "department"
                            ? onSelectDepartment
                            : null
                        } // Function will trigger on select event
                        onRemove={
                          item.name === "collage_name"
                            ? onRemoveCollage
                            : item.name === "department"
                            ? onRemoveDepartment
                            : null
                        } // Function will trigger on remove event
                        displayValue={
                          item.name === "collage_name"
                            ? "collage_name"
                            : item.name === "department"
                            ? "department_name"
                            : null
                        } // Property name to display in the dropdown options
                        placeholder={`اختار ${item.label}`}
                        className="text-center rounded-3"
                        showArrow={true}
                        singleSelect={true}
                        emptyRecordMsg="لاتوجد بيانات"
                      />
                    </div>
                    <div
                      className={` position-absolut text-danger fw-bold my-2`}
                      style={{ fontSize: "12px" }}
                    >
                      {item.msgError}
                    </div>
                  </>
                ) : (
                  <FormInput
                    type={"text"}
                    onChange={item.action}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                    note={item.note}
                  />
                )}
              </div>
            ))}
          </form>
        }
      />

      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري تعديل بيانات المشرف"} width={"250px"} />
          ) : (
            <Button
              title={"تعديل"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewStudent}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من تعديل بيانات المشرف`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSopervisorEditContainer;
