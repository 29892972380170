/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { EyeFilled } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  getStudentInsideCompany,
  getStudentInsideCompanyPage,
} from "../../../../redux/actions/company/ActionCompany";
import { tokenCompanyUsername } from "../../../../redux/types/Types";
import Badge from "../../../duplicateComponents/Badge";
import LinkRoundedFill from "../../../duplicateComponents/LinkRoundedFill";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import FormInput from "../../../duplicateComponents/FormInput";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
const { Column } = Table;

const CompanyUserStudentIndexContainer = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getStudentInsideCompany(tokenCompanyUsername));
  }, []);

  // api student inside company
  const myDataStudentInsideCompany = useSelector(
    (state) => state.rootCompany.companyInside
  );

  // const myDataStudentInsideCompanyTotal = useSelector(
  //   (state) => state.rootCompany.companyInside.total
  // );

  const loadingStudentInsideCompany = useSelector(
    (state) => state.rootCompany.loadingInside
  );

  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "حالة المتدرب في الجهة",
      dataIndex: "username",
      key: "username",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex">
            <Badge
              title={record.username == null ? "غير مسجل" : "مسجل"}
              bgColor={
                record.username == null
                  ? "var(--primary-color)"
                  : "var(--green-opacity-color)"
              }
              color={record.username == null ? "#fff" : "var(--green-color)"}
            />
          </div>
        );
      },
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EyeFilled />}
                  colorHover="#fff"
                  width={40}
                  to={`/company/users/view/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];

  return (
    <Spin
      // spinning={isLoadingDeleteStudent}
      spinning={false}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع المتدربين"}
                subTitle={"عدد المتدربين"}
                num={
                  myDataStudentInsideCompany && myDataStudentInsideCompany.total
                }
              />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingStudentInsideCompany ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total:
                    myDataStudentInsideCompany &&
                    myDataStudentInsideCompany.total,

                  // pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(
                      getStudentInsideCompanyPage(tokenCompanyUsername, page)
                    );
                  },
                }}
                size="middle"
                dataSource={
                  myDataStudentInsideCompany && myDataStudentInsideCompany.data
                }
                rowKey={"student_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CompanyUserStudentIndexContainer;
