// import React from "react";
import { useContext } from "react";
import { useState, createContext } from "react";

export const AuthContext = createContext(null);

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("role") || null);

  //   login
  const login = (users) => {
    setUser(users);
  };
  //   logout
  const logout = () => {
    setUser(localStorage.clear() || null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
