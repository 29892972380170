import React from "react";
import Breadcrumbs from "../../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import CompanyUserStudentViewContainer from "../../../../components/company/users/student/CompanyUserStudentViewContainer";

const CompanyUserStudentViewPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/company/users"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              متابعة المتدربين
            </Link>,
            "تفاصيل المتدرب ",
          ]}
        />
        <div className="container p-3 p-md-5">
          <CompanyUserStudentViewContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyUserStudentViewPage;
