import React from "react";
import TimeLineWithTitleAndSubTitle from "../../duplicateComponents/TimeLineWithTitleAndSubTitle";
import TimeLineBoxCardDarkGreen from "../../duplicateComponents/TimeLineBoxCardDarkGreen";
import LoadingTimeLineLightGreen from "../../duplicateComponents/LoadingTimeLineLightGreen";
import SopervisorProfileContainerLogic from "../../../logic/sopervisor/profile/SopervisorProfileContainerLogic";
import FormsContainer from "../../forms/FormsContainer";
import FormInputTitleHead from "../../duplicateComponents/FormInputTitleHead";
import FormInput from "../../duplicateComponents/FormInput";
import ButtonLoading from "../../duplicateComponents/ButtonLoading";
import Button from "../../duplicateComponents/Button";

const SopervisorProfileContainer = () => {
  const [
    myDataSopervisor,
    loadingSopervisor,
    columnAr,
    columnEng,
    password,
    handelPassword,
    submitResetPassword,
    handelNewPassword,
    newPassword,
    loading,
    isShowMsgErrorPassword,
    isShowMsgErrorNewPassword,
    isShowMsgErrorPasswordLength,
    isShowMsgErrorNewPasswordLength,
  ] = SopervisorProfileContainerLogic();

  return (
    <div className="row">
      {/* Display info student */}
      <div className="col-12 col-xl-6">
        <TimeLineBoxCardDarkGreen
          titleHeader={"معلوماتك الشخصية"}
          item={
            <div>
              {loadingSopervisor ? (
                <LoadingTimeLineLightGreen countPlaceholder={4} />
              ) : myDataSopervisor ? (
                Object.values(myDataSopervisor).length > 0 > 0 ? (
                  columnAr.map((item, i) => (
                    <TimeLineWithTitleAndSubTitle
                      key={i}
                      title={item}
                      subTitle={
                        myDataSopervisor[columnEng[i]] === null
                          ? "-"
                          : myDataSopervisor[columnEng[i]]
                      }
                      whatColor={"lightGreen"}
                    />
                  ))
                ) : (
                  <TimeLineWithTitleAndSubTitle
                    title={"لاتوجد بيانات"}
                    whatColor={"lightGreen"}
                  />
                )
              ) : (
                <LoadingTimeLineLightGreen countPlaceholder={4} />
              )}
            </div>
          }
        />
      </div>

      <div className="col-12 col-xl-6">
        <FormsContainer
          titleHead={
            <FormInputTitleHead title={"تغيير كلمة المرور"} fs={"21px"} />
          }
          item={
            <div className="row gx-5 gy-3 ">
              <div className="col-12">
                <FormInput
                  type={"password"}
                  onChange={handelPassword}
                  value={password}
                  label={"كلمة المرور القديمة"}
                  placeholder={"كلمة المرور القديمة"}
                  msgError={
                    isShowMsgErrorPassword
                      ? "الحقل مطلوب"
                      : isShowMsgErrorPasswordLength
                      ? "يجب ان يكون الطول اكبر او يساوي 8"
                      : ""
                  }
                />
              </div>
              <div className="col-12">
                <FormInput
                  type={"password"}
                  label={"كلمة المرور الجديدة"}
                  placeholder={"كلمة المرور الجديدة"}
                  onChange={handelNewPassword}
                  value={newPassword}
                  msgError={
                    isShowMsgErrorNewPassword
                      ? "الحقل مطلوب"
                      : isShowMsgErrorNewPasswordLength
                      ? "يجب ان يكون الطول اكبر او يساوي 8"
                      : ""
                  }
                />
              </div>
              <div className="col-12">
                <div className="col-12 col-sm-6 p-2 m-auto">
                  {loading ? (
                    <ButtonLoading width={"100%"} title={"جاري ارسال"} />
                  ) : (
                    <Button
                      onclick={submitResetPassword}
                      type={"button"}
                      title={"ارسال"}
                    />
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SopervisorProfileContainer;
