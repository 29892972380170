import React from "react";
import { Input } from "antd";
import { LockFilled, SearchOutlined } from "@ant-design/icons";
const FormInput = ({
  type,
  label,
  placeholder,
  idAndfor,
  msgError,
  name,
  onChange,
  value,
  isShowIcon = false,
  icon,
  isLabel,
  shadow,
  readOnly,
  onblur,
  note = "",
  onSearch,
  bgColor = "#f9f9f9",
  disabled,
}) => {
  return (
    <div className="position-relative">
      {isLabel === null ? null : (
        <label
          htmlFor={idAndfor}
          className="form-label fw-bold "
          style={{ fontSize: "14px" }}
        >
          {label}
          {note === "" ? null : (
            <span className="opacity-50 mx-1" style={{ fontSize: 11 }}>
              <span className="mx-1">#</span> {note}
            </span>
          )}
        </label>
      )}

      {type === "password" ? (
        <Input.Password
          type={type}
          name={name}
          className={` ${shadow}`}
          style={{
            backgroundColor: "#f9f9f9",
            padding: "9px",
            // paddingRight: isShowIcon ? "30px" : "10xp",
            fontWeight: "bold",
          }}
          id={idAndfor}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required
          readOnly={readOnly}
          onBlur={onblur}
          prefix={isShowIcon ? <LockFilled /> : null}
          autoComplete="off"
        />
      ) : type === "search" ? (
        <Input.Search
          type={type}
          name={name}
          className={` ${shadow}`}
          style={{
            backgroundColor:
              bgColor === "" ? "#f9f9f9" : `${bgColor} !important`,
            padding: "9px",
            // paddingRight: isShowIcon ? "30px" : "10xp",
            fontWeight: "bold",
          }}
          id={idAndfor}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required
          onSearch={onSearch}
          readOnly={readOnly}
          onBlur={onblur}
          prefix={isShowIcon ? <SearchOutlined /> : null}
          // enterButton={false}
        />
      ) : (
        <>
          <input
            type={type}
            name={name}
            className={`form-control ${shadow}`}
            style={{
              backgroundColor: "#f9f9f9",
              padding: "10px",
              paddingRight: isShowIcon ? "30px" : "10xp",
            }}
            autoComplete="off"
            id={idAndfor}
            onChange={onChange}
            value={value}
            // value="Mark"
            placeholder={placeholder}
            required
            readOnly={readOnly}
            disabled={disabled}
            onBlur={onblur}
          />
          {isShowIcon ? (
            <div
              className="position-absolute"
              style={{ top: "45px", right: "10px" }}
            >
              {icon}
            </div>
          ) : null}
        </>
      )}

      <div
        className={`  text-danger fw-bold my-2`}
        style={{ fontSize: "12px" }}
      >
        {msgError}
      </div>
    </div>
  );
};

export default FormInput;
