import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import AdminExcelCoopContainer from "../../../components/admin/excel/AdminExcelCoopContainer";

const AdminExcelCoopPage = () => {
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        <Breadcrumbs
          // mainPath={"/admin/status"}
          // main={"مدير النظام"}
          element={["إضافة البيانات", "المنسقين"]}
        />
        <div className="container p-3 p-md-5">
          <AdminExcelCoopContainer />
        </div>{" "}
      </div>
    </div>
  );
};

export default AdminExcelCoopPage;
