/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FormInputTextArea from "../FormInputTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserXmark } from "@fortawesome/free-solid-svg-icons";
import ButtonLoading from "../ButtonLoading";
import PopupAreYouSure from "../PopupAreYouSure";
import FollowUpDetailsDropStudentFromCompanyLogic from "../../../logic/sopervisor/followUp/FollowUpDetailsDropStudentFromCompanyLogic";
const FollowUpDetailsDropStudentFromCompanyContainer = ({
  propsStudent,
  propsCompany,
}) => {
  const [handelNoteToStudent, noteToStudent, isLoading, submitfDrop] =
    FollowUpDetailsDropStudentFromCompanyLogic({
      propsStudent,
      propsCompany,
    });

  return (
    <div
      className="bg-white style-shadow-small p-3 col-12 col-xl-6"
      style={{
        borderRadius: "var(--radius)",
      }}
    >
      <div className=" ">
        <div>
          <FormInputTextArea
            onChange={handelNoteToStudent}
            value={noteToStudent}
            label={"ملاحضاتك على المتدرب"}
            placeholder={"ملاحضاتك على المتدرب"}
            idAndfor={"validationCustomIdPrivate"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>

        <div
          className="p-2 mt-3 d-flex justify-content-center"
          style={{
            borderRadius: "0 0 10px 0",
          }}
        >
          {isLoading ? (
            <ButtonLoading
              width={"250px"}
              title={"جاري الغاء المتدرب"}
              className={"bg-danger"}
            />
          ) : (
            <button
              data-bs-toggle={"modal"}
              data-bs-target={"#exampleModalSubfDrop"}
              //   dataBsTarget={"#exampleModalSubfDrop"}
              // id="hover-btn-green-light"
              style={{ width: "250px" }}
              className=" border-0 btn btn-danger text-white fw-bold d-flex align-items-center justify-content-center"
              //   onClick={submitfDrop}
            >
              <div>رفع طلب الغاء المتدرب</div>
              <div className="mx-2">
                <FontAwesomeIcon icon={faUserXmark} />
              </div>
            </button>
          )}
        </div>
      </div>
      <PopupAreYouSure
        id={"exampleModalSubfDrop"}
        onCLick={submitfDrop}
        idPopupStar={"exampleModalSubfDrop"}
        textAlign={"center"}
        title={"هل أنت متأكد من الغاء المتدرب ؟"}
      />
    </div>
  );
};

export default FollowUpDetailsDropStudentFromCompanyContainer;
