import {
  EDIT_COMPANY,
  GET_ALL_COMPANY,
  GET_ALL_COMPANY_PAGE,
  GET_COMPANY_BY_USERNAME,
  GET_ERROR,
  GET_STUDENT_INSIDE_COMPANY,
  GET_STUDENT_INSIDE_COMPANY_PAGE,
  GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN,
  INSERT_NEW_COMPANY,
} from "../../types/Types";

const inital = {
  company: [],
  allCompany: [],
  editCompany: [],
  companyInside: [],
  companyInsideWithoutPagination: [],
  loading: true,
  loadingAllCompany: true,
  loadingEditCompany: true,
  loadingInside: true,
  loadingInsideWithoutPagination: true,
};

const reducerCompany = (state = inital, action) => {
  switch (action.type) {
    // GET {companyname : "سدايا" , id:652413 , .....}
    case GET_COMPANY_BY_USERNAME:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    // POST {companyname : "سدايا" , id:652413 , .....}
    case INSERT_NEW_COMPANY:
      return {
        company: action.payload,
        loading: false,
      };
    // GET ALL [{},{},{}]
    case GET_ALL_COMPANY:
      return {
        ...state,
        allCompany: action.payload,
        loadingAllCompany: false,
      };
    case GET_ALL_COMPANY_PAGE:
      return {
        ...state,
        allCompany: action.payload,
        loadingAllCompany: false,
      };

    case GET_STUDENT_INSIDE_COMPANY:
      return {
        ...state,
        companyInside: action.payload,
        loadingInside: false,
      };
    case GET_STUDENT_INSIDE_COMPANY_PAGE:
      return {
        ...state,
        companyInside: action.payload,
        loadingInside: false,
      };
    case GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN:
      return {
        ...state,
        companyInsideWithoutPagination: action.payload,
        loadingInsideWithoutPagination: false,
      };
    case EDIT_COMPANY:
      return {
        ...state,
        editCompany: action.payload,
        loadingEditCompany: false,
      };
    case GET_ERROR:
      return {
        company: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerCompany;
