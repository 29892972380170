import { UseCreateData } from "../../../hooks/UseCreateData";
import { DELETE_DEPARTMENT, GET_ERROR } from "../../types/Types";

const deleteDepartment = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_department", dataParams);

    dispatch({
      type: DELETE_DEPARTMENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { deleteDepartment };
