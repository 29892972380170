import React from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import SubscriptionsCard from "../Subscriptions/SubscriptionsCard";
import { useNavigate } from "react-router-dom";
const SubscriptionsHome = () => {
  const navigation = useNavigate();
  const myDataPackages = [
    {
      price: "300",
      month: "للترم الواحد",
      AdvandagesItems: ["توفير الدعم الفني", "لكل طالب 150 ريال"],
    },
    {
      price: "1000",
      month: "سنوياً",
      AdvandagesItems: [
        "توفير الدعم الفني",
        "لكل طالب 150 ريال",
        "تكلفة أقل",
        "مزايا أكثر",
      ],
    },
    {
      price: "500",
      month: "للترمين",
      AdvandagesItems: ["توفير الدعم الفني", "لكل طالب 150 ريال", "تكلفة أقل"],
    },
  ];
  return (
    <div className="container  p-3 p-md-5 mt-5" id="packages-homepage">
      <TitleHeadSpecialHomePage title={"الاشتراكات"} />
      <div className="row gy-4">
        {myDataPackages.map((item, i) => (
          <div className="col-12 col-sm-6 col-lg-4" key={i}>
            <SubscriptionsCard
              price={item.price}
              month={item.month}
              AdvandagesItems={item.AdvandagesItems}
              onclick={() =>
                navigation("/subscription", {
                  state: { myData: item },
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionsHome;
