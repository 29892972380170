/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-case */
import {
  AUTH_LOGIN,
  AUTH_REGISTER,
  GET_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_LOGIN,
  RESET_PASSWORD_PROFILE,
} from "../../types/Types";

const inital = {
  login: [],
  resetPasswordLogin: [],
  resetPasswordProfile: [],
  loading: true,
  loadingResetPasswordLogin: true,
  loadingResetPasswordProfile: true,
};

const reducerAuth = (state = inital, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        login: action.payload,
        loading: false,
      };
    case RESET_PASSWORD_LOGIN:
      return {
        ...state,
        resetPasswordLogin: action.payload,
        loadingResetPasswordLogin: false,
      };
    case RESET_PASSWORD_PROFILE:
      return {
        ...state,
        resetPasswordProfile: action.payload,
        loadingResetPasswordProfile: false,
      };
    case GET_ERROR:
      return {
        login: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default reducerAuth;
