/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubmissitionAll,
  getSubmissitionAllPage,
} from "../../../redux/actions/submissition/ActionSubLastApp";
import SopervisorTitleWithNumber from "../../duplicateComponents/TitleWithNumber";
import FormInput from "../../duplicateComponents/FormInput";
import { Spin, Table } from "antd";
import TableLodaing from "../../duplicateComponents/TableLodaing";
import Badge from "../../duplicateComponents/Badge";
const { Column } = Table;

const AdminOrderStatus = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getSubmissitionAll());
  }, []);
  // api submisstion
  const myDataSubmissitionlLastApp = useSelector(
    (state) => state.rootSubLastApp.submissitionLastApp
  );

  const loadingSubmissitionLastApp = useSelector(
    (state) => state.rootSubLastApp.loading
  );
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record, index) => {
        return record.name === null ? "-" : record.name;
      },
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.student_id === null ? "-" : record.student_id;
      },
    },
    {
      title: "الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.collage_name === null ? "-" : record.collage_name;
      },
    },
    {
      title: "القسم",
      dataIndex: "Department",
      key: "Department",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.Department === null ? "-" : record.Department;
      },
    },
    {
      title: "التخصص",
      dataIndex: "major",
      key: "major",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.major === null ? "-" : record.major;
      },
    },
    {
      title: "أسم الجهة",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.company_name === null ? "-" : record.company_name;
      },
    },
    {
      title: "الحالة",
      dataIndex: "approved",
      key: "approved",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex">
            {record.approved === "0" ? (
              <Badge
                title={"قيد الانتظار"}
                bgColor={"var(--yellow-opacity-color)"}
                color={"var(--yellow-color)"}
              />
            ) : record.approved === "1" ? (
              <Badge
                title={"غير مقبول"}
                bgColor={"var(--red-opacity-color)"}
                color={"var(--red-color)"}
              />
            ) : record.approved === "2" ? (
              <Badge
                title={"قبول مبدئي"}
                bgColor={"var(--green-opacity-color)"}
                color={"var(--green-color)"}
              />
            ) : record.approved === "3" ? (
              <Badge
                title={"قبول نهائي"}
                bgColor={"var(--primary-color)"}
                color={"#fff"}
              />
            ) : record.approved === "4" ? (
              <Badge
                title={"الغاء مبدئي"}
                bgColor={"var(--red-opacity-color)"}
                color={"var(--red-color)"}
              />
            ) : record.approved === "5" ? (
              <Badge
                title={"الغاء نهائي"}
                bgColor={"var(--red-color)"}
                color={"#fff"}
              />
            ) : null}
            {/* <Badge
              title={record.username == null ? "غير مسجل" : "مسجل"}
              bgColor={
                record.username == null
                  ? "var(--primary-color)"
                  : "var(--green-opacity-color)"
              }
              color={record.username == null ? "#fff" : "var(--green-color)"}
            /> */}
          </div>
        );
      },
    },
  ];

  return (
    <Spin
      spinning={loadingSubmissitionLastApp}
      // spinning={false}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <SopervisorTitleWithNumber
                title={"جميع الحالات"}
                subTitle={"عدد حالة الطلبات"}
                num={
                  myDataSubmissitionlLastApp && myDataSubmissitionlLastApp.total
                }
              />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingSubmissitionLastApp ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total:
                    myDataSubmissitionlLastApp &&
                    myDataSubmissitionlLastApp.total,
                  // pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getSubmissitionAllPage(page));
                  },
                }}
                size="middle"
                dataSource={
                  myDataSubmissitionlLastApp && myDataSubmissitionlLastApp.data
                }
                rowKey={"submissions_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminOrderStatus;
