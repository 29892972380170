import { GET_ERROR, INSERT_VISIT_TOTAL } from "../../types/Types";

const inital = {
  visitTotal: [],
  loading: true,
};

const reducerVisitTotal = (state = inital, action) => {
  switch (action.type) {
    case INSERT_VISIT_TOTAL:
      return {
        visitTotal: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        visitTotal: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerVisitTotal;
