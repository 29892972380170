import { Skeleton, Table } from "antd";
import React from "react";
const { Column } = Table;
const SkeletonLoading = () => {
  return <Skeleton.Button active size={"small"} shape={"round"} block />;
};
const TableLodaing = ({ itemsColumns = [] }) => {
  return (
    <Table
      pagination={false}
      size="middle"
      dataSource={itemsColumns}
      rowKey={"key"}
      className="style-scroll-radius"
    >
      {itemsColumns.map((item, i) => (
        <Column
          title={item.title}
          key={i}
          render={() => {
            return SkeletonLoading();
          }}
        />
      ))}
    </Table>
  );
};

export default TableLodaing;
