import {
  GET_ALL_REVIEWS_COMPANY,
  GET_ALL_REVIEWS_COMPANY_PAGE,
  GET_ERROR,
} from "../../types/Types";

const inital = {
  reviewsCompany: [],
  loading: true,
};
const reducerGetReviewsCompany = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS_COMPANY:
      return {
        ...state,
        reviewsCompany: action.payload,
        loading: false,
      };
    case GET_ALL_REVIEWS_COMPANY_PAGE:
      return {
        ...state,
        reviewsCompany: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        reviewsCompany: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default reducerGetReviewsCompany;
