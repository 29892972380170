import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import SopervisorOrderDropContainer from "../../../components/sopervisor/order/SopervisorOrderDropContainer";

const SopervisorOrderDropPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["طلبات الغاء المتدربين"]} />
        <div className="container p-3 p-md-5">
          <SopervisorOrderDropContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorOrderDropPage;
