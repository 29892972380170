/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ProfileOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { role } from "../../../redux/types/Types";
import { useAuth } from "../../auth/Auth";
const AdminHeaderLogic = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log(window.innerWidth);
    // console.log(collapseWidth);
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
      // setCollapseWidth(window.innerWidth)
      // setCollapse(true);
    } else {
      // setCollapse(false);
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);

  const nav = useNavigate();
  const auth = useAuth();

  const handelLogout = () => {
    auth.logout();
    nav("/", { replace: true });
  };

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <Link style={{ textDecoration: "none" }} to={"/sopervisor/profile"}>
    //       المعلومات الشخصية
    //     </Link>
    //   ),
    //   icon: <ProfileOutlined className="mt-1" />,
    // },

    {
      key: "1",
      danger: true,
      // label: (
      //   <div
      //     onClick={() => {
      //       if (
      //         localStorage.getItem("token") &&
      //         localStorage.getItem("storageDataObj") &&
      //         localStorage.getItem("role")
      //       ) {
      //         localStorage.clear();
      //         window.location.href = "/";
      //         // console.log("logout");
      //       }
      //     }}
      //   >
      //     تسجيل خروج
      //   </div>
      // ),
      label: <div onClick={handelLogout}>تسجيل خروج</div>,
      icon: <LogoutOutlined className="mt-1" />,
    },
  ];

  const [adminName, setAdminName] = useState("");
  useEffect(() => {
    if (localStorage.getItem("storageDataObj") && role) {
      setAdminName(JSON.parse(localStorage.getItem("storageDataObj")).name);
    }
  }, []);

  return [adminName, open, setOpen, onClose, items];
};

export default AdminHeaderLogic;
