import React from "react";

const SopervisorAdditionGradesDetailsTriningSopervisorTotalItem = ({
  index,
  grade,
  totalVisitOne,
  totalVisitTwo,
  totalVisitThree,
  // totalGrade = [],
  // inputCount = [],
}) => {
  return (
    <tr className="style-tr-without-border-bottom">
      {/* index */}
      <th>
        <div className="mt-2">{index}</div>
      </th>
      {/* title */}
      <td>
        <div
          style={{ color: "var(--primary-color)" }}
          className="fw-bold  mt-2 text-nowrap"
        >
          المجموع
        </div>
      </td>
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
          {grade}
        </div>
      </td>
      <td>
        <div
          style={{ width: "154px" }}
          className="me-2 text-center  d-flex align-items-center justify-content-center fw-bold"
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--primary-color)",
            }}
            className="text-white fs-5 d-flex align-items-center justify-content-center rounded-circle"
          >
            {totalVisitOne === null ? "-" : totalVisitOne}
          </div>
        </div>
      </td>
      {/* <td>
        <div
          style={{ width: "154px" }}
          className="me-2 text-center  d-flex align-items-center justify-content-center fw-bold"
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--primary-color)",
            }}
            className="text-white fs-5 d-flex align-items-center justify-content-center rounded-circle"
          >
            {totalVisitTwo === null ? "-" : totalVisitTwo}
          </div>
        </div>
      </td> */}
      {/* <td>
        <div
          style={{ width: "154px" }}
          className="me-2 text-center  d-flex align-items-center justify-content-center fw-bold"
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--primary-color)",
            }}
            className="text-white fs-5 d-flex align-items-center justify-content-center rounded-circle"
          >
            {totalVisitThree === null ? "-" : totalVisitThree}
          </div>
        </div>
      </td> */}
      {/* {inputCount.length === 0
        ? null
        : inputCount.map((item, i) => (
            <td key={i}>
              <div
                style={{ width: "154px" }}
                className="me-2 text-center  d-flex align-items-center justify-content-center fw-bold"
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "var(--primary-color)",
                  }}
                  className="text-white fs-5 d-flex align-items-center justify-content-center rounded-circle"
                >
                  {totalGrade[i] === null ? "-" : totalGrade[i]}
                </div>
              </div>
            </td>
          ))} */}
      {/* <td>
        <div
          style={{ width: "154px" }}
          className=" me-2 text-center fs-5 fw-bold"
          // className="fw-bold text-dark bg-danger mt-2 rounded-3 fs bg-dange text-center  d-inline-block text-nowrap"
        >
          {totalGrade.one === null ? "لم ترصد" : totalGrade.one}
        </div>
      </td>
      <td>
        <div
          style={{ width: "154px" }}
          className=" me-2 text-center fs-5 fw-bold"
          // className="fw-bold text-dark bg-danger mt-2 rounded-3 fs bg-dange text-center  d-inline-block text-nowrap"
        >
          {totalGrade.two === null ? "لم ترصد" : totalGrade.two}
        </div>
      </td>
      <td>
        <div
          style={{ width: "154px" }}
          className=" me-2 text-center fs-5 fw-bold"
          // className="fw-bold text-dark bg-danger mt-2 rounded-3 fs bg-dange text-center  d-inline-block text-nowrap"
        >
          {totalGrade.three === null ? "لم ترصد" : totalGrade.three}
        </div>
      </td> */}
    </tr>
  );
};

export default SopervisorAdditionGradesDetailsTriningSopervisorTotalItem;
