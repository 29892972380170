import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_REVIEWS_COMPANY,
  GET_ALL_REVIEWS_COMPANY_PAGE,
  GET_ERROR,
} from "../../types/Types";

const getAllReviewsCompany = () => async (dispatch) => {
  try {
    const response = await UseGetData("get_reviews_companys");
    dispatch({
      type: GET_ALL_REVIEWS_COMPANY,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllReviewsCompanyPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(
      `get_reviews_companys?page=${dataParams}`
    );
    dispatch({
      type: GET_ALL_REVIEWS_COMPANY_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getAllReviewsCompany, getAllReviewsCompanyPage };
