/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllStudentPage } from "../../../../redux/actions/student/ActionStudent";
import { getCompanyByUsername } from "../../../../redux/actions/company/ActionCompany";
import { getGradesByUsername } from "../../../../redux/actions/grades/ActionGrades";
import { getVisitNumberByStudentId } from "../../../../redux/actions/visit/ActionVisit";
import ReactLoading from "react-loading";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import Taps from "../../../duplicateComponents/Taps";
import FollowUpDetailsInfoStudentContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoStudentContainer";
import FollowUpDetailsInfoCompanyContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoCompanyContainer";
import FollowUpDetailsGradesContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsGradesContainer";
import FollowUpDetailsVisitsContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsVisitsContainer";
import FollowUpDetailsFinalReportContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsFinalReportContainer";
import FollowUpDetailsDropStudentFromCompanyContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsDropStudentFromCompanyContainer";

const AdminStudentViewContainer = () => {
  const idRoute = useParams();
  const myDispatch = useDispatch();
  // api sutudent
  const [idParams, setIdParams] = useState(0);

  const myDataStudent = useSelector((state) => state.rootStudent.student);

  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(getAllStudentPage(idRoute.page));
  }, []);

  // api compnay
  const myDataCompany = useSelector((state) => state.rootCompany.company);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);

  // api grades
  const myDataGrades = useSelector((state) => state.rootGrades.grades);

  useEffect(() => {
    myDispatch(
      getCompanyByUsername(
        loadingStudent
          ? null
          : myDataStudent && myDataStudent.data[idParams].username
      )
    );
    myDispatch(
      getGradesByUsername(
        loadingStudent
          ? null
          : myDataStudent && myDataStudent.data[idParams].student_id
      )
    );
    myDispatch(
      getVisitNumberByStudentId(
        loadingStudent
          ? null
          : myDataStudent && myDataStudent.data[idParams].student_id
      )
    );
  }, [myDataStudent]);

  // api visit
  const myDataVisit = useSelector((state) => state.rootVisit.visit.data);

  return (
    <div className="">
      <div className="mb-2">
        {loadingStudent ? (
          <ReactLoading
            type={"bubbles"}
            color={"var(--primary-color)"}
            width={40}
            height={40}
          />
        ) : (
          <TitleWithNumber
            title={
              myDataStudent ? (
                myDataStudent.data[idParams].name
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            subTitle={
              myDataStudent ? (
                myDataStudent.data[idParams].student_id
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            num={null}
          />
        )}
      </div>
      <Taps
        title={[
          "معلومات المتدرب",
          "معلومات الجهة",
          "تفاصيل الدرجات",
          "عدد الزيارات",
          "التقرير النهائي",
          "طلب الغاء المتدرب",
        ]}
        charIdAndTarget={["a", "b", "c", "d", "e", "f"]}
        titleItem={[
          <FollowUpDetailsInfoStudentContainer
            propsLoadingStudent={loadingStudent}
            propsStudent={
              loadingStudent
                ? null
                : myDataStudent && myDataStudent.data[idParams]
            }
          />,
          <FollowUpDetailsInfoCompanyContainer
            propsLaodingCompany={loadingCompany}
            propsCompany={myDataCompany.data && myDataCompany.data}
          />,
          <FollowUpDetailsGradesContainer
            propsGrades={myDataGrades && myDataGrades}
          />,
          <FollowUpDetailsVisitsContainer
            //props student_id => 441202295
            propsVisitCount={myDataVisit && myDataVisit}
          />,
          <FollowUpDetailsFinalReportContainer />,
          <FollowUpDetailsDropStudentFromCompanyContainer
            propsStudent={
              loadingStudent
                ? null
                : myDataStudent && myDataStudent.data[idParams]
            }
            propsCompany={myDataCompany.data && myDataCompany.data}
          />,
        ]}
      />
    </div>
  );
};

export default AdminStudentViewContainer;
