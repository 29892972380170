import React from "react";

const FormInputCheckBox = ({ name, onChange, value, chacked }) => {
  return (
    <div className="form-check d-flex justify-content-center align-items-center mb-1">
      <input
        className="form-check-input border shadow-none"
        type="checkbox"
        value={value}
        name={name}
        onChange={onChange}
        checked={chacked}
      />
    </div>
  );
};

export default FormInputCheckBox;
