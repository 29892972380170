import React from "react";
import Button from "../Button";
import Badge from "../Badge";
import FollowUpDetailsCardBgWhite from "../FollowUpDetailsCardBgWhite";

const FollowUpDetailsFinalReportContainer = () => {
  return (
    <FollowUpDetailsCardBgWhite
      title={"التقرير النهائي"}
      // subTitle={"باسل محمد يحيى ال ظفره"}
      item={
        <div className="row mt-4">
          {/* <TableContainer thead={["حالة التقرير", "تنزيل"]} /> */}
          <div className="col-12 col-sm-6 text-center">
            <div>
              <div className="fw-bold ">الحالة التقرير للمتدرب</div>
              <div className="d-flex justify-content-center mt-2">
                {/* <Badge
                title={"أرفق"}
                bgColor={"var(--green-opacity-color)"}
                color={"var(--green-color)"}
              /> */}
                <Badge
                  title={"لم يرفق"}
                  bgColor={"var(--red-opacity-color)"}
                  color={"var(--red-color)"}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 text-center mt-4 mt-sm-0">
            <div>
              <div className="fw-bold">تنزيل التقرير</div>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  disabled
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="text-white">تنزيل التقرير</div>
                      <div className="mx-2">
                        <img
                          src="../../../images/icon-download.png"
                          style={{ width: "17px", height: "17px" }}
                          alt=""
                        />
                      </div>
                    </div>
                  }
                />
                {/* <Button
                title={
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-white">تنزيل التقرير</div>
                    <div className="mx-2">
                      <img
                        src="../../images/icon-download.png"
                        style={{ width: "17px", height: "17px" }}
                        alt=""
                      />
                    </div>
                  </div>
                }
              /> */}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FollowUpDetailsFinalReportContainer;
