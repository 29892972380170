/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/duplicateComponents/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { tokenSopervisorUsername } from "../../../../redux/types/Types";
import {
  getStudentInsideSopervisor,
  getStudentInsideSopervisorPage,
} from "../../../../redux/actions/sopervisor/ActionSopervisor";
import { Spin, Table } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import TableLodaing from "../../../duplicateComponents/TableLodaing";
import LinkRoundedFill from "../../../duplicateComponents/LinkRoundedFill";
const { Column } = Table;
const SopervisorAdditionGradesContainer = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getStudentInsideSopervisor(tokenSopervisorUsername));
  }, []);

  // api sopervsior Inside
  const myDataInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisorInside
  );

  const loadingInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.loadingInside
  );

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم المتدرب",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "إضافة الدرجات",
      dataIndex: "action",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<PlusCircleFilled />}
                  colorHover="#fff"
                  width={40}
                  to={`/sopervisor/addition-grades/create/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];
  return (
    <Spin spinning={false} delay={500} size="large">
      <div className="placeholder-glow">
        <div className="row">
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingInsideSopervisor ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: {
                    display: "flex",
                    flexDirection: "row-reverse",
                  },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total: myDataInsideSopervisor && myDataInsideSopervisor.total,
                  // total:20,
                  // pageSize: 100,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(
                      getStudentInsideSopervisorPage(
                        tokenSopervisorUsername,
                        page
                      )
                    );
                  },
                }}
                size="middle"
                dataSource={
                  myDataInsideSopervisor && myDataInsideSopervisor.data
                }
                rowKey={"student_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SopervisorAdditionGradesContainer;

//   <div className="">
//     <div className="mb-3 col-12 col-lg-6">
//       <FormInput
//         type={"search"}
//         onChange={handelQuery}
//         value={query}
//         placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//         idAndfor={"validationCustomFullName"}
//         isValid={"valid"}
//         msgValid={"Gooooooooood !!"}
//         shadow={"style-shadow-large-extra"}
//         isShowIcon={true}
//         icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//       />
//     </div>
//     {role === "emp" ? (
//       loadingInsideSopervisor ? (
//         <>
//           <TableContainer
//             className={"my-4"}
//             thead={["#", "أسم المتدرب", "الرقم التدريبي", "إضافة الدرجات"]}
//             item={Array(4)
//               .fill(0)
//               .map((_, i) => (
//                 <SopervisorAdditionGradesItem
//                   key={i}
//                   index={i + 1}
//                   name={
//                     <LoadingPlaceholderItem
//                       col={"col-12 cl-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   studentId={
//                     <LoadingPlaceholderItem
//                       col={"col-12 cl-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   isHideBtn={null}
//                 />
//               ))}
//           />
//         </>
//       ) : myDataInsideSopervisor.data ? (
//         Object.values(myDataInsideSopervisor.data).length > 0 ? (
//           <>
//             <TableContainer
//               className={"my-4"}
//               thead={["#", "أسم المتدرب", "الرقم التدريبي", "إضافة الدرجات"]}
//               item={
//                 <>
//                   {Object.values(myDataInsideSopervisor.data)
//                     .filter((item, i) => {
//                       if (query === "") {
//                         return item;
//                       } else if (
//                         item.name
//                           .toLocaleLowerCase()
//                           .includes(query.toLocaleLowerCase()) ||
//                         item.student_id.toString().includes(query)
//                       ) {
//                         return item;
//                       }
//                     })
//                     .map((item, i) => (
//                       <SopervisorAdditionGradesItem
//                         key={i}
//                         index={i + 1}
//                         name={item.name}
//                         studentId={item.student_id}
//                         idStudentId={i}
//                         propsPageNum={pageNum}
//                       />
//                     ))}
//                 </>
//               }
//             />
//             {/*Start pagination */}
//             <div className="my-3 d-flex justify-content-center align-items-center bg-dange">
//               <Pagination
//                 propsCountPage={
//                   myDataInsideSopervisor && myDataInsideSopervisor.last_page
//                 }
//                 onPress={getNowPageEmp}
//               />
//             </div>
//             {/*End pagination */}
//           </>
//         ) : (
//           <AlertMsg msg={"لايوجد متدربين"} />
//         )
//       ) : (
//         <AlertMsg msg={"لايوجد متدربين"} />
//       )
//     ) : role === "company" ? (
//       loadingStudentInsideCompany ? (
//         <>
//           <TableContainer
//             className={"my-4"}
//             thead={["#", "أسم المتدرب", "الرقم التدريبي", "إضافة الدرجات"]}
//             item={Array(4)
//               .fill(0)
//               .map((_, i) => (
//                 <SopervisorAdditionGradesItem
//                   key={i}
//                   index={i + 1}
//                   name={
//                     <LoadingPlaceholderItem
//                       col={"col-12 cl-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   studentId={
//                     <LoadingPlaceholderItem
//                       col={"col-12 cl-lg-6"}
//                       isSize={"lg"}
//                     />
//                   }
//                   isHideBtn={null}
//                 />
//               ))}
//           />
//         </>
//       ) : myDataStudentInsideCompany.data ? (
//         Object.values(myDataStudentInsideCompany.data).length > 0 ? (
//           <>
//             <TableContainer
//               className={"my-4"}
//               thead={["#", "أسم المتدرب", "الرقم التدريبي", "إضافة الدرجات"]}
//               item={
//                 <>
//                   {Object.values(myDataStudentInsideCompany.data)
//                     .filter((item, i) => {
//                       if (query === "") {
//                         return item;
//                       } else if (
//                         item.name
//                           .toLocaleLowerCase()
//                           .includes(query.toLocaleLowerCase()) ||
//                         item.student_id.toString().includes(query)
//                       ) {
//                         return item;
//                       }
//                     })
//                     .map((item, i) => (
//                       <SopervisorAdditionGradesItem
//                         key={i}
//                         index={i + 1}
//                         name={item.name}
//                         studentId={item.student_id}
//                         idStudentId={i}
//                         propsPageNum={pageNum}
//                       />
//                     ))}
//                 </>
//               }
//             />
//             {/*Start pagination */}
//             <div className="my-3 d-flex justify-content-center align-items-center bg-dange">
//               <Pagination
//                 propsCountPage={
//                   myDataStudentInsideCompany &&
//                   myDataStudentInsideCompany.last_page
//                 }
//                 onPress={getNowPageCompany}
//               />
//             </div>
//             {/*End pagination */}
//           </>
//         ) : (
//           <AlertMsg msg={"لايوجد متدربين"} />
//         )
//       ) : (
//         <AlertMsg msg={"لايوجد متدربين"} />
//       )
//     ) : null}
//   </div>
// );
