/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllDepartment,
  insertDepartment,
} from "../../../../redux/actions/department/ActionDepartment";
import { role } from "../../../../redux/types/Types";
import { getAllStudentWithoutPagination } from "../../../../redux/actions/student/ActionStudentWithoutPagination";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
const AdminDepartmentCreateContainer = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllCollage());
  }, []);

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [departmentName, setDepartmentName] = useState("");
  const [selectedCollageName, setSelectedCollageName] = useState("");
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isShowMsgErrorSelect, setIsShowMsgErrorSelect] = useState(false);

  const responseNewDepartment = useSelector(
    (state) => state.rootDepartment.department
  );

  // api collage
  const myDataCollage = useSelector((state) => state.rootCollage.collage.data);

  const handelDataInputs = (e) => {
    if (e.target.value.length > 0) {
      setIsShowMsgError(false);
    }
    setDepartmentName(e.target.value);
  };

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.collage_name === "") {
      setIsShowMsgErrorSelect(true);
    } else {
      setIsShowMsgErrorSelect(false);
    }
    setSelectedCollageName(selectedItem.collage_name);
  };
  const onRemove = (selectedList, selectedItem) => {
    // let newArr = selectedList.filter((val) => val !== val.username_emp);
    // let newArr2 = newArr.map((val) => val.username_emp);
    // setArrSelectedMuiltySopervisor(newArr2);
    setSelectedCollageName("");
  };

  //   console.log(selectedCollageName);

  const fun = () => {
    setOptions(myDataCollage);
  };

  useEffect(() => {
    fun();
  }, [options]);

  const submitInsertNewDepartment = async (e) => {
    e.preventDefault();

    if (departmentName === "" && selectedCollageName === "") {
      setIsShowMsgError(true);
      setIsShowMsgErrorSelect(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }

    if (departmentName === "") {
      setIsShowMsgError(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }
    if (selectedCollageName === "") {
      setIsShowMsgErrorSelect(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      insertDepartment({
        collage: selectedCollageName,
        department: departmentName,
        type: role,
      })
    );
    setIsLoad(false);
    setDepartmentName("");
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewDepartment) {
        // console.log(responseNewDepartment);
        myDispatch(getAllDepartment());
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseNewDepartment.status === 200) {
        if (responseNewDepartment.data === "done") {
          NotificationsToast("done", null, "تم إضافة القسم بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewDepartment.data === "already") {
          NotificationsToast("warn", null, "القسم مسجلة سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewDepartment.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة القسم");
          setIsLoading(false);
          return;
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  return (
    <div>
      <FormsContainer
        titleHead={<FormInputTitleHead title={"إضافة قسم جديدة"} fs={"30px"} />}
        item={
          <form className="row gx-5 gy-3 needs-validation">
            <div className="col-12 col-lg-6">
              <div className="">
                <FormInputTitleHead
                  textAlign={"center"}
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <div>تحديد الكلية</div>
                      <div className="mx-2">
                        <FontAwesomeIcon icon={faArrowDown} />{" "}
                      </div>
                    </div>
                  }
                />
              </div>
              <div
                className="style-scroll-radiu"
                // here if click container MultipleSelected get all data skills
                onClick={async () => {
                  await myDispatch(getAllCollage());
                  fun();
                }}
              >
                <Multiselect
                  options={options} // Options to display in the dropdown
                  // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                  // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue={"collage_name"} // Property name to display in the dropdown options
                  placeholder="اختار الكلية"
                  className="text-center rounded-3 "
                  showArrow={true}
                  singleSelect={true}
                />
              </div>
              <div
                className={` position-absolut text-danger fw-bold my-2`}
                style={{ fontSize: "12px" }}
              >
                {isShowMsgErrorSelect ? "الحقل مطلوب" : ""}
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-5">
              <FormInput
                type={"text"}
                onChange={handelDataInputs}
                value={departmentName}
                label={"أسم القسم"}
                placeholder={"أسم القسم"}
                msgError={isShowMsgError ? "الحقل مطلوب" : ""}
              />
            </div>
          </form>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة القسم"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCollage"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCollage"}
            onCLick={submitInsertNewDepartment}
            idPopupStar={"exampleModal_InsertNewCollage"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة القسم`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminDepartmentCreateContainer;
