/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Drawer, Dropdown, Layout, Space } from "antd";
import Hamburger from "hamburger-react";
import AdminSidebar from "./AdminSidebar";
import AdminHeaderLogic from "../../../logic/admin/layout/AdminHeaderLogic";
const { Header } = Layout;
const AdminHeader = () => {
  const [adminName, open, setOpen, onClose, items] = AdminHeaderLogic();

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        // marginRight: window.innerWidth > 768 ? 230 : 0,
        background: "var(--primary-color)",
        // height: 60,
        // background: theme === "dark" ? "var(--green)" : "var(--primary)",
      }}
      className=" d-flex justify-content-between p-3"
    >
      {window.innerWidth > 768 ? (
        <div></div>
      ) : (
        <div className="text-white border rounded" style={{}}>
          <Hamburger toggled={open} toggle={setOpen} />
        </div>
      )}
      {/* <div className="text-white">Account</div> */}
      <Dropdown
        trigger={"click"}
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()} className="text-white ">
          <Space>
            {/* {adminName} */}
            مدير النظام
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>

      <Drawer
        title=""
        placement="right"
        onClose={onClose}
        open={open}
        width={230}
        style={{
          background: "#fff",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
          className="d-flex justify-content-between flex-column style-sidebar-scroll-radius"
        >
          <AdminSidebar isPositionFixed={false} />
        </div>
      </Drawer>
    </Header>
  );
};

export default AdminHeader;
