import React from "react";

const ButtonLoading = ({ title, width, className }) => {
  return (
    <div className="">
      <button
        className={`btn text-white ${className}`}
        style={{ backgroundColor: "var(--primary-color)", width: width }}
        type="button"
        disabled
      >
        <div
          className="spinner-border mx-2 text-white spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></div>
        {title}
      </button>
    </div>
  );
};

export default ButtonLoading;
