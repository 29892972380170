/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../duplicateComponents/Button";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormsContainer from "./FormsContainer";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import FormInputRadio from "../duplicateComponents/FormInputRadio";
import FormInputRadioContainer from "../duplicateComponents/FormInputRadioContainer";
import TableContainer from "../duplicateComponents/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getStudentByUsername } from "../../redux/actions/student/ActionStudent";
import { tokenStudentUsername } from "../../redux/types/Types";
import FormsEnrollCoopTriningDesiredItem from "./FormsEnrollCoopTriningDesiredItem";
import { getSubmissitionByUsername } from "../../redux/actions/submissition/ActionSubmissitions";
import LoadingPlaceholderItem from "../duplicateComponents/LoadingPlaceholderItem";
import AlertMsg from "../duplicateComponents/AlertMsg";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import { insertFormsPdfEnroll } from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";
// import { useFormik } from "formik";
// import * as Yub from "yub";
const FormsEnrollCoopTriningContainer = ({ propsTitle, propsSubTitle }) => {
  const myDispatch = useDispatch();
  // api get all studnet
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loadingStudent = useSelector((state) => state.rootStudent.loading);
  const myDataSubmissitionByUsername = useSelector(
    (state) => state.rootSubmissitions.submissition
  );
  const loadingSubmissitionByUsername = useSelector(
    (state) => state.rootSubmissitions.loading
  );
  useEffect(() => {
    myDispatch(getStudentByUsername(tokenStudentUsername));
    myDispatch(getSubmissitionByUsername(tokenStudentUsername));
  }, []);


  // console.log(myDataSubmissitionByUsername)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [downloadPathFile, setDownloadPathFile] = useState("");
  // const { download } = useDownloader();
  // api forms
  const responseFormsPdf = useSelector((state) => state.rootFormsPdf.formsPdf);

  // console.log(myDataStudent && myDataStudent);
  // console.log(myDataSubmissitionByUsername && myDataSubmissitionByUsername);
  // console.log(loadingStudent);

  const [dataInputs, setDataInputs] = useState({});
  // show msg is required if onclick the btn
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  useEffect(() => {
    if (myDataStudent && myDataSubmissitionByUsername) {
      setDataInputs({
        student_name: `${loadingStudent ? "" : myDataStudent.name}`,
        student_id: `${loadingStudent ? "" : myDataStudent.student_id}`,
        department: `${loadingStudent ? "" : myDataStudent.Department}`,
        major: `${loadingStudent ? "" : myDataStudent.major}`,
        GPA: `${loadingStudent ? "" : myDataStudent.GPA}`,
        phone_number: `${loadingStudent ? "" : myDataStudent.phone}`,
        phone_number2: "",
        address: "",
        submissitions:
          myDataSubmissitionByUsername === "somthing wrong"
            ? ""
            : loadingSubmissitionByUsername
            ? ""
            : myDataSubmissitionByUsername.data.map((item) => {
                return item.company_name;
              }),
        free: "",
      });
    }
  }, [myDataStudent]);

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  const submitPDF = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfEnroll(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseFormsPdf) {
        // console.log(responseFormsPdf);

        if (responseFormsPdf.status === 200) {
          // download(
          //   responseFormsPdf.data.replace("demo.", ""),
          //   `${propsTitle}.pdf`
          // );
          const link = document.createElement("a");
          link.target = "_blanck";
          link.href = responseFormsPdf.data.replace("demo.", "");
          link.download = `${propsTitle}.pdf`;
          link.click();

          NotificationsToast("done", null, "تم تنزيل النموذج بنجاح");
          setIsLoading(false);
          return;
        } else {
          if (responseFormsPdf.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في تنزيل النموذج");
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "student_name",
      value: dataInputs.student_name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      msgError: isShowMsgError
        ? dataInputs.student_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "student_id",
      value: dataInputs.student_id || "",
      label: "الرقم التدريبي",
      placeholder: "الرقم التدريبي",
      msgError: isShowMsgError
        ? dataInputs.student_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "GPA",
      value: dataInputs.GPA || "",
      label: "المعدل التراكمي",
      placeholder: "المعدل التراكمي",
      msgError: isShowMsgError
        ? dataInputs.GPA === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone_number",
      value: dataInputs.phone_number || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      msgError: isShowMsgError
        ? dataInputs.phone_number === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone_number2",
      value: dataInputs.phone_number2 || "",
      label: "جوال أخر",
      placeholder: "جوال أخر",
      msgError: isShowMsgError
        ? dataInputs.phone_number2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: " موقع السكن (الحي - الشارع)",
      placeholder: " موقع السكن (الحي - الشارع)",
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        fs={"22px"}
        titleHeader={`ماهو ${propsTitle} ؟`}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />

      {/* forms */}
      <div className="">
        <FormsContainer
          titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
          item={
            <form className="row gx-5 gy-3 needs-validation" noValidate>
              {arrDataInputs.map((item, i) => (
                <div className="col-lg-6" key={i}>
                  <FormInput
                    type={"text"}
                    onChange={handelDataInputs}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                  />
                </div>
              ))}
              <div>
                {myDataSubmissitionByUsername === "somthing wrong" ? (
                  <TableContainer
                    className={"my-3"}
                    thead={["#", "الرغبات"]}
                    item={
                      <FormsEnrollCoopTriningDesiredItem
                        index={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: "20px", height: "20px" }}
                            className="bi bi-exclamation-triangle-fill  flex-shrink-0 me-2"
                            viewBox="0 0 16 16"
                            role="img"
                            aria-label="Danger:"
                          >
                            <path
                              fill="var(--red-color)"
                              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                            />
                          </svg>
                        }
                        title={"لاتوجد رغبات لك"}
                      />
                    }
                  />
                ) : loadingSubmissitionByUsername ? (
                  <TableContainer
                    className={"my-3"}
                    thead={["#", "الرغبات"]}
                    item={Array(4)
                      .fill(0)
                      .map((_, i) => (
                        <FormsEnrollCoopTriningDesiredItem
                          key={i}
                          index={1 + i}
                          title={
                            <LoadingPlaceholderItem
                              col={"col-12 col-lg-6"}
                              isSize={"lg"}
                            />
                          }
                        />
                      ))}
                  />
                ) : myDataSubmissitionByUsername.data ? (
                  Object.values(myDataSubmissitionByUsername.data).length >
                  0 ? (
                    <TableContainer
                      className={"my-3"}
                      thead={["#", "الرغبات"]}
                      item={myDataSubmissitionByUsername.data.map((item, i) => (
                        <FormsEnrollCoopTriningDesiredItem
                          key={i}
                          index={i + 1}
                          title={item.company_name}
                        />
                      ))}
                    />
                  ) : (
                    <AlertMsg msg={"لاتوجد رغبات"} />
                  )
                ) : (
                  <AlertMsg msg={"لاتوجد رغبات"} />
                )}
              </div>
              <div className="col-12">
                <FormInputRadioContainer
                  title={"الوضع الأكاديمي للمتدربة"}
                  item={
                    <div>
                      <div className="my-2">
                        <FormInputRadio
                          label={"متفرغة للتدريب التعاوني "}
                          name={"free"}
                          idAndFor={"emptyId1"}
                          onChange={handelDataInputs}
                          value={1}
                        />
                      </div>
                      <div className="my-2">
                        <FormInputRadio
                          label={"تبقى مقرر إضافة إلى التدريب التعاوني"}
                          name={"free"}
                          idAndFor={"emptyId2"}
                          onChange={handelDataInputs}
                          value={2}
                        />
                      </div>
                      <div
                        className={` text-danger fw-bold my-2`}
                        style={{ fontSize: "12px" }}
                      >
                        {isShowMsgError
                          ? dataInputs.free === ""
                            ? "الحقل مطلوب"
                            : ""
                          : ""}
                      </div>
                    </div>
                  }
                  note={
                    "# عند الضغط على الخيار الثاني اذهب الى نموذج طلب الاستثناء"
                  }
                  path={"/student/forms-request-exception"}
                />
              </div>
            </form>
          }
        />
        <div className="col-12">
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
            ) : (
              <Button
                title={"أرسال"}
                width={"250px"}
                dataBsToggle="modal"
                dataBsTarget="#exampleModalForms-request-exception"
                type="submit"
              />
            )}
            <PopupAreYouSure
              id={"exampleModalForms-request-exception"}
              // onCLick={countTimeLineNow}
              onCLick={submitPDF}
              idPopupStar={"exampleModalForms-request-exception"}
              textAlign={"center"}
              title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsEnrollCoopTriningContainer;
