import { createStore, applyMiddleware } from "redux";
// import { rootReducer } from "../rootReducer/RootReducer";
import thunk from "redux-thunk";
// import ReducerStudent from "../reducers/ReducerStudent";
import { rootReducer } from "../rootReducer/RootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
const initalState = {};
const middleware = [thunk];

export const store = createStore(
  rootReducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware))
);
