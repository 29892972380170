/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SopervisorAdditionGradesDetailsTriningProvider from "../../../sopervisor/addition/grades/SopervisorAdditionGradesDetailsTriningProvider";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStudentInsideCompanyPage } from "../../../../redux/actions/company/ActionCompany";
import { tokenCompanyUsername } from "../../../../redux/types/Types";
import SopervisorTitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import ReactLoading from "react-loading";

const CompanyAdditionGradesDetailsContainer = () => {
  const idRoute = useParams();
  const myDispatch = useDispatch();
  const [idParams, setIdParams] = useState(0);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(getStudentInsideCompanyPage(tokenCompanyUsername, idRoute.page));
  }, []);

  // api student inside company
  const myDataStudentInsideCompany = useSelector(
    (state) => state.rootCompany.companyInside
  );
  const loadingStudentInsideCompany = useSelector(
    (state) => state.rootCompany.loadingInside
  );
  return (
    <div>
      {loadingStudentInsideCompany ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        <SopervisorTitleWithNumber
          title={
            myDataStudentInsideCompany ? (
              myDataStudentInsideCompany.data[idParams].name
            ) : (
              <ReactLoading
                type={"bubbles"}
                color={"var(--primary-color)"}
                width={40}
                height={40}
              />
            )
          }
          subTitle={
            myDataStudentInsideCompany ? (
              myDataStudentInsideCompany.data[idParams].student_id
            ) : (
              <ReactLoading
                type={"bubbles"}
                color={"var(--primary-color)"}
                width={40}
                height={40}
              />
            )
          }
          num={null}
        />
      )}
      <SopervisorAdditionGradesDetailsTriningProvider
        propsDataStudent={
          myDataStudentInsideCompany.data &&
          myDataStudentInsideCompany.data[idParams]
        }
      />
    </div>
  );
};

export default CompanyAdditionGradesDetailsContainer;
