import React from "react";
// import SidebarPage from "../../sideBar/SidebarPage";
// import HeaderStudent from "../../../components/student/HeaderStudent";
import TimeLine from "../../../components/duplicateComponents/TimeLine";
import JoinRequestCardContainer from "../../../components/student/joinRequest/JoinRequestCardContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import { useSelector } from "react-redux";
import CheckRouteWhereYotGo from "../../../components/duplicateComponents/CheckRouteWhereYotGo";

const StudentJoinRequestPage = () => {
  const countTimeLine = useSelector((state) => state.rootForms.countTimeLine);
  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* BreadCumb Router */}
        <Breadcrumbs
          // main={"المتدرب"}
          // mainPath={"/student/status"}
          element={["طلب انضمام للتدريب"]}
        />
        <div className="container p-3 p-md-5">
          {/* TimeLine */}
          <TimeLine count={countTimeLine} activeBool={[true]} />
          <JoinRequestCardContainer />
        </div>
      </div>
    </div>
  );
};

export default StudentJoinRequestPage;
