/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { arabicRegex, tokenCompanyUsername } from "../../../redux/types/Types";
import { useEffect, useState } from "react";
import { getCompanyByUsername } from "../../../redux/actions/company/ActionCompany";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { resetPasswordProfile } from "../../../redux/actions/auth/ActionAuth";
const CompanyProfileContainerLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getCompanyByUsername(tokenCompanyUsername));
  }, []);

  // api company
  const myDataCompany = useSelector((state) => state.rootCompany.company.data);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);

  const columnCompanyEng = [
    "company_name",
    "username",
    "department_name",
    "about",
    "address",
    "phone",
    "email",
    "Working_hours",
    "Zip_code",
    "email",
    "Fax",
  ];

  const columnCompanyAr = [
    "أسم الجهة",
    "رقم الجهة",
    "قسم الجهة",
    "نبذة عن الجهة",
    "العنوان",
    "رقم الهاتف",
    "البريد الكتروني",
    "ساعات العمل",
    "الرمز البريدي",
    "البريد الكتروني",
    "الفاكس",
  ];

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isShowMsgErrorPassword, setIsShowMsgErrorPassword] = useState(false);
  const [isShowMsgErrorNewPassword, setIsShowMsgErrorNewPassword] =
    useState(false);
  const [isShowMsgErrorPasswordLength, setIsShowMsgErrorPasswordLength] =
    useState(false);
  const [isShowMsgErrorNewPasswordLength, setIsShowMsgErrorNewPasswordLength] =
    useState(false);
  //   const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  // selector
  const responseResetPassword = useSelector(
    (state) => state.rootAuth.resetPasswordProfile
  );

  const handelPassword = (e) => {
    if (arabicRegex.test(e.target.value)) {
      return;
    }

    if (e.target.value.length > 0) {
      setIsShowMsgErrorPassword(false);
    } else {
      setIsShowMsgErrorPassword(true);
    }
    if (e.target.value.length >= 8) {
      setIsShowMsgErrorPasswordLength(false);
    } else {
      setIsShowMsgErrorPasswordLength(true);
    }
    setPassword(e.target.value);
  };

  const handelNewPassword = (e) => {
    if (arabicRegex.test(e.target.value)) {
      return;
    }

    if (e.target.value.length > 0) {
      setIsShowMsgErrorNewPassword(false);
    } else {
      setIsShowMsgErrorNewPassword(true);
    }
    if (e.target.value.length >= 8) {
      setIsShowMsgErrorNewPasswordLength(false);
    } else {
      setIsShowMsgErrorNewPasswordLength(true);
    }
    setNewPassword(e.target.value);
  };

  const submitResetPassword = async (e) => {
    e.preventDefault();

    if (password === "" && newPassword === "") {
      setIsShowMsgErrorNewPassword(true);
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }

    if (password === "") {
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }

    if (newPassword === "") {
      setIsShowMsgErrorNewPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }
    if (password.length < 8) {
      setIsShowMsgErrorPasswordLength(true);
      return;
    }

    if (newPassword.length < 8) {
      setIsShowMsgErrorNewPasswordLength(true);
      return;
    }
    setLoad(true);
    // this is show btn load if click btn wating give me data from API
    setLoading(true);
    await myDispatch(
      resetPasswordProfile({
        username: tokenCompanyUsername,
        password: password,
        new_password: newPassword,
      })
    );
    setLoad(false);
    setPassword("");
    setNewPassword("");
  };
  useEffect(() => {
    if (load === false) {
      if (responseResetPassword) {
        // console.log(responseResetPassword);
      }
      if (responseResetPassword.status !== 200) {
        NotificationsToast("error", " هناك مشكلة في تغيير كلمة المرور ");
        setLoading(false);
        return;
      }
      if (responseResetPassword.status === 200) {
        if (responseResetPassword.data === "done") {
          NotificationsToast("done", "تم تغيير كلمة المرور");
          setLoading(false);
        } else if (responseResetPassword.data === "nothing here") {
          NotificationsToast("warn", "أسم المستخدم غير موجود");
          setLoading(false);
        } else if (responseResetPassword.data === "password wrong") {
          NotificationsToast("warn", "كلمة المرور القديمة غير صحيحة");
          setLoading(false);
        }
      }
    }
  }, [load]);

  return [
    myDataCompany,
    loadingCompany,
    columnCompanyAr,
    columnCompanyEng,
    password,
    handelPassword,
    submitResetPassword,
    handelNewPassword,
    newPassword,
    loading,
    isShowMsgErrorPassword,
    isShowMsgErrorNewPassword,
    isShowMsgErrorPasswordLength,
    isShowMsgErrorNewPasswordLength,
  ];
};

export default CompanyProfileContainerLogic;
