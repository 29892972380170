import { combineReducers } from "redux";
import reducerStudent from "../reducers/student/ReducerStudent";
import reducerForms from "../reducers/forms/ReducerForms";
import reducerSopervisor from "../reducers/sopervisor/ReducerSopervisor";
import reducerCompany from "../reducers/company/ReducerCompany";
import reducerTasks from "../reducers/tasks/ReducerTasks";
import reducerSkills from "../reducers/skills/ReducerSkills";
import reducerGrades from "../reducers/grades/ReducerGrades";
import reducerSubmissions from "../reducers/submissions/ReducerSubmissions";
import reducerAuth from "../reducers/auth/AuthReducer";
import reducerExecl from "../reducers/excel/ReducerExcel";
import reducerSubmissionsDec from "../reducers/submissions/ReducerSubDec";
import reducerVisit from "../reducers/visit/ReducerVisit";
import reducerSubmissionsApp from "../reducers/submissions/ReducerSubmissionsApp";
import reducerSubmissionsLastApp from "../reducers/submissions/ReducerSubmissionsLastApp";
import reducerInsert40Grades from "../reducers/grades/ReducerInsert40Grades";
import reducerInsert30Grades from "../reducers/grades/ReducerInsert30Grades";
import reducerInsideCompany from "../reducers/company/ReducerInsideCompany";
import reducerVisitTotal from "../reducers/visit/ReducerVisitTotal";
import reducerReviews from "../reducers/reviews/ReducerReviews";
import reducerSubmissionsFirstDrop from "../reducers/submissions/ReducerSubFirstDrop";
import reducerGradesTotal from "../reducers/grades/ReducerGradeTotal";
import reducerSubmissionsLastDrop from "../reducers/submissions/ReducerSubLastDrop";
import reducerGetReviewsStudent from "../reducers/reviews/ReducerGetReviewsStudent";
import reducerGetReviewsCompany from "../reducers/reviews/ReducerGetReviewsCompany";
import reducerFormsPdf from "../reducers/forms/ReducerFormsPdf";
import reducerRelation from "../reducers/relation/ReducerRelation";
import reducerAllCompany from "../reducers/company/ReducerAllCompany";
import reducerSubmissionsApproved from "../reducers/submissions/ReducerSubApproved";
import reducerSubmissionsApproved2 from "../reducers/submissions/ReducerSubApproved2";
import reducerCollage from "../reducers/collage/ReducerCollage";
import reducerStudentWithoutPagination from "../reducers/student/ReducerStudentWithoutPagination";
import reducerInsideSopervisor from "../reducers/sopervisor/ReducerInsideSopervisor";
import reducerInsideSopervisor2 from "../reducers/sopervisor/ReducerInsideSopervisorWithputPagination";
import reducerMajor from "../reducers/major/ReducerMajor";
import reducerDepartment from "../reducers/department/ReducerDepartment";
import reducerClear from "../reducers/clearUsers/ReducerClear";
import reducerDeleteStudent from "../reducers/delete/ReducerDeleteStudent";
import reducerDeleteCollage from "../reducers/delete/ReducerDeleteCollage";
import reducerDeleteDepartment from "../reducers/delete/ReducerDeleteDepartment";
import reducerDeleteMajor from "../reducers/delete/ReducerDeleteMajor";
import reducerDelCompany from "../reducers/delete/ReducerDeleteCompany";
import reducerDelSopervisor from "../reducers/delete/ReducerDeleteSopervisor";

export const rootReducer = combineReducers({
  rootStudent: reducerStudent,
  rootSopervisor: reducerSopervisor,
  rootInsideSopervisor: reducerInsideSopervisor,
  rootInsideSopervisor2: reducerInsideSopervisor2,
  rootCompany: reducerCompany,
  rootAllCompany: reducerAllCompany,
  rootTasks: reducerTasks,
  rootSkills: reducerSkills,
  rootGrades: reducerGrades,
  rootSubmissitions: reducerSubmissions,
  rootForms: reducerForms,
  rootAuth: reducerAuth,
  rootExcel: reducerExecl,
  rootSubDec: reducerSubmissionsDec,
  rootSubApp: reducerSubmissionsApp,
  rootSubLastApp: reducerSubmissionsLastApp,
  rootVisit: reducerVisit,
  rootInsert40Grades: reducerInsert40Grades,
  rootInsert30Grades: reducerInsert30Grades,
  rootInsideCompany: reducerInsideCompany,
  rootVisitTotal: reducerVisitTotal,
  rootReviews: reducerReviews,
  rootSubFirstDrop: reducerSubmissionsFirstDrop,
  rootSubLastDrop: reducerSubmissionsLastDrop,
  rootInsertGradesTotal: reducerGradesTotal,
  rootReviewsStudent: reducerGetReviewsStudent,
  rootReviewsCompany: reducerGetReviewsCompany,
  rootFormsPdf: reducerFormsPdf,
  rootRelation: reducerRelation,
  rootSubmissitionApproved: reducerSubmissionsApproved,
  rootSubmissitionApproved2: reducerSubmissionsApproved2,
  rootCollage: reducerCollage,
  rootStudentWithoutPagination: reducerStudentWithoutPagination,
  rootMajor: reducerMajor,
  rootDepartment: reducerDepartment,
  rootClear: reducerClear,
  rootDelStudent: reducerDeleteStudent,
  rootDelSopervisor: reducerDelSopervisor,
  rootDelCompany: reducerDelCompany,
  rootDelCollage: reducerDeleteCollage,
  rootDelDepartment: reducerDeleteDepartment,
  rootDelMajor: reducerDeleteMajor,
});
