// import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  FORMS_PDF_CERF,
  FORMS_PDF_DIRECT_TRINING,
  FORMS_PDF_ENROLL,
  FORMS_PDF_FORMSGUIDANCE,
  FORMS_PDF_REQUEST_EXCEPTION,
  FORMS_PDF_REQUEST_TRINING,
  FORMS_PDF_TRINING_AVALIBAL,
  FORMS_PDF_TRINING_PROCESS,
  GET_ERROR,
} from "../../types/Types";

const insertFormsPdfDirectTrining = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formDirect`, dataParams);
    dispatch({
      type: FORMS_PDF_DIRECT_TRINING,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertFormsPdfFormsGuidance = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formsGuidance`, dataParams);
    dispatch({
      type: FORMS_PDF_FORMSGUIDANCE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertFormsPdfEnroll = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formsEn`, dataParams);
    dispatch({
      type: FORMS_PDF_ENROLL,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertFormsPdfRequestException = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formRequestException`, dataParams);
    dispatch({
      type: FORMS_PDF_REQUEST_EXCEPTION,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertFormsPdfRequestTrining = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`FormRequestTraining`, dataParams);
    dispatch({
      type: FORMS_PDF_REQUEST_TRINING,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertFormsPdfCerf = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`cerf_form`, dataParams);
    dispatch({
      type: FORMS_PDF_CERF,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertFormsPdfTriningProcess = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formsTriningProcess`, dataParams);
    dispatch({
      type: FORMS_PDF_TRINING_PROCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertFormsPdfTriningAvalible = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`formsTriningProcess`, dataParams);
    dispatch({
      type: FORMS_PDF_TRINING_AVALIBAL,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export {
  insertFormsPdfDirectTrining,
  insertFormsPdfFormsGuidance,
  insertFormsPdfEnroll,
  insertFormsPdfRequestException,
  insertFormsPdfRequestTrining,
  insertFormsPdfCerf,
  insertFormsPdfTriningProcess,
  insertFormsPdfTriningAvalible,
};
