/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsToast } from "../../components/duplicateComponents/NotificationsToast";
import { resetPasswordLogin } from "../../redux/actions/auth/ActionAuth";
import { arabicRegex } from "../../redux/types/Types";

const ResetPasswordLogic = () => {
  const myDispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShowMsgErrorUsrename, setIsShowMsgErrorUsrename] = useState(false);
  const [isShowMsgErrorPassword, setIsShowMsgErrorPassword] = useState(false);
  const [isShowMsgErrorPasswordLength, setIsShowMsgErrorPasswordLength] =
    useState(false);

  //   const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  // selector
  const responseResetPassword = useSelector(
    (state) => state.rootAuth.resetPasswordLogin
  );

  const handelUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        if (split.length > 0) {
          setIsShowMsgErrorUsrename(false);
        } else {
          setIsShowMsgErrorUsrename(true);
        }
        setUsername(split.join(""));
      }
    }
  };

  const handelPassword = (e) => {
    if (arabicRegex.test(e.target.value)) {
      return;
    }

    if (e.target.value.length > 0) {
      setIsShowMsgErrorPassword(false);
    } else {
      setIsShowMsgErrorPassword(true);
    }
    if (e.target.value.length >= 8) {
      setIsShowMsgErrorPasswordLength(false);
    } else {
      setIsShowMsgErrorPasswordLength(true);
    }
    setPassword(e.target.value);
  };

  const submitResetPassword = async (e) => {
    e.preventDefault();

    if (username === "" && password === "") {
      setIsShowMsgErrorUsrename(true);
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }
    if (username === "") {
      setIsShowMsgErrorUsrename(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }

    if (password === "") {
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }

    if (password.length < 8) {
      setIsShowMsgErrorPasswordLength(true);
      return;
    }

    setLoad(true);
    // this is show btn load if click btn wating give me data from API
    setLoading(true);
    await myDispatch(
      resetPasswordLogin({
        username: username,
        new_password: password,
      })
    );
    setLoad(false);
    setUsername("");
    setPassword("");
  };

  useEffect(() => {
    if (load === false) {
      if (responseResetPassword) {
        // console.log(responseResetPassword);
      }
      if (responseResetPassword.status !== 200) {
        NotificationsToast("error", " هناك مشكلة في تغيير كلمة المرور ");
        setLoading(false);
        return;
      }
      if (responseResetPassword.status === 200) {
        if (responseResetPassword.data === "done") {
          NotificationsToast("done", "تم تغيير كلمة المرور");
          setLoading(false);
        } else if (responseResetPassword.data === "nothing here") {
          NotificationsToast("warn", "أسم المستخدم غير موجود");
          setLoading(false);
        }
      }
    }
  }, [load]);

  return [
    username,
    password,
    handelUsername,
    handelPassword,
    submitResetPassword,
    loading,
    isShowMsgErrorUsrename,
    isShowMsgErrorPassword,
    isShowMsgErrorPasswordLength,
  ];
};

export default ResetPasswordLogic;
