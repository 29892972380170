import React from "react";
import Breadcrumbs from "../../../../components/duplicateComponents/Breadcrumbs";
import AdminCollageIndexContainer from "../../../../components/admin/users/collage/AdminCollageIndexContainer";

const AdminCollageIndexPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["متابعة الكليات"]} />
        <div className="container p-3 p-md-5">
          <AdminCollageIndexContainer />
        </div>
      </div>
    </div>
  );
};

export default AdminCollageIndexPage;
