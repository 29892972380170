/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllDepartment } from "../../../redux/actions/department/ActionDepartment";
import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import AdminDeleteDepartmentLogic from "../deleteUsers/AdminDeleteDepartmentLogic";

const AdminDepartmentFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  // api Department
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department
  );
  const myDataDepartmentTotal = useSelector(
    (state) => state.rootDepartment.department.total
  );
  const loadingDepartment = useSelector(
    (state) => state.rootDepartment.loading
  );

  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [submitDeleteDepartment, isLoadingDeleteDepartment] =
    AdminDeleteDepartmentLogic();
  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم القسم",
      dataIndex: "department_name",
      key: "department_name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.department_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },

    {
      title: "أسم الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: null,
    },

    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitDeleteDepartment(e, record.department_name);
                  }}
                />
              </span>
              {/* <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  // to={`/admin/projects/edit/${0}`}
                  // onclick={() => console.log("first")}
                />
              </span> */}
            </>
          </div>
        );
      },
    },
  ];

  return [
    myDataDepartment,
    loadingDepartment,
    myDataDepartmentTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteDepartment,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminDepartmentFollowUpLogic;
