import React from "react";
import TimeLineWithTitleAndSubTitle from "./TimeLineWithTitleAndSubTitle";
import TimeLineBoxCardDarkGreen from "./TimeLineBoxCardDarkGreen";
import Positioned from "./Positioned";

const PollCardHeader = ({ titleHeader, items = [] }) => {
  return (
    <div className="">
      <div className=" position-relative">
        <Positioned
          title={"ملاحضة"}
          bgColor={"var(--primary-color-hover)"}
          color={"#fff"}
          top={"-20px"}
          right={"35px"}
          z={1}
        />
        <TimeLineBoxCardDarkGreen
          titleHeader={titleHeader}
          item={
            <div>
              {items.map((item, i) => (
                <TimeLineWithTitleAndSubTitle
                  key={i}
                  title={item.title}
                  subTitle={item.subTitle}
                  whatColor={"lightGreen"}
                />
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PollCardHeader;
