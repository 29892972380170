/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStudent,
  // getAllStudentPage,
} from "../../../redux/actions/student/ActionStudent";
import AdminDeleteStudentLogic from "../deleteUsers/AdminDeleteStudentLogic";
import Badge from "../../../components/duplicateComponents/Badge";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import { DeleteFilled, EditFilled, EyeFilled } from "@ant-design/icons";
import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
const AdminStudentFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudent());
  }, []);

  // api sutudent
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const myDataStudentTotal = useSelector(
    (state) => state.rootStudent.student.total
  );
  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  const [page, setPage] = useState(1);
  const [submitDeleteStudent, isLoadingDeleteStudent] =
    AdminDeleteStudentLogic();

  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "حالة المتدرب في الجهة",
      dataIndex: "username",
      key: "username",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex">
            <Badge
              title={record.username == null ? "غير مسجل" : "مسجل"}
              bgColor={
                record.username == null
                  ? "var(--primary-color)"
                  : "var(--green-opacity-color)"
              }
              color={record.username == null ? "#fff" : "var(--green-color)"}
            />
          </div>
        );
      },
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  // isloading={isLoading}
                  onclick={(e) => {
                    console.log(record.student_id);
                    submitDeleteStudent(e, record.student_id);
                  }}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  to={`/admin/users-student/edit/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EyeFilled />}
                  colorHover="#fff"
                  width={40}
                  to={`/admin/users-student/view/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];

  return [
    myDataStudent,
    loadingStudent,
    myDataStudentTotal,
    page,
    setPage,
    isLoadingDeleteStudent,
    setSearchText,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminStudentFollowUpLogic;
