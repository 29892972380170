import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  GET_ERROR,
  INSERT_RELATION_STUDENT_WITH_SOPERVIOSR,
} from "../../types/Types";

const insertRelationStudentWithSopervisor =
  (dataParams) => async (dispatch) => {
    try {
      const response = await UseCreateData("attach_super", dataParams);
      dispatch({
        type: INSERT_RELATION_STUDENT_WITH_SOPERVIOSR,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };
export { insertRelationStudentWithSopervisor };
