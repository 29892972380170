/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSkills,
  insertAttachSkills,
  insertNewSkill,
} from "../../../../redux/actions/skills/ActionSkills";
import { getStudentInsideCompanyWithoutPagination } from "../../../../redux/actions/company/ActionCompany";
import { role, tokenCompanyUsername } from "../../../../redux/types/Types";
import { NotificationsToast } from "../../../../components/duplicateComponents/NotificationsToast";

const CompanyAdditionSkillsLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllSkills());
    myDispatch(getStudentInsideCompanyWithoutPagination(tokenCompanyUsername));
  }, []);

  // api student inside company
  const myDataStudentInsideCompany = useSelector(
    (state) => state.rootCompany.companyInsideWithoutPagination
  );
  //   const loadingStudentInsideCompany = useSelector(
  //     (state) => state.rootCompany.loadingInsideWithoutPagination
  //   );

  // api skills
  const myDataSkills = useSelector((state) => state.rootSkills.skills);
  const loadingSkills = useSelector((state) => state.rootSkills.loading);
  const responseSkills = useSelector((state) => state.rootSkills.insertSkills);
  const responseAttachSkills = useSelector(
    (state) => state.rootSkills.insertAttachSkills
  );

  // // api sutudent
  // const myDataStudent = useSelector((state) => state.rootStudent.student.data);
  // const loadingStudent = useSelector((state) => state.rootStudent.loading);

  const [skillname, setSkillname] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [arrChecked, setArrChecked] = useState([]);
  const [arrCheckedSkills, setArrCheckedSkills] = useState([]);
  const [arrCheckedBool, setArrCheckedBool] = useState(false);
  const [arrSelectedMuiltySkills, setArrSelectedMuiltySkills] = useState([]);
  const [isLoadAttach, setIsLoadAttach] = useState(true);
  const [isLoadingAttach, setIsLoadingAttach] = useState(false);
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  const onSelect = (selectedList, selectedItem) => {
    // let newArr = selectedList.map((val) => val.skill_id);
    // setArrSelectedMuiltySkills(selectedList);
    setArrSelectedMuiltySkills([
      ...arrSelectedMuiltySkills,
      selectedItem.skill_id,
    ]);
  };
  const onRemove = (selectedList, selectedItem) => {
    let newArr = selectedList.filter((val) => val !== val.skill_id);
    let newArr2 = newArr.map((val) => val.skill_id);
    setArrSelectedMuiltySkills(newArr2);
  };

  const handelSkillname = (e) => {
    if (e.target.value.length > 0) {
      setIsShowMsgError(false);
    } else {
      setIsShowMsgError(true);
    }
    setSkillname(e.target.value);
  };

  const addNewSkill = async (e) => {
    e.preventDefault();
    if (skillname === "") {
      setIsShowMsgError(true);
      NotificationsToast("warn", null, "الحقل إضافة المهارة مطلوب");
      return;
    }
    setSkillname("");
    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      insertNewSkill({
        skill_type: skillname,
        type: role,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseSkills.status !== 200) {
        NotificationsToast("error", null, "هناك مشكلة في إضافة المهارة");
        setIsLoading(false);
        return;
      }
      if (responseSkills.status === 200) {
        if (responseSkills.data === "done") {
          NotificationsToast("done", null, "تم إاضافة المهارة بنجاح");
          myDispatch(getAllSkills());
          setIsLoading(false);
        }
      }
      myDispatch(getAllSkills());
    }
    setIsLoad(true);
  }, [isLoad]);

  const addSubmitSkills = async (e) => {
    e.preventDefault();
    if (arrSelectedMuiltySkills.length === 0) {
      NotificationsToast("warn", null, "يجب تحديد المهارات");
      return;
    }

    if (arrChecked.length === 0) {
      NotificationsToast("warn", null, "يجب إختيار المتدربين");
      return;
    }

    setIsLoadAttach(true);
    setIsLoadingAttach(true);
    await myDispatch(
      insertAttachSkills({
        skills: arrSelectedMuiltySkills,
        students: arrChecked,
      })
    );
    setIsLoadAttach(false);
  };

  useEffect(() => {
    if (isLoadAttach === false) {
      myDispatch(getAllSkills());

      if (responseAttachSkills.status === 200) {
        if (responseAttachSkills.data === "done") {
          NotificationsToast("done", null, "تم إاضافة المهارة للمتدرب");
          setIsLoadingAttach(false);
          return;
        } else if (responseAttachSkills.data === "empty") {
          NotificationsToast("warn", null, "يجب تحديد المهارات");
          setIsLoadingAttach(false);
          return;
        } else {
          if (responseAttachSkills.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في الاضافة");
            setIsLoadingAttach(false);
            return;
          }
        }
      }
      setIsLoadAttach(true);
    }
  }, [isLoadAttach]);

  return [
    handelSkillname,
    isLoading,
    addNewSkill,
    myDataSkills,
    loadingSkills,
    myDataStudentInsideCompany,
    onSelect,
    onRemove,
    setArrChecked,
    setArrCheckedBool,
    arrChecked,
    addSubmitSkills,
    setArrSelectedMuiltySkills,
    isLoadingAttach,
    isShowMsgError,
  ];
};

export default CompanyAdditionSkillsLogic;
