import React from "react";
import Taps from "../../duplicateComponents/Taps";
import CompanyOrderApprovedOrDeclined from "./CompanyOrderApprovedOrDeclined";
import CompanyOrderStatus from "./CompanyOrderStatus";

const CompanyOrderContainer = () => {
  return (
    <Taps
      title={["طلبات الجهة", "حالة الطلبات"]}
      charIdAndTarget={["a", "b"]}
      titleItem={[
        <CompanyOrderApprovedOrDeclined />,
         <CompanyOrderStatus />
      ]}
    />
  );
};

export default CompanyOrderContainer;
