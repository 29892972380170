// import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  EDIT_ALL_STUDENT,
  GET_ALL_STUDENT,
  GET_ALL_STUDENT_PAGE,
  GET_ALL_STUDENT_WITHOUT_PAGINATION,
  GET_ALL_STUDENT_WITHOUT_SOPERVISOR,
  GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE,
  GET_ERROR,
  GET_STUDENT_BY_USERNAME,
  INSERT_ALL_STUDENT,
  // INSERT_ALL_STUDENT,
} from "../../types/Types";

const getAllStudent = () => async (dispatch) => {
  try {
    const response = await UseGetData("get_students");
    dispatch({
      type: GET_ALL_STUDENT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllStudentWithoutSopervisor = () => async (dispatch) => {
  try {
    const response = await UseGetData("select_students");
    dispatch({
      type: GET_ALL_STUDENT_WITHOUT_SOPERVISOR,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllStudentWithoutSopervisorPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`select_students?page=${dataParams}`);
    dispatch({
      type: GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getStudentByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_student/${dataParams}`);
    dispatch({
      type: GET_STUDENT_BY_USERNAME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("ins_students", dataParams);
    dispatch({
      type: INSERT_ALL_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const editStudent = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("update_students", dataParams);
    dispatch({
      type: EDIT_ALL_STUDENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllStudentPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(
      `get_students?page=${dataParams}&limit=10`
    );

    dispatch({
      type: GET_ALL_STUDENT_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllStudentWithoutPagination = () => async (dispatch) => {
  try {
    const response = await UseGetData("students_np");
    dispatch({
      type: GET_ALL_STUDENT_WITHOUT_PAGINATION,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export {
  getAllStudent,
  getAllStudentWithoutSopervisor,
  getStudentByUsername,
  insertStudent,
  getAllStudentPage,
  getAllStudentWithoutSopervisorPage,
  getAllStudentWithoutPagination,
  editStudent,
};
