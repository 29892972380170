import { UseCreateData } from "../../../hooks/UseCreateData";
import { DELETE_SOPERVISOR, GET_ERROR } from "../../types/Types";

const deleteSopervisor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("delete_emp", dataParams);

    dispatch({
      type: DELETE_SOPERVISOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { deleteSopervisor };
