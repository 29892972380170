/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ButtonLoading from "./ButtonLoading";
import ButtonLoadingFill from "./ButtonLoadingFill";
const ButtonRoundedFill = ({
  title,
  onclick,
  onchange,
  value,
  dataBsToggle,
  dataBsTarget,
  dataBsDismiss,
  width,
  // bgColor = themeStorage == "dark" ? "var(--green)" : "var(--primary-color)",
  target,
  bgColor = "var(--primary-color)",
  bgHover = "var(--primary-color)",
  color = "var(--primary-color)",
  colorHover = "#fff",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={{
        background: isHovered ? bgHover : "#fff",
        width: width,
        border: `3px solid ${isHovered ? bgHover : bgColor}`,
        color: isHovered ? colorHover : color,
        padding: "5px 0",
        borderRadius: "50px",
        transition: "var(--timeing)",
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: 16,
      }}
      // className="hover-btn-pill"
      onClick={onclick}
      onChange={onchange}
      value={value}
      data-bs-toggle={dataBsToggle}
      data-bs-target={dataBsTarget}
      data-bs-dismiss={dataBsDismiss}
      formTarget={target}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {title}
    </button>
  );
};

export default ButtonRoundedFill;
