/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubmissitionCompanyByCompanyName012 } from "../../../redux/actions/submissition/ActionSubmissitions";

const CompanySidebarLogic = () => {
  const myDispatch = useDispatch();
  // useEffect(() => {
  //   // if (role === "emp") {
  //   myDispatch(getSubmissitionByApproved("2"));
  //   // } else if (role === "company") {
  //   myDispatch(
  //     getSubmissitionCompanyByCompanyName(
  //       JSON.parse(localStorage.getItem("storageDataObj")).company_name
  //     )
  //   );
  //   myDispatch(getSubmissitionByApproved2("4"));
  //   // }
  // }, []);

  // // api submisstion
  // const myDataSubmissitionApp = useSelector(
  //   (state) => state.rootSubmissitionApproved.submissitionApproved.data
  // );
  // const myDataSubmissitionAppTotal = useSelector(
  //   (state) => state.rootSubmissitionApproved.submissitionApproved.total
  // );
  // // api submisstion
  // const myDataSubmissitionCompany = useSelector(
  //   (state) => state.rootSubmissitions.submissition[0]
  // );

  // const myDataSubmissitionFirstDrop = useSelector(
  //   (state) => state.rootSubmissitionApproved2.submissitionApproved2.data
  // );
  // const myDataSubmissitionFirstDropTotal = useSelector(
  //   (state) => state.rootSubmissitionApproved2.submissitionApproved2.total
  // );
  // console.log(myDataSubmissitionCompany && myDataSubmissitionCompany);
  // console.log(myDataSubmissitionApp && myDataSubmissitionApp);
  // console.log(myDataSubmissitionAppTotal);
  // console.log(myDataSubmissitionFirstDrop && myDataSubmissitionFirstDrop);
  // console.log(myDataSubmissitionFirstDropTotal);

  // get submisitions if appropved === 0,1,2
  // let lengthSub0and1and2;
  // if (myDataSubmissitionCompany) {
  //   // console.log(myDataSubmissitionCompany);
  //   if (myDataSubmissitionCompany === "s") {
  //     console.log("");
  //   } else {
  //     // get submisitions if appropved === 0,1,2
  //     lengthSub0and1and2 =
  //       myDataSubmissitionCompany &&
  //       myDataSubmissitionCompany.data.filter((item) => {
  //         return (
  //           item.approved === "0" ||
  //           item.approved === "1" ||
  //           item.approved === "2"
  //         );
  //       });
  //   }
  // }

  // console.log(myDataSubmissitionCompany && myDataSubmissitionCompany);

  // console.log(lengthSub0and1and2);

  useEffect(() => {
    myDispatch(
      getSubmissitionCompanyByCompanyName012(
        JSON.parse(localStorage.getItem("storageDataObj")).company_name
      )
    );
  }, []);
  // api submisstion
  const myDataSubmissitionCompany = useSelector(
    (state) => state.rootSubmissitions.submissitionCompany012
  );

  const loadingSubmissitionCompany = useSelector(
    (state) => state.rootSubmissitions.loadingSubmissitionCompany012
  );

  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);
  return [loadingSubmissitionCompany, myDataSubmissitionCompany];
};

export default CompanySidebarLogic;
