/* eslint-disable no-unused-vars */

// this is url my demo in website api/(apiName)
// export const URL = "https://demo.daoobapi.cti.college/api/";

///////////////////////////////////////////////////////////////////
// export const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const arabicRegex =
  /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
//## Start all types
export const GET_ERROR = "GET_ERROR";
export const GET_FORMS = "GET_FORMS";
export let tokenStudentUsername = "";
export let role = "";
export let tokenSopervisorUsername = "";
export let tokenCompanyUsername = "";

if (localStorage.getItem("storageDataObj")) {
  tokenStudentUsername = JSON.parse(
    localStorage.getItem("storageDataObj")
  ).student_id;

  // if show the code if fidnsh project daoop
  // ##01##
  tokenSopervisorUsername = JSON.parse(
    localStorage.getItem("storageDataObj")
  ).username_emp;
  // ##02##
  tokenCompanyUsername = JSON.parse(
    localStorage.getItem("storageDataObj")
  ).username;
}
if (localStorage.getItem("role")) {
  role = localStorage.getItem("role");
}

// console.log("com : " + tokenCompanyUsername);
// console.log("sop : " + tokenSopervisorUsername);
// console.log("stu : " + tokenStudentUsername);

///////////
export let countTimeLine = 0;
//## End all vars
///////////////////////////////////////////////////////////////////
//## Start Student
export const GET_ALL_STUDENT = "GET_ALL_STUDENT";
export const GET_ALL_STUDENT_WITHOUT_PAGINATION =
  "GET_ALL_STUDENT_WITHOUT_PAGINATION";
export const GET_ALL_STUDENT_WITHOUT_SOPERVISOR =
  "GET_ALL_STUDENT_WITHOUT_SOPERVISOR";
export const GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE =
  "GET_ALL_STUDENT_WITHOUT_SOPERVISOR_PAGE";
export const INSERT_ALL_STUDENT = "INSERT_ALL_STUDENT";
export const EDIT_ALL_STUDENT = "EDIT_ALL_STUDENT";
export const GET_ALL_STUDENT_PAGE = "GET_ALL_STUDENT_PAGE";
export const GET_STUDENT_BY_USERNAME = "GET_STUDENT_BY_USERNAME";
//## End Student
///////////////////////////////////////////////////////////////////
//## Start Sopervisor
export const GET_ALL_SOPERVISOR = "GET_ALL_SOPERVISOR";
export const INSERT_SOPERVISOR = "INSERT_SOPERVISOR";
export const EDIT_SOPERVISOR = "EDIT_SOPERVISOR";
export const GET_ALL_SOPERVISOR_PAGE = "GET_ALL_SOPERVISOR_PAGE";
export const GET_SOPERVISOR_BY_USERNAME = "GET_SOPERVISOR_BY_USERNAME";
export const GET_STUDENT_INSIDE_SOPERVISOR = "GET_STUDENT_INSIDE_SOPERVISOR";
export const GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION =
  "GET_STUDENT_INSIDE_SOPERVISOR_WITHOUT_PAGINATION";
export const GET_STUDENT_INSIDE_SOPERVISOR_PAGE =
  "GET_STUDENT_INSIDE_SOPERVISOR_PAGE";

//## End Sopervisor
///////////////////////////////////////////////////////////////////
//## Start Company
export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ALL_COMPANY_PAGE = "GET_ALL_COMPANY_PAGE";
export const INSERT_NEW_COMPANY = "INSERT_NEW_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const GET_COMPANY_BY_USERNAME = "GET_COMPANY_BY_USERNAME";
export const GET_STUDENT_INSIDE_COMPANY = "GET_STUDENT_INSIDE_COMPANY";
export const GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN =
  "GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN";
export const GET_STUDENT_INSIDE_COMPANY_PAGE =
  "GET_STUDENT_INSIDE_COMPANY_PAGE";
//## End Company
///////////////////////////////////////////////////////////////////
//## Start Tasks
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_ALL_TASKS_PAGE = "GET_ALL_TASKS_PAGE";
export const GET_TASKS_BY_USERNAME = "GET_TASKS_BY_USERNAME";
export const INSERT_ATTACH_TASKS = "INSERT_ATTACH_TASKS";

//## End Tasks
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//## Start Skills
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const GET_ALL_SKILLS_PAGE = "GET_ALL_SKILLS_PAGE";
export const GET_SKILLS_BY_USERNAME = "GET_SKILLS_BY_USERNAME";
export const INSERT_NEW_SKILLS = "INSERT_NEW_SKILLS";
export const INSERT_ATTACH_SKILLS = "INSERT_ATTACH_SKILLS";

//## End Skills
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//## Start Grades
export const GET_ALL_GRADES = "GET_ALL_GRADES";
export const GET_GRADES_BY_USERNAME = "GET_GRADES_BY_USERNAME";
export const ISNERT_40_GRADES = "ISNERT_40_GRADES";
export const ISNERT_30_GRADES = "ISNERT_30_GRADES";
export const ISNERT_GRADE_TOTAL = "ISNERT_GRADE_TOTAL";
//## End Grades
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//## Start Submissitions
export const GET_SUBMISSITIONS_BY_USERNAME = "GET_SUBMISSITIONS_BY_USERNAME";
export const GET_SUBMISSITIONS_BY_APPROVED = "GET_SUBMISSITIONS_BY_APPROVED";
export const GET_SUBMISSITIONS_BY_APPROVED_PAGE =
  "GET_SUBMISSITIONS_BY_APPROVED_PAGE";
export const GET_SUBMISSITIONS_BY_APPROVED2 = "GET_SUBMISSITIONS_BY_APPROVED2";
export const GET_SUBMISSITIONS_BY_APPROVED2_PAGE =
  "GET_SUBMISSITIONS_BY_APPROVED2_PAGE";
export const GET_SUBMISSITIONS_ALL = "GET_SUBMISSITIONS_ALL";
export const GET_SUBMISSITIONS_ALL_PAGE = "GET_SUBMISSITIONS_ALL_PAGE";
export const GET_SUBMISSITIONS_BY_USERNAME_COMPANY =
  "GET_SUBMISSITIONS_BY_USERNAME_COMPANY";
export const GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE =
  "GET_SUBMISSITIONS_BY_USERNAME_COMPANY_PAGE";
export const GET_SUBMISSITIONS_BY_USERNAME_COMPANY012 =
  "GET_SUBMISSITIONS_BY_USERNAME_COMPANY012";
export const GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE =
  "GET_SUBMISSITIONS_BY_USERNAME_COMPANY012_PAGE";
export const ADD_SUBMISSITIONS = "ADD_SUBMISSITIONS";
export const ADD_SUBMISSITIONS_DEC = "ADD_SUBMISSITIONS_DEC";
// last approved
export const ADD_SUBMISSITIONS_APPRO_LAST = "ADD_SUBMISSITIONS_APPRO_LAST";
// frist approved
export const ADD_SUBMISSITIONS_APPRO_FIRST = "ADD_SUBMISSITIONS_APPRO_FIRST";
export const GET_SUBMISSITIONS_APPRO_FIRST = "GET_SUBMISSITIONS_APPRO_FIRST";
// frist drop
export const INSERT_SUBMISSITIONS_FIRST_DROP =
  "INSERT_SUBMISSITIONS_FIRST_DROP";
export const GET_SUBMISSITIONS_FIRST_DROP = "GET_SUBMISSITIONS_FIRST_DROP";
// frist drop
export const INSERT_SUBMISSITIONS_LAST_DROP = "INSERT_SUBMISSITIONS_LAST_DROP";
export const GET_SUBMISSITIONS_LAST_DROP = "GET_SUBMISSITIONS_LAST_DROP";
// getSubmissitionByWaitAndFirstDeclinedAndFirstApproved
export const GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE =
  "GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED_PAGE";
export const GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED =
  "GET_SUBMISSITIONS_BY_WAIT_FIRST_DECLINDED_FIRST_APPROVED";

//## End Submissitions
///////////////////////////////////////////////////////////////////
//## Start Auth
export const AUTH_LOGIN = "AUTH_LOGIN";
export const RESET_PASSWORD_LOGIN = "RESET_PASSWORD_LOGIN";
export const RESET_PASSWORD_PROFILE = "RESET_PASSWORD_PROFILE";
export const AUTH_REGISTER = "AUTH_REGISTER";
//## End Auth
///////////////////////////////////////////////////////////////////
//## Start Excel
export const INSERT_EXCEL_STUDENT = "INSERT_EXCEL_STUDENT";
export const INSERT_EXCEL_SOPERVISOR = "INSERT_EXCEL_SOPERVISOR";
export const INSERT_EXCEL_COOP = "INSERT_EXCEL_COOP";
export const INSERT_EXCEL_COLLAGE = "INSERT_EXCEL_COLLAGE";
export const INSERT_EXCEL_COMPANY = "INSERT_EXCEL_COMPANY";
export const INSERT_EXCEL_MAJOR = "INSERT_EXCEL_MAJOR";
export const INSERT_EXCEL_DEPARTMENT = "INSERT_EXCEL_DEPARTMENT";
//## End Excel
///////////////////////////////////////////////////////////////////
//## Start Visit
export const INSERT_VISIT = "INSERT_VISIT";
export const GET_VISIT_NUMBER_BY_STUDENT_ID = "GET_VISIT_NUMBER_BY_STUDENT_ID";
export const INSERT_VISIT_TOTAL = "INSERT_VISIT_TOTAL";
//## End Visit
///////////////////////////////////////////////////////////////////
//## Start Visit
export const INSERT_REVIEWS_STUDENT = "INSERT_REVIEWS_STUDENT";
export const INSERT_REVIEWS_COMPANY = "INSERT_REVIEWS_COMPANY";
export const GET_ALL_REVIEWS_STUDENT = "GET_ALL_REVIEWS_STUDENT";
export const GET_ALL_REVIEWS_STUDENT_PAGE = "GET_ALL_REVIEWS_STUDENT_PAGE";
export const GET_ALL_REVIEWS_COMPANY = "GET_ALL_REVIEWS_COMPANY";
export const GET_ALL_REVIEWS_COMPANY_PAGE = "GET_ALL_REVIEWS_COMPANY_PAGE";
//## End Visit
///////////////////////////////////////////////////////////////////
//## Start Forms
export const FORMS_PDF_DIRECT_TRINING = "FORMS_PDF_DIRECT_TRINING";
export const FORMS_PDF_FORMSGUIDANCE = "FORMS_PDF_FORMSGUIDANCE";
export const FORMS_PDF_ENROLL = "FORMS_PDF_ENROLL";
export const FORMS_PDF_REQUEST_EXCEPTION = "FORMS_PDF_REQUEST_EXCEPTION";
export const FORMS_PDF_REQUEST_TRINING = "FORMS_PDF_REQUEST_TRINING";
export const FORMS_PDF_TRINING_PROCESS = "FORMS_PDF_TRINING_PROCESS";
export const FORMS_PDF_TRINING_AVALIBAL = "FORMS_PDF_TRINING_AVALIBAL";
export const FORMS_PDF_CERF = "FORMS_PDF_CERF";
//## End Forms
///////////////////////////////////////////////////////////////////
//## Start relation
export const INSERT_RELATION_STUDENT_WITH_SOPERVIOSR =
  "INSERT_RELATION_STUDENT_WITH_SOPERVIOSR";

//## End relation
///////////////////////////////////////////////////////////////////
//## Start collage
export const GET_ALL_COLLAGE = "GET_ALL_COLLAGE";
export const GET_ALL_COLLAGE_PAGE = "GET_ALL_COLLAGE_PAGE";
export const INSERT_COLLAGE = "INSERT_COLLAGE";

//## End collage
///////////////////////////////////////////////////////////////////
//## Start major
export const GET_ALL_MAJOR = "GET_ALL_MAJOR";
export const GET_ALL_MAJOR_PAGE = "GET_ALL_MAJOR_PAGE";
export const INSERT_MAJOR = "INSERT_MAJOR";
//## End major
///////////////////////////////////////////////////////////////////
//## Start Department
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_ALL_DEPARTMENT_PAGE = "GET_ALL_DEPARTMENT_PAGE";
export const INSERT_DEPARTMENT = "INSERT_DEPARTMENT";
//## End Department
///////////////////////////////////////////////////////////////////
//## Start Clear
export const INSERT_CLEAR_STUDENT = "INSERT_CLEAR_STUDENT";
export const INSERT_CLEAR_COMPANY = "INSERT_CLEAR_COMPANY";
export const INSERT_CLEAR_SOPERVISOR = "INSERT_CLEAR_SOPERVISOR";
export const INSERT_CLEAR_COLLAGE = "INSERT_CLEAR_COLLAGE";
export const INSERT_CLEAR_DEPARTMENT = "INSERT_CLEAR_DEPARTMENT";
export const INSERT_CLEAR_MAJOR = "INSERT_CLEAR_MAJOR";
//## End Clear
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//## Start Delete
export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_SOPERVISOR = "DELETE_SOPERVISOR";
export const DELETE_COLLAGE = "DELETE_COLLAGE";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_MAJOR = "DELETE_MAJOR";
// export const INSERT_CLEAR_COMPANY = "INSERT_CLEAR_COMPANY";
// export const INSERT_CLEAR_SOPERVISOR = "INSERT_CLEAR_SOPERVISOR";
// export const INSERT_CLEAR_COLLAGE = "INSERT_CLEAR_COLLAGE";
// export const INSERT_CLEAR_DEPARTMENT = "INSERT_CLEAR_DEPARTMENT";
// export const INSERT_CLEAR_MAJOR = "INSERT_CLEAR_MAJOR";
//## End Delete
//## Start forms (title,subTitle)
export const dataForms = {
  mainStudent: "المتدرب",
  mainSopervisor: "المشرف",
  Guidance: {
    title: "توجية المتدرب",
    subTitle: "يعبأ من مشرف التدريب التعاوني بالقسم",
  },
  DirectTrining: {
    title: "مباشرة التدريب",
    subTitle: "يعبأ من جهة التدريب",
  },
  RequestTrining: {
    title: "طلب فرص تدريب",
    subTitle:
      "نفيدكم علماً بأن المتدربة الموضحة بياناتها أعلاه هي إحدى متدربات الكلية التقنية للبنات بالرياض المتوقع التحاقهن ببرنامج التدريب التعاوني للفصل التدريبي الثاني من العام التدريبي 1440/1441هـ, وترغب في تطبيق البرنامج لديكم لمدة فصل تدريبي كامل ابتداءً من 8 / 6 / 1441هـ الموافق 2 / 2 / 2020 م  ولمدة 13 أسبوع (بمعدل لا يقل عن 390 ساعة ), فنرجو من سعادتكم منحها الفرصة و اعتماد ذلك بملء المعلومات أدناه بأنه سيتم تأكيد التدريب للفرص المتاحة بخطاب رسمي من الكلية التقنية للبنات بالرياض موجه لكم. شاكرين لكم حسن تعاونكم معنا,,,",
  },
  EnrollCoopTrining: {
    title: "التحاق بالتدريب التعاوني",
    subTitle:
      "تسلم رغبات التدريب في الوقت المحدد في الإعلان أو لن يقبل طلب المتدربةفي حال عدم تعبئة الرغبات بشكل صحيح ستحدد منسقة التدريب التعاوني جهة التدريب للمتدربة حسب الفرص المتاحة",
  },
  RequestException: {
    title: "طلب استثناء",
    subTitle: "هو من  تبقى له مقرر في التدريب التعاوني",
  },

  TriningProcess: {
    title: "سير العملية التدريبية",
    subTitle: "يعبأ النموذج لكل جهه على حده",
  },

  TriningAvalibleInCompany: {
    title: "الفرص التدريبية المتاحة في المنشأة",
    subTitle: "يعبأ من قبل المنشأة",
  },
  cerf: {
    title: "شهادة تدريب تعاوني",
    subTitle: "شهادة إتمام برنامج التدريب التعاوني",
  },
};

//## End forms (title,subTitle)

// URL Icons Lottile
// send
/////## https://lottie.host/68a06230-c1b0-48b4-81b8-9e00cc1f9bbe/kcsKOGDnTG.json
// star
/////##
