import React from "react";
import { Table, Slider } from "antd";
import PollCardHeader from "../../duplicateComponents/PollCardHeader";
import FollowUpPollCardStudentLogic from "../../../logic/student/poll/FollowUpPollCardStudentLogic";
import ButtonLoading from "../../duplicateComponents/ButtonLoading";
import Button from "../../duplicateComponents/Button";
import PopupAreYouSure from "../../duplicateComponents/PopupAreYouSure";
// import FormInputRadio from "../../duplicateComponents/FormInputRadio";
const { Column } = Table;
const StudentPollContainer = () => {
  const [myDataPollStudent, arrStorage, isLoading, submitPoll] =
    FollowUpPollCardStudentLogic();

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "البنود",
      dataIndex: "title",
      key: "title",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "التقييم من 1 الى 5",
      dataIndex: "review",
      key: "review",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="">
                {/* <FormInputRadio
                        name={`radioTriningProcess${1}`}
                        idAndFor={`radioTriningProcess${1}`}
                        value={1}
                        onChange={(e) => {
                          let val = e.target.value;
                          console.log(val);
                          arrStorage[1 - 1] = parseInt(val);
                        }}
                      /> */}
                <Slider
                  defaultValue={1}
                  style={{ width: 200, trackBg: "red" }}
                  min={1}
                  max={5}
                  handleStyle={{ accentColor: "red" }}
                  dotActiveBorderColor="red"
                  onChange={(e) => {
                    arrStorage[record.key] = parseInt(e);
                  }}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];
  return (
    <div className="">
      <PollCardHeader
        titleHeader={"استبانة استطلاع رأي المتدرب عن التدريب التعاوني"}
        items={[
          {
            title: "أخي المتدرب",
            subTitle:
              "أهلاً بك عزيزي المتدرب , مع شكرنا الجزيل لتعاونكم ونود معرفة وجهة نظركم عن التدريب العاوني , نأمل منكم تعاونكم معنا في إعطاء المعلومات الدقيقة حتى نتكمن من تحسين وتطوير الأداء في المستقبل مقدرين لكم جهدكم المبذولة .",
          },
          {
            title: "درجة التقييم",
            subTitle:
              "الدرجة من  خمسة ( 5 ) تعني أعلى تقدير والدرجة واحد ( 1 ) تعني أقل تقدير",
          },
        ]}
      />
      <div className="row mt-4">
        <div>
          <Table
            // pagination={{
            //   position: ["bottomCenter"],
            //   size: "small",
            //   style: { display: "flex", flexDirection: "row-reverse" },
            // }}
            pagination={false}
            size="middle"
            dataSource={myDataPollStudent}
            rowKey={"title"}
            className="style-scroll-radius style-shadow-large bg-white rounded-3"
          >
            {dataTableColumns.map((item, i) => (
              <Column
                title={item.title}
                dataIndex={item.dataIndex}
                key={item.key}
                filteredValue={item.filteredValue}
                onFilter={item.onFilter}
                render={item.render}
              />
            ))}
          </Table>
        </div>
      </div>

      <div className="p-2 mt-4 d-flex justify-content-center">
        {isLoading ? (
          <ButtonLoading title={"جاري التقييم"} width={"200px"} />
        ) : (
          <Button
            title={"إرسال"}
            type={"button"}
            width={"200px"}
            dataBsToggle={"modal"}
            dataBsTarget={"#exampleModalPollDone"}
          />
        )}
      </div>

      <PopupAreYouSure
        id={"exampleModalPollDone"}
        onCLick={submitPoll}
        idPopupStar={"exampleModalPollDone"}
        textAlign={"center"}
        title={"إرسال استطلاع رأيك عن التدريب التعاوني"}
      />
    </div>
  );
};

export default StudentPollContainer;
