/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../duplicateComponents/Button";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormsContainer from "./FormsContainer";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import { insertFormsPdfRequestTrining } from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";

const FormsRequestTriningContainer = ({ propsTitle, propsSubTitle }) => {
  const myDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const { download } = useDownloader();
  // api forms
  const responseFormsPdf = useSelector((state) => state.rootFormsPdf.formsPdf);

  const [dataInputs, setDataInputs] = useState({
    student_id: "",
    student_name: "",
    department: "",
    major: "",
    name: "",
    email: "",
    phone: "",
    company_name: "",
    phone2: "",
    fax: "",
    p_b: "",
    email2: "",
    address: "",
    work_hours: "",
    sub: "",
    name2: "",
    job: "",
    phone3: "",
    fax2: "",
    phone4: "",
    sign: false,
    sign2: false,
  });

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  // show msg is required if onclick the btn
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const submitPDF = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfRequestTrining(formData));
    setIsLoad(false);
    // console.log(dataInputs);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseFormsPdf) {
        // console.log(responseFormsPdf);

        if (responseFormsPdf.status === 200) {
          // download(
          //   responseFormsPdf.data.replace("demo.", ""),
          //   `${propsTitle}.pdf`
          // );
          const link = document.createElement("a");
          link.target = "_blanck";
          link.href = responseFormsPdf.data.replace("demo.", "");
          link.download = `${propsTitle}.pdf`;
          link.click();

          NotificationsToast("done", null, "تم تنزيل النموذج بنجاح");
          setIsLoading(false);
          return;
        } else {
          if (responseFormsPdf.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في تنزيل النموذج");
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      title: "بيانات المتدرب",
    },
    {
      name: "student_id",
      value: dataInputs.student_id || "",
      label: "الرقم التدريبي",
      placeholder: "الرقم التدريبي",
      msgError: isShowMsgError
        ? dataInputs.student_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "student_name",
      value: dataInputs.student_name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      msgError: isShowMsgError
        ? dataInputs.student_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "بيانات منسق و مشرف التدريب التعاوني بالكلية",
    },
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المنسق",
      placeholder: "أسم المنسق",
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      msgError: isShowMsgError
        ? dataInputs.email === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "الجوال",
      placeholder: "الجوال",
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "بيانات منشأة التدريب",
    },
    {
      name: "company_name",
      value: dataInputs.company_name || "",
      label: "أسم المنشأة",
      placeholder: "أسم المنشأة",
      msgError: isShowMsgError
        ? dataInputs.company_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone2",
      value: dataInputs.phone2 || "",
      label: "الهاتف الثابت",
      placeholder: "الهاتف الثابت",
      msgError: isShowMsgError
        ? dataInputs.phone2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "fax",
      value: dataInputs.fax || "",
      label: "الفاكس",
      placeholder: "الفاكس",
      msgError: isShowMsgError
        ? dataInputs.fax === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "p_b",
      value: dataInputs.p_b || "",
      label: "ص.ب",
      placeholder: "ص.ب",
      msgError: isShowMsgError
        ? dataInputs.p_b === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email2",
      value: dataInputs.email2 || "",
      label: "البريد الالكتروني",
      placeholder: "البريد الالكتروني",
      msgError: isShowMsgError
        ? dataInputs.email2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: "العنوان",
      placeholder: "العنوان",
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "work_hours",
      value: dataInputs.work_hours || "",
      label: "فترات الدوام",
      placeholder: "فترات الدوام",
      msgError: isShowMsgError
        ? dataInputs.work_hours === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "sub",
      value: dataInputs.sub || "",
      label: "عدد الفرص  المتاحة ",
      placeholder: "عدد الفرص  المتاحة ",
      msgError: isShowMsgError
        ? dataInputs.sub === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      title: "بيانات مسؤول التدريب في المنشأة",
    },
    {
      name: "name2",
      value: dataInputs.name2 || "",
      label: "اسم مسؤول المباشر",
      placeholder: "اسم مسؤول المباشر",
      msgError: isShowMsgError
        ? dataInputs.name2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "job",
      value: dataInputs.job || "",
      label: "الوظيفة",
      placeholder: "الوظيفة",
      msgError: isShowMsgError
        ? dataInputs.job === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone3",
      value: dataInputs.phone3 || "",
      label: "الهاتف الثابت",
      placeholder: "الهاتف الثابت",
      msgError: isShowMsgError
        ? dataInputs.phone3 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "fax2",
      value: dataInputs.fax2 || "",
      label: "رقم الفاكس",
      placeholder: "رقم الفاكس",
      msgError: isShowMsgError
        ? dataInputs.fax2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "phone4",
      value: dataInputs.phone4 || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      msgError: isShowMsgError
        ? dataInputs.phone4 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    // {
    //   name: "sign",
    //   value: dataInputs.sign || "",
    //   label: "للتوقيع",
    //   placeholder: "للتوقيع",
    // },
    // {
    //   name: "theSeal",
    //   value: dataInputs.theSeal || "",
    //   label: "الختم",
    //   placeholder: "الختم",
    // },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        titleHeader={`ماهو ${propsTitle} ؟`}
        fs={"22px"}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />
      <FormsContainer
        titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) =>
              i === 0 || i === 5 || i === 9 || i === 18 ? (
                <div className="col-12" key={i}>
                  <FormInputTitleHead fs={"21px"} title={item.title} />
                </div>
              ) : (
                <div className="col-lg-6" key={i}>
                  <FormInput
                    type={"text"}
                    onChange={handelDataInputs}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                  />
                </div>
              )
            )}
            <div className="col-12 d-flex align-items-center ">
              <span className="">
                <input
                  className="form-check-input border shadow-none"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      dataInputs.sign = true;
                    } else {
                      dataInputs.sign = false;
                    }
                  }}
                  name={"sign"}
                  value={dataInputs.sign}
                />
              </span>{" "}
              <span className="bg-dange fw-bold mx-3">للتوقيع اضغط هنا</span>
              {/* <div
                className={` text-danger fw-bold my-2`}
                style={{ fontSize: "12px" }}
              >
                {isShowMsgError
                  ? dataInputs.sign === false
                    ? "الحقل مطلوب"
                    : ""
                  : ""}
              </div> */}
            </div>
            <div className="col-12 d-flex align-items-center ">
              <span className="">
                <input
                  className="form-check-input border shadow-none"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      dataInputs.sign2 = true;
                    } else {
                      dataInputs.sign2 = false;
                    }
                  }}
                  name={"sign2"}
                  value={dataInputs.sign2}
                />
              </span>{" "}
              <span className="bg-dange fw-bold mx-3">للختم اضغط هنا</span>
              {/* <div
                className={` text-danger fw-bold my-2`}
                style={{ fontSize: "12px" }}
              >
                {isShowMsgError
                  ? dataInputs.sign2 === false
                    ? "الحقل مطلوب"
                    : ""
                  : ""}
              </div> */}
            </div>
          </form>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
          ) : (
            <Button
              title={"أرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModalForms-request-exception"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModalForms-request-exception"}
            // onCLick={countTimeLineNow}
            onCLick={submitPDF}
            idPopupStar={"exampleModalForms-request-exception"}
            textAlign={"center"}
            title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
          />
        </div>
      </div>
    </div>
  );
};

export default FormsRequestTriningContainer;
