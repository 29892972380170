import { DELETE_MAJOR, GET_ERROR } from "../../types/Types";

const inital = {
  deleteMajor: [],
  loading: true,
};

const reducerDeleteMajor = (state = inital, action) => {
  switch (action.type) {
    case DELETE_MAJOR:
      return {
        deleteMajor: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        deleteMajor: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerDeleteMajor;
