import React from "react";
import CompanyStatusContainer from "../../../components/company/status/CompanyStatusContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";

const CompanyStatusPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["حالة الجهة"]} />
        <div className="container p-3 p-md-5">
          <CompanyStatusContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyStatusPage;
