import { GET_ERROR, ISNERT_40_GRADES } from "../../types/Types";

const inital = {
  insert40grades: [],
  loading: true,
};
const reducerInsert40Grades = (state = inital, action) => {
  switch (action.type) {
    case ISNERT_40_GRADES:
      return {
        insert40grades: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        insert40grades: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerInsert40Grades;
