import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import SopervisorUserViewContainer from "../../../components/sopervisor/users/SopervisorUserViewContainer";

const SopervisorUserViewPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs
          element={[
            <Link
              to={"/sopervisor/follow-up"}
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              متابعة المتدربين
            </Link>,
            "تفاصيل المتدرب ",
          ]}
        />

        <div className="container p-3 p-md-5">
          <SopervisorUserViewContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorUserViewPage;
