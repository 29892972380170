/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const LinkRoundedFill = ({
  title,
  onclick,
  onchange,
  value,
  width,
  to,
  target,
  bgColor = "var(--primary-color)",
  bgHover = "var(--primary-color)",
  color = "var(--primary-color)",
  colorHover = "#fff",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Link
      to={to}
      style={{
        background: isHovered ? bgHover : "#fff",
        width: width,
        border: `3px solid ${isHovered ? bgHover : bgColor}`,
        color: isHovered ? colorHover : color,
        padding: "5px 0",
        borderRadius: "50px",
        transition: "var(--timeing)",
        fontWeight: "bold",
        cursor: "pointer",
        textDecoration: "none",
        textAlign: "center",
        display: "block",
        fontSize: 16,
      }}
      onClick={onclick}
      onChange={onchange}
      value={value}
      target={target}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {title}
    </Link>
  );
};

export default LinkRoundedFill;
