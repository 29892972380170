import {
  GET_ERROR,
  GET_VISIT_NUMBER_BY_STUDENT_ID,
  INSERT_VISIT,
  INSERT_VISIT_TOTAL,
} from "../../types/Types";

const inital = {
  visit: [],
  loading: true,
};

const reducerVisit = (state = inital, action) => {
  switch (action.type) {
    case GET_VISIT_NUMBER_BY_STUDENT_ID:
      return {
        ...state,
        visit: action.payload,
        loading: false,
      };
    case INSERT_VISIT:
      return {
        visit: action.payload,
        loading: false,
      };
    case INSERT_VISIT_TOTAL:
      return {
        visit: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        visit: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerVisit;
