import { DELETE_SOPERVISOR, GET_ERROR } from "../../types/Types";

const inital = {
  deleteSopervisor: [],
  loading: true,
};

const reducerDeleteSopervisor = (state = inital, action) => {
  switch (action.type) {
    case DELETE_SOPERVISOR:
      return {
        deleteSopervisor: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        deleteSopervisor: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerDeleteSopervisor;
