/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";
import StudentJoinRequestPage from "./pages/student/joinRequest/StudentJoinRequestPage";
import StudentFollowUpTasksPage from "./pages/student/followUp/StudentFollowUpTasksPage";
import StudentFollowUpGradePage from "./pages/student/followUp/StudentFollowUpGradePage";
import StudentJoinRequestDetailsPage from "./pages/student/joinRequest/StudentJoinRequestDetailsPage";
import StudentJoinRequestFillFormPage from "./pages/student/joinRequest/StudentJoinRequestFillFormPage";
import StudentStatusPage from "./pages/student/status/StudentStatusPage";
import FormsGuidancePage from "./pages/forms/FormsGuidancePage";
import FormsDirectTriningPage from "./pages/forms/FormsDirectTriningPage";
import FormsRequestTriningPage from "./pages/forms/FormsRequestTriningPage";
import FormsEnrollCoopTriningPage from "./pages/forms/FormsEnrollCoopTriningPage";
import FormsRequestExceptionPage from "./pages/forms/FormsRequestExceptionPage";
import StudentProfilePage from "./pages/student/profile/StudentProfilePage";
import FormsTriningProcessPage from "./pages/forms/FormsTriningProcessPage";
import FormsTriningAvalibleInCompanyPage from "./pages/forms/FormsTriningAvalibleInCompanyPage";
import SopervisorStatusPage from "./pages/sopervisor/status/SopervisorStatusPage";
import LoginPage from "./pages/authPage/LoginPage";
import SopervisorProfilePage from "./pages/sopervisor/profile/SopervisorProfilePage";
import SopervisorAdditionTasksPage from "./pages/sopervisor/addition/SopervisorAdditionTasksPage";
import SopervisorAdditionSkillsPage from "./pages/sopervisor/addition/SopervisorAdditionSkillsPage";
import SopervisorAdditionGradesPage from "./pages/sopervisor/addition/SopervisorAdditionGradesPage";
import SopervisorAdditionGradesDetailsPage from "./pages/sopervisor/addition/SopervisorAdditionGradesDetailsPage";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useEffect } from "react";
import Page404 from "./components/duplicateComponents/Page404";
import Page404System from "./components/duplicateComponents/Page404System";
import AdminExcelStudentPage from "./pages/admin/excel/AdminExcelStudentPage";
import AdminStatusPage from "./pages/admin/status/AdminStatusPage";
import AdminExcelSopervisorPage from "./pages/admin/excel/AdminExcelSopervisorPage";
import AdminExcelCoopPage from "./pages/admin/excel/AdminExcelCoopPage";
import AdminExcelCompanyPage from "./pages/admin/excel/AdminExcelCompanyPage";
import AdminExcelCollagePage from "./pages/admin/excel/AdminExcelCollagePage";
import FormsCerfPage from "./pages/forms/FormsCerfPage";
import AdminRelationStudentWithSoperPage from "./pages/admin/relation/AdminRelationStudentWithSoperPage";
import SopervisorOrderDropPage from "./pages/sopervisor/order/SopervisorOrderDropPage";
import AdminExcelDepartmentPage from "./pages/admin/excel/AdminExcelDepartmentPage";
import AdminExcelMajorPage from "./pages/admin/excel/AdminExcelMajorPage";
import AdminOrderPage from "./pages/admin/order/AdminOrderPage";
import AdminClearUsersPage from "./pages/admin/clear/AdminClearUsersPage";
import { ContextProvider } from "./logic/auth/Auth";
import RequiredAuth from "./logic/auth/RequiredAuth";
import { role } from "./redux/types/Types";
import CompanyStatusPage from "./pages/company/status/CompanyStatusPage";
import CompanyProfilePage from "./pages/company/profile/CompanyProfilePage";
import CompanyOrderPage from "./pages/company/order/CompanyOrderPage";
import CompanyAdditionTasksPage from "./pages/company/addition/CompanyAdditionTasksPage";
import CompanyAdditionSkillsPage from "./pages/company/addition/CompanyAdditionSkillsPage";
import CompanyAdditionGradesPage from "./pages/company/addition/CompanyAdditionGradesPage";
import CompanyAdditionGradesDetailsPage from "./pages/company/addition/CompanyAdditionGradesDetailsPage";
import CompanyPollPage from "./pages/company/poll/CompanyPollPage";
import CompanyHeader from "./components/company/layout/CompanyHeader";
import CompanySidebar from "./components/company/layout/CompanySidebar";
import SopervisorSidebar from "./components/sopervisor/layout/SopervisorSidebar";
import SopervisorHeader from "./components/sopervisor/layout/SopervisorHeader";
import AdminHeader from "./components/admin/layout/AdminHeader";
import AdminSidebar from "./components/admin/layout/AdminSidebar";
import StudentHeader from "./components/student/layout/StudentHeader";
import StudentSidebar from "./components/student/layout/StudentSidebar";
import AdminStudentEditPage from "./pages/admin/users/student/AdminStudentEditPage";
import AdminCompanyEditPage from "./pages/admin/users/company/AdminCompanyEditPage";
import AdminStudentIndexPage from "./pages/admin/users/student/AdminStudentIndexPage";
import AdminStudentViewPage from "./pages/admin/users/student/AdminStudentViewPage";
import AdminStudentCreatePage from "./pages/admin/users/student/AdminStudentCreatePage";
import AdminSopervisorEditPage from "./pages/admin/users/sopervisor/AdminSopervisorEditPage";
import AdminSopervisorIndexPage from "./pages/admin/users/sopervisor/AdminSopervisorIndexPage";
import AdminSopervisorViewPage from "./pages/admin/users/sopervisor/AdminSopervisorViewPage";
import { AdminSopervisorCreatePage } from "./pages/admin/users/sopervisor/AdminSopervisorCreatePage";
import AdminCompanyIndexPage from "./pages/admin/users/company/AdminCompanyIndexPage";
import AdminCompanyCreatePage from "./pages/admin/users/company/AdminCompanyCreatePage";
import AdminCompanyViewPage from "./pages/admin/users/company/AdminCompanyViewPage";
import AdminCollageIndexPage from "./pages/admin/users/collage/AdminCollageIndexPage";
import AdminCollageCreatePage from "./pages/admin/users/collage/AdminCollageCreatePage";
import AdminDepartmentIndexPage from "./pages/admin/users/department/AdminDepartmentIndexPage";
import AdminDepartmentCreatePage from "./pages/admin/users/department/AdminDepartmentCreatePage";
import AdminMajorIndexPage from "./pages/admin/users/major/AdminMajorIndexPage";
import AdminMajorCreatePage from "./pages/admin/users/major/AdminMajorCreatePage";
import ResetPasswordPage from "./pages/authPage/ResetPasswordPage";
import AdminPollReviewPage from "./pages/admin/pollReview/AdminPollReviewPage";
import StudentPollPage from "./pages/student/poll/StudentPollPage";
import CompanyUserStudentIndexPage from "./pages/company/users/student/CompanyUserStudentIndexPage";
import CompanyUserStudentViewPage from "./pages/company/users/student/CompanyUserStudentViewPage";
import SopervisorUserIndexPage from "./pages/sopervisor/users/SopervisorUserIndexPage";
import SopervisorUserViewPage from "./pages/sopervisor/users/SopervisorUserViewPage";
import SubscriptionsPage from "./pages/Subscriptions/SubscriptionsPage";
import HeaderHomePage from "./components/homePage/HeaderHomePage";
import Footer from "./components/homePage/Footer";

function App() {
  // is Show Routes
  // const [test, setTest] = useState("");
  // const [role, setRole] = useState("");
  // const [isHideNavAndSide, setIsHideNavAndSide] = useState(true);
  // // const nav = useNavigate();

  // useEffect(() => {
  //   //### here if user on(homePage or Login) => Hide navbar and Side

  //   if (
  //     window.location.pathname === "/" ||
  //     window.location.pathname === "/login"
  //   ) {
  //     setIsHideNavAndSide(true);
  //   } else {
  //     setIsHideNavAndSide(false);
  //   }
  // }, [window.location.pathname]);
  // ///////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   if (localStorage.getItem("token") && localStorage.getItem("role")) {
  //     // console.log(localStorage.getItem("token"));
  //     // console.log("token done");
  //     setTest(localStorage.getItem("token"));
  //     setRole(localStorage.getItem("role"));
  //   } else {
  //     // console.log("token empty");
  //     setTest("");
  //     setRole("");
  //   }
  // }, []);

  // useEffect(() => {
  //   // if (localStorage.getItem("token")) {
  //   //   return;
  //   // } else {
  //   //   // window.location.href = "/";
  //   //   // if (window.location.pathname === "/") {
  //   //   // } else if (window.location.pathname === "/login") {
  //   //   //   nav("/login");
  //   //   // } else {
  //   //   nav("/");
  //   //   // }
  //   // }
  //   if (localStorage.getItem("role")) {
  //     if (role === "student") {
  //       return (window.location.pathname = "/student/status");
  //     } else if (role === "sopervisor") {
  //       return (window.location.pathname = "/sopervisor/status");
  //     } else if (role === "admin") {
  //       return (window.location.pathname = "/admin/status");
  //     }
  //   } else {
  //     return (window.location.pathname = "/");
  //   }
  // }, [window.location.pathname]);

  // localStorage.clear()

  useEffect(() => {
    if (window.location.pathname === "/") {
      localStorage.clear();
    }
  }, []);

  const RouteWithElementComponentStudent = [
    {
      path: "/student/status",
      component: <StudentStatusPage />,
    },
    {
      path: "/student/profile",
      component: <StudentProfilePage />,
    },
    {
      path: "/student/follow-up-tasks",
      component: <StudentFollowUpTasksPage />,
    },
    {
      path: "/student/follow-up-grade",
      component: <StudentFollowUpGradePage />,
    },
    {
      path: "/student/poll",
      component: <StudentPollPage />,
    },
    {
      path: "/student/join-request",
      component: <StudentJoinRequestPage />,
    },
    {
      path: "/student/join-request-details/:id",
      component: <StudentJoinRequestDetailsPage />,
    },
    {
      path: "/student/join-request-fill-form/:id",
      component: <StudentJoinRequestFillFormPage />,
    },
    {
      path: "/student/forms-enroll-trining",
      component: <FormsEnrollCoopTriningPage />,
    },
    {
      path: "/student/forms-request-exception",
      component: <FormsRequestExceptionPage />,
    },
    {
      path: "*",
      component: <Page404System />,
    },
  ];

  const RouteWithElementComponentSopervisor = [
    {
      path: "/sopervisor/status",
      component: <SopervisorStatusPage />,
    },
    {
      path: "/sopervisor/profile",
      component: <SopervisorProfilePage />,
    },
    {
      path: "/sopervisor/users",
      component: <SopervisorUserIndexPage />,
    },
    {
      path: "/sopervisor/users/view/:page/:id",
      component: <SopervisorUserViewPage />,
    },
    {
      path: "/sopervisor/addition-tasks",
      component: <SopervisorAdditionTasksPage />,
    },
    {
      path: "/sopervisor/addition-skills",
      component: <SopervisorAdditionSkillsPage />,
    },
    {
      path: "/sopervisor/addition-grades",
      component: <SopervisorAdditionGradesPage />,
    },
    {
      path: "/sopervisor/addition-grades/create/:page/:id",
      component: <SopervisorAdditionGradesDetailsPage />,
    },
    {
      path: "/sopervisor/order-drop",
      component: <SopervisorOrderDropPage />,
    },
    {
      path: "/sopervisor/forms-Guidance",
      component: <FormsGuidancePage />,
    },
    {
      path: "/sopervisor/forms-request-trining",
      component: <FormsRequestTriningPage />,
    },
    {
      path: "*",
      component: <Page404System />,
    },
  ];
  const RouteWithElementComponentCompany = [
    {
      path: "/company/status",
      component: <CompanyStatusPage />,
    },
    {
      path: "/company/profile",
      component: <CompanyProfilePage />,
    },
    {
      path: "/company/order",
      component: <CompanyOrderPage />,
    },
    {
      path: "/company/users",
      component: <CompanyUserStudentIndexPage />,
    },
    {
      path: "/company/users/view/:page/:id",
      component: <CompanyUserStudentViewPage />,
    },
    {
      path: "/company/addition-tasks",
      component: <CompanyAdditionTasksPage />,
    },
    {
      path: "/company/addition-skills",
      component: <CompanyAdditionSkillsPage />,
    },
    {
      path: "/company/addition-grades",
      component: <CompanyAdditionGradesPage />,
    },
    {
      path: "/company/addition-grades/create/:page/:id",
      component: <CompanyAdditionGradesDetailsPage />,
    },

    {
      path: "/company/Poll",
      component: <CompanyPollPage />,
    },
    {
      path: "/company/forms-direct",
      component: <FormsDirectTriningPage />,
    },
    {
      path: "/company/forms-trining-Process",
      component: <FormsTriningProcessPage />,
    },
    {
      path: "/company/forms-trining-avalible",
      component: <FormsTriningAvalibleInCompanyPage />,
    },
    {
      path: "/company/forms-cerf",
      component: <FormsCerfPage />,
    },

    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////

    {
      path: "*",
      component: <Page404System />,
    },
  ];
  const RouteWithElementComponentAdmin = [
    {
      path: "/admin/status",
      component: <AdminStatusPage />,
    },

    {
      path: "/admin/excel-student",
      component: <AdminExcelStudentPage />,
    },
    {
      path: "/admin/excel-sopervisor",
      component: <AdminExcelSopervisorPage />,
    },
    {
      path: "/admin/excel-coop",
      component: <AdminExcelCoopPage />,
    },
    {
      path: "/admin/excel-company",
      component: <AdminExcelCompanyPage />,
    },
    {
      path: "/admin/excel-collage",
      component: <AdminExcelCollagePage />,
    },
    {
      path: "/admin/excel-department",
      component: <AdminExcelDepartmentPage />,
    },
    {
      path: "/admin/excel-major",
      component: <AdminExcelMajorPage />,
    },
    {
      path: "/admin/relation-student-soper",
      component: <AdminRelationStudentWithSoperPage />,
    },
    {
      path: "/admin/poll-reviews",
      component: <AdminPollReviewPage />,
    },
    {
      path: "/admin/order",
      component: <AdminOrderPage />,
    },
    {
      path: "/admin/clear-users",
      component: <AdminClearUsersPage />,
    },
    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    {
      path: "/admin/users-student",
      component: <AdminStudentIndexPage />,
    },
    {
      path: "/admin/users-student/create",
      component: <AdminStudentCreatePage />,
    },
    {
      path: "/admin/users-student/edit/:page/:id",
      component: <AdminStudentEditPage />,
    },
    {
      path: "/admin/users-student/view/:page/:id",
      component: <AdminStudentViewPage />,
    },
    {
      path: "/admin/users-sopervisor",
      component: <AdminSopervisorIndexPage />,
    },
    {
      path: "/admin/users-sopervisor/create",
      component: <AdminSopervisorCreatePage />,
    },
    {
      path: "/admin/users-sopervisor/view/:page/:id",
      component: <AdminSopervisorViewPage />,
    },
    {
      path: "/admin/users-sopervisor/edit/:page/:id",
      component: <AdminSopervisorEditPage />,
    },
    {
      path: "/admin/users-company",
      component: <AdminCompanyIndexPage />,
    },
    {
      path: "/admin/users-company/create",
      component: <AdminCompanyCreatePage />,
    },
    {
      path: "/admin/users-company/view/:page/:id",
      component: <AdminCompanyViewPage />,
    },
    {
      path: "/admin/users-company/edit/:page/:id",
      component: <AdminCompanyEditPage />,
    },
    {
      path: "/admin/users-collage",
      component: <AdminCollageIndexPage />,
    },
    {
      path: "/admin/users-collage/create",
      component: <AdminCollageCreatePage />,
    },

    {
      path: "/admin/users-department",
      component: <AdminDepartmentIndexPage />,
    },
    {
      path: "/admin/users-department/create",
      component: <AdminDepartmentCreatePage />,
    },
    {
      path: "/admin/users-major",
      component: <AdminMajorIndexPage />,
    },

    {
      path: "/admin/users-major/create",
      component: <AdminMajorCreatePage />,
    },

    {
      path: "*",
      component: <Page404System />,
    },
  ];

  const RouteWithElementComponentIndex = [
    {
      path: "/",
      component: <HomePage />,
    },
    {
      path: "/subscription",
      component: <SubscriptionsPage />,
    },
  ];
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <BrowserRouter>
        <ContextProvider>
          <Routes>
            <>
              {RouteWithElementComponentStudent.map((item, i) => (
                <Route
                  key={i}
                  path={item.path}
                  element={
                    <>
                      <RequiredAuth>
                        {role === "student" ? (
                          <>
                            <div className="main">
                              <StudentHeader />
                            </div>
                            {item.component}
                            <div className="d-none d-md-block">
                              <StudentSidebar />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/" />
                        )}
                      </RequiredAuth>
                    </>
                  }
                />
              ))}
              {RouteWithElementComponentSopervisor.map((item, i) => (
                <Route
                  key={i}
                  path={item.path}
                  element={
                    <>
                      <RequiredAuth>
                        {role === "emp" ? (
                          <>
                            <div className="main">
                              {/* <HeaderSopervisor /> */}
                              <SopervisorHeader />
                            </div>
                            {item.component}
                            <div className="d-none d-md-block">
                              {/* <SidebarSopervisorPage /> */}
                              <SopervisorSidebar />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/" />
                        )}
                      </RequiredAuth>
                    </>
                  }
                />
              ))}
              {RouteWithElementComponentCompany.map((item, i) => (
                <Route
                  key={i}
                  path={item.path}
                  element={
                    <>
                      <RequiredAuth>
                        {role === "company" ? (
                          <>
                            <div className="main">
                              <CompanyHeader />
                            </div>
                            {item.component}
                            <div className="d-none d-md-block">
                              <CompanySidebar />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/" />
                        )}
                      </RequiredAuth>
                    </>
                  }
                />
              ))}
              {RouteWithElementComponentAdmin.map((item, i) => (
                <Route
                  key={i}
                  path={item.path}
                  element={
                    <>
                      <RequiredAuth>
                        {role === "admin" ? (
                          <>
                            <div className="main">
                              {/* <HeaderAdmin /> */}
                              <AdminHeader />
                            </div>
                            {item.component}
                            <div className="d-none d-md-block">
                              {/* <SidebarAdminPage /> */}
                              <AdminSidebar />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/" />
                        )}
                      </RequiredAuth>
                    </>
                  }
                />
              ))}
              {RouteWithElementComponentIndex.map((item, i) => (
                <Route key={i} path={item.path} element={item.component} />
              ))}

              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="*" element={<Page404 />} />
              {/* Students Routes */}
              {/* //////////// Start stadent ////////////*/}
              {/* <Route path="/student/profile" element={<StudentProfilePage />} />
              <Route path="/student/status" element={<StudentStatusPage />} />
              <Route
                path="/student/join-request"
                element={<StudentJoinRequestPage />}
              />
              <Route
                path="/student/join-request-details/:id"
                element={<StudentJoinRequestDetailsPage />}
              />
              <Route
                path="/student/join-request-fill-form/:id"
                element={<StudentJoinRequestFillFormPage />}
              />
              <Route
                path="/student/follow-up-tasks"
                element={<StudentFollowUpTasksPage />}
              />
              <Route
                path="/student/follow-up-grade"
                element={<StudentFollowUpGradePage />}
              />
              <Route
                path="/student/follow-up-poll"
                element={<StudentFollowUpPollPage />}
              />
              <Route
                path="/student/forms-enroll-trining"
                element={<FormsEnrollCoopTriningPage />}
              />
              <Route
                path="/student/forms-request-exception"
                element={<FormsRequestExceptionPage />}
              /> */}
              {/* //////////// end stadent ////////////*/}
              {/* /////////Start Sopervoiser Routes /////////////*/}
              {/* <Route
                path="/sopervisor/status"
                element={<SopervisorStatusPage />}
              />
              <Route path="/sopervisor/order" element={<OrderPage />} />
              <Route
                path="/sopervisor/profile"
                element={<SopervisorProfilePage />}
              />
              <Route
                path="/sopervisor/follow-up"
                element={<SopervisorFollowUpPage />}
              />
              <Route
                path="/sopervisor/follow-up-details/:page/:id"
                element={<SopervisorFollowUpDetailsPage />}
              />
              <Route
                path="/sopervisor/addition-tasks"
                element={<SopervisorAdditionTasksPage />}
              />
              <Route
                path="/sopervisor/addition-skills"
                element={<SopervisorAdditionSkillsPage />}
              />
              <Route
                path="/sopervisor/addition-grades"
                element={<SopervisorAdditionGradesPage />}
              />
              <Route
                path="/sopervisor/poll-reviews"
                element={<PollTriningAndCompanyPage />}
              />
              <Route
                path="/sopervisor/addition-grades-details/:page/:id"
                element={<SopervisorAdditionGradesDetailsPage />}
              />
              <Route path="/sopervisor/Poll" element={<SopervisorPollPage />} />
              <Route
                path="/sopervisor/order-drop"
                element={<OrderDropPage />}
              />
              <Route
                path="/sopervisor/forms-Guidance"
                element={<FormsGuidancePage />}
              />
              <Route
                path="/sopervisor/forms-direct"
                element={<FormsDirectTriningPage />}
              />
              <Route
                path="/sopervisor/forms-request-trining"
                element={<FormsRequestTriningPage />}
              />
              <Route
                path="/sopervisor/forms-trining-Process"
                element={<FormsTriningProcessPage />}
              />
              <Route
                path="/sopervisor/forms-trining-avalible"
                element={<FormsTriningAvalibleInCompanyPage />}
              />
              <Route
                path="/sopervisor/forms-cerf"
                element={<FormsCerfPage />}
              /> */}
              {/* /////////End Sopervoiser Routes /////////////*/}
              {/* //////////// Start Admin ////////////*/}
              {/* <Route path="/admin/status" element={<AdminStatusPage />} />
              <Route
                path="/admin/follow-up-student"
                element={<AdminStudentFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-student/:page/:id"
                element={<AdminStudntFollowUpDetailsPage />}
              />
              <Route
                path="/admin/follow-up-company"
                element={<AdminCompanyFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-coop"
                element={<AdminCoopFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-sopervisor"
                element={<AdminSopervisorFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-collage"
                element={<AdminCollageFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-department"
                element={<AdminDepartmentFollowUpPage />}
              />
              <Route
                path="/admin/follow-up-major"
                element={<AdminMajorFollowUpPage />}
              />
           
              <Route
                path="/admin/excel-student"
                element={<AdminExcelStudentPage />}
              />
              <Route
                path="/admin/excel-sopervisor"
                element={<AdminExcelSopervisorPage />}
              />
              <Route
                path="/admin/excel-coop"
                element={<AdminExcelCoopPage />}
              />
              <Route
                path="/admin/excel-company"
                element={<AdminExcelCompanyPage />}
              />
              <Route
                path="/admin/excel-collage"
                element={<AdminExcelCollagePage />}
              />
              <Route
                path="/admin/excel-department"
                element={<AdminExcelDepartmentPage />}
              />
              <Route
                path="/admin/excel-major"
                element={<AdminExcelMajorPage />}
              />
             
              <Route
                path="/admin/follow-up-company/create"
                element={<AdminCompanyAddtionPage />}
              />
              <Route
                path="/admin/follow-up-company/view/:page/:id"
                element={<AdminCompanyFollowUpViewPage />}
              />
              <Route
                path="/admin/follow-up-collage/create"
                element={<AdminCollageAddtionPage />}
              />
              <Route
                path="/admin/follow-up-student/create"
                element={<AdminStudentAddtionPage />}
              />
              <Route
                path="/admin/follow-up-department/create"
                element={<AdminDepartmentAddtionPage />}
              />
              <Route
                path="/admin/follow-up-major/create"
                element={<AdminMajorAddtionPage />}
              />
              <Route
                path="/admin/follow-up-sopervisor/create"
                element={<AdminSopervisorAddtionPage />}
              /> 
              <Route
                path="/admin/follow-up-sopervisor/view/:page/:id"
                element={<AdminSopervisorFollowUpViewPage />}
              />
              
              <Route
                path="/admin/relation-student-soper"
                element={<AdminRelationStudentWithSoperPage />}
              />
        
              <Route path="/admin/order" element={<AdminOrderPage />} />
              
              <Route
                path="/admin/clear-users"
                element={<AdminClearUsersPage />}
              /> */}
              {/* //////////// End Admin ////////////*/}
            </>
          </Routes>
        </ContextProvider>
      </BrowserRouter>
      <NotificationContainer />
    </div>
  );
}

export default App;
