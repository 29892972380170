/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { tokenSopervisorUsername } from "../../../../redux/types/Types";
import SopervisorAdditionGradesDetailsTriningSopervisor from "./SopervisorAdditionGradesDetailsTriningSopervisor";
import SopervisorAdditionGradesDetailsFinalReport from "./SopervisorAdditionGradesDetailsFinalReport";
import SopervisorTitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStudentInsideSopervisorPage } from "../../../../redux/actions/sopervisor/ActionSopervisor";
import Taps from "../../../duplicateComponents/Taps";
import ReactLoading from "react-loading";

const SopervisorAdditionGradesDetailsContainer = () => {
  const idRoute = useParams();
  const myDispatch = useDispatch();
  const [idParams, setIdParams] = useState(0);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);

    myDispatch(
      getStudentInsideSopervisorPage(tokenSopervisorUsername, idRoute.page)
    );
  }, []);

  // api sopervsior Inside
  const myDataInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisorInside
  );
  const loadingInsideSopervisor = useSelector(
    (state) => state.rootSopervisor.loadingInside
  );

  // console.log(
  //   myDataInsideSopervisor.data && myDataInsideSopervisor.data[idRoute.id]
  // );
  return (
    <div className="">
      {loadingInsideSopervisor ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        <SopervisorTitleWithNumber
          title={
            myDataInsideSopervisor ? (
              myDataInsideSopervisor.data[idParams].name
            ) : (
              <ReactLoading
                type={"bubbles"}
                color={"var(--primary-color)"}
                width={40}
                height={40}
              />
            )
          }
          subTitle={
            myDataInsideSopervisor ? (
              myDataInsideSopervisor.data[idParams].student_id
            ) : (
              <ReactLoading
                type={"bubbles"}
                color={"var(--primary-color)"}
                width={40}
                height={40}
              />
            )
          }
          num={null}
        />
      )}
      <Taps
        title={["تقييم الزيارات", "تقييم التقرير الفني"]}
        charIdAndTarget={["a", "b"]}
        titleItem={[
          <SopervisorAdditionGradesDetailsTriningSopervisor
            propsDataStudent={
              myDataInsideSopervisor.data &&
              myDataInsideSopervisor.data[idParams]
            }
          />,
          <SopervisorAdditionGradesDetailsFinalReport
            propsDataStudent={
              myDataInsideSopervisor.data &&
              myDataInsideSopervisor.data[idParams]
            }
          />,
        ]}
      />
    </div>
  );
};

export default SopervisorAdditionGradesDetailsContainer;
