import React from "react";
import Button from "../../../duplicateComponents/Button";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";

const SopervisorAdditionGradesDetailsTriningSopervisorAvrageItem = ({
  index,
  onclickVisitOne,
  isLoadingVisitOne,
  onclickVisitTwo,
  isLoadingVisitTwo,
  onclickVisitThree,
  isLoadingVisitThree,
  // inputCount = [],
}) => {
  return (
    <tr className="style-tr-without-border-bottom">
      {/* index */}
      <th>
        <div className="mt-2">{index}</div>
      </th>
      {/* title */}
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
          حساب متوسط الدرجة
        </div>
      </td>
      <td>
        <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
          -
        </div>
      </td>
      <td>
        <div>
          {isLoadingVisitOne ? (
            <ButtonLoading title={"جاري الحساب"} />
          ) : (
            <Button
              onclick={onclickVisitOne}
              title={
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-white mx-1">أحسب الزيارة</div>
                  <div className="text-white d-flex fw-bold mx-2 align-items-center justify-content-center fs-6">
                    <ion-icon
                      color="#"
                      name="calculator-outline"
                      size="12"
                    ></ion-icon>
                  </div>
                </div>
              }
              fs={"12px"}
              width={"154px"}
            />
          )}
        </div>
      </td>
   
    </tr>
  );
};

export default SopervisorAdditionGradesDetailsTriningSopervisorAvrageItem;
