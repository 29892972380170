import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_MAJOR,
  GET_ALL_MAJOR_PAGE,
  GET_ERROR,
  INSERT_MAJOR,
} from "../../types/Types";

const getAllMajor = () => async (dispatch) => {
  try {
    const response = await UseGetData(`getmajor`);
    dispatch({
      type: GET_ALL_MAJOR,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertMajor = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`inmajors`, dataParams);
    dispatch({
      type: INSERT_MAJOR,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllMajorPage = (dataParamsPage) => async (dispatch) => {
  const response = await UseGetData(`getmajor?page=${dataParamsPage}&limit=10`);
  try {
    dispatch({
      type: GET_ALL_MAJOR_PAGE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export { getAllMajor, insertMajor, getAllMajorPage };
