import {
  GET_ALL_STUDENT_WITHOUT_PAGINATION,
  GET_ERROR,
} from "../../types/Types";

const inital = {
  studentWithoutPagination: [],
  loading: true,
};

const reducerStudentWithoutPagination = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT_WITHOUT_PAGINATION:
      return {
        ...state,
        studentWithoutPagination: action.payload,
        loading: false,
      };

    case GET_ERROR:
      return {
        studentWithoutPagination: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
export default reducerStudentWithoutPagination;
