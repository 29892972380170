import { DELETE_STUDENT, GET_ERROR } from "../../types/Types";

const inital = {
  deleteStudent: [],
  loading: true,
};

const reducerDeleteStudent = (state = inital, action) => {
  switch (action.type) {
    case DELETE_STUDENT:
      return {
        deleteStudent: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        deleteStudent: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerDeleteStudent;
