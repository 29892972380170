import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import CompanyOrderContainer from "../../../components/company/order/CompanyOrderContainer";

const CompanyOrderPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["حالة الطلبات"]} />
        <div className="container p-3 p-md-5">
          <CompanyOrderContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyOrderPage;
