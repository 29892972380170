import React from "react";
// import HeaderStudent from "../../components/student/HeaderStudent";
import Breadcrumbs from "../../components/duplicateComponents/Breadcrumbs";
// import SidebarPage from "../sideBar/SidebarPage";
import FormsRequestExceptionContainer from "../../components/forms/FormsRequestExceptionContainer";
import { useSelector } from "react-redux";
import CheckRouteWhereYotGo from "../../components/duplicateComponents/CheckRouteWhereYotGo";

const FormsRequestExceptionPage = () => {
  const formsAddress = useSelector((state) => state.rootForms.dataForms);

  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* <HeaderStudent /> */}
        <Breadcrumbs
          main={formsAddress.mainStudent}
          element={["النماذج", formsAddress.RequestException.title]}
        />
        <FormsRequestExceptionContainer
          propsTitle={formsAddress.RequestException.title}
          propsSubTitle={formsAddress.RequestException.subTitle}
        />
      </div>
      {/* here side bar */}
      {/* <div className="d-none d-md-block">
        <SidebarPage />
      </div> */}
    </div>
  );
};

export default FormsRequestExceptionPage;
