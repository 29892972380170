/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";

import { Progress } from "antd";
import {
  getAllReviewsCompany,
  getAllReviewsCompanyPage,
} from "../../../redux/actions/reviews/ActionGetReviewsCompany";
import TitleWithNumber from "../../duplicateComponents/TitleWithNumber";
import FormInput from "../../duplicateComponents/FormInput";
import TableLodaing from "../../duplicateComponents/TableLodaing";
const { Column } = Table;

const AdminPollReviewCompanyContainer = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllReviewsCompany());
  }, []);
  // api Review Student
  const myDataReviewCompany = useSelector(
    (state) => state.rootReviewsCompany.reviewsCompany
  );
  const laodingReviewCompany = useSelector(
    (state) => state.rootReviewsCompany.loading
  );

  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "اسم الجهة",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.company_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text, record, index) => {
        return record.company_name === null ? "-" : record.company_name;
      },
    },
    {
      title: "البريد الالكتروني",
      dataIndex: "email",
      key: "email",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.email === null ? "-" : record.email;
      },
    },
    {
      title: "درجة التقييم",
      dataIndex: "total",
      key: "total",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <Progress
            type="circle"
            status="active"
            percent={parseInt(record.total)}
            size={50}
            className="fw-bold m-0 p-0"
            strokeColor="var(--primary-color)"
          />
        );
      },
    },
  ];
  return (
    <div className="placeholder-glow">
      <div className="row">
        <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
          <span>
            <TitleWithNumber
              subTitle={"عدد تقييمات"}
              num={myDataReviewCompany && myDataReviewCompany.total}
            />
          </span>
        </div>
        <div className="col-12 col-lg-6 my-3">
          <FormInput
            type={"search"}
            placeholder={"البحث ..."}
            shadow={"style-shadow-large-extra"}
            isShowIcon={true}
            onSearch={(val) => {
              setSearchText(val);
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            bgColor="#fff"
          />
        </div>
        <div>
          {laodingReviewCompany ? (
            <TableLodaing itemsColumns={dataTableColumns} />
          ) : (
            <Table
              pagination={{
                position: ["bottomCenter"],
                size: "small",
                style: { display: "flex", flexDirection: "row-reverse" },
                // 1,2,3,4
                current: page,
                // items show
                total: myDataReviewCompany && myDataReviewCompany.total,
                // pageSize: 10,
                onChange: (page, pageSize) => {
                  setPage(page);
                  myDispatch(getAllReviewsCompanyPage(page));
                },
              }}
              size="middle"
              dataSource={myDataReviewCompany && myDataReviewCompany.data}
              rowKey={"company_name"}
              className="style-scroll-radius style-shadow-large bg-white rounded-3"
            >
              {dataTableColumns.map((item, i) => (
                <Column
                  title={item.title}
                  dataIndex={item.dataIndex}
                  key={item.key}
                  filteredValue={item.filteredValue}
                  onFilter={item.onFilter}
                  render={item.render}
                />
              ))}
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPollReviewCompanyContainer;
