/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import FormInput from "../../../duplicateComponents/FormInput";
// import SopervisorTitleWithNumber from "../../SopervisorTitleWithNumber";
import Button from "../../../duplicateComponents/Button";
// import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import Taps from "../../../duplicateComponents/Taps";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import SopervisorAdditionSkillsLogic from "../../../../logic/sopervisor/skills/SopervisorAdditionSkillsLogic";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useDispatch } from "react-redux";
import {
  getAllSkills,
  getAllSkillsPage,
} from "../../../../redux/actions/skills/ActionSkills";
import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
import { Select, Spin, Table, Typography } from "antd";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
const { Column } = Table;
const { Paragraph } = Typography;
const SopervisorAdditionSkillsContainer = () => {
  const [
    handelSkillname,
    isLoading,
    addNewSkill,
    myDataSkills,
    loadingSkills,
    onSelect,
    onRemove,
    myDataInsideSopervisor,
    setArrChecked,
    setArrCheckedBool,
    arrChecked,
    addSubmitSkills,
    setArrSelectedMuiltySkills,
    isLoadingAttach,
    isShowMsgError,
  ] = SopervisorAdditionSkillsLogic();

  const myDispatch = useDispatch();

  const [options, setOptions] = useState([]);

  const fun = () => {
    setOptions(myDataSkills.data);
  };

  useEffect(() => {
    fun();
  }, [options]);

  // console.log(myDataSkills && myDataSkills);

  // if use MultipleSelect pakage must be using useState
  // if use MultipleSelect pakage must be using useState
  // if use MultipleSelect pakage must be using useState
  // if use MultipleSelect pakage must be using useState

  let filteredOptions = [];

  if (myDataInsideSopervisor) {
    filteredOptions = myDataInsideSopervisor.filter(
      (o) => !arrChecked.includes(o)
    );
  }

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "المهارة",
      dataIndex: "skill_type",
      key: "skill_type",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.skill_type)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "نسخ المهارة",
      dataIndex: "skill_id",
      key: "skill_id",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return <Paragraph copyable={{ text: record.skill_type }}></Paragraph>;
      },
    },
  ];

  return (
    <Taps
      title={["إضافة مهارات", "أرسال المهارات للمتدربين", "المهارات السابقة"]}
      charIdAndTarget={["a", "b", "c"]}
      titleItem={[
        <>
          <div
            className="col-12 col-lg-6 py-3 bg-white p-3 style-shadow-small"
            style={{ borderRadius: "var(--radius)" }}
          >
            <div>
              <h5
                className="text-center fw-bold my-2"
                style={{ color: "var(--primary-color)" }}
              >
                إضافة مهارة جديدة
              </h5>
              <div className="row gx-5 gy-3 ">
                <div className="col-12 mb-3">
                  <FormInputTextArea
                    type={"text"}
                    onChange={handelSkillname}
                    label={"عنوان مهارة"}
                    placeholder={"عنوان مهارة..."}
                    msgError={isShowMsgError ? "الحقل مطلوب" : ""}
                  />
                </div>
              </div>
              {isLoading ? (
                <ButtonLoading title={"جاري الإضافة"} width={"100%"} />
              ) : (
                <Button
                  onclick={addNewSkill}
                  title={
                    <div className="d-flex align-items-center justify-content-center">
                      <span>إضافة المهارة</span>
                      <span className="mx-2 mt-1">
                        <FontAwesomeIcon icon={faCirclePlus} />
                      </span>
                    </div>
                  }
                  type="button"
                />
              )}
            </div>
          </div>
        </>,
        <>
          <div className="row">
            <div className="col-12 col-l ">
              <div
                className=" mb-4  bg-white p-3 style-shadow-small"
                style={{ borderRadius: "var(--radius)" }}
              >
                <div className="col-12 col-xl-6 m-auto">
                  <div className="">
                    <FormInputTitleHead
                      textAlign={"center"}
                      title={
                        <div className="d-flex align-items-center justify-content-center">
                          <div>تحديد المهارات المطلوبة</div>
                          <div className="mx-2">
                            <FontAwesomeIcon icon={faArrowDown} />{" "}
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div
                    className="style-scroll-radiu"
                    // here if click container MultipleSelected get all data skills
                    onClick={async () => {
                      await myDispatch(getAllSkills());
                      fun();
                    }}
                  >
                    <Multiselect
                      // options={options} // Options to display in the dropdown
                      options={options} // Options to display in the dropdown
                      // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                      // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                      onSelect={onSelect} // Function will trigger on select event
                      onRemove={onRemove} // Function will trigger on remove event
                      displayValue="skill_type" // Property name to display in the dropdown options
                      placeholder="ابحث عن المهارة"
                      className="text-center rounded-3 "
                      showArrow={true}
                    />
                  </div>
                  <div
                    className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
                    style={{
                      borderRadius: "var(--radius)",
                      // width: "190px",
                    }}
                  >
                    <span className="">
                      <input
                        className="form-check-input border shadow-none"
                        type="checkbox"
                        // value={arrChecked}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          let newArrCheckedMap;
                          if (myDataSkills) {
                            newArrCheckedMap = Object.values(
                              myDataSkills.data
                            ).map((item) => item.skill_id);
                          }

                          setArrSelectedMuiltySkills([]);
                          if (checked) {
                            setArrSelectedMuiltySkills(newArrCheckedMap);
                            NotificationsToast(
                              "done",
                              null,
                              "تم تحديد جميع المهارات"
                            );
                          } else {
                            newArrCheckedMap = [];
                            setArrSelectedMuiltySkills(newArrCheckedMap);
                            NotificationsToast(
                              "warn",
                              null,
                              "تم الغاء جميع المهارات"
                            );
                          }
                          // if seletedted all
                          // setArrCheckedBool(checked);
                        }}
                      />
                    </span>{" "}
                    <span className="bg-dange fw-bold mx-3">
                      تحديد جميع المهارات
                    </span>
                  </div>
                  <div
                    className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
                    style={{
                      borderRadius: "var(--radius)",
                      // width: "190px",
                    }}
                  >
                    <span className="">
                      <input
                        className="form-check-input border shadow-none"
                        type="checkbox"
                        // value={arrChecked}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          let newArrCheckedMap;
                          if (myDataInsideSopervisor) {
                            newArrCheckedMap = Object.values(
                              myDataInsideSopervisor
                            ).map((item) => item.student_id);
                          }

                          if (checked) {
                            setArrChecked(newArrCheckedMap);
                            NotificationsToast(
                              "done",
                              null,
                              "تم تحديد جميع المتدربين"
                            );
                          } else {
                            newArrCheckedMap = [];
                            setArrChecked(newArrCheckedMap);
                            NotificationsToast(
                              "warn",
                              null,
                              "تم الغاء جميع المتدربين"
                            );
                          }
                          // if seletedted all
                          setArrCheckedBool(checked);
                        }}
                      />
                    </span>{" "}
                    <span className="bg-dange fw-bold mx-3">
                      تحديد جميع المتدربين
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column flex-md-row my-3">
            <Select
              mode="multiple"
              placeholder="البحث بالرقم التدريبي"
              value={arrChecked}
              onChange={setArrChecked}
              style={{
                width: "100%",
              }}
              options={filteredOptions.map((item, i) => ({
                value: item.student_id,
                label: item.name + " - " + item.student_id,
              }))}
              // options={selectedItems.map((item, i) => ({
              //   value: item,
              //   label: saveName[i] + " - " + item,
              // }))}
            />

            <div className=" me-0 my-3 my-md-0 me-md-3 d-flex justify-content-center align-items-center ">
              {isLoadingAttach ? (
                <ButtonLoading
                  title={"جاري الاضافة"}
                  className={"fw-bold"}
                  width={"150px"}
                />
              ) : (
                <Button
                  width={"150px"}
                  onclick={addSubmitSkills}
                  title={"إرسال"}
                />
              )}
            </div>
          </div>
        </>,
        <>
          <Spin spinning={false} delay={500} size="large">
            <div className="placeholder-glow">
              <div className="row">
                <div className="col-12 col-lg-6 my-3">
                  <FormInput
                    type={"search"}
                    placeholder={"البحث ..."}
                    shadow={"style-shadow-large-extra"}
                    isShowIcon={true}
                    onSearch={(val) => {
                      setSearchText(val);
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    bgColor="#fff"
                  />
                </div>
                <div>
                  {loadingSkills ? (
                    <TableLodaing itemsColumns={dataTableColumns} />
                  ) : (
                    <Table
                      pagination={{
                        position: ["bottomCenter"],
                        size: "small",
                        style: {
                          display: "flex",
                          flexDirection: "row-reverse",
                        },
                        // 1,2,3,4
                        current: page,
                        // items show
                        total: myDataSkills && myDataSkills.total,
                        // total:20,
                        // pageSize: 100,
                        onChange: (page, pageSize) => {
                          setPage(page);
                          myDispatch(getAllSkillsPage(page));
                        },
                      }}
                      size="middle"
                      dataSource={myDataSkills && myDataSkills.data}
                      rowKey={"skill_id"}
                      className="style-scroll-radius style-shadow-large bg-white rounded-3"
                    >
                      {dataTableColumns.map((item, i) => (
                        <Column
                          title={item.title}
                          dataIndex={item.dataIndex}
                          key={item.key}
                          filteredValue={item.filteredValue}
                          onFilter={item.onFilter}
                          render={item.render}
                        />
                      ))}
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </Spin>
        </>,
      ]}
    />
  );
};

export default SopervisorAdditionSkillsContainer;

// <div className="">
//   <Taps
//     title={["إضافة مهارات", "أرسال المهارات للمتدربين", "جميع المهارات"]}
//     charIdAndTarget={["a", "b", "c"]}
//     titleItem={[
//       <div className="col-12 col-md-10 col-lg-8 col-xl-6">
//         <FormsContainer
//           titleHead={
//             <FormInputTitleHead
//               title={"إضافة مهارات للمتدربين"}
//               fs={"21px"}
//             />
//           }
//           item={
//             <form className="row gx-5 gy-3 ">
//               <div className="col-12">
//                 <FormInput
//                   type={"text"}
//                   onChange={handelSkillname}
//                   // value={""}
//                   label={"اضافة مهارة جديدة"}
//                   placeholder={"عنوان المهارة..."}
//                   idAndfor={"validationCustomFullName"}
//                   isValid={"valid"}
//                   msgValid={"Gooooooooood !!"}
//                 />
//               </div>

//               <div className="col-12">
//                 <div className="col-12 col-sm-6 mt-2  m-auto">
//                   {isLoading ? (
//                     <ButtonLoading title={"جاري الإضافة"} width={"100%"} />
//                   ) : (
//                     <Button
//                       onclick={addNewSkill}
//                       title={
//                         <div className="d-flex align-items-center justify-content-center">
//                           <span>إضافة المهارة</span>
//                           <span className="mx-2 mt-1">
//                             <FontAwesomeIcon icon={faCirclePlus} />
//                           </span>
//                         </div>
//                       }
//                       //
//                       type="button"
//                     />
//                   )}
//                 </div>
//               </div>
//             </form>
//           }
//         />
//       </div>,
// <div className="">
//   <div className="row">
//     <div className="col-12 col-l ">
//       <div
//         className=" mb-4  bg-white p-3 style-shadow-small"
//         style={{ borderRadius: "var(--radius)" }}
//       >
//         <div className="col-12 col-xl-6 m-auto">
//           <div className="">
//             <FormInputTitleHead
//               textAlign={"center"}
//               title={
//                 <div className="d-flex align-items-center justify-content-center">
//                   <div>تحديد المهارات المطلوبة</div>
//                   <div className="mx-2">
//                     <FontAwesomeIcon icon={faArrowDown} />{" "}
//                   </div>
//                 </div>
//               }
//             />
//           </div>
//           <div
//             className="style-scroll-radiu"
//             // here if click container MultipleSelected get all data skills
//             onClick={async () => {
//               await myDispatch(getAllSkills());
//               fun();
//             }}
//           >
//             <Multiselect
//               // options={options} // Options to display in the dropdown
//               options={options} // Options to display in the dropdown
//               // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
//               // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
//               onSelect={onSelect} // Function will trigger on select event
//               onRemove={onRemove} // Function will trigger on remove event
//               displayValue="skill_type" // Property name to display in the dropdown options
//               placeholder="ابحث عن المهارة"
//               className="text-center rounded-3 "
//               showArrow={true}
//             />
//           </div>
//           <div
//             className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
//             style={{
//               borderRadius: "var(--radius)",
//               // width: "190px",
//             }}
//           >
//             <span className="">
//               <input
//                 className="form-check-input border shadow-none"
//                 type="checkbox"
//                 // value={arrChecked}
//                 onChange={(e) => {
//                   let checked = e.target.checked;
//                   let newArrCheckedMap;
//                   if (myDataSkills) {
//                     newArrCheckedMap = Object.values(
//                       myDataSkills
//                     ).map((item) => item.skill_id);
//                   }
//                   setArrSelectedMuiltySkills([]);
//                   if (checked) {
//                     setArrSelectedMuiltySkills(newArrCheckedMap);
//                     NotificationsToast(
//                       "done",
//                       null,
//                       "تم تحديد جميع المهارات"
//                     );
//                   } else {
//                     newArrCheckedMap = [];
//                     setArrSelectedMuiltySkills(newArrCheckedMap);
//                     NotificationsToast(
//                       "warn",
//                       null,
//                       "تم الغاء جميع المهارات"
//                     );
//                   }
//                   // if seletedted all
//                   // setArrCheckedBool(checked);
//                 }}
//               />
//             </span>{" "}
//             <span className="bg-dange fw-bold mx-3">
//               تحديد جميع المهارات
//             </span>
//           </div>
//           <div
//             className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
//             style={{
//               borderRadius: "var(--radius)",
//               // width: "190px",
//             }}
//           >
//             <span className="">
//               <input
//                 className="form-check-input border shadow-none"
//                 type="checkbox"
//                 // value={arrChecked}
//                 onChange={(e) => {
//                   let checked = e.target.checked;
//                   let newArrCheckedMap;
//                   if (role === "emp") {
//                     if (myDataInsideSopervisor) {
//                       newArrCheckedMap = Object.values(
//                         myDataInsideSopervisor.data
//                       ).map((item) => item.student_id);
//                     }
//                   } else if (role === "company") {
//                     if (myDataStudentInsideCompany) {
//                       newArrCheckedMap = Object.values(
//                         myDataStudentInsideCompany.data
//                       ).map((item) => item.student_id);
//                     }
//                   }

//                   if (checked) {
//                     setArrChecked(newArrCheckedMap);
//                     NotificationsToast(
//                       "done",
//                       null,
//                       "تم تحديد جميع المتدربين"
//                     );
//                   } else {
//                     newArrCheckedMap = [];
//                     setArrChecked(newArrCheckedMap);
//                     NotificationsToast(
//                       "warn",
//                       null,
//                       "تم الغاء جميع المتدربين"
//                     );
//                   }
//                   // if seletedted all
//                   setArrCheckedBool(checked);
//                 }}
//               />
//             </span>{" "}
//             <span className="bg-dange fw-bold mx-3">
//               تحديد جميع المتدربين
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//         {role === "emp" ? (
//           loadingInsideSopervisor ? (
//             <TableContainer
//               thead={["#", "أسم المتدرب", "الرقم التدريبي"]}
//               item={Array(4)
//                 .fill(0)
//                 .map((_, i) => (
//                   <SopervisorAdditionSkillsTableItem
//                     key={i}
//                     index={i + 1}
//                     name={
//                       <LoadingPlaceholderItem
//                         col={"col-12 col-lg-6"}
//                         isSize={"lg"}
//                       />
//                     }
//                     studentId={
//                       <LoadingPlaceholderItem
//                         col={"col-12 col-lg-6"}
//                         isSize={"lg"}
//                       />
//                     }
//                     isShowCheckBox={null}
//                   />
//                 ))}
//             />
//           ) : myDataInsideSopervisor.data ? (
//             Object.values(myDataInsideSopervisor.data).length > 0 ? (
//               <>
//                 <TableContainer
//                   thead={[
//                     "#",
//                     "أسم المتدرب",
//                     "الرقم التدريبي",
//                     "تحديد المتدرب",
//                   ]}
//                   item={
//                     <>
//                       {Object.values(myDataInsideSopervisor.data).map(
//                         (item, i) => (
//                           <SopervisorAdditionSkillsTableItem
//                             key={i}
//                             index={i + 1}
//                             name={item.name}
//                             studentId={item.student_id}
//                             onchange={(e) => {
//                               let value = e.target.value;
//                               let checked = e.target.checked;
//                               // if selceted all out the onChange
//                               if (arrCheckedBool) {
//                                 if (checked) {
//                                   NotificationsToast(
//                                     "warn",
//                                     null,
//                                     "جميع المتدربين محددين سابقاً"
//                                   );
//                                 }
//                                 return;
//                               }
//                               // if checked  === true  ? add from array
//                               if (checked) {
//                                 setArrChecked([
//                                   ...arrChecked,
//                                   parseInt(value),
//                                 ]);
//                               } else {
//                                 // if checked  === false  ? remove from array
//                                 let newArrChecked = arrChecked.filter(
//                                   (val) => val !== parseInt(value)
//                                 );
//                                 setArrChecked(newArrChecked);
//                               }
//                             }}
//                           />
//                         )
//                       )}
//                     </>
//                   }
//                 />
//                 <div className="  d-flex justify-content-center align-items-center flex-column">
//                   <div className=" my-3 ">
//                     {isLoadingAttach ? (
//                       <ButtonLoading
//                         title={"جاري الاضافة"}
//                         className={"fw-bold"}
//                         width={"292px"}
//                       />
//                     ) : (
//                       <Button
//                         width={"292px"}
//                         onclick={addSubmitSkills}
//                         title={"إرسال"}
//                       />
//                     )}
//                   </div>

//                   {/*Start pagination */}
//                   {/* <div className="">
//                     <Pagination
//                       propsCountPage={
//                         myDataInsideSopervisor &&
//                         myDataInsideSopervisor.last_page
//                       }
//                       onPress={getNowPageEmp}
//                     />
//                   </div> */}
//                   {/*End pagination */}
//                 </div>
//               </>
//             ) : (
//               <AlertMsg msg={"لايوجد متدربين"} />
//             )
//           ) : (
//             <AlertMsg msg={"لايوجد متدربين"} />
//           )
//         ) : role === "company" ? (
//           loadingStudentInsideCompany ? (
//             <TableContainer
//               thead={["#", "أسم المتدرب", "الرقم التدريبي"]}
//               item={Array(4)
//                 .fill(0)
//                 .map((_, i) => (
//                   <SopervisorAdditionSkillsTableItem
//                     key={i}
//                     index={i + 1}
//                     name={
//                       <LoadingPlaceholderItem
//                         col={"col-12 col-lg-6"}
//                         isSize={"lg"}
//                       />
//                     }
//                     studentId={
//                       <LoadingPlaceholderItem
//                         col={"col-12 col-lg-6"}
//                         isSize={"lg"}
//                       />
//                     }
//                     isShowCheckBox={null}
//                   />
//                 ))}
//             />
//           ) : myDataStudentInsideCompany.data ? (
//             Object.values(myDataStudentInsideCompany.data).length > 0 ? (
//               <>
//                 <TableContainer
//                   thead={[
//                     "#",
//                     "أسم المتدرب",
//                     "الرقم التدريبي",
//                     "تحديد المتدرب",
//                   ]}
//                   item={
//                     <>
//                       {Object.values(myDataStudentInsideCompany.data).map(
//                         (item, i) => (
//                           <SopervisorAdditionSkillsTableItem
//                             key={i}
//                             index={i + 1}
//                             name={item.name}
//                             studentId={item.student_id}
//                             onchange={(e) => {
//                               let value = e.target.value;
//                               let checked = e.target.checked;
//                               // if selceted all out the onChange
//                               if (arrCheckedBool) {
//                                 if (checked) {
//                                   NotificationsToast(
//                                     "warn",
//                                     null,
//                                     "جميع المتدربين محددين سابقاً"
//                                   );
//                                 }
//                                 return;
//                               }
//                               // if checked  === true  ? add from array
//                               if (checked) {
//                                 setArrChecked([
//                                   ...arrChecked,
//                                   parseInt(value),
//                                 ]);
//                               } else {
//                                 // if checked  === false  ? remove from array
//                                 let newArrChecked = arrChecked.filter(
//                                   (val) => val !== parseInt(value)
//                                 );
//                                 setArrChecked(newArrChecked);
//                               }
//                             }}
//                           />
//                         )
//                       )}
//                     </>
//                   }
//                 />
//                 <div className="  d-flex justify-content-center align-items-center flex-column">
//                   <div className=" my-3 ">
//                     {isLoadingAttach ? (
//                       <ButtonLoading
//                         title={"جاري الاضافة"}
//                         className={"fw-bold"}
//                         width={"292px"}
//                       />
//                     ) : (
//                       <Button
//                         width={"292px"}
//                         onclick={addSubmitSkills}
//                         title={"إرسال"}
//                       />
//                     )}
//                   </div>

//                   {/*Start pagination */}
//                   {/* <div className="">
//                     <Pagination
//                       propsCountPage={
//                         myDataStudentInsideCompany &&
//                         myDataStudentInsideCompany.last_page
//                       }
//                       onPress={getNowPageCompany}
//                     />
//                   </div> */}
//                   {/*End pagination */}
//                 </div>
//               </>
//             ) : (
//               <AlertMsg msg={"لايوجد متدربين"} />
//             )
//           ) : (
//             <AlertMsg msg={"لايوجد متدربين"} />
//           )
//         ) : null}
//       </div>,
//       <div>
//         {myDataSkills.data ? (
//           Object.values(myDataSkills.data).length > 0 ? (
//             <>
//               <TableContainer
//                 thead={["#", "أسم المهارة", "حذف المهارة"]}
//                 item={[
//                   Object.values(myDataSkills.data).map((item, i) => (
//                     <SopervisorAllSkillsItem
//                       key={i}
//                       index={i + 1}
//                       name={item.skill_type}
//                     />
//                   )),
//                 ]}
//               />
//               {/*Start pagination */}
//               {/* <div className="my-3 d-flex justify-content-center align-items-center">
//                 <Pagination
//                   propsCountPage={myDataSkills && myDataSkills.last_page}
//                   onPress={getNowPageSkills}
//                 />
//               </div> */}
//               {/*End pagination */}
//             </>
//           ) : (
//             <AlertMsg msg={"لاتوجد مهارات"} />
//           )
//         ) : (
//           <AlertMsg msg={"لاتوجد مهارات"} />
//         )}
//       </div>,
//     ]}
//   />
// </div>
