import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import CompanyAdditionTasksContainer from "../../../components/company/addition/tasks/CompanyAdditionTasksContainer";

const CompanyAdditionTasksPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["إضافات للمتدربين", "إضافة مهام"]} />
        <div className="container p-3 p-md-5">
          <CompanyAdditionTasksContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyAdditionTasksPage;
