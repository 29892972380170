import React from "react";
import FollowUpGradeTotalCard from "../FollowUpGradeTotalCard";
import LoadingPlaceholderItem from "../../../../duplicateComponents/LoadingPlaceholderItem";
// import FollowUpGradeContainerLogic from "../../../../../logic/student/grades/FollowUpGradeContainerLogic";

const FollowUpGradesSummaryContainer = ({
  propsMyDataCompnay,
  propsLoading,
}) => {
  //   const [myDataGrades, loading] = FollowUpGradeContainerLogic();

  //   console.log(myDataGrades && myDataGrades);

  return (
    <div className="col-12 col-lg-6 placeholder-glow">
      {propsLoading ? (
        <FollowUpGradeTotalCard
          grade={
            <LoadingPlaceholderItem
              isSize={"sm"}
              col={"col-3 col-sm-2 mx-2"}
              styleBgColor={"var(--primary-color-light)"}
            />
          }
          percent={null}
        />
      ) : propsMyDataCompnay ? (
        <FollowUpGradeTotalCard
          grade={
            !isNaN(propsMyDataCompnay.total)
              ? propsMyDataCompnay.total === null
                ? "-"
                : parseInt(propsMyDataCompnay.total)
              : "-"
          }
          percent={
            !isNaN(propsMyDataCompnay.total)
              ? propsMyDataCompnay.total === null
                ? "-"
                : parseInt(propsMyDataCompnay.total)
              : "-"
          }
        />
      ) : (
        <FollowUpGradeTotalCard grade={null} percent={0} />
      )}
    </div>
  );
};

export default FollowUpGradesSummaryContainer;
