import React from "react";
import CompanyAdditionSkillsContainer from "../../../components/company/addition/skills/CompanyAdditionSkillsContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";

const CompanyAdditionSkillsPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["إضافات للمتدربين", "إضافة مهارات"]} />
        <div className="container p-3 p-md-5">
          <CompanyAdditionSkillsContainer />
        </div>
      </div>
    </div>
  );
};

export default CompanyAdditionSkillsPage;
