import { UseCreateData } from "../../../hooks/UseCreateData";
import {
  AUTH_LOGIN,
  GET_ERROR,
  RESET_PASSWORD_LOGIN,
  RESET_PASSWORD_PROFILE,
} from "../../types/Types";

const authLogin = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("store", dataParams);
    dispatch({
      type: AUTH_LOGIN,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err.response,
    });
  }
};
// reset Password login
const resetPasswordLogin = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("reset_pass", dataParams);
    dispatch({
      type: RESET_PASSWORD_LOGIN,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err.response,
    });
  }
};

// reset Password profile
const resetPasswordProfile = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("reset_password", dataParams);
    dispatch({
      type: RESET_PASSWORD_PROFILE,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err.response,
    });
  }
};

export { authLogin, resetPasswordLogin, resetPasswordProfile };
