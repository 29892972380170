/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailPattern, role } from "../../../../redux/types/Types";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  getAllStudentWithoutPagination,
  getAllStudentWithoutSopervisor,
} from "../../../../redux/actions/student/ActionStudent";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  editSopervisor,
  getAllSopervisorPage,
} from "../../../../redux/actions/sopervisor/ActionSopervisor";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";
import {
  editCompany,
  getAllCompanyPage,
} from "../../../../redux/actions/company/ActionCompany";

const AdminCompanyEditContainer = () => {
  const myDispatch = useDispatch();
  const idRoute = useParams();
  const [idParams, setIdParams] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [studentId, setstudentId] = useState("");

  // api get all comapny
  const myDataCompany = useSelector((state) => state.rootCompany.allCompany);
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(getAllCompanyPage(idRoute.page));
  }, []);

  const responseEditCompany = useSelector(
    (state) => state.rootCompany.editCompany
  );
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );

  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsCollage, setOptionsCollage] = useState([]);

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  // start selcet dropdown
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({ ...dataInputs, department: selectedItem.department_name });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, department: "" });
  };

  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };

  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funDepartment();
  }, [optionsDepartment, optionsCollage]);
  // end selcet dropdown

  const [dataInputs, setDataInputs] = useState({});

  // console.log(dataInputs);
  useEffect(() => {
    if (myDataCompany) {
      setDataInputs({
        username: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].username === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].username
        }`,
        company_name: `${
          loadingCompany
            ? ""
            : myDataCompany &&
              myDataCompany.data[idParams].company_name === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].company_name
        }`,
        Fax: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].Fax === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].Fax
        }`,
        department_name: `${
          loadingCompany
            ? ""
            : myDataCompany &&
              myDataCompany.data[idParams].department_name === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].department_name
        }`,
        about: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].about === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].about
        }`,
        phone: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].phone === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].phone
        }`,
        email: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].email === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].email
        }`,
        Zip_code: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].Zip_code === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].Zip_code
        }`,
        Working_hours: `${
          loadingCompany
            ? ""
            : myDataCompany &&
              myDataCompany.data[idParams].Working_hours === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].Working_hours
        }`,
        address: `${
          loadingCompany
            ? ""
            : myDataCompany && myDataCompany.data[idParams].address === null
            ? ""
            : myDataCompany && myDataCompany.data[idParams].address
        }`,
      });
    }
  }, [myDataCompany]);

  const submitInsertNewStudent = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(editCompany(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseEditCompany) {
        myDispatch(getAllCompanyPage(idRoute.page));
        myDispatch(getAllStudentWithoutPagination());
      }

      if (!responseEditCompany) {
        NotificationsToast("error", null, "هناك مشكلة في تعديل بيانات الجهة");
        setIsLoading(false);
        return;
      }
      if (responseEditCompany.status === 200) {
        if (responseEditCompany.data === "done") {
          NotificationsToast("done", null, "تم تعديل بيانات الجهة بنجاح");
          setIsLoading(false);
          return;
        }
      } else {
        NotificationsToast("error", null, "هناك مشكلة في تعديل بيانات الجهة");
        setIsLoading(false);
        return;
      }
      myDispatch(getAllStudentWithoutSopervisor());
      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "company_name",
      value: dataInputs.company_name || "",
      label: "أسم الجهة",
      placeholder: "أسم الجهة",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.company_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "about",
      value: dataInputs.about || "",
      label: "نبذة عن الجهة",
      placeholder: "نبذة عن الجهة",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.about === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "الهاتف",
      placeholder: "الهاتف",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
    {
      name: "department_name",
      value: dataInputs.department_name || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.department_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Working_hours",
      value: dataInputs.Working_hours || "",
      label: "عدد ساعات الدوام",
      placeholder: "عدد ساعات الدوام",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.Working_hours === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: "العنوان",
      placeholder: "العنوان",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Fax",
      value: dataInputs.Fax || "",
      label: "الفاكس",
      placeholder: "الفاكس",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.Fax === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Zip_code",
      value: dataInputs.Zip_code || "",
      label: "ص.ب",
      placeholder: "ص.ب",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.Zip_code === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div>
      <FormsContainer
        titleHead={
          <FormInputTitleHead
            title={
              <div className="d-flex align-items-center">
                <span>تعديل بيانات الجهة - </span>
                <span className="mx-1">
                  {loadingCompany ? (
                    <ReactLoading
                      type={"bubbles"}
                      color={"var(--primary-color)"}
                      width={40}
                      height={40}
                    />
                  ) : myDataCompany &&
                    myDataCompany.data[idParams].username === null ? (
                    ""
                  ) : (
                    myDataCompany && myDataCompany.data[idParams].username
                  )}
                </span>
              </div>
            }
            fs={"30px"}
          />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) => (
              <div className="col-12 col-lg-6" key={i}>
                {item.formTypes === "dropdown" ? (
                  <>
                    <label
                      className="form-label fw-bold "
                      style={{ fontSize: "14px" }}
                    >
                      {item.label}
                    </label>
                    <div
                      className="style-scroll-radiu"
                      // here if click container MultipleSelected get all data skills
                      onClick={async () => {
                        await myDispatch(getAllDepartment());
                        funDepartment();
                      }}
                    >
                      <Multiselect
                        options={optionsDepartment} // Options to display in the dropdown
                        // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                        // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                        onSelect={onSelectDepartment} // Function will trigger on select event
                        onRemove={onRemoveDepartment} // Function will trigger on remove event
                        displayValue={"department_name"} // Property name to display in the dropdown options
                        placeholder={`اختار ${item.label}`}
                        className="text-center rounded-3"
                        showArrow={true}
                        singleSelect={true}
                        emptyRecordMsg="لاتوجد بيانات"
                      />
                    </div>
                    <div
                      className={` position-absolut text-danger fw-bold my-2`}
                      style={{ fontSize: "12px" }}
                    >
                      {item.msgError}
                    </div>
                  </>
                ) : (
                  <FormInput
                    type={"text"}
                    onChange={item.action}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                    note={item.note}
                  />
                )}
              </div>
            ))}
          </form>
        }
      />

      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري تعديل بيانات الجهة"} width={"250px"} />
          ) : (
            <Button
              title={"تعديل"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewStudent}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من تعديل بيانات الجهة`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCompanyEditContainer;
