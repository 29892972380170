/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import {
  getCompanyByUsername,
  getStudentInsideCompanyPage,
} from "../../../../redux/actions/company/ActionCompany";
import { tokenCompanyUsername } from "../../../../redux/types/Types";
import { getGradesByUsername } from "../../../../redux/actions/grades/ActionGrades";
import { getVisitNumberByStudentId } from "../../../../redux/actions/visit/ActionVisit";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import Taps from "../../../duplicateComponents/Taps";
import FollowUpDetailsInfoStudentContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoStudentContainer";
import FollowUpDetailsInfoCompanyContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsInfoCompanyContainer";
import FollowUpDetailsGradesContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsGradesContainer";
import FollowUpDetailsVisitsContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsVisitsContainer";
import FollowUpDetailsDropStudentFromCompanyContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsDropStudentFromCompanyContainer";
import FollowUpDetailsFinalReportContainer from "../../../duplicateComponents/followUpItemWithTaps/FollowUpDetailsFinalReportContainer";

const CompanyUserStudentViewContainer = () => {
  const idRoute = useParams();

  const myDispatch = useDispatch();

  const [idParams, setIdParams] = useState(0);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);
    myDispatch(getStudentInsideCompanyPage(tokenCompanyUsername, idRoute.page));
  }, []);

  // api student inside company
  const myDataStudentInsideCompany = useSelector(
    (state) => state.rootCompany.companyInside
  );
  const loadingStudentInsideCompany = useSelector(
    (state) => state.rootCompany.loadingInside
  );

  // api compnay username
  const myDataCompany = useSelector((state) => state.rootCompany.company);
  const loadingCompany = useSelector((state) => state.rootCompany.loading);

  // api grades
  const myDataGrades = useSelector((state) => state.rootGrades.grades);

  useEffect(() => {
    myDispatch(
      getCompanyByUsername(
        loadingStudentInsideCompany
          ? null
          : myDataStudentInsideCompany &&
              myDataStudentInsideCompany.data[idParams].username
      )
    );
    myDispatch(
      getGradesByUsername(
        loadingStudentInsideCompany
          ? null
          : myDataStudentInsideCompany &&
              myDataStudentInsideCompany.data[idParams].student_id
      )
    );
    myDispatch(
      getVisitNumberByStudentId(
        loadingStudentInsideCompany
          ? null
          : myDataStudentInsideCompany &&
              myDataStudentInsideCompany.data[idParams].student_id
      )
    );
  }, [myDataStudentInsideCompany]);

  // api visit
  const myDataVisit = useSelector((state) => state.rootVisit.visit.data);
  // console.log(myDataCompany && myDataCompany);
  return (
    <div className="">
      <div className="mb-2">
        {loadingStudentInsideCompany ? (
          <ReactLoading
            type={"bubbles"}
            color={"var(--primary-color)"}
            width={40}
            height={40}
          />
        ) : (
          <TitleWithNumber
            title={
              myDataStudentInsideCompany ? (
                myDataStudentInsideCompany.data[idParams].name
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            subTitle={
              myDataStudentInsideCompany ? (
                myDataStudentInsideCompany.data[idParams].student_id
              ) : (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--primary-color)"}
                  width={40}
                  height={40}
                />
              )
            }
            num={null}
          />
        )}
      </div>

      <Taps
        title={[
          "معلومات المتدرب",
          "معلومات الجهة",
          "تفاصيل الدرجات",
          "عدد الزيارات",
          "التقرير النهائي",
          "طلب الغاء المتدرب",
        ]}
        charIdAndTarget={["a", "b", "c", "d", "e", "F"]}
        titleItem={[
          <FollowUpDetailsInfoStudentContainer
            propsLoadingStudent={loadingStudentInsideCompany}
            propsStudent={
              loadingStudentInsideCompany
                ? null
                : myDataStudentInsideCompany &&
                  myDataStudentInsideCompany.data[idParams]
            }
          />,
          <FollowUpDetailsInfoCompanyContainer
            propsLaodingCompany={loadingCompany}
            propsCompany={myDataCompany.data && myDataCompany.data}
          />,
          <FollowUpDetailsGradesContainer
            propsGrades={myDataGrades && myDataGrades}
          />,
          <FollowUpDetailsVisitsContainer
            //props student_id => 441202295
            propsVisitCount={myDataVisit && myDataVisit}
          />,
          <FollowUpDetailsFinalReportContainer />,
          <FollowUpDetailsDropStudentFromCompanyContainer
            propsStudent={
              myDataStudentInsideCompany.data &&
              myDataStudentInsideCompany.data[idParams]
            }
            propsCompany={myDataCompany.data && myDataCompany.data}
          />,
        ]}
      />
    </div>
  );
};

export default CompanyUserStudentViewContainer;
