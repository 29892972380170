import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_DEPARTMENT,
  GET_ALL_DEPARTMENT_PAGE,
  GET_ERROR,
  INSERT_DEPARTMENT,
} from "../../types/Types";

const getAllDepartment = () => async (dispatch) => {
  try {
    const response = await UseGetData(`getdepartment`);
    dispatch({
      type: GET_ALL_DEPARTMENT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllDepartmentPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(
      `getdepartment?page=${dataParams}&limit=10`
    );
    dispatch({
      type: GET_ALL_DEPARTMENT_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const insertDepartment = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`indepartment`, dataParams);
    dispatch({
      type: INSERT_DEPARTMENT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export { getAllDepartment, insertDepartment, getAllDepartmentPage };
