import React from "react";
import { Sidebar, Menu } from "react-pro-sidebar";
import DateAndDay from "../../duplicateComponents/DateAndDay";
import {
  PieChartOutlined,
  PieChartFilled,
  DotChartOutlined,
  CaretLeftOutlined,
  LeftCircleFilled,
  OrderedListOutlined,
  FileExcelOutlined,
  BlockOutlined,
  DeleteFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import SidebarLogo from "../../sideBar/SidebarLogo";
import SideBarItem from "../../sideBar/SideBarItem";
import SideBarItemWithSubItem from "../../sideBar/SideBarItemWithSubItem";
import SideBarSubItem from "../../sideBar/SideBarSubItem";
import AdminSidebarLogic from "../../../logic/admin/layout/AdminSidebarLogic";
const AdminSidebar = ({ isPositionFixed = true }) => {
  const [loadingSubmissitionApp, myDataSubmissitionApp] = AdminSidebarLogic();

  return (
    <Sidebar
      rtl={true}
      className="border-0"
      rootStyles={{
        position: isPositionFixed ? "fixed" : "relative",
        height: "100%",
        overflowY: "auto !important",
        Zndex: 3,
        top: 0,
        userSelect: "none !important",
      }}
    >
      <div className=" h-100 d-flex justify-content-between flex-column ">
        <Menu>
          <SidebarLogo />

          <SideBarItem
            title={`حالة مدير النظام`}
            icon={<PieChartOutlined />}
            iconActive={<PieChartFilled />}
            path={"/admin/status"}
          />
          <SideBarItemWithSubItem
            title={"المستخدمين"}
            icon={<DotChartOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"المتدربين"}
                  path={"/admin/users-student"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"المشرفين"}
                  path={"/admin/users-sopervisor"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                {/* <SideBarSubItem
                    title={"المنسقين"}
                    path={"/admin/follow-up-coop"}
                    icon={<CaretLeftOutlined />}
                    iconActive={<LeftCircleFilled />}
                  /> */}
                <SideBarSubItem
                  title={"الجهات"}
                  path={"/admin/users-company"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"كليات"}
                  path={"/admin/users-collage"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"الاقسام"}
                  path={"/admin/users-department"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"التخصصات"}
                  path={"/admin/users-major"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />

          <SideBarItemWithSubItem
            title={"إضافة Excel"}
            icon={<FileExcelOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"المتدربين"}
                  path={"/admin/excel-student"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                {/* <SideBarSubItem
                   title={"المشرفين"}
                   path={"/admin/excel-sopervisor"}
                    aretLeftOutlined />}
                    iconActive={<LeftCircleFilled />}
                   />
                 <SideBarSubItem
                   title={"المنسقين"}
                   path={"/admin/excel-coop"}
                    icon={<CaretLeftOutlined />}
                    iconActive={<LeftCircleFilled />}
                   />
                 <SideBarSubItem
                   title={"الجهات"}
                   path={"/admin/excel-company"}
                    icon={<CaretLeftOutlined />}
                    iconActive={<LeftCircleFilled />}
                   />
                 <SideBarSubItem
                   title={"كليات"}
                   path={"/admin/excel-collage"}
                    icon={<CaretLeftOutlined />}
                    iconActive={<LeftCircleFilled />}
                 /> */}
              </div>
            }
          />

          {/* <SideBarItemWithSubItem
            title={"النماذج"}
            icon={<FileTextOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"توجية المتدرب"}
                  path={"/sopervisor/forms-Guidance"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"مباشرة التدريب"}
                  path={"/sopervisor/forms-direct"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"طلب فرص تدريب"}
                  path={"/sopervisor/forms-request-trining"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"سير العملية التدريبية"}
                  path={"/sopervisor/forms-trining-Process"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"الفرص التدريبية المتاحة"}
                  path={"/sopervisor/forms-trining-avalible"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"شهادة اتمام للمتدرب"}
                  path={"/sopervisor/forms-cerf"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          /> */}
          <SideBarItemWithSubItem
            title={"ربط المستخدمين"}
            icon={<BlockOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"المتدربين و المشرفين"}
                  path={"/admin/relation-student-soper"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />
          <SideBarItem
            title={`تقييم التدريب التعاوني`}
            icon={<PieChartOutlined />}
            iconActive={<PieChartFilled />}
            path={"/admin/poll-reviews"}
          />
          <SideBarItem
            title={`حالة الطلبات`}
            icon={<OrderedListOutlined />}
            iconActive={<OrderedListOutlined />}
            path={"/admin/order"}
            isHideNotification={true}
            valueNotification={
              loadingSubmissitionApp
                ? 0
                : myDataSubmissitionApp && myDataSubmissitionApp.total
            }
          />
          <SideBarItem
            title={`حذف المستخدمين`}
            icon={<DeleteOutlined />}
            iconActive={<DeleteFilled />}
            path={"/admin/clear-users"}
          />
        </Menu>
        <div className="mt-5">
          <div className="my-3">
            <DateAndDay />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default AdminSidebar;
