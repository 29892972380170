/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { getStudentInsideCompanyWithoutPagination } from "../../../../redux/actions/company/ActionCompany";
import { NotificationsToast } from "../../../../components/duplicateComponents/NotificationsToast";
import {
  getAllTasks,
  insertAttachTasks,
} from "../../../../redux/actions/tasks/ActionTasks";
import { tokenCompanyUsername } from "../../../../redux/types/Types";

const CompanyAdditionTasksLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllTasks());
    myDispatch(getStudentInsideCompanyWithoutPagination(tokenCompanyUsername));
  }, []);

  // api student inside company
  const myDataStudentInsideCompany = useSelector(
    (state) => state.rootCompany.companyInsideWithoutPagination
  );
  //   const loadingStudentInsideCompany = useSelector(
  //     (state) => state.rootCompany.loading
  //   );

  // api tasks
  const myDataTasks = useSelector((state) => state.rootTasks.tasks);
  const loadingTasks = useSelector((state) => state.rootTasks.loading);
  const responseTasks = useSelector((state) => state.rootTasks.insertTasks);
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  const [tasksName, setTasksName] = useState("");
  const [arrChecked, setArrChecked] = useState([]);
  const [isLoadAttach, setIsLoadAttach] = useState(true);
  const [isLoadingAttach, setIsLoadingAttach] = useState(false);
  //   const [arrCheckedBool, setArrCheckedBool] = useState(false);

  const handelTaskName = (e) => {
    if (e.target.value.length > 0) {
      setIsShowMsgError(false);
    } else {
      setIsShowMsgError(true);
    }
    setTasksName(e.target.value);
  };

  const submitAddTasks = async (e) => {
    e.preventDefault();

    if (tasksName === "") {
      setIsShowMsgError(true);
      NotificationsToast("warn", null, "الحقل إضافة المهام مطلوب");
      return;
    }

    if (arrChecked.length === 0) {
      NotificationsToast("warn", null, "يجب إختيار المتدربين");
      return;
    }

    setIsLoadAttach(true);
    setIsLoadingAttach(true);
    await myDispatch(
      insertAttachTasks({
        task_details: tasksName,
        students: arrChecked,
      })
    );
    setIsLoadAttach(false);
    setTasksName("");
  };

  useEffect(() => {
    if (isLoadAttach === false) {
      if (responseTasks) {
        //   console.log(responseTasks);
        myDispatch(getAllTasks());
      }
      if (responseTasks.status === 200) {
        if (responseTasks.data === "done") {
          NotificationsToast("done", null, "تم إاضافة المهارة للمتدرب");
          setIsLoadingAttach(false);
          return;
        }
      } else {
        NotificationsToast("error", null, "هناك مشكلة في اضافة المهمة");
        setIsLoadingAttach(false);
        return;
      }
      setIsLoadAttach(true);
    }
  }, [isLoadAttach]);

  return [
    handelTaskName,
    tasksName,
    setArrChecked,
    arrChecked,
    isLoadingAttach,
    submitAddTasks,
    myDataTasks,
    loadingTasks,
    myDataStudentInsideCompany,
    isShowMsgError,
  ];
};

export default CompanyAdditionTasksLogic;
