/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spin, Table } from "antd";
import { StopFilled } from "@ant-design/icons";
import ButtonRoundedFill from "../../duplicateComponents/ButtonRoundedFill";
import SopervisorTitleWithNumber from "../../duplicateComponents/TitleWithNumber";
import FormInput from "../../duplicateComponents/FormInput";
import TableLodaing from "../../duplicateComponents/TableLodaing";
import {
  getSubmissitionByApproved,
  getSubmissitionByApprovedPage,
  insertSubmissitionLastDrop,
} from "../../../redux/actions/submissition/ActionSubmissitions";
import { NotificationsToast } from "../../duplicateComponents/NotificationsToast";

const { Column } = Table;
const SopervisorOrderDropContainer = () => {
  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getSubmissitionByApproved("4"));
  }, []);

  const myDataSubmissitionFirstDrop = useSelector(
    (state) => state.rootSubmissitions.submissitionApproved
  );

  const loadingSubmissitionFirstDrop = useSelector(
    (state) => state.rootSubmissitions.loadingSubmissitionApproved
  );

  const responseSubmissitionLastDrop = useSelector(
    (state) => state.rootSubmissitions.submissitionLastDrop
  );

  const [isLoadA, setIsLoadA] = useState(true);
  const [isLoadingA, setIsLoadingA] = useState(false);
  const submitSubLastDrop = async (e, stuedntId) => {
    e.preventDefault();

    setIsLoadA(true);
    setIsLoadingA(true);
    await myDispatch(
      insertSubmissitionLastDrop({
        student_id: stuedntId,
      })
    );
    setIsLoadA(false);
  };

  useEffect(() => {
    if (isLoadA === false) {
      if (responseSubmissitionLastDrop) {
        myDispatch(getSubmissitionByApproved("4"));
      }

      if (responseSubmissitionLastDrop.status === 200) {
        if (responseSubmissitionLastDrop.data === "somthing wrong") {
          NotificationsToast("warn", null, "تم القبول مسبقاً");
          setIsLoadingA(false);
          return;
        } else if (responseSubmissitionLastDrop.data === "done") {
          NotificationsToast("done", null, "تم الغاء المتدرب ");
          setIsLoadingA(false);
          return;
        } else {
          if (responseSubmissitionLastDrop.status !== 200) {
            // console.log("app");
            NotificationsToast("error", "في الغاء المتدرب ", " هناك مشكلة");
            setIsLoadingA(false);
            return;
          }
        }
      }
      setIsLoadA(true);
    }
  }, [isLoadA]);

  const [page, setPage] = useState(2);
  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "اسم المتدرب",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "الجهة",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.company_name === null ? "-" : record.company_name;
      },
    },
    {
      title: "الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.collage_name === null ? "-" : record.collage_name;
      },
    },
    {
      title: "القسم",
      dataIndex: "Department",
      key: "Department",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.Department === null ? "-" : record.Department;
      },
    },
    {
      title: "التخصص",
      dataIndex: "major",
      key: "major",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.major === null ? "-" : record.major;
      },
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<StopFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => submitSubLastDrop(e, record.student_id)}
                />
              </span>{" "}
            </>
          </div>
        );
      },
    },
  ];

  return (
    <Spin
      spinning={isLoadingA}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <SopervisorTitleWithNumber
                title={"جميع الطلبات الغاء المتدربين"}
                subTitle={"عدد الطلبات"}
                num={
                  myDataSubmissitionFirstDrop
                    ? myDataSubmissitionFirstDrop === "nothing here"
                      ? 0
                      : myDataSubmissitionFirstDrop.total
                    : 0
                }
              />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingSubmissitionFirstDrop ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total:
                    myDataSubmissitionFirstDrop &&
                    myDataSubmissitionFirstDrop.total,
                  // pageSize: 2,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(getSubmissitionByApprovedPage("4", page));
                  },
                }}
                size="middle"
                dataSource={
                  myDataSubmissitionFirstDrop &&
                  myDataSubmissitionFirstDrop.data
                }
                rowKey={"submissions_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SopervisorOrderDropContainer;

// <div className="">
//   <div className="col-12 col-lg-6 mb-3">
//     <SopervisorTitleWithNumber
//       title={"جميع الطلبات الغاء المتربين"}
//       subTitle={"عدد الطلبات"}
//       num={
//         myDataSubmissitionFirstDrop &&
//         myDataSubmissitionFirstDrop === "nothing here"
//           ? 0
//           : myDataSubmissitionFirstDropTotal
//       }
//     />
//     <FormInput
//       type={"search"}
//       onChange={handelQuery}
//       placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//       idAndfor={"validationCustomFullName"}
//       isValid={"valid"}
//       msgValid={"Gooooooooood !!"}
//       shadow={"style-shadow-large-extra"}
//       isShowIcon={true}
//       icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//     />
//   </div>
//   {loadingSubmissitionFirstDrop ? (
//     <TableContainer
//       thead={[
//         "#",
//         "اسم المتدرب",
//         "الرقم التدريبي",
//         "الكلية",
//         "القسم",
//         "التخصص",
//         "الغاء المتدرب",
//       ]}
//       item={Array(4)
//         .fill(0)
//         .map((_, i) => (
//           <OrderItem
//             key={i}
//             index={i + 1}
//             name={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             stuedntId={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             collageName={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             major={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             specialty={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             isHideBtn={null}
//           />
//         ))}
//     />
//   ) : (
//     <>
//       {myDataSubmissitionFirstDrop.data ? (
//         myDataSubmissitionFirstDrop.data.length > 0 ? (
//           <>
//             <TableContainer
//               thead={[
//                 "#",
//                 "اسم المتدرب",
//                 "الرقم التدريبي",
//                 "الكلية",
//                 "القسم",
//                 "التخصص",
//                 "الغاء المتدرب",
//               ]}
//               item={
//                 <>
//                   {Object.values(myDataSubmissitionFirstDrop.data).length >
//                   0 ? (
//                     Object.values(myDataSubmissitionFirstDrop.data)
//                       .filter((item, i) => {
//                         if (query === "") {
//                           return item;
//                         } else if (
//                           item.name
//                             .toLocaleLowerCase()
//                             .includes(query.toLocaleLowerCase()) ||
//                           item.student_id.toString().includes(query)
//                         ) {
//                           return item;
//                         }
//                       })
//                       .map((item, i) => (
//                         <OrderDropItem
//                           key={i}
//                           index={i + 1}
//                           name={item.name === null ? "-" : item.name}
//                           stuedntId={
//                             item.student_id === null ? "-" : item.student_id
//                           }
//                           collageName={
//                             item.collage_name === null
//                               ? "-"
//                               : item.collage_name
//                           }
//                           major={
//                             item.Department === null ? "-" : item.Department
//                           }
//                           specialty={item.major === null ? "-" : item.major}
//                           subId={item.submissions_id}
//                         />
//                       ))
//                   ) : (
//                     <AlertMsg msg={"لاتوجد طلبات الغاء "} />
//                   )}
//                 </>
//               }
//             />
//             {/*Start pagination */}
//             <div className="my-3 d-flex justify-content-center align-items-center col">
//               <Pagination
//                 propsCountPage={
//                   myDataSubmissitionFirstDrop &&
//                   myDataSubmissitionFirstDrop.last_page
//                 }
//                 onPress={getNowPage}
//               />{" "}
//             </div>
//             {/*End pagination */}
//           </>
//         ) : (
//           <AlertMsg msg={"لاتوجد طلبات الغاء "} />
//         )
//       ) : (
//         <AlertMsg msg={"لاتوجد طلبات الغاء "} />
//       )}
//     </>
//   )}
// </div>
