import {
  GET_ERROR,
  INSERT_REVIEWS_COMPANY,
  INSERT_REVIEWS_STUDENT,
} from "../../types/Types";

const inital = {
  reviews: [],
  loading: true,
};
const reducerReviews = (state = inital, action) => {
  switch (action.type) {
    case INSERT_REVIEWS_STUDENT:
      return {
        reviews: action.payload,
        loading: false,
      };

    case INSERT_REVIEWS_COMPANY:
      return {
        reviews: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        reviews: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default reducerReviews;
