/* eslint-disable no-unused-vars */
import React from "react";
import DateAndDay from "../../duplicateComponents/DateAndDay";
import { Menu, Sidebar } from "react-pro-sidebar";
import {
  PieChartOutlined,
  PieChartFilled,
  QuestionCircleOutlined,
  QuestionCircleFilled,
  DotChartOutlined,
  CaretLeftOutlined,
  LeftCircleFilled,
  FileTextOutlined,
  RiseOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import SideBarItem from "../../sideBar/SideBarItem";
import SideBarItemWithSubItem from "../../sideBar/SideBarItemWithSubItem";
import SideBarSubItem from "../../sideBar/SideBarSubItem";
const StudentSidebar = ({ isPositionFixed = true }) => {
  return (
    <Sidebar
      rtl={true}
      className="border-0"
      rootStyles={{
        position: isPositionFixed ? "fixed" : "relative",
        height: "100%",
        overflowY: "auto !important",
        Zndex: 3,
        top: 0,
        userSelect: "none !important",
      }}
    >
      <div className=" h-100 d-flex justify-content-between flex-column ">
        <Menu>
          <div
            style={{ width: 200, height: 100 }}
            className="w-100 d-flex justify-content-center align-items-center "
          >
            <img
              src={`../../../images/logo.png`}
              alt="logo"
              style={{ maxWidth: "70%", height: "70%" }}
            />
          </div>
          <SideBarItem
            title={"حالة المتدرب"}
            icon={<PieChartOutlined />}
            iconActive={<PieChartFilled />}
            path={"/student/status"}
          />
          <SideBarItemWithSubItem
            title={"متابعة تنفيذ الخطة"}
            icon={<DotChartOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"المهام و المهارات"}
                  path={"/student/follow-up-tasks"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"الدرجات"}
                  path={"/student/follow-up-grade"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />
          <SideBarItemWithSubItem
            title={"النماذج"}
            icon={<FileTextOutlined />}
            item={
              <div className="">
                <SideBarSubItem
                  title={"التحاق بالتدريب التعاوني"}
                  path={"/student/forms-enroll-trining"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
                <SideBarSubItem
                  title={"طلب استثناء"}
                  path={"/student/forms-request-exception"}
                  icon={<CaretLeftOutlined />}
                  iconActive={<LeftCircleFilled />}
                />
              </div>
            }
          />
          <SideBarItem
            title={"استطلاع رأي"}
            icon={<QuestionCircleOutlined />}
            iconActive={<QuestionCircleFilled />}
            path={"/student/poll"}
          />
          <SideBarItem
            title={"طلب انضمام للتدريب"}
            icon={<RiseOutlined />}
            iconActive={<ShrinkOutlined />}
            path={"/student/join-request"}
          />
        </Menu>
        <div className="mt-5">
          <div className="my-3">
            <DateAndDay />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default StudentSidebar;
