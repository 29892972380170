/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SopervisorAdditionGradesDetailsTotalItem from "./SopervisorAdditionGradesDetailsTotalItem";
import TableContainer from "../../../duplicateComponents/TableContainer";
import SopervisorTitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import Button from "../../../duplicateComponents/Button";
import SopervisorAdditionGradesDetailsTriningSopervisorItem from "./SopervisorAdditionGradesDetailsTriningSopervisorItem";
import SopervisorAdditionGradesDetailsTriningSopervisorAvrageItem from "./SopervisorAdditionGradesDetailsTriningSopervisorAvrageItem";
import SopervisorAdditionGradesDetailsTriningSopervisorTotalItem from "./SopervisorAdditionGradesDetailsTriningSopervisorTotalItem";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisitNumberByStudentId,
  insertVisit,
} from "../../../../redux/actions/visit/ActionVisit";
import { insertVisitTotal } from "../../../../redux/actions/visit/ActionVisitTotal";
import { role } from "../../../../redux/types/Types";
import FormInput from "../../../duplicateComponents/FormInput";
import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
import { getAllStudent } from "../../../../redux/actions/student/ActionStudent";
import { insertGradesTotal } from "../../../../redux/actions/grades/ActionGradeTotal";
const SopervisorAdditionGradesDetailsTriningSopervisor = ({
  propsDataStudent,
}) => {
  const [dataVisits, setDataVisits] = useState([
    {
      title: "مدي مزاولة المتدرب لتخصصة",
      grade: 5,
    },
    {
      title: "مدى رغبة المتدرب لاكتساب مزيداً من الخبرات في مجال التخصص",
      grade: 5,
    },
    {
      title:
        "قدرة المتدرب على التعامل مع أدوات التواصل الالكتروني المعتمدة في التدريب",
      grade: 5,
    },
    {
      title: "تقديم التقارير الدورية",
      grade: 5,
    },
    {
      title: "تقبل التوجيهات",
      grade: 5,
    },
    {
      title: "المظهر الشخصي",
      grade: 5,
    },
  ]);

  // const idRoute = useParams();
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudent());
  }, []);

  // // api student
  const myDataStudent = useSelector((state) => state.rootStudent.student.data);

  // api 30Visit add visit
  const responseVisit = useSelector((state) => state.rootVisit.visit);

  // api 30Visit add visit
  const responseVisitTotal = useSelector(
    (state) => state.rootVisitTotal.visitTotal
  );

  //####### var Visit One
  const [valVisitOnea, setVisitOnea] = useState("");
  const [valVisitOneb, setVisitOneb] = useState("");
  const [valVisitOnec, setVisitOnec] = useState("");
  const [valVisitOned, setVisitOned] = useState("");
  const [valVisitOnee, setVisitOnee] = useState("");
  const [valVisitOnef, setVisitOnef] = useState("");
  //####### var Visit Two

  //##### loading btn and total grade => One
  const [totalVisitOne, setTotalVisitOne] = useState(null);
  const [isLoadingVisitOne, setIsLoadingVisitOne] = useState(false);
  const [isLoadVisitOne, setIsLoadVisitOne] = useState(true);
  const [noteForVisit, setNoteForVisit] = useState("");

  // // api grade total
  const responseGradeTotal = useSelector(
    (state) => state.rootInsertGradesTotal.gradesTotal
  );

  // api visit
  const myDataVisit = useSelector((state) => state.rootVisit.visit.data);

  useEffect(() => {
    if (propsDataStudent) {
      myDispatch(getVisitNumberByStudentId(propsDataStudent.student_id));
    }
  }, [myDataVisit, propsDataStudent]);

  const handelNoteVisit = (e) => {
    let val = e.target.value;
    setNoteForVisit(val);
  };
  let valLimit = 5;

  //##### handels => One
  const handelVisitOneA = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOnea(val);
      }
    }
  };

  const handelVisitOneB = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOneb(val);
      }
    }
  };
  const handelVisitOneC = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOnec(val);
      }
    }
  };

  const handelVisitOneD = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOned(val);
      }
    }
  };
  const handelVisitOneE = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOnee(val);
      }
    }
  };
  const handelVisitOneF = (e) => {
    let val = e.target.value;
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setVisitOnef(val);
      }
    }
  };

  //#### arr Handels => One
  let arrHandelVisitOne = [
    handelVisitOneA,
    handelVisitOneB,
    handelVisitOneC,
    handelVisitOneD,
    handelVisitOneE,
    handelVisitOneF,
  ];

  //#### arr val => One
  let arrValVisitOne = [
    valVisitOnea,
    valVisitOneb,
    valVisitOnec,
    valVisitOned,
    valVisitOnee,
    valVisitOnef,
  ];

  let arrValNumVisitOne = [
    parseInt(valVisitOnea),
    parseInt(valVisitOneb),
    parseInt(valVisitOnec),
    parseInt(valVisitOned),
    parseInt(valVisitOnee),
    parseInt(valVisitOnef),
  ];

  // summistion all data
  let sumArrValVisitOne = arrValNumVisitOne.reduce((one, two) => one + two);

  //#### Submit Visit one => One
  const submitAddVisitOne = async (e) => {
    e.preventDefault();

    // check the val if empty => give me msg
    if (
      valVisitOnea === "" ||
      valVisitOneb === "" ||
      valVisitOnec === "" ||
      valVisitOned === "" ||
      valVisitOnee === "" ||
      valVisitOnef === "" ||
      noteForVisit === ""
    ) {
      NotificationsToast(
        "warn",
        "جميع الحقول مطلوبة",
        `الزيارة ${!isNaN(myDataVisit) ? myDataVisit + 1 : "-"}`
      );
      return;
    }

    // the total if not empty => give me msg
    // if (totalVisitOne !== null) {
    //   NotificationsToast("warn", `الدرجة مسجلة سابقاً`, "الزيارة 1");
    //   return;
    // }

    // if summ > 40 => give me msg
    if (sumArrValVisitOne > dataVisitsReduce) {
      NotificationsToast(
        "warn",
        `الدرجات اعلى من ${dataVisitsReduce} يجب ان تكون اقل`,
        `الزيارة ${!isNaN(myDataVisit) ? myDataVisit + 1 : "-"}`
      );
      return;
    }
    if (propsDataStudent.username_emp === null) {
      NotificationsToast("warn", `المتدرب ليس لدية مشرف`);
      return;
    }
    if (propsDataStudent.username === null) {
      NotificationsToast("warn", `المتدرب ليس مسجل في الجهة`);
      return;
    }
    setIsLoadingVisitOne(true);

    setIsLoadVisitOne(true);
    await myDispatch(
      insertVisit({
        student_id: propsDataStudent && propsDataStudent.student_id,
        username_emp: propsDataStudent && propsDataStudent.username_emp,
        visit_number: !isNaN(myDataVisit) ? myDataVisit : 0,
        notes: noteForVisit,
      })
    );

    await myDispatch(
      insertVisitTotal({
        student_id: propsDataStudent && propsDataStudent.student_id,
        total: sumArrValVisitOne,
      })
    );
    await myDispatch(
      insertGradesTotal(propsDataStudent && propsDataStudent.student_id)
    );
    setIsLoadVisitOne(false);
  };

  useEffect(() => {
    if (isLoadVisitOne === false) {
      if (responseVisit.status === 200 || responseVisitTotal.status === 200) {
        // the check it is Done sem sem
        if (
          responseVisit.data === "done" ||
          responseVisitTotal.data === "done"
        ) {
          NotificationsToast("done", null, "تم إدخال الدرجة للطالب");
          setTotalVisitOne(sumArrValVisitOne);
          setIsLoadingVisitOne(false);
        } else if (
          responseVisit.data === "there is no company for this student"
        ) {
          NotificationsToast("warn", null, `المتدرب ليس مسجل في الجهة`);
          setIsLoadingVisitOne(false);
        } else {
          if (
            responseVisit.status !== 200 ||
            responseVisitTotal.status !== 200
          ) {
            NotificationsToast("error", null, `هناك مشكلة في إدخال الدرجة`);
            setIsLoadingVisitOne(false);
          }
        }
      }
      setIsLoadVisitOne(true);
    }
  }, [isLoadVisitOne]);

  // maping => [7, 5, 5, 5, 10, 4, 4]
  let dataVisitsMap = dataVisits.map((item) => item.grade);
  //###################################################
  // summiation => 30
  let dataVisitsReduce = dataVisitsMap.reduce((a, b) => a + b);

  //   let spring = <div class="spinner-border spinner-border-sm" role="status">
  //   <span class="visually-hidden">Loading...</span>
  // </div>

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row"></div>
      <TableContainer
        thead={[
          "#",
          "عناصر التقييم",
          "درجة التقييم",
          // `الزيارة ${myDataVisit} `,
          `الزيارة ${!isNaN(myDataVisit) ? myDataVisit + 1 : "..."}`,
          // "الزيارة 2",
          // "الزيارة 3",
        ]}
        item={
          <>
            {dataVisits.map((item, i) => (
              <SopervisorAdditionGradesDetailsTriningSopervisorItem
                key={i}
                index={i + 1}
                title={item.title}
                grade={item.grade}
                dueGradeOne={arrHandelVisitOne[i]}
                valueOne={arrValVisitOne[i]}
                // dueGradeTwo={arrHandelVisitTwo[i]}
                // valueTwo={arrValVisitTwo[i]}
                // dueGradeThree={arrHandelVisitThree[i]}
                // valueThree={arrValVisitThree[i]}
              />
            ))}
            <tr>
              <th>
                <div className="mt-2">7</div>
              </th>
              {/* title */}
              <td>
                <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowra">
                  ملاحضات للمتدرب
                </div>
              </td>
              <td>
                <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowrap">
                  -
                </div>
              </td>
              <td className="bg-dange ">
                <div style={{ width: "300px" }}>
                  <FormInputTextArea
                    type={"text"}
                    onChange={handelNoteVisit}
                    value={noteForVisit}
                    isLabel={null}
                    placeholder={"الملاحضات..."}
                    idAndfor={"validationCustomIdNo"}
                    isValid={"valid"}
                    msgValid={"Gooooooooood !!"}
                  />
                </div>
              </td>
            </tr>

            <SopervisorAdditionGradesDetailsTriningSopervisorAvrageItem
              index={8}
              onclickVisitOne={submitAddVisitOne}
              isLoadingVisitOne={isLoadingVisitOne}
              // onclickVisitTwo={submitAddVisitTwo}
              // isLoadingVisitTwo={isLoadingVisitTwo}
              // onclickVisitThree={submitAddVisitThree}
              // isLoadingVisitThree={isLoadingVisitThree}
              // inputCount={visitsInputCount}
            />

            <SopervisorAdditionGradesDetailsTriningSopervisorTotalItem
              index={8}
              grade={dataVisitsReduce}
              totalVisitOne={totalVisitOne}
              // totalVisitTwo={totalVisitTwo}
              // totalVisitThree={totalVisitThree}
              // inputCount={visitsInputCount}
              // totalGrade={visitsValueTotal}
            />
          </>
        }
      />
      {/* <div className="bg-dange  mt-3 d-flex justify-content-center justify-content-sm-end">
        <Button title={"حساب الدرجات"} width={"130px"} />
      </div> */}
    </div>
  );
};

export default SopervisorAdditionGradesDetailsTriningSopervisor;
