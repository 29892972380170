/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import { DeleteFilled, EditFilled, EyeFilled } from "@ant-design/icons";
import AdminDelettCompanyLogic from "../deleteUsers/AdminDelettCompanyLogic";
import { getAllCompany } from "../../../redux/actions/company/ActionCompany";

const AdminCompanyFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllCompany());
  }, []);

  // api company
  const myDataCompany = useSelector((state) => state.rootCompany.allCompany);
  const myDataCompanyTotal = useSelector(
    (state) => state.rootCompany.allCompany.total
  );
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );

  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [submitDeleteCompany, isLoadingDeleteCompany] =
    AdminDelettCompanyLogic();

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم الجهة",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.company_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "العنوان",
      dataIndex: "address",
      key: "address",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "البريد الالكتروني",
      dataIndex: "email",
      key: "email",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitDeleteCompany(e, record.company_name);
                  }}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  to={`/admin/users-company/edit/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
              <span className="mx-1">
                <LinkRoundedFill
                  title={<EyeFilled />}
                  colorHover="#fff"
                  width={40}
                  to={`/admin/users-company/view/${page}/${index}`}
                  // onclick={() => console.log("first")}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];

  return [
    myDataCompany,
    loadingCompany,
    myDataCompanyTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteCompany,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminCompanyFollowUpLogic;
