/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { getAllMajor } from "../../../redux/actions/major/ActionMajor";
import ButtonRoundedFill from "../../../components/duplicateComponents/ButtonRoundedFill";
import LinkRoundedFill from "../../../components/duplicateComponents/LinkRoundedFill";
import AdminDeleteMajorLogic from "../deleteUsers/AdminDeleteMajorLogic";

const AdminMajorFollowUpLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllMajor());
  }, []);

  // api Major
  const myDataMajor = useSelector((state) => state.rootMajor.major);
  const myDataMajorTotal = useSelector((state) => state.rootMajor.major.total);
  const loadingMajor = useSelector((state) => state.rootMajor.loading);

  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [submitDeleteMajor, isLoadingDeleteMajor] = AdminDeleteMajorLogic();
  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "أسم التخصص",
      dataIndex: "major_name",
      key: "major_name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.major_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },

    {
      title: "أسم القسم",
      dataIndex: "department_name",
      key: "department_name",
      filteredValue: null,
      onFilter: null,
      render: null,
    },

    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<DeleteFilled />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitDeleteMajor(e, record.major_name);
                  }}
                />
              </span>
              {/* <span className="mx-1">
                <LinkRoundedFill
                  title={<EditFilled />}
                  bgColor="var(--yellow)"
                  color="var(--yellow)"
                  colorHover="var(--darkColor)"
                  bgHover="var(--yellow)"
                  width={40}
                  // to={`/admin/projects/edit/${0}`}
                  // onclick={() => console.log("first")}
                />
              </span> */}
            </>
          </div>
        );
      },
    },
  ];

  return [
    myDataMajor,
    loadingMajor,
    myDataMajorTotal,
    setPage,
    setSearchText,
    page,
    isLoadingDeleteMajor,
    dataTableColumns,
    myDispatch,
  ];
};

export default AdminMajorFollowUpLogic;
