import React from "react";

const FormInputRadio = ({ label, name, idAndFor, value,onChange ,checked}) => {
  return (
    <div className="">
      <input
        className="form-check-input shadow-none"
        style={{cursor:"pointer"}}
        type="radio"
        name={name}
        id={idAndFor}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label
        className="form-check-label mx-2"
        style={{ fontSize: "14px" }}
        htmlFor={idAndFor}
      >
        {label}
      </label>
    </div>
  );
};

export default FormInputRadio;
