/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import {
  ProfileOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getStudentByUsername } from "../../../redux/actions/student/ActionStudent";
import { tokenStudentUsername } from "../../../redux/types/Types";
const StudentHeaderLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getStudentByUsername(tokenStudentUsername));
  }, []);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log(window.innerWidth);
    // console.log(collapseWidth);
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
      // setCollapseWidth(window.innerWidth)
      // setCollapse(true);
    } else {
      // setCollapse(false);
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);

  // const nav = useNavigate();
  // // const auth = useAuth();

  // const handelLogout = () => {
  //   auth.logout();
  //   nav("/", { replace: true });
  // };
  const nav = useNavigate();
  const auth = useAuth();

  const handelLogout = () => {
    auth.logout();
    nav("/", { replace: true });
  };
  const items = [
    {
      key: "1",
      label: (
        <Link style={{ textDecoration: "none" }} to={"/student/profile"}>
          المعلومات الشخصية
        </Link>
      ),
      icon: <ProfileOutlined className="mt-1" />,
    },

    {
      key: "2",
      danger: true,
      // label: (
      //   <div
      //     onClick={() => {
      //       if (
      //         localStorage.getItem("token") &&
      //         localStorage.getItem("storageDataObj") &&
      //         localStorage.getItem("role")
      //       ) {
      //         localStorage.clear();
      //         window.location.href = "/";
      //         // console.log("logout");
      //       }
      //     }}
      //   >
      //     تسجيل خروج
      //   </div>
      // ),
      label: <div onClick={handelLogout}>تسجيل خروج</div>,
      icon: <LogoutOutlined className="mt-1" />,
    },
  ];
  // api students
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loading = useSelector((state) => state.rootStudent.loading);
  // isLoad show loading if no data get form api
  return [myDataStudent, loading, open, setOpen, onClose, items];
};

export default StudentHeaderLogic;
