/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentByUsername } from "../../../redux/actions/student/ActionStudent";
import { tokenStudentUsername } from "../../../redux/types/Types";

const StudentStatusPageLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getStudentByUsername(tokenStudentUsername));
  }, []);

  // Start Student
  const myDataStudent = useSelector((state) => state.rootStudent.student);

  const loading = useSelector((state) => state.rootStudent.loading);

  return [myDataStudent, loading];
};

export default StudentStatusPageLogic;
