import React from "react";
import StudentStatusContainer from "../../../components/student/status/StudentStatusContainer";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import StudentStatusCardContainer from "../../../components/student/status/StudentStatusCardContainer";
// import { Link } from "react-router-dom";
const StudentStatusPage = () => {
  return (
    <div className="position-relative">
      <div className="main ">
        <Breadcrumbs element={["حالة المتدرب"]} />
        <div className="container p-3 p-md-5">
          <StudentStatusCardContainer />
          {/* <Link to={"/student/profile"}>رفع</Link> */}
          <StudentStatusContainer />
        </div>
      </div>
    </div>
  );
};

export default StudentStatusPage;
