/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import TimeLineBoxCardDarkGreen from "../../../duplicateComponents/TimeLineBoxCardDarkGreen";
import LoadingTimeLineLightGreen from "../../../duplicateComponents/LoadingTimeLineLightGreen";
import TimeLineWithTitleAndSubTitle from "../../../duplicateComponents/TimeLineWithTitleAndSubTitle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllSopervisorPage } from "../../../../redux/actions/sopervisor/ActionSopervisor";

const AdminSopervisorViewContainer = () => {
  const columnEng = [
    "name",
    "username_emp",
    "phone",
    "email",
    "job_type",
    "department",
    "collage_name",
  ];

  const columnAr = [
    "الأسم الكامل",
    "اسم الستخدم",
    "رقم الهاتف",
    "البريد الالكتروني",
    "الرقم الوظيفي",
    "القسم",
    "أسم الكلية",
  ];

  const idRoute = useParams();

  const myDispatch = useDispatch();

  useEffect(() => {
    myDispatch(getAllSopervisorPage(idRoute.page));
  }, []);

  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor.data
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );

  return (
    <TimeLineBoxCardDarkGreen
      titleHeader={"معلومات المشرف"}
      item={
        <>
          {loadingSopervisor ? (
            <LoadingTimeLineLightGreen countPlaceholder={4} />
          ) : myDataSopervisor ? (
            columnAr.map((item, i) => (
              <TimeLineWithTitleAndSubTitle
                key={i}
                title={item}
                subTitle={
                  myDataSopervisor[idRoute.id][columnEng[i]] === null
                    ? "-"
                    : myDataSopervisor[idRoute.id][columnEng[i]]
                }
                whatColor={"lightGreen"}
              />
            ))
          ) : (
            <TimeLineWithTitleAndSubTitle
              title={"لاتوجد بيانات"}
              whatColor={"lightGreen"}
            />
          )}
        </>
      }
    />
  );
};

export default AdminSopervisorViewContainer;
