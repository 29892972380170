/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Fade from "react-reveal/Fade";

const About = () => {
  return (
    <div
      className="container-fluid p-0 m-0 position-relative  text-white"
      id="about-homepage"
      style={{ backgroundColor: "var(--primary-color)" }}
    >
      {/* <div
        className="position-absolute border-radius-contact-us-2"
        style={{
          backgroundImage: "url('../../images/bg-contact-us.jpg')",
          width: "100%",
          height: "100%",
          bottom: "0",
          // opacity: "0.2",
        }}
      ></div>

      <div
        className="position-absolute bg-dar border-radius-contact-us-2"
        style={{
          opacity: "0.9",
          backgroundColor: "var(--green-light-color)",
          width: "100%",
          height: "100%",
          bottom: "0",
        }}
      ></div> */}
      <div className="Triangles"></div>
      <div className="container d-flex flex-column p-3  flex-md-row">
        <div className=" d-flex justify-content-center">
          <lottie-player
            src="https://lottie.host/0c84e6f5-c477-48cb-9b46-27cba2fbf0f5/LE3m3u60Yz.json"
            background="transparent"
            speed="1"
            // style={{ width: "300px", height: "300px" }}
            loop
            class="style-img-goal"
            autoplay
          ></lottie-player>
        </div>
        {/* text */}
        <Fade center>
          <div className="style-margin-about-text">
            <h1 className=" fw-bold">مارس</h1>
            <div className=" text-sub-titl  mt-3 text-white text-opacity-75 fw-bold lh-lg"style={{fontSize:14}} >
              رحلة تضم الطلاب المقبلين على التخرج والمشرفين في التدريب التعاوني 
              والجهات التي تقدم تدريب تعاوني للوصل الى وجهتهم
              <span className="mx-2">
                <img
                  src="../../images/icon-Quotation-mark.png"
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                />
              </span>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default About;
