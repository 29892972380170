import React from "react";
import TimeLineBoxCardDarkGreen from "../TimeLineBoxCardDarkGreen";
import LoadingTimeLineLightGreen from "../LoadingTimeLineLightGreen";
import TimeLineWithTitleAndSubTitle from "../TimeLineWithTitleAndSubTitle";
const FollowUpDetailsInfoStudentContainer = ({
  propsLoadingStudent,
  propsStudent,
}) => {
  const columnEng = [
    "name",
    "student_id",
    "natioal_id",
    "address",
    "phone",
    "email",
    "collage_name",
    "Department",
    "major",
    "GPA",
  ];

  const columnAr = [
    "الأسم الكامل",
    "رقمك التدريبي",
    "الهوية الشخصية للمتدرب",
    "العنوان",
    "رقم الهاتف",
    "البريد الكتروني",
    "أسم الكلية",
    "القسم",
    "التخصص",
    "المعدل التراكمي",
  ];

  return (
    <TimeLineBoxCardDarkGreen
      titleHeader={"معلومات المتدرب"}
      item={
        <>
          {propsLoadingStudent ? (
            <LoadingTimeLineLightGreen countPlaceholder={4} />
          ) : propsStudent ? (
            columnAr.map((item, i) => (
              <TimeLineWithTitleAndSubTitle
                key={i}
                title={item}
                subTitle={
                  propsStudent[columnEng[i]] === null
                    ? "-"
                    : propsStudent[columnEng[i]]
                }
                whatColor={"lightGreen"}
              />
            ))
          ) : (
            <TimeLineWithTitleAndSubTitle
              title={"لاتوجد بيانات"}
              whatColor={"lightGreen"}
            />
          )}
        </>
      }
    />
  );
};

export default FollowUpDetailsInfoStudentContainer;
