import { UseCreateData } from "../../../hooks/UseCreateData";
import { GET_ERROR, ISNERT_30_GRADES } from "../../types/Types";

const insert30Grades = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("igrades", dataParams);
    dispatch({
      type: ISNERT_30_GRADES,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
export { insert30Grades };
