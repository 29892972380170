/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import FormsContainer from "./FormsContainer";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormInput from "../duplicateComponents/FormInput";
import Button from "../duplicateComponents/Button";
import FormsTriningProcessTableItem from "./FormsTriningProcessTableItem";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import TableContainer from "../duplicateComponents/TableContainer";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import { insertFormsPdfTriningProcess } from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";

const FormsTriningProcessContainer = ({ propsTitle, propsSubTitle }) => {
  const dataTriningProcess = [
    {
      title: "مدى توفر الأجهزة و الخامات لدى الجهة التدريبية ",
    },
    {
      title: "وجود بيئة آمنة و مناسبة للتدريب المتدربات   ",
    },
    {
      title: "التزام جهة التدريب بالجدية في تدريب المتدربات",
    },
    {
      title: "مدى رضا المتدربات على جهة التدريب  ",
    },
    {
      title: "ارتباط التدريب بمجال تخصص المتدربة  ",
    },
    {
      title: "مدى توفر المهارات اللازمة لدى  المتدربة ",
    },
    {
      title: "مدى جودة متدربات التدريب التعاوني  ",
    },
    {
      title: "مدى رضا جهات التدريب عن المتدربات",
    },
  ];

  const myDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const { download } = useDownloader();
  // api forms
  const responseFormsPdf = useSelector((state) => state.rootFormsPdf.formsPdf);

  let arrValReviewBool = [];

  for (let index = 0; index < 8; index++) {
    arrValReviewBool.push(false);
  }

  const [dataInputs, setDataInputs] = useState({
    name: "",
    phone: "",
    major: "",
    company_name: "",
    student_numbers: "",
    review: "",
    review2: "",
    review3: "",
    review4: "",
    review5: "",
    review6: "",
    review7: "",
    review8: "",
    notes: "",
    notes2: "",
  });

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  const submitPDF = async (e) => {
    e.preventDefault();

    // first review
    dataInputs[`review`] = arrValReviewBool[0];

    // another reviews assigment
    for (let index = 0; index < 7; index++) {
      dataInputs[`review${index + 2}`] = arrValReviewBool[index + 1];
    }

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    for (let index = 0; index < arrValReviewBool.length; index++) {
      if (arrValReviewBool[index] === false) {
        NotificationsToast("warn", null, "عناصر التقييم سير العملية التدريبية مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfTriningProcess(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseFormsPdf) {
        // console.log(responseFormsPdf);

        if (responseFormsPdf.status === 200) {
          // download(
          //   responseFormsPdf.data.replace("demo.", ""),
          //   `${propsTitle}.pdf`
          // );
          const link = document.createElement("a");
          link.target = "_blanck";
          link.href = responseFormsPdf.data.replace("demo.", "");
          link.download = `${propsTitle}.pdf`;
          link.click();

          NotificationsToast("done", null, "تم تنزيل النموذج بنجاح");
          setIsLoading(false);
          return;
        } else {
          if (responseFormsPdf.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في تنزيل النموذج");
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);
  const arrDataInputs = [
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المشرف",
      placeholder: "أسم المشرف",
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الجوال",
      placeholder: "رقم الجوال",
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "company_name",
      value: dataInputs.company_name || "",
      label: "أسم الجهة",
      placeholder: "أسم الجهة",
      msgError: isShowMsgError
        ? dataInputs.company_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "student_numbers",
      value: dataInputs.student_numbers || "",
      label: "عدد المتدربين",
      placeholder: "عدد المتدربين",
      msgError: isShowMsgError
        ? dataInputs.student_numbers === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "notes",
      value: dataInputs.notes || "",
      label: "مرئياتك حول وضع متدربات التدريب التعاوني خلال هذة الفترة",
      placeholder: "مرئياتك حول وضع متدربات التدريب التعاوني خلال هذة الفترة",
      msgError: isShowMsgError
        ? dataInputs.notes === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "notes2",
      value: dataInputs.notes2 || "",
      label: "مرئياتك حول وضع جهات التدريب خلال هذة الفترة",
      placeholder: "مرئياتك حول وضع جهات التدريب خلال هذة الفترة",
      msgError: isShowMsgError
        ? dataInputs.notes2 === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        titleHeader={`ماهو ${propsTitle} ؟`}
        fs={"22px"}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />

      {/* forms */}
      <div className="">
        <FormsContainer
          titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
          item={
            <form className="row gx-5 gy-3 needs-validation">
              {arrDataInputs.map((item, i) =>
                i === 5 || i === 6 ? (
                  <div className="col-12" key={i}>
                    <FormInputTextArea
                      type={"text"}
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                    />
                  </div>
                ) : (
                  <div className="col-lg-6" key={i}>
                    <FormInput
                      type={"text"}
                      onChange={handelDataInputs}
                      name={item.name}
                      value={item.value}
                      label={item.label}
                      placeholder={item.placeholder}
                      msgError={item.msgError}
                    />
                  </div>
                )
              )}

              <div className="col-12">
                <TableContainer
                  className={"my-3"}
                  thead={[
                    "#",
                    "عناصر التقييم",
                    "ممتاز",
                    "جيد جدا",
                    "جيد",
                    "غير مقبول",
                  ]}
                  item={
                    <>
                      {dataTriningProcess.map((item, i) => (
                        <FormsTriningProcessTableItem
                          key={i}
                          index={i + 1}
                          title={item.title}
                          propsReview={arrValReviewBool}
                        />
                      ))}
                    </>
                  }
                />
              </div>
            </form>
          }
        />
        <div className="col-12">
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
            ) : (
              <Button
                title={"أرسال"}
                width={"250px"}
                dataBsToggle="modal"
                dataBsTarget="#exampleModalForms-request-exception"
                type="submit"
              />
            )}
            <PopupAreYouSure
              id={"exampleModalForms-request-exception"}
              // onCLick={countTimeLineNow}
              onCLick={submitPDF}
              idPopupStar={"exampleModalForms-request-exception"}
              textAlign={"center"}
              title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsTriningProcessContainer;
