/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import axios from "axios";

const SectionContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState({
    app_name: "",
    from: "",
    phone: "",
    email: "",
    sub: "",
    Msg: "",
  });
  const [dataInputs, setDataInputs] = useState({
    app_name: "تكتي",
    from: "",
    phone: "",
    email: "",
    sub: "",
    Msg: "",
  });
  const handelDataInputs = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!isNaN(value)) {
        setDataInputs({ ...dataInputs, [name]: value });
      }
    } else {
      setDataInputs({ ...dataInputs, [name]: value });
    }
  };

  const arrDataInputs = [
    {
      name: "from",
      value: dataInputs.from || "",
      label: "الأسم",
      placeholder: "الأسم",
      msgError: isError
        ? dataInputs.from === ""
          ? "الحقل مطلوب"
          : errors.from
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "رقم الهاتف",
      placeholder: "رقم الهاتف",
      msgError: isError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : errors.phone
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "الإيميل",
      placeholder: "الإيميل",
      msgError: isError
        ? dataInputs.email === ""
          ? "الحقل مطلوب"
          : errors.email
        : "",
    },
    {
      name: "sub",
      value: dataInputs.sub || "",
      label: "عنوان الرسالة",
      placeholder: "عنوان الرسالة",
      msgError: isError
        ? dataInputs.sub === ""
          ? "الحقل مطلوب"
          : errors.sub
        : "",
    },
    {
      name: "Msg",
      value: dataInputs.Msg || "",
      label: "رسالتك",
      placeholder: "رسالتك",
      msgError: isError
        ? dataInputs.Msg === ""
          ? "الحقل مطلوب"
          : errors.Msg
        : "",
    },
  ];

  const sendEmail = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsError(true);
        // NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    console.log(dataInputs);
    setLoading(true);

    await axios
      .post("https://form.osoolsys.sa/public/api/FormRecive", dataInputs)
      .then((val) => {
        setLoading(false);
        setLoad(true);
        console.log("done", val);
        setResponse(val);
        setLoad(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoad(true);
        console.log("not Done", error);
        setLoad(false);
      });
  };
  useEffect(() => {
    if (load === false) {
      if (response.status !== 200) {
        NotificationsToast("error", "هناك مشكلة في إرسال الطلب ");
        return;
      }
      if (response.data === "done") {
        NotificationsToast("done", "تم إرسال طلب ");
        return;
      }
    }
  }, [load]);
  return (
    <Fade bottom>
      <div className="container p-3 p-md-5 mt-0 mt-5" id="contact-us-homepage">
        <TitleHeadSpecialHomePage title={"تواصـل معنـا"} />
        {/* <div className="text-center fw-bold mb-3">
        في حال كان لديك أي استفسارات او أسئلة تواصل معنا عبرالنموذج التالي
      </div> */}
        {/* col one */}
        <div
          className="row mt-0 style-shadow-small  mx-1"
          style={{ borderRadius: "var(--radius)", minHeight: "50vh" }}
        >
          <div className="col-12 col-lg-6 border-radius-contact-us-1 bg-white d-flex justify-content-center align-items-center flex-column p-3">
            {arrDataInputs.map((item, i) => (
              <div className="col-12 col-sm-10" key={i}>
                {i === arrDataInputs.length - 1 ? (
                  <FormInputTextArea
                    onChange={handelDataInputs}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    inputType={item.inputType}
                    icon={item.icon}
                    msgError={item.msgError}
                  />
                ) : (
                  <FormInput
                    onChange={handelDataInputs}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    inputType={item.inputType}
                    icon={item.icon}
                    msgError={item.msgError}
                    // bgColor="#fff"
                  />
                )}
              </div>
            ))}
            <div className="d-flex justify-content-center mt-4">
              {loading ? (
                <button
                  className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
                  id="style-btn-start-service"
                  style={{
                    width: "200px",
                  }}
                >
                  ... جاري الارسال
                </button>
              ) : (
                <button
                  className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
                  id="style-btn-start-service"
                  style={{
                    width: "200px",
                  }}
                  onClick={sendEmail}
                >
                  إرسال
                </button>
              )}
            </div>
          </div>
          <div
            className="col-12 col-lg-6 position-relative border-radius-contact-us-2 order-first order-lg-last d-flex justify-content-center align-items-center flex-column p-3 pt-5"
            style={{
              backgroundImage:
                "linear-gradient( 135deg, var(--primary-color) 10%, var(--primary-color) 100%)",
            }}
          >
            <div
              className="position-absolute border-radius-contact-us-2"
              style={{
                backgroundImage: "url('../../images/bg-contact-us.jpg')",
                // backgroundImage: "url('../../images/bg-landing-blur.png')",
                // backgroundRepeat: "no-repeat",
                // minHeight: "60vh",
                // backgroundSize: "100% 100%",
                // filter: "blur(5px)",
                width: "100%",
                height: "100%",
                bottom: "0",
                // opacity: "0.2",
              }}
            ></div>

            <div
              className="position-absolute bg-dar border-radius-contact-us-2"
              style={{
                opacity: "0.7",
                backgroundImage:
                  "linear-gradient( 135deg, var(--primary-color) 10%, var(--primary-color) 100%)",
                width: "100%",
                height: "100%",
                bottom: "0",
              }}
            ></div>

            <div
              className="text-center text-white mb-5"
              style={{ zIndex: "100" }}
            >
              <FontAwesomeIcon icon={faHeadset} size="4x" />

              <div className="fs-3 fw-bold mt-4">
                {/* <span className="ms-3">
                  <FontAwesomeIcon icon={faHeadset} size="3x"/>
                </span> */}
                <span>تواصـل معنـا</span>
              </div>
              <p className="mt-3 text-title-icons-blobs">
                في حال كان لديك أي استفسارات او أسئلة تواصل معنا عبرالنموذج
                التالي
              </p>
            </div>
          </div>

          {/* <div className="col-12 col-lg-6">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={"الأسم"}
            placeholder={"الأسم"}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={"البريد الإلكتروني"}
            placeholder={"البريد الإلكتروني"}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12">
          <FormInputTextArea
            // onChange={handelChangeForms}
            // value={""}
            label={"رسالتك"}
            placeholder={"رسالتك"}
            idAndfor={"validationCustomIdPrivate"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
            id="style-btn-start-service"
            style={{
              width: "200px",
            }}
          >
            إرسال
          </button>
        </div> */}
        </div>
      </div>
    </Fade>
  );
};

export default SectionContactUs;
