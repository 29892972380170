import {
  GET_ALL_REVIEWS_STUDENT,
  GET_ALL_REVIEWS_STUDENT_PAGE,
  GET_ERROR,
} from "../../types/Types";

const inital = {
  reviewsStudent: [],
  loading: true,
};
const reducerGetReviewsStudent = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS_STUDENT:
      return {
        ...state,
        reviewsStudent: action.payload,
        loading: false,
      };
    case GET_ALL_REVIEWS_STUDENT_PAGE:
      return {
        ...state,
        reviewsStudent: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        reviewsStudent: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default reducerGetReviewsStudent;
