import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_REVIEWS_STUDENT,
  GET_ALL_REVIEWS_STUDENT_PAGE,
  GET_ERROR,
} from "../../types/Types";

const getAllReviewsStudent = () => async (dispatch) => {
  try {
    const response = await UseGetData("get_students_reviews");
    dispatch({
      type: GET_ALL_REVIEWS_STUDENT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getAllReviewsStudentPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(
      `get_students_reviews?page=${dataParams}`
    );
    dispatch({
      type: GET_ALL_REVIEWS_STUDENT_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getAllReviewsStudent, getAllReviewsStudentPage };
