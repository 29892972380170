import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_STUDENT_WITHOUT_PAGINATION,
  GET_ERROR,
} from "../../types/Types";

const getAllStudentWithoutPagination = () => async (dispatch) => {
  try {
    const response = await UseGetData("students_np");
    dispatch({
      type: GET_ALL_STUDENT_WITHOUT_PAGINATION,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getAllStudentWithoutPagination };
