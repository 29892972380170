import React from "react";
// import HeaderStudent from "../../../components/student/HeaderStudent";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
// import SidebarPage from "../../sideBar/SidebarPage";
import StudentProfileContainer from "../../../components/student/profile/StudentProfileContainer";
import CheckRouteWhereYotGo from "../../../components/duplicateComponents/CheckRouteWhereYotGo";

const StudentProfilePage = () => {
  // Not History Back()
  CheckRouteWhereYotGo();
  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* <HeaderStudent /> */}
        <Breadcrumbs element={["المعلومات الشخصية"]} />
        <div className="container p-3 p-md-5">
          <StudentProfileContainer />
        </div>
      </div>
    </div>
  );
};

export default StudentProfilePage;
