/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../duplicateComponents/Button";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTitleHead from "../duplicateComponents/FormInputTitleHead";
import FormsContainer from "./FormsContainer";
import TimeLineBoxCardDarkGreen from "../duplicateComponents/TimeLineBoxCardDarkGreen";
import TimeLineWithTitleAndSubTitle from "../duplicateComponents/TimeLineWithTitleAndSubTitle";
import PopupAreYouSure from "../duplicateComponents/PopupAreYouSure";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";
import {
  insertFormsPdfDirectTrining,
  insertFormsPdfFormsGuidance,
} from "../../redux/actions/forms/ActionFormsPdf";
import ButtonLoading from "../duplicateComponents/ButtonLoading";
// import PopupDone from "../duplicateComponents/PopupDone";

const FormsGuidanceContainer = ({ propsTitle, propsSubTitle }) => {
  const myDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [downloadPathFile, setDownloadPathFile] = useState("");
  // const { download } = useDownloader();
  // api forms
  const responseFormsPdf = useSelector((state) => state.rootFormsPdf.formsPdf);

  const [dataInputs, setDataInputs] = useState({
    student_name: "",
    name: "",
    supervisor_name: "",
    sign: false,
    department: "",
    major: "",
    from: "",
    to: "",
  });

  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };

  // show msg is required if onclick the btn
  const [isShowMsgError, setIsShowMsgError] = useState(false);

  const submitPDF = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        return;
      }
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(insertFormsPdfFormsGuidance(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseFormsPdf) {
        // console.log(responseFormsPdf);

        if (responseFormsPdf.status === 200) {
          const link = document.createElement("a");
          link.target = "_blanck";
          link.href = responseFormsPdf.data.replace("demo.", "");
          link.download = `${propsTitle}.pdf`;
          link.click();

          NotificationsToast("done", null, "تم تنزيل النموذج بنجاح");
          setIsLoading(false);
          return;
        } else {
          if (responseFormsPdf.status !== 200) {
            NotificationsToast("error", null, "هناك مشكلة في تنزيل النموذج");
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "student_name",
      value: dataInputs.student_name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      msgError: isShowMsgError
        ? dataInputs.student_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "department",
      value: dataInputs.department || "",
      label: "القسم",
      placeholder: "القسم",
      msgError: isShowMsgError
        ? dataInputs.department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "supervisor_name",
      value: dataInputs.supervisor_name || "",
      label: "أسم مشرف الكلية",
      placeholder: "أسم مشرف الكلية",
      msgError: isShowMsgError
        ? dataInputs.supervisor_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم مشرف الجهة",
      placeholder: "أسم مشرف الجهة",
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "from",
      value: dataInputs.from || "",
      label: "تاريخ بداية التدريب",
      placeholder: "تاريخ بداية التدريب",
      msgError: isShowMsgError
        ? dataInputs.from === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "to",
      value: dataInputs.to || "",
      label: "تاريخ نهاية التدريب",
      placeholder: "تاريخ نهاية التدريب",
      msgError: isShowMsgError
        ? dataInputs.to === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div className="container p-3 p-md-5">
      <TimeLineBoxCardDarkGreen
        titleHeader={`ماهو ${propsTitle} ؟`}
        fs={"22px"}
        item={
          <div>
            <TimeLineWithTitleAndSubTitle
              title={propsTitle}
              subTitle={propsSubTitle}
              whatColor={"lightGreen"}
            />
          </div>
        }
      />

      {/* forms */}
      <div className="">
        <FormsContainer
          // title Head put becouse if the  print
          titleHead={<FormInputTitleHead title={propsTitle} fs={"30px"} />}
          item={
            <form className="row gx-5 gy-3 needs-validation">
              {arrDataInputs.map((item, i) => (
                <div className="col-lg-6" key={i}>
                  <FormInput
                    type={i === 5 || i === 6 ? "date" : "text"}
                    onChange={handelDataInputs}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                  />
                </div>
              ))}
              <div className="col-12 d-flex align-items-center ">
                <span className="">
                  <input
                    className="form-check-input border shadow-none"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        dataInputs.sign = true;
                      } else {
                        dataInputs.sign = false;
                      }
                    }}
                    id="sign"
                    name={"sign"}
                    value={dataInputs.sign}
                  />
                </span>{" "}
                <label htmlFor="sign" className="bg-dange fw-bold mx-3">
                  للتوقيع اضغط هنا
                </label>
                <div
                  className={` text-danger fw-bold my-2`}
                  style={{ fontSize: "12px" }}
                >
                  {isShowMsgError
                    ? dataInputs.sign === false
                      ? "الحقل مطلوب"
                      : ""
                    : ""}
                </div>
              </div>
            </form>
          }
        />
        <div className="col-12">
          <div className="d-flex justify-content-center">
            {isLoading ? (
              <ButtonLoading title={"جاري التنزيل"} width={"250px"} />
            ) : (
              <Button
                title={"أرسال"}
                width={"250px"}
                dataBsToggle="modal"
                dataBsTarget="#exampleModalForms-request-exception"
                type="submit"
              />
            )}
            <PopupAreYouSure
              id={"exampleModalForms-request-exception"}
              // onCLick={countTimeLineNow}
              onCLick={submitPDF}
              idPopupStar={"exampleModalForms-request-exception"}
              textAlign={"center"}
              title={`هل تريد  تنزيل نموذج : ${propsTitle} ؟`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsGuidanceContainer;
