/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { read, utils, writeFile } from "xlsx";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { getAllDepartment } from "../../../redux/actions/department/ActionDepartment";
import { insertExcelDepartment } from "../../../redux/actions/excel/ActionExcel";
import { getAllStudentWithoutPagination } from "../../../redux/actions/student/ActionStudentWithoutPagination";
const AdminExcelDepartmentLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllDepartment());
  }, []);

  // api sopervsior
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department
  );
  // api excel
  const responseExcel = useSelector((state) => state.rootExcel.excel);

  const [dataExcel, setDataExcel] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [sendFileName, setSendFileName] = useState(null);
  const [isLoadExcel, setIsLoadExcel] = useState(true);

  const handleImport = async ($event) => {
    const files = $event.target.files;
    let file = "";

    if (files.length) {
      file = files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // if (myDataDepartment) {
          //   // setDataExcel(myDataDepartment);
          // }
          // setDataExcel(rows);
          // NotificationsToast("done", null, "لحفظ البيانات اضغط على حفظ");
        }
      };
      reader.readAsArrayBuffer(file);
    }
    if (files && $event.target.files[0]) {
      setFileName(files[0].name);
    }

    if (myDataDepartment.length > 0) {
      NotificationsToast("warn", null, "الاقسام مسجلة سابقاً");
      return;
    }

    console.log(file);

    const formDate = new FormData();

    formDate.append("file", file);

    setIsLoadExcel(true);
    await myDispatch(insertExcelDepartment(formDate));
    setIsLoadExcel(false);

    console.log(file);
    setSendFileName(file);
  };

  useEffect(() => {
    if (isLoadExcel === false) {
      if (responseExcel) {
        // console.log(responseExcel);
        myDispatch(getAllStudentWithoutPagination());
      }
      if (responseExcel.status === 200) {
        if (responseExcel.data === "success") {
          NotificationsToast("done", null, "تم إضافة بيانات الاقسام");
        }
      } else {
        if (responseExcel.status !== 200) {
          NotificationsToast(
            "error",
            null,
            "هناك مشكلة في إضافة الاقسام أو الملف"
          );
        }
      }
      setIsLoadExcel(true);
    }
  }, [isLoadExcel]);

  const handleExport = () => {
    const headings = [["department_name", "collage_name"]];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, myDataDepartment && myDataDepartment, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "بيانات الأقسام.xlsx");
  };

  const handleExportStyleEmpty = () => {
    const headings = [["department_name", "collage_name"]];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, dataExcel, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "تنسيق الأعمدة.xlsx");
  };

  // console.log(myDataDepartment && myDataDepartment);
  return [handleExport, handleExportStyleEmpty, handleImport, fileName];
};

export default AdminExcelDepartmentLogic;
