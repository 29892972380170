import { DELETE_DEPARTMENT, GET_ERROR } from "../../types/Types";

const inital = {
  deleteDepartment: [],
  loading: true,
};

const reducerDeleteDepartment = (state = inital, action) => {
  switch (action.type) {
    case DELETE_DEPARTMENT:
      return {
        deleteDepartment: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        deleteDepartment: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducerDeleteDepartment;
