import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ERROR,
  GET_VISIT_NUMBER_BY_STUDENT_ID,
  INSERT_VISIT,
} from "../../types/Types";

const insertVisit = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`ivist`, dataParams);
    // console.log(response)
    dispatch({
      type: INSERT_VISIT,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getVisitNumberByStudentId = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_visit_number/${dataParams}`);
    // console.log(response)
    dispatch({
      type: GET_VISIT_NUMBER_BY_STUDENT_ID,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getVisitNumberByStudentId, insertVisit };
