/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import TimeLineBoxCardLightGreen from "../TimeLineBoxCardLightGreen";
import TimeLineWithTitleAndSubTitle from "../TimeLineWithTitleAndSubTitle";
import LoadingTimeLineDarkGreen from "../LoadingTimeLineDarkGreen";

const FollowUpDetailsInfoCompanyContainer = ({
  propsCompany,
  propsLaodingCompany,
}) => {
  const columnCompanyEn = [
    "company_name",
    "about",
    "email",
    "phone",
    "address",
    "Working_hours",
    "Fax",
    "Zip_code",
  ];

  const columnCompanyAr = [
    "أسم الجهة",
    "نبذة عن الجهة",
    "البريد الإلكتروني",
    "رقم الهاتف",
    "العنوان",
    "ساعات العمل",
    "فاكس",
    "الرمز البريدي",
  ];


  return (
    <TimeLineBoxCardLightGreen
      titleHeader={"معلومات الجهة "}
      fs={"22px"}
      item={
        <>
          {propsLaodingCompany ? (
            <LoadingTimeLineDarkGreen countPlaceholder={4} />
          ) : propsCompany ? (
            columnCompanyAr.map((item, i) => (
              <TimeLineWithTitleAndSubTitle
                key={i}
                title={item}
                subTitle={propsCompany[columnCompanyEn[i]]}
                whatColor={"darkGreen"}
                fw={"fw-bold"}
              />
            ))
          ) : (
            <TimeLineWithTitleAndSubTitle
              title={"لاتوجد بيانات"}
              whatColor={"darkGreen"}
            />
          )}
        </>
      }
    />
  );
};

export default FollowUpDetailsInfoCompanyContainer;
