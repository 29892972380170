import React from "react";
import FormInputRadio from "../duplicateComponents/FormInputRadio";

const FormsTriningProcessTableItem = ({ index, title, propsReview }) => {
  let arrInputRadio = [1, 2, 3, 4];
  return (
    <tr className="style-tr-without-border-bottom">
      {/* index */}
      <th>
        <div>{index}</div>
      </th>
      {/* title */}
      <td>
        <div style={{ width: "350px" }}>{title}</div>
      </td>
      {/* input radio */}
      {arrInputRadio.map((_, i) => (
        <td key={i}>
          <FormInputRadio
            name={`radioTriningProcess${index}`}
            idAndFor={`radioTriningProcess${index}`}
            onChange={(e) => {
              // console.log(e.target.checked);
              propsReview[index - 1] = parseInt(e.target.value);

              // console.log(propsReview);
            }}
            value={i + 1}
          />
        </td>
      ))}
      {/* <td>
        <FormInputRadio
          name={`radioTriningProcess${index}`}
          idAndFor={`radioTriningProcess${index}`}
        />
      </td>
      <td>
        <FormInputRadio
          name={`radioTriningProcess${index}`}
          idAndFor={`radioTriningProcess${index}`}
        />
      </td>
      <td>
        <FormInputRadio
          name={`radioTriningProcess${index}`}
          idAndFor={`radioTriningProcess${index}`}
        />
      </td> */}
    </tr>
  );
};

export default FormsTriningProcessTableItem;
