import React from "react";
import { Table, Progress } from "antd";
import TableLodaing from "../../../../duplicateComponents/TableLodaing";
const { Column } = Table;
const FollowUpGradesDetailsContainer = ({
  propsMyDataCompnay,
  propsLoading,
}) => {
  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "البنود",
      dataIndex: "title",
      key: "title",
      filteredValue: null,
    },
    {
      title: "درجة التقييم",
      dataIndex: "limitGrade",
      key: "limitGrade",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "الدرجة المستحقة",
      dataIndex: "val",
      key: "val",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "نسبة الانجاز",
      dataIndex: "total",
      key: "total",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <Progress
            type="circle"
            status="active"
            percent={((record.val * 100) / record.limitGrade).toFixed(0)}
            size={50}
            className="fw-bold m-0 p-0"
            strokeColor="var(--primary-color)"
          />
        );
      },
    },
  ];
  let dataHeaader = [
    {
      title: "تقييم جهة التدريب",
      limitGrade: 40,
      val: !isNaN(propsMyDataCompnay.training_provider)
        ? propsMyDataCompnay.training_provider === null
          ? 0
          : parseInt(propsMyDataCompnay.training_provider)
        : 0,
      i: 0,
    },
    {
      title: "تقييم جهة التدريب",
      val: !isNaN(propsMyDataCompnay.Training_supervisor_evaltion)
        ? propsMyDataCompnay.Training_supervisor_evaltion === null
          ? 0
          : parseInt(propsMyDataCompnay.Training_supervisor_evaltion)
        : 0,
      limitGrade: 30,
      i: 1,
    },
    {
      title: "تقييم جهة التدريب",
      val: !isNaN(propsMyDataCompnay.final_report_evaltion)
        ? propsMyDataCompnay.final_report_evaltion === null
          ? 0
          : parseInt(propsMyDataCompnay.final_report_evaltion)
        : 0,
      limitGrade: 30,
      i: 2,
    },
  ];
  return (
    <div className="">
      {propsLoading ? (
        <TableLodaing itemsColumns={dataTableColumns} />
      ) : (
        <Table
          pagination={false}
          size="middle"
          dataSource={dataHeaader}
          rowKey={"i"}
          className="style-scroll-radius style-shadow-large bg-white rounded-3"
        >
          {dataTableColumns.map((item, i) => (
            <Column
              title={item.title}
              dataIndex={item.dataIndex}
              key={item.key}
              filteredValue={item.filteredValue}
              onFilter={item.onFilter}
              render={item.render}
            />
          ))}
        </Table>
      )}
    </div>
  );
};

export default FollowUpGradesDetailsContainer;

// return (
//   <TableContainer
//     className={"my-5"}
//     thead={["#", "البنود", "درجة التقييم", "الدرجة المستحقة", "نسبة الانجاز"]}
//     item={
//       <>
//         {propsLoading ? (
//           <>
//             <FollowUpGradeTableCard
//               index={1}
//               title={
//                 <LoadingPlaceholderItem
//                   isSize={"lg"}
//                   styleBgColor={"var(--primary-color-opacity)"}
//                   col={"col-12 "}
//                 />
//               }
//               limitGrade={40}
//               finalTotal={0}
//             />

//             <FollowUpGradeTableCard
//               index={2}
//               title={
//                 <LoadingPlaceholderItem
//                   isSize={"lg"}
//                   styleBgColor={"var(--primary-color-opacity)"}
//                   col={"col-12 "}
//                 />
//               }
//               limitGrade={40}
//               finalTotal={0}
//             />
//             <FollowUpGradeTableCard
//               index={3}
//               title={
//                 <LoadingPlaceholderItem
//                   isSize={"lg"}
//                   styleBgColor={"var(--primary-color-opacity)"}
//                   col={"col-12 "}
//                 />
//               }
//               limitGrade={40}
//               finalTotal={0}
//             />
//           </>
//         ) : propsMyDataCompnay ? (
//           <>
//             <FollowUpGradeTableCard
//               index={1}
//               title={"تقييم جهة التدريب"}
//               limitGrade={40}
//               // grade={null}
//               finalTotal={
//                 !isNaN(propsMyDataCompnay.training_provider)
//                   ? propsMyDataCompnay.training_provider === null
//                     ? "-"
//                     : parseInt(propsMyDataCompnay.training_provider)
//                   : "-"
//               }
//             />
//             <FollowUpGradeTableCard
//               index={2}
//               title={"تقييم مشرف التدريب"}
//               limitGrade={30}
//               // grade={12}
//               finalTotal={
//                 !isNaN(propsMyDataCompnay.Training_supervisor_evaltion)
//                   ? propsMyDataCompnay.Training_supervisor_evaltion === null
//                     ? "-"
//                     : parseInt(
//                         propsMyDataCompnay.Training_supervisor_evaltion
//                       )
//                   : "-"
//               }
//             />
//             <FollowUpGradeTableCard
//               index={3}
//               title={"تقييم ومناقشة التقرير النهائي "}
//               limitGrade={30}
//               // grade={35}
//               finalTotal={
//                 !isNaN(propsMyDataCompnay.final_report_evaltion)
//                   ? propsMyDataCompnay.final_report_evaltion === null
//                     ? "-"
//                     : parseInt(propsMyDataCompnay.final_report_evaltion)
//                   : "-"
//               }
//             />
//           </>
//         ) : (
//           <>
//             <FollowUpGradeTableCard
//               index={1}
//               title={"تقييم جهة التدريب"}
//               limitGrade={40}
//               // grade={null}
//               finalTotal={"-"}
//             />
//             <FollowUpGradeTableCard
//               index={2}
//               title={"تقييم مشرف التدريب"}
//               limitGrade={30}
//               // grade={12}
//               finalTotal={"-"}
//             />
//             <FollowUpGradeTableCard
//               index={3}
//               title={"تقييم ومناقشة التقرير النهائي "}
//               limitGrade={30}
//               // grade={35}
//               finalTotal={"-"}
//             />
//           </>
//         )}
//       </>
//     }
//   />
