import React from "react";
import FormInput from "../../../duplicateComponents/FormInput";

const SopervisorAdditionGradesDetailsFinalReportItem = ({
  index,
  title,
  grade,
  dueGrade,
  value,
  indexId,
}) => {
  return (
    <>
      {indexId === 5 ? (
        <tr
          className="style-tr-without-border-bottom"
          style={{ backgroundColor: "var(--primary-color)" }}
        >
          <th className="p-3 text-white style-Breadcrumbs-fs">#</th>
          <th className="p-3 text-white style-Breadcrumbs-fs">
            عناصر مناقشة التقرير
          </th>
          <th className="p-3 text-white style-Breadcrumbs-fs">درجة التقييم</th>
          <th className="p-3 text-white style-Breadcrumbs-fs">
            الدرجة المستحقة
          </th>
        </tr>
      ) : null}
      <tr className="style-tr-without-border-bottom">
        {/* index */}
        <th>
          <div className="mt-2">{index}</div>
        </th>
        {/* title */}
        <td>
          <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowra">
            {title}
          </div>
        </td>
        <td>
          <div className="fw-bold text-dark text-opacity-75 mt-2 text-nowra">
            {grade}
          </div>
        </td>
        {/* <div>{title}</div> */}
        <td>
          <div style={{ width: "170px" }}>
            <FormInput
              type={"text"}
              onChange={dueGrade}
              value={value}
              isLabel={null}
              placeholder={"الدرجة المستحقة..."}
              idAndfor={"validationCustomIdNo"}
              isValid={"valid"}
              msgValid={"Gooooooooood !!"}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default SopervisorAdditionGradesDetailsFinalReportItem;
