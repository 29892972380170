import React from "react";
import ResetPasswordContainer from "../../components/auth/ResetPasswordContainer";

const ResetPasswordPage = () => {
  return (
    <div>
      <ResetPasswordContainer />
    </div>
  );
};

export default ResetPasswordPage;
