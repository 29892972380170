/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TableContainer from "../../../duplicateComponents/TableContainer";
// import FormInput from "../../../duplicateComponents/FormInput";
import SopervisorTitleWithNumber from "../../../duplicateComponents/TitleWithNumber";
import SopervisorAdditionGradesDetailsTriningProviderItem from "./SopervisorAdditionGradesDetailsFinalReportItem";
import Button from "../../../duplicateComponents/Button";
import SopervisorAdditionGradesDetailsTotalItem from "./SopervisorAdditionGradesDetailsTotalItem";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudent } from "../../../../redux/actions/student/ActionStudent";
import { insert40Grades } from "../../../../redux/actions/grades/ActionInsert40Grades";
import { insertGradesTotal } from "../../../../redux/actions/grades/ActionGradeTotal";

const SopervisorAdditionGradesDetailsTriningProvider = ({
  propsDataStudent,
}) => {
  const [valueTriningProvider, setValueTriningProvider] = useState("");
  const [arrvalueTriningProvider, setArrValueTriningProvider] = useState([]);

  // console.log(propsDataStudent);
  // const idRoute = useParams();
  const myDispatch = useDispatch();
  // useEffect(() => {
  //   myDispatch(getAllStudent());
  // }, []);

  // // api student
  // const myDataStudent = useSelector((state) => state.rootStudent.student.data);

  // api 40Grade
  const response40grade = useSelector(
    (state) => state.rootInsert40Grades.insert40grades
  );

  // api grade total
  const responseGradeTotal = useSelector(
    (state) => state.rootInsertGradesTotal.gradesTotal
  );

  // const response40grade = useSelector((state) => state.rootGrades.grades);
  // const [dataStudent, setDataStudent] = useState("");
  // useEffect(() => {
  //   if (myDataStudent) {
  //     setDataStudent(myDataStudent[idRoute.id]);
  //   }
  // }, [myDataStudent]);

  const [vala, seta] = useState("");
  const [valb, setb] = useState("");
  const [valc, setc] = useState("");
  const [vald, setd] = useState("");
  const [vale, sete] = useState("");
  const [valf, setf] = useState("");
  const [valg, setg] = useState("");
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);

  const handelA = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[0];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        seta(val);
      }
    }
  };

  const handelB = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[1];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setb(val);
      }
    }
  };
  const handelC = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[2];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setc(val);
      }
    }
  };

  const handelD = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[3];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setd(val);
      }
    }
  };
  const handelE = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[4];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        sete(val);
      }
    }
  };

  const handelF = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[5];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit

    if (!isNaN(val)) {
      if (val <= valLimit) {
        setf(val);
      }
    }
  };
  const handelG = (e) => {
    let val = e.target.value;
    let valLimit = dataTriningProviderMap[6];
    //##01## if  one only numbers
    //##02## if  must be a few valLimit
    if (!isNaN(val)) {
      if (val <= valLimit) {
        setg(val);
      }
    }
  };

  let arrHandel = [
    handelA,
    handelB,
    handelC,
    handelD,
    handelE,
    handelF,
    handelG,
  ];

  const [dataTriningProvider, setDataTriningProvider] = useState([
    {
      title: "الالتزام بساعات الدوام",
      grade: 7,
    },
    {
      title: "تقبل التوجيهات والارشاد",
      grade: 5,
    },
    {
      title: "المظهر الشخصي",
      grade: 5,
    },
    {
      title: "العلاقة مع زملاء العمل",
      grade: 5,
    },
    {
      title: "درجة اتقان العمل",
      grade: 10,
    },
    {
      title: "القدرة على عرض الافكار",
      grade: 4,
    },
    {
      title: "التعاون و المبادرة",
      grade: 4,
    },
  ]);

  let arrVal = [vala, valb, valc, vald, vale, valf, valg];

  let arrValNum = [
    parseInt(vala),
    parseInt(valb),
    parseInt(valc),
    parseInt(vald),
    parseInt(vale),
    parseInt(valf),
    parseInt(valg),
  ];
  // summistion all data
  let sumArrVal = arrValNum.reduce((one, two) => one + two);

  const submitAddTriningProvider = async (e) => {
    e.preventDefault();

    // check the val if empty => give me msg
    if (
      vala === "" ||
      valb === "" ||
      valc === "" ||
      vald === "" ||
      vale === "" ||
      valf === "" ||
      valg === ""
    ) {
      NotificationsToast("warn", null, "جميع الحقول مطلوبة");
      return;
    }

    // the total if not empty => give me msg
    // if (total !== null) {
    //   NotificationsToast("warn", null, `الدرجة مسجلة سابقاً`);
    //   return;
    // }

    // if summ > 40 => give me msg
    if (sumArrVal > dataTriningProviderReduce) {
      NotificationsToast(
        "warn",
        null,
        `الدرجات اعلى من ${dataTriningProviderReduce} يجب ان تكون اقل`
      );
      return;
    }
    setIsLoading(true);
    setIsLoad(true);
    await myDispatch(
      insert40Grades({
        student_id: propsDataStudent && propsDataStudent.student_id,
        training_provider: sumArrVal,
      })
    );

    await myDispatch(
      insertGradesTotal(propsDataStudent && propsDataStudent.student_id)
    );
    setIsLoad(false);
  };
  useEffect(() => {
    if (isLoad === false) {
      // if (response40grade) {
      //   console.log(response40grade);
      //   setIsLoading(false);
      // }
      // if (responseGradeTotal) {
      //   console.log("11111");
      //   console.log(responseGradeTotal);
      //   console.log("22222");
      //   setIsLoading(false);
      // }
      if (response40grade.status === 200) {
        // the check it is Done sem sem
        if (response40grade.data === "done") {
          NotificationsToast("done", null, "تم إدخال الدرجة للطالب");
          setTotal(sumArrVal);
          setIsLoading(false);
        } else if (response40grade.data === "already") {
          NotificationsToast("warn", null, `الدرجة مسجلة سابقاً`);
          setIsLoading(false);
        } else {
          if (response40grade.status !== 200) {
            NotificationsToast("error", null, `هناك مشكلة في إدخال الدرجة`);
            setIsLoading(false);
          }
        }
      }
      setIsLoad(true);
    }
  }, [isLoad]);

  // maping => [7, 5, 5, 5, 10, 4, 4]
  let dataTriningProviderMap = dataTriningProvider.map((item) => item.grade);
  //###################################################
  // summiation => value Limit (40)
  let dataTriningProviderReduce = dataTriningProviderMap.reduce(
    (a, b) => a + b
  );

  return (
    <div>
      <TableContainer
        className={"mt-3"}
        thead={["#", "القدرات", "درجة التقييم", "الدرجة المستحقة"]}
        item={
          <>
            {dataTriningProvider.map((item, i) => (
              <SopervisorAdditionGradesDetailsTriningProviderItem
                key={i}
                index={i + 1}
                title={item.title}
                grade={item.grade}
                dueGrade={arrHandel[i]}
                value={arrVal[i]}
              />
            ))}

            <SopervisorAdditionGradesDetailsTotalItem
              index={8}
              grade={dataTriningProviderReduce}
              totalGrade={total}
            />
          </>
        }
      />
      <div className=" mt-3 d-flex justify-content-center">
        {isLoading ? (
          <ButtonLoading title={"جاري إدخال الدرجة"} width={"180px"} />
        ) : (
          <Button
            title={"حساب الدرجات"}
            onclick={submitAddTriningProvider}
            width={"180px"}
          />
        )}
      </div>
    </div>
  );
};

export default SopervisorAdditionGradesDetailsTriningProvider;
