/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Taps from "../../duplicateComponents/Taps";
import AdminOrderStatus from "./AdminOrderStatus";
import AdminOrderApprovedOrDeclined from "./AdminOrderApprovedOrDeclined";

const AdminOrderContainer = () => {
  return (
    <Taps
      title={["الطلبات", "حالة الطلبات"]}
      charIdAndTarget={["a", "b"]}
      titleItem={[<AdminOrderApprovedOrDeclined />, <AdminOrderStatus />]}
    />
  );
};

export default AdminOrderContainer;

// <div>
//   <div className="col-12 col-lg-6 mb-3">
//     <SopervisorTitleWithNumber
//       title={"جميع الحالات"}
//       subTitle={"عدد حالة الطلبات"}
//       num={myDataSubmissitionlLastApp && myDataSubmissitionlLastAppTotal}
//     />
//     <FormInput
//       type={"search"}
//       onChange={handelQuery}
//       placeholder={"البحث بالأسم - الرقم التدريبي ..."}
//       idAndfor={"validationCustomFullName"}
//       isValid={"valid"}
//       msgValid={"Gooooooooood !!"}
//       shadow={"style-shadow-large-extra"}
//       isShowIcon={true}
//       icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
//     />
//   </div>
//   {loadingSubmissitionLastApp ? (
//     <TableContainer
//       thead={[
//         "#",
//         "اسم المتدرب",
//         "الرقم التدريبي",
//         "الكلية",
//         "القسم",
//         "التخصص",
//         "أسم الجهة",
//         "الحالة",
//       ]}
//       item={Array(4)
//         .fill(0)
//         .map((_, i) => (
//           <OrderUnAcceptedAndAcceptedItem
//             key={i}
//             index={i + 1}
//             name={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             stuedntId={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             collageName={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             major={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             companyName={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             specialty={
//               <LoadingPlaceholderItem
//                 col={"col-12 cl-lg-6"}
//                 styleBgColor={"var(--primary-color)"}
//                 isSize={"lg"}
//               />
//             }
//             status={null}
//           />
//         ))}
//     />
//   ) : (
//     <>
//       {myDataSubmissitionlLastApp.data ? (
//         Object.values(myDataSubmissitionlLastApp.data).length > 0 ? (
//           <>
//             <TableContainer
//               thead={[
//                 "#",
//                 "اسم المتدرب",
//                 "الرقم التدريبي",
//                 "الكلية",
//                 "القسم",
//                 "التخصص",
//                 "أسم الجهة",
//                 "القبول أو الرفض",
//               ]}
//               item={
//                 <>
//                   {Object.values(myDataSubmissitionlLastApp.data)
//                     .filter((item, i) => {
//                       if (query === "") {
//                         return item;
//                       } else if (
//                         item.name
//                           .toLocaleLowerCase()
//                           .includes(query.toLocaleLowerCase()) ||
//                         item.student_id.toString().includes(query)
//                       ) {
//                         return item;
//                       }
//                     })
//                     .map((item, i) => (
//                       <OrderUnAcceptedAndAcceptedItem
//                         key={i}
//                         index={i + 1}
//                         name={item.name === null ? "-" : item.name}
//                         stuedntId={
//                           item.student_id === null ? "-" : item.student_id
//                         }
//                         collageName={
//                           item.collage_name === null
//                             ? "-"
//                             : item.collage_name
//                         }
//                         major={
//                           item.Department === null ? "-" : item.Department
//                         }
//                         specialty={item.major === null ? "-" : item.major}
//                         companyName={
//                           item.company_name === null
//                             ? "-"
//                             : item.company_name
//                         }
//                         // status={12}
//                         status={parseInt(item.approved)}
//                       />
//                     ))}
//                 </>
//               }
//             />
//             {/*Start pagination */}
//             <div className="my-3 d-flex justify-content-center align-items-center col">
//               <Pagination
//                 propsCountPage={
//                   myDataSubmissitionlLastApp &&
//                   myDataSubmissitionlLastApp.last_page
//                 }
//                 onPress={getNowPage}
//               />
//             </div>
//             {/*End pagination */}
//           </>
//         ) : (
//           <AlertMsg msg={"لايوجد متدربين"} />
//         )
//       ) : (
//         <AlertMsg msg={"لايوجد متدربين"} />
//       )}
//     </>
//   )}
// </div>
