/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudentWithoutSopervisor } from "../../../redux/actions/student/ActionStudent";
import { getAllSopervisor } from "../../../redux/actions/sopervisor/ActionSopervisor";
import { NotificationsToast } from "../../../components/duplicateComponents/NotificationsToast";
import { insertRelationStudentWithSopervisor } from "../../../redux/actions/relation/ActionRelation";

const AdminRelationStudentWithSoperLogic = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudentWithoutSopervisor());
    myDispatch(getAllSopervisor());
  }, []);
  // api sutudent
  // const myDataStudent = useSelector((state) => state.rootStudent.student);
  const myDataStudentAll = useSelector(
    (state) => state.rootStudent.studentWithoutSopervisor
  );
  const loadingStudent = useSelector((state) => state.rootStudent.loading);
  // api sopervsior
  const myDataSopervsior = useSelector(
    (state) => state.rootSopervisor.sopervisor.data
  );
  const loadingSopervsior = useSelector(
    (state) => state.rootSopervisor.loading
  );

  const responseRelation = useSelector((state) => state.rootRelation.relation);

  // console.log(myDataSopervsior && myDataSopervsior);
  // for sopervisor

  const [SelectedMuiltySopervisor, setSelectedMuiltySopervisor] = useState("");
  let filteredOptions = [2];

  const [options, setOptions] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [arrCheckedBool, setArrCheckedBool] = useState(false);
  // for student
  const [arrChecked, setArrChecked] = useState([]);

  if (myDataStudentAll) {
    filteredOptions = myDataStudentAll.filter((o) => !arrChecked.includes(o));
  }
  // console.log(arrChecked);

  const fun = () => {
    setOptions(myDataSopervsior);
  };

  useEffect(() => {
    fun();
  }, [options]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedMuiltySopervisor(selectedItem.username_emp);
  };
  const onRemove = (selectedList, selectedItem) => {
    setSelectedMuiltySopervisor("");
  };

  // console.log(SelectedMuiltySopervisor);
  // console.log(arrChecked);
  const addSubmitRelation = async (e) => {
    e.preventDefault();

    if (SelectedMuiltySopervisor.length === 0) {
      NotificationsToast("warn", null, "يجب إختيار المشرف");
      return;
    }

    if (arrChecked.length === 0) {
      NotificationsToast("warn", null, "يجب إختيار المتدربين");
      return;
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      insertRelationStudentWithSopervisor({
        username_emp: SelectedMuiltySopervisor,
        students: arrChecked,
      })
    );
    setIsLoad(false);
  };
  // useEffect(() => {
  //   setArrChecked();
  // }, [arrChecked]);

  useEffect(() => {
    if (isLoad === false) {
      if (responseRelation) {
        // console.log(responseRelation);
        myDispatch(getAllStudentWithoutSopervisor());
        setIsLoading(false);
      }
      if (responseRelation.status === 200) {
        if (responseRelation.data === "done") {
          NotificationsToast("done", null, "تم إضافة المشرف للمتدربين");
          setIsLoading(false);
          return;
        } else {
          if (responseRelation.status !== 200) {
            NotificationsToast(
              "error",
              null,
              "هناك مشكلة في إضافة المشرف للمتدربين"
            );
            setIsLoading(false);
            return;
          }
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  // if (isLoad === false) {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 500);
  // }

  return [
    isLoading,
    onSelect,
    onRemove,
    myDataStudentAll,
    setArrCheckedBool,
    myDispatch,
    fun,
    addSubmitRelation,
    options,
    arrChecked,
    filteredOptions,
    setArrChecked,
  ];
};

export default AdminRelationStudentWithSoperLogic;
