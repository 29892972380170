import React from "react";
import PopupAreYouSure from "../../duplicateComponents/PopupAreYouSure";
import PopupDone from "../../duplicateComponents/PopupDone";
import AdminExcelSopervisorLogic from "../../../logic/admin/excel/AdminExcelSopervisorLogic";
import ComponentUploadDrog from "../../duplicateComponents/ComponentUploadDrog";
const AdminExcelSopervisorContainer = () => {
  const [handleExport, handleExportStyleEmpty, handleImport, fileName] =
    AdminExcelSopervisorLogic();
  return (
    <div>
      <ComponentUploadDrog
        handleImport={handleImport}
        handleExport={handleExport}
        handleExportStyleEmpty={handleExportStyleEmpty}
        fileName={fileName}
        titleUpload={"المشرفين"}
      />
      <PopupAreYouSure
        id={"exampleModalUploadExcel"}
        title={"انت متأكد من تزيل جميع بيانات المشرفين  في ملف Excel ؟"}
        textAlign={"center"}
        idPopupStar={"exampleModalUploadExcelDone"}
        onCLick={handleExport}
      />
      <PopupDone
        id={"exampleModalUploadExcelDone"}
        icon={
          "https://lottie.host/68a06230-c1b0-48b4-81b8-9e00cc1f9bbe/kcsKOGDnTG.json"
        }
        title={"تم التنزيل بنجاح"}
        subTitle={"تم تنزيل جميع بيانات المشرفين في ملف Excel"}
      />
    </div>
  );
};

export default AdminExcelSopervisorContainer;
