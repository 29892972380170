import React from "react";
// import { useState } from "react";
// import { FileUploader } from "react-drag-drop-files";
import FormInputTitleHead from "./FormInputTitleHead";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowDown,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
const ComponentUploadDrog = ({
  handleImport,
  handleExport,
  fileName,
  handleExportStyleEmpty,
  titleUpload,
}) => {
  return (
    <div
      className="card my-4 border-0 style-shadow-small p-3 p-md-4 "
      style={{ borderRadius: "var(--radius)" }}
    >
      <FormInputTitleHead
        title={`تنزيل وتصدير بيانات ${titleUpload}`}
        fs={"25px"}
        textAlign={"center"}
      />
      <div className="fs-6 fw-bold">خطوات التنزيل والتصدير</div>
      <ul className="mx-3">
        <li style={{ fontSize: 13 }}>
          يجب أن يكون تنسيق الاعمدة متطابق مع قاعدة البيانات
        </li>
        <li style={{ fontSize: 13 }}>
          لتغيير تنسيق الأعمدة يجب التواصل مع مدير النظام
        </li>
      </ul>
      <div className="my-3 d-flex align-items-cente justify-content-end justify-content-sm-between flex-column flex-sm-row">
        <div className="style-btn-upload-width my-1">
          <Button
            dataBsTarget={"#exampleModalUploadExcel"}
            dataBsToggle={"modal"}
            title={
              <div className="d-flex align-items-center justify-content-center">
                <div className="text-white">تنزيل {titleUpload}</div>
                <div className="mx-2">
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </div>
              </div>
            }
          />
        </div>
        <div className="style-btn-upload-width my-1">
          <Button
            onclick={handleExportStyleEmpty}
            title={
              <div className="d-flex align-items-center justify-content-center">
                <div className="text-white"> تنسيق الأعمدة</div>
                <div className="mx-2">
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className=" bg-white rounded-3 ">
        {/* <FileUploader
              // multiple={true}
              handleChange={handleChange}
              onSelect={handleImport}
              name="file"
              label={"إرفع ملف الاكسل هنا"}
              hoverTitle={"إرفق هنا"}
              // types={fileTypes}
              // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
        <div className="style-drop-drog">
          <div
            // onDrop={handleDrop}
            // onDragOver={handleDragOver}
            className="style-drop-drog"
            style={{
              width: "100%",
              height: "300px",
              border: "5px dashed var(--primary-color)",
              borderRadius: "5px",
              backgroundColor: "var(--primary-color-light)",
              fontSize: "20px",
            }}
          >
            <label htmlFor="fileUpload">
              <span>
                <FontAwesomeIcon
                  icon={faCloudArrowDown}
                  size="4x"
                  color="var(--primary-color)"
                />
              </span>
              <span className="text-center mt-3">
                اضغط على الصندوق لرفع ملف الاكسل{" "}
              </span>
              {fileName && (
                <span
                  className="mt-2 w-100"
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  <span className="fw-bold fs-6">أسم الملف: </span>{" "}
                  <span className="fs-6">{fileName}</span>
                </span>
              )}
            </label>
            <input
              type="file"
              id="fileUpload"
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
          <div className=" text-center p-1"></div>
        </div>
      </div>
      {/* <p className="text-center fw-bold my-2">
            {file ? `File name: ${file[0].name}` : "لايوجد ملف مرفق.."}
          </p> */}
    </div>
  );
};

export default ComponentUploadDrog;
