/* eslint-disable no-unused-vars */
import React from "react";
import SubscriptionsContainer from "../../components/Subscriptions/SubscriptionsContainer";
import FuncScrollToUp from "../../components/duplicateComponents/FuncScrollToUp";
import Footer from "../../components/homePage/Footer";
import HeaderHomePage from "../../components/homePage/HeaderHomePage";

const SubscriptionsPage = () => {
  FuncScrollToUp();

  return (
    <div>
      <div className="container p-3 p-md-5">
        <SubscriptionsContainer />
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionsPage;
