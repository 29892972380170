/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailPattern, role } from "../../../../redux/types/Types";
import { getAllMajor } from "../../../../redux/actions/major/ActionMajor";
import { getAllDepartment } from "../../../../redux/actions/department/ActionDepartment";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import {
  editStudent,
  getAllStudentPage,
  getAllStudentWithoutPagination,
  getAllStudentWithoutSopervisor,
} from "../../../../redux/actions/student/ActionStudent";
import FormsContainer from "../../../forms/FormsContainer";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import Multiselect from "multiselect-react-dropdown";
import FormInput from "../../../duplicateComponents/FormInput";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { getAllCollage } from "../../../../redux/actions/collage/ActionCollage";

const AdminStudentEditContainer = () => {
  const myDispatch = useDispatch();
  const idRoute = useParams();
  const [idParams, setIdParams] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  // const [studentId, setstudentId] = useState("");

  // api get all studnet
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  useEffect(() => {
    setIdParams(!isNaN(idRoute.id) ? (idRoute.id > 10 ? 0 : idRoute.id) : 0);

    myDispatch(getAllStudentPage(idRoute.page));
  }, []);

  const responseEditStudent = useSelector(
    (state) => state.rootStudent.editStudent
  );
  const myDataDepartment = useSelector(
    (state) => state.rootDepartment.department.data
  );
  const myDataMajor = useSelector((state) => state.rootMajor.major.data);
  const myDataCollage = useSelector((state) => state.rootCollage.collage.data);

  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isShowMsgEmail, setIsShowMsgEmail] = useState(false);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [selectedMajorName, setSelectedMajorName] = useState("");
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsMajor, setOptionsMajor] = useState([]);
  const [optionsCollage, setOptionsCollage] = useState([]);
  const [selectedCollageName, setSelectedCollageName] = useState("");

  const handelNumberInteger = (e) => {
    if (!isNaN(e.target.value)) {
      setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
    }
  };
  const handelNumberIntegerUsername = (e) => {
    if (!isNaN(e.target.value)) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        setDataInputs({ ...dataInputs, [e.target.name]: split.join("") });
      }
    }
  };
  const handelEmail = (e) => {
    const val = e.target.value;

    // Test the input email against the pattern (true or false)
    const isValid = emailPattern.test(val);

    setIsValidEmail(isValid);

    // if true email => hide msg email
    if (isValid) {
      setIsShowMsgEmail(false);
    } else {
      setIsShowMsgEmail(true);
    }

    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  const handelDataInputs = (e) => {
    setDataInputs({ ...dataInputs, [e.target.name]: e.target.value });
  };
  // start selcet dropdown
  // onSelect Collage
  const onSelectCollage = (selectedList, selectedItem) => {
    setSelectedCollageName(selectedItem.collage_name);
    setDataInputs({ ...dataInputs, collage_name: selectedItem.collage_name });
  };
  // onRemove Collage
  const onRemoveCollage = (selectedList, selectedItem) => {
    setSelectedCollageName("");
    setDataInputs({ ...dataInputs, collage_name: "" });
  };
  // onSelect Department
  const onSelectDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName(selectedItem.department_name);
    setDataInputs({ ...dataInputs, Department: selectedItem.department_name });
  };
  // onRemove Department
  const onRemoveDepartment = (selectedList, selectedItem) => {
    setSelectedDepartmentName("");
    setDataInputs({ ...dataInputs, Department: "" });
  };
  // onSelect Major
  const onSelectMajor = (selectedList, selectedItem) => {
    setSelectedMajorName(selectedItem.major_name);
    setDataInputs({ ...dataInputs, major: selectedItem.major_name });
  };
  // onRemove Major
  const onRemoveMajor = (selectedList, selectedItem) => {
    setSelectedMajorName("");
    setDataInputs({ ...dataInputs, major: "" });
  };
  // fun Collage
  const funCollage = () => {
    setOptionsCollage(myDataCollage);
  };
  // fun Department
  const funDepartment = () => {
    setOptionsDepartment(myDataDepartment);
  };
  // fun Major
  const funMajor = () => {
    setOptionsMajor(myDataMajor);
  };
  useEffect(() => {
    myDispatch(getAllCollage());
    myDispatch(getAllMajor());
    myDispatch(getAllDepartment());
  }, []);

  useEffect(() => {
    funCollage();
    funDepartment();
    funMajor();
  }, [optionsDepartment, optionsMajor, optionsCollage]);
  // end selcet dropdown

  const [dataInputs, setDataInputs] = useState({});

  // console.log(dataInputs);
  useEffect(() => {
    if (myDataStudent) {
      setDataInputs({
        name: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].name === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].name
        }`,
        student_id: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].student_id === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].student_id
        }`,
        collage_name: `${
          loadingStudent
            ? ""
            : myDataStudent &&
              myDataStudent.data[idParams].collage_name === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].collage_name
        }`,
        Department: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].Department === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].Department
        }`,
        major: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].major === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].major
        }`,
        GPA: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].GPA === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].GPA
        }`,
        phone: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].phone === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].phone
        }`,
        email: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].email === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].email
        }`,
        address: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].address === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].address
        }`,
        natioal_id: `${
          loadingStudent
            ? ""
            : myDataStudent && myDataStudent.data[idParams].natioal_id === null
            ? ""
            : myDataStudent && myDataStudent.data[idParams].natioal_id
        }`,
        type: role,
      });
    }
  }, [myDataStudent]);

  const submitInsertNewStudent = async (e) => {
    e.preventDefault();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      if (Object.entries(dataInputs)[index][1] === "") {
        setIsShowMsgError(true);
        NotificationsToast("warn", null, "جميع الحقول مطلوبة");
        if (!isValidEmail) {
          setIsShowMsgEmail(true);
        }
        return;
      }
    }
    if (!isValidEmail) {
      setIsShowMsgEmail(true);
      return;
    }

    const formData = new FormData();

    for (let index = 0; index < Object.entries(dataInputs).length; index++) {
      formData.append(
        Object.entries(dataInputs)[index][0],
        Object.entries(dataInputs)[index][1]
      );
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(editStudent(formData));
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseEditStudent) {
        myDispatch(getAllStudentPage(idRoute.page));
        myDispatch(getAllStudentWithoutPagination());
      }

      if (responseEditStudent.status === 200) {
        if (responseEditStudent.data === "done") {
          NotificationsToast("done", null, "تم تعديل بيانات المتدرب بنجاح");
          setIsLoading(false);
          return;
        }
        //  else if (responseEditStudent.data === "already") {
        //   NotificationsToast("warn", null, "المتدرب مسجل سابقاً");
        //   setIsLoading(false);
        //   return;
        // }
      } else {
        if (responseEditStudent.status !== 200) {
          NotificationsToast(
            "error",
            null,
            "هناك مشكلة في تعديل بيانات المتدرب"
          );
          setIsLoading(false);
          return;
        }
      }
      myDispatch(getAllStudentWithoutSopervisor());
      setIsLoad(true);
    }
  }, [isLoad]);

  const arrDataInputs = [
    {
      name: "name",
      value: dataInputs.name || "",
      label: "أسم المتدرب",
      placeholder: "أسم المتدرب",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "phone",
      value: dataInputs.phone || "",
      label: "الجوال",
      placeholder: "الجوال",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.phone === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "email",
      value: dataInputs.email || "",
      label: "البريد الكتروني",
      placeholder: "البريد الكتروني",
      note: "",
      formTypes: "input",
      action: handelEmail,
      msgError: isShowMsgEmail ? " البريد الكتروني غير صالح أو فارغ" : "",
    },
    {
      name: "collage_name",
      value: dataInputs.collage_name || "",
      label: "الكلية",
      placeholder: "الكلية",
      note: "",
      formTypes: "dropdown",

      msgError: isShowMsgError
        ? dataInputs.collage_name === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "Department",
      value: dataInputs.Department || "",
      label: "القسم",
      placeholder: "القسم",
      note: "",
      formTypes: "dropdown",

      msgError: isShowMsgError
        ? dataInputs.Department === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "major",
      value: dataInputs.major || "",
      label: "التخصص",
      placeholder: "التخصص",
      note: "",
      formTypes: "dropdown",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.major === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },

    {
      name: "GPA",
      value: dataInputs.GPA || "",
      label: "المعدل التراكمي",
      placeholder: "المعدل التراكمي",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.GPA === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "address",
      value: dataInputs.address || "",
      label: "العنوان",
      placeholder: "العنوان",
      note: "",
      formTypes: "input",
      action: handelDataInputs,
      msgError: isShowMsgError
        ? dataInputs.address === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
    {
      name: "natioal_id",
      value: dataInputs.natioal_id || "",
      label: "الهوية الشخصية",
      placeholder: "الهوية الشخصية",
      note: "",
      formTypes: "input",
      action: handelNumberInteger,
      msgError: isShowMsgError
        ? dataInputs.natioal_id === ""
          ? "الحقل مطلوب"
          : ""
        : "",
    },
  ];

  return (
    <div>
      <FormsContainer
        titleHead={
          <FormInputTitleHead
            title={
              <div className="d-flex align-items-center">
                <span>تعديل بيانات متدرب - </span>
                <span className="mx-1">
                  {loadingStudent ? (
                    <ReactLoading
                      type={"bubbles"}
                      color={"var(--primary-color)"}
                      width={40}
                      height={40}
                    />
                  ) : myDataStudent &&
                    myDataStudent.data[idParams].student_id === null ? (
                    ""
                  ) : (
                    myDataStudent && myDataStudent.data[idParams].student_id
                  )}
                </span>
              </div>
            }
            fs={"30px"}
          />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            {arrDataInputs.map((item, i) => (
              <div className="col-12 col-lg-6" key={i}>
                {item.formTypes === "dropdown" ? (
                  <>
                    <label
                      className="form-label fw-bold "
                      style={{ fontSize: "14px" }}
                    >
                      {item.label}
                    </label>
                    <div
                      className="style-scroll-radiu"
                      // here if click container MultipleSelected get all data skills
                      onClick={async () => {
                        if (item.name === "major") {
                          await myDispatch(getAllMajor());
                          funMajor();
                        } else if (item.name === "Department") {
                          await myDispatch(getAllDepartment());
                          funDepartment();
                        } else if (item.name === "collage_name") {
                          await myDispatch(getAllCollage());
                          funCollage();
                        }
                      }}
                    >
                      <Multiselect
                        options={
                          item.name === "major"
                            ? optionsMajor
                            : item.name === "Department"
                            ? optionsDepartment
                            : item.name === "collage_name"
                            ? optionsCollage
                            : []
                        } // Options to display in the dropdown
                        // options={myDataSkills ? Object.values(myDataSkills) : []} // Options to display in the dropdown
                        // selectedValues={Object.values(arrSelectedMuiltySkills)} // Preselected value to persist in dropdown
                        onSelect={
                          item.name === "major"
                            ? onSelectMajor
                            : item.name === "Department"
                            ? onSelectDepartment
                            : item.name === "collage_name"
                            ? onSelectCollage
                            : null
                        } // Function will trigger on select event
                        onRemove={
                          item.name === "major"
                            ? onRemoveMajor
                            : item.name === "Department"
                            ? onRemoveDepartment
                            : item.name === "collage_name"
                            ? onRemoveCollage
                            : null
                        } // Function will trigger on remove event
                        displayValue={
                          item.name === "major"
                            ? "major_name"
                            : item.name === "Department"
                            ? "department_name"
                            : item.name === "collage_name"
                            ? "collage_name"
                            : null
                        } // Property name to display in the dropdown options
                        placeholder={`اختار ${item.label}`}
                        className="text-center rounded-3"
                        showArrow={true}
                        singleSelect={true}
                        emptyRecordMsg="لاتوجد بيانات"
                      />
                    </div>
                    <div
                      className={` position-absolut text-danger fw-bold my-2`}
                      style={{ fontSize: "12px" }}
                    >
                      {item.msgError}
                    </div>
                  </>
                ) : (
                  <FormInput
                    type={"text"}
                    onChange={item.action}
                    name={item.name}
                    value={item.value}
                    label={item.label}
                    placeholder={item.placeholder}
                    msgError={item.msgError}
                    note={item.note}
                  />
                )}
              </div>
            ))}
          </form>
        }
      />

      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading
              title={"جاري تعديل بيانات المتدرب"}
              width={"250px"}
            />
          ) : (
            <Button
              title={"تعديل"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCompany"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCompany"}
            onCLick={submitInsertNewStudent}
            idPopupStar={"exampleModal_InsertNewCompany"}
            textAlign={"center"}
            title={`هل انت متأكد من تعديل بيانات المتدرب`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminStudentEditContainer;
