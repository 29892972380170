import React from "react";
import ButtonLink from "./ButtonLink";
import { PlusCircleFilled } from "@ant-design/icons";
const ButtonWithIcon = ({
  path,
  title = "إضافة جديد",
  icon = <PlusCircleFilled />,
//   width = "140px",
}) => {
  return (
    <ButtonLink
      path={path}
      title={
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-white mx-1">{title}</div>
          <div className="text-white d-flex fw-bold mx-2 align-items-center justify-content-center fs-6">
            {icon}
          </div>
        </div>
      }
      fs={"14px"}
    //   width={width}
    />
  );
};

export default ButtonWithIcon;
