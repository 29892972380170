/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import PopupAreYouSure from "../../../duplicateComponents/PopupAreYouSure";
import FormInput from "../../../duplicateComponents/FormInput";
import FormInputTitleHead from "../../../duplicateComponents/FormInputTitleHead";
import FormsContainer from "../../../forms/FormsContainer";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import { getAllStudentWithoutPagination } from "../../../../redux/actions/student/ActionStudentWithoutPagination";
import {
  getAllCollage,
  insertCollage,
} from "../../../../redux/actions/collage/ActionCollage";
import { role } from "../../../../redux/types/Types";
import { useDispatch, useSelector } from "react-redux";

const AdminCollageCreateContainer = () => {
  const myDispatch = useDispatch();
  const [isShowMsgError, setIsShowMsgError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [collageName, setCollageName] = useState("");

  const responseNewCollage = useSelector((state) => state.rootCollage.collage);

  const handelDataInputs = (e) => {
    if (e.target.value.length > 0) {
      setIsShowMsgError(false);
    }
    setCollageName(e.target.value);
  };

  const submitInsertNewCollage = async (e) => {
    e.preventDefault();

    if (collageName === "") {
      setIsShowMsgError(true);
      NotificationsToast("warn", null, "الحقل مطلوب");
      return;
    }

    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      insertCollage({
        collage: collageName,
        type: role,
      })
    );
    setIsLoad(false);
    setCollageName("");
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseNewCollage) {
        // console.log(responseNewCollage);
        myDispatch(getAllStudentWithoutPagination());
        myDispatch(getAllCollage());
        // setIsLoading(false);
      }

      if (responseNewCollage.status === 200) {
        if (responseNewCollage.data === "done") {
          NotificationsToast("done", null, "تم إضافة الكلية بنجاح");
          setIsLoading(false);
          return;
        } else if (responseNewCollage.data === "already") {
          NotificationsToast("warn", null, "الكلية مسجلة سابقاً");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseNewCollage.status !== 200) {
          NotificationsToast("error", null, "هناك مشكلة في إضافة الكلية");
          setIsLoading(false);
          return;
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  return (
    <div className="col-12 col-lg-6">
      <FormsContainer
        titleHead={
          <FormInputTitleHead title={"إضافة كلية جديدة"} fs={"30px"} />
        }
        item={
          <form className="row gx-5 gy-3 needs-validation">
            <div className="col-12">
              <FormInput
                type={"text"}
                onChange={handelDataInputs}
                value={collageName}
                label={"أسم الكلية"}
                placeholder={"أسم الكلية"}
                msgError={isShowMsgError ? "الحقل مطلوب" : ""}
              />
            </div>
          </form>
        }
      />
      <div className="col-12">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <ButtonLoading title={"جاري إضافة الكلية"} width={"250px"} />
          ) : (
            <Button
              title={"إرسال"}
              width={"250px"}
              dataBsToggle="modal"
              dataBsTarget="#exampleModal_InsertNewCollage"
              type="submit"
            />
          )}
          <PopupAreYouSure
            id={"exampleModal_InsertNewCollage"}
            onCLick={submitInsertNewCollage}
            idPopupStar={"exampleModal_InsertNewCollage"}
            textAlign={"center"}
            title={`هل انت متأكد من إضافة الكلية`}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCollageCreateContainer;
