import React from "react";

const Taps = ({ title = [], charIdAndTarget = [], titleItem = [] }) => {
  return (
    <div>
      <ul
        className=" nav nav-tabs style-scroll-radius pb-1 rounded "
        id="myTab"
        role="tablist"
      >
        {title.map((item, i) => (
          // <li className="nav-item" role="presentation">
          <button
            key={i}
            className={`nav-link fw-bold style-Breadcrumbs-fs text-nowrap text-end ${
              i === 0 ? "active" : null
            }`}
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target={`#${charIdAndTarget[i]}`}
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            {item}
          </button>
          // </li>
        ))}
      </ul>
      <div className="mt-4 tab-content" id="myTabContent">
        {titleItem.map((item, i) => (
          <div
            key={i}
            className={`tab-pane  fade show ${i === 0 ? "active" : null}`}
            id={charIdAndTarget[i]}
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex="0"
          >
            {item}
            {/* <FollowUpTaskCardContainer /> */}
          </div>
        ))}
      </div>
    </div>
  );
  // const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  // const onTab1Change = (key) => {
  //   setActiveTabKey1(key);
  // };
  // const onTab2Change = (key) => {
  //   setActiveTabKey2(key);
  // };
  // const contentListNoTitle = {
  //   article: <p>article content</p>,
  //   app: <p>app content</p>,
  //   project: <p>project content</p>,
  // };

  // const tabListNoTitle = [
  //   {
  //     key: "article",
  //     label: "article",
  //   },
  //   {
  //     key: "app",
  //     label: "app",
  //   },
  //   {
  //     key: "project",
  //     label: "project",
  //   },
  // ];

  // const first = [];

  // let gg = title.map((item) => first.push({ key: item, label: item }));
  // console.log(gg);
  // console.log(first);

  // const [activeTabKey2, setActiveTabKey2] = useState(first[0].key);
  // const onTab2Change2 = (key) => {
  //   console.log(key);
  //   setActiveTabKey2(key);
  // };

  // return (
  //   <Card
  //     style={{
  //       width: "100%",
  //     }}
  //     tabList={first}
  //     activeTabKey={activeTabKey2}
  //     tabBarExtraContent={<a href="#">More</a>}
  //     onTabChange={onTab2Change2}
  //   >
  //     {contentListNoTitle[activeTabKey2]}
  //   </Card>
  // );
};

export default Taps;
