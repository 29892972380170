import React from "react";
// import HeaderStudent from "../../../components/student/HeaderStudent";
import TimeLine from "../../../components/duplicateComponents/TimeLine";
// import SidebarPage from "../../sideBar/SidebarPage";
import JoinRequestFillForm from "../../../components/student/joinRequest/JoinRequestFillForm";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import { useSelector } from "react-redux";
import { useState } from "react";
import CheckRouteWhereYotGo from "../../../components/duplicateComponents/CheckRouteWhereYotGo";
import { useParams } from "react-router-dom";

const StudentJoinRequestFillFormPage = () => {
  const countTimeLine = useSelector((state) => state.rootForms.countTimeLine);
  const idRoute = useParams();

  // number timeline now
  const [Now, setNow] = useState(false);
  const countTimeLineNow = (e) => {
    setNow(e);
  };
  // Not History Back()
  CheckRouteWhereYotGo();
  // useEffect(() => {
  //   countTimeLineNow(true);
  // }, []);

  return (
    <div className="position-relative">
      {/* here all contant */}
      <div className="main">
        {/* BreadCumb Router */}
        <Breadcrumbs
          // main={"المتدرب"}
          // mainPath={"/student/status"}
          element={["طلب انضمام للتدريب"]}
        />
        <div className="container p-3 p-md-5">
          {/* TimeLine */}
          <TimeLine
            count={countTimeLine + Now}
            activeBool={[true, true, true, Now]}
            idRoute={idRoute.id}
          />
          <JoinRequestFillForm test={countTimeLineNow} />
        </div>
      </div>
    </div>
  );
};

export default StudentJoinRequestFillFormPage;
