import React from "react";
import Breadcrumbs from "../../../components/duplicateComponents/Breadcrumbs";
import SopervisorAdditionGradesContainer from "../../../components/sopervisor/addition/grades/SopervisorAdditionGradesContainer";

const SopervisorAdditionGradesPage = () => {
  return (
    <div className="position-relative">
      <div className="main">
        <Breadcrumbs element={["إضافات للمتدربين", "إضافة الدرجات"]} />
        <div className="container p-3 p-md-5">
          <SopervisorAdditionGradesContainer />
        </div>
      </div>
    </div>
  );
};

export default SopervisorAdditionGradesPage;
