import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  EDIT_COMPANY,
  GET_ALL_COMPANY,
  GET_ALL_COMPANY_PAGE,
  GET_COMPANY_BY_USERNAME,
  GET_ERROR,
  GET_STUDENT_INSIDE_COMPANY,
  GET_STUDENT_INSIDE_COMPANY_PAGE,
  GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN,
  INSERT_NEW_COMPANY,
} from "../../types/Types";

const getCompanyByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_company/${dataParams}`);
    dispatch({
      type: GET_COMPANY_BY_USERNAME,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertNewCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`insert_company`, dataParams);
    dispatch({
      type: INSERT_NEW_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const editCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData("update_companys", dataParams);
    dispatch({
      type: EDIT_COMPANY,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllCompany = () => async (dispatch) => {
  try {
    const response = await UseGetData("get_companys");
    dispatch({
      type: GET_ALL_COMPANY,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllCompanyPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(
      `get_companys?page=${dataParams}&limit=10`
    );

    dispatch({
      type: GET_ALL_COMPANY_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getStudentInsideCompany = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_student_comp/${dataParams}`);
    dispatch({
      type: GET_STUDENT_INSIDE_COMPANY,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

// without paginatiom
const getStudentInsideCompanyWithoutPagination =
  (dataParams) => async (dispatch) => {
    try {
      const response = await UseGetData(`get_student_comp2/${dataParams}`);
      dispatch({
        type: GET_STUDENT_INSIDE_COMPANY_WITHOUT_PAGINATOIN,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

const getStudentInsideCompanyPage =
  (dataParams, dataParamsPage) => async (dispatch) => {
    try {
      const response = await UseGetData(
        `get_student_comp/${dataParams}?page=${dataParamsPage}`
      );
      dispatch({
        type: GET_STUDENT_INSIDE_COMPANY_PAGE,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERROR,
        payload: err,
      });
    }
  };

export {
  getCompanyByUsername,
  insertNewCompany,
  getAllCompany,
  getAllCompanyPage,
  getStudentInsideCompany,
  getStudentInsideCompanyPage,
  getStudentInsideCompanyWithoutPagination,
  editCompany,
};
