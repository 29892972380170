import { UseCreateData } from "../../../hooks/UseCreateData";
import { UseGetData } from "../../../hooks/UseGetData";
import {
  GET_ALL_TASKS,
  GET_ALL_TASKS_PAGE,
  GET_ERROR,
  GET_TASKS_BY_USERNAME,
  INSERT_ATTACH_TASKS,
} from "../../types/Types";

const getAllTasks = () => async (dispatch) => {
  try {
    const response = await UseGetData(`getttasks`);
    dispatch({
      type: GET_ALL_TASKS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};
const getAllTasksPage = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`getttasks?page=${dataParams}`);
    dispatch({
      type: GET_ALL_TASKS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const getTasksByUsername = (dataParams) => async (dispatch) => {
  try {
    const response = await UseGetData(`get_tasks/${dataParams}`);
    dispatch({
      type: GET_TASKS_BY_USERNAME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

const insertAttachTasks = (dataParams) => async (dispatch) => {
  try {
    const response = await UseCreateData(`attach_tasks`, dataParams);
    dispatch({
      type: INSERT_ATTACH_TASKS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: GET_ERROR,
      payload: err,
    });
  }
};

export { getTasksByUsername, getAllTasksPage, getAllTasks, insertAttachTasks };
